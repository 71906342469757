
export const postDataPromise = ( hash, value ) => {

   return new Promise( ( resolve, reject ) => {

      return fetch( `https://portal.aeroflow.online/api/rest/v1/email-simple-responses`, {
         method: `POST`,
         headers: {
            'Content-Type': `application/json`
            // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         body: JSON.stringify({
            hash:hash,
            value: value
         })
      })
         .then( response => { return resolve( response.json() ) })
         .catch( err => { return reject( err ) })
   })
}