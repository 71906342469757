import React from 'react'
import PropTypes from 'prop-types'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { connect } from 'react-redux'
import { setUser } from 'modules/auth/actions'

// @NOTE This component has much in common with `src/components/Forms/ContactShippingSplitForm/ModalActionsBox.js`

export const EditContactInfoModalActionsBox = ( props ) => {
   const {
      hasInvalidFields,
      closeModal,
      handleSetUser
   } = props

   return (
      <MuiDialogActions>
         <div
            className="flex flex-row justify-around w-full my-5"
         >
            <button
               className="curated-btn-previous cursor-pointer hover:opacity-70"
               onClick={( e ) => {
                  e.preventDefault()
                  closeModal()
               }}
            >
               {`cancel`}
            </button>

            <button
               size="medium"
               className="curated-btn cursor-pointer hover:opacity-70"
               variant="contained"
               disabled={hasInvalidFields}
               onClick={handleSetUser}
            >
               {`Update`}
            </button>
         </div>
      </MuiDialogActions>
   )
}

EditContactInfoModalActionsBox.propTypes = {
   hasInvalidFields: PropTypes.bool,
   closeModal: PropTypes.func.isRequired,
   handleSetUser: PropTypes.func.isRequired
}

export const mapStateToProps = state => {
   return {
      user: state.auth.user
   }
}

export const mapDispatchToProps = dispatch => {
   return {
      handleSetUser: ( user ) => {

         return dispatch( setUser( user ) )
      }
   }
}

export default connect( mapStateToProps )( EditContactInfoModalActionsBox )