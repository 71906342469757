import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { hasQueryParamByKey } from 'utils/url'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import login_graphic from 'resources/img/login_graphic.png'
import Alert from 'modules/claims/components/Alert'
import { getQueryParam } from 'utils/getQueryParams'
import { postDataPromise } from './postDataPromise'

/* eslint-disable no-unused-vars */
const StyledYesGraphic = styled.img`
   max-width: 100%;
   object-fit: contain;
   width: 210.5px;
   height: 156px;
   align-self:center;
   @media (max-width: 768px) {
      height: 76.5;
      width: 105.25px;
   }
`

const green = `#09A603`
const red = `#D91604`
const black = `#0D0D0D`
const white = `#F2F2F2`
const tanRgb = `217,203,186`

const ltBlue = `#05AFF2`
const dkBlue = `#0583F2`

const funPurp = `#F230BF`

const LogoImg = styled.img`
   width: 300px;
`

const GreenBtn = styled.button`
   background-color: ${green};
   cursor: pointer;
   margin-bottom: 10px;
   font-weight: 700;
   text-transform: none;
   @media (max-width: 768px) {
      font-weight: 500;
      &.large{
         font-size: 20px;
         line-height: 24px;
      }
    }
`

const NoBtn = styled.button`
   background-color: ${dkBlue};
   color: ${white};
   cursor: pointer;
   margin: 20px 10px 10px 20px;
   padding: 20px;
   text-transform: none;
`

const MaybeBtn = styled.button`
   background-color: ${dkBlue};
   color: ${white};
   cursor: pointer;
   margin: 20px 10px 10px 20px;
   padding: 20px;
   text-transform: none;
`

const NotInterestedBox = styled.div`
   display: flex;
   flex-direction: row;
   margin-top: 65px;
   @media (max-width: 768px) {
      margin-top: 40px;
      flex-direction: column;
    }
`

const StyledPortalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  background-color: ${black};
  @media (max-width: 768px) {
   padding: 0 0 0 0;
 }
`

const YesBlock = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   padding: 0 0 35px 0;
   border-bottom: 1px solid ${ltBlue};
   @media (max-width: 768px) {
      flex-direction: column;
      justify-content: 
      align-items: center;
      padding: 0 0 15px 0;
    }
`

const YesButtonWithLinkBox = styled.div`
   align-self: center;
   padding: 20px;
   margin-left: 50px;
   @media (max-width: 768px) {
      margin-left: auto;
    }
`

const ContentBox = styled.div`
   width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 50px 10px 25px 10px;
    @media (max-width: 768px) {
      padding: 10px;
      width: 100%
    }
`

const FocusBox = styled.div`
   background-color: ${white};
   width: 90%;
   margin: 0 auto;
   border-radius: 2.5px;
   -webkit-box-shadow: 8px 9px 14px 1px rgba(${tanRgb},0.66); 
   box-shadow: 8px 9px 14px 1px rgba(${tanRgb},0.66);
`
const HeaderLogoBox = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 10px 35px 10px 45px;
   box-shadow: 2px 2px 2px 0 rgba(153, 153, 153, 0.5);
   border-radius: 0px 0px 0px 25px;
   background-color: #fff;
   @media(min-width: 768px){
      padding: 10px;
      box-shadow: none;
      border-radius: 0px;
      background-color: transparent;
      flex-direction: column;
   }
`
const HeaderBox = styled.div`
   background-color: ${red};
   color: ${white};
   width: 100%;
   margin: 20px 0px 12.5px 0px;
   padding: 15px 75px 10px 75px;
   font-weight: 700;
   font-size: 32px;
   text-align: center;
   border: 1px solid ${funPurp};
   @media (max-width: 768px) {
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      margin: 0px 0px 7.5px 0px;
    }
`

const StealthPrototype = props => {

   const [ urlHash, setUrlHash ] = useState( null )
   const [ showAlert, setShowAlert ] = useState( false )
   const [ userSelection, setUserSelection ] = useState( null )

   useEffect( () => {

      const hash = getQueryParam( `hash` )
      const value = getQueryParam( `value` )

      if ( hash && value ) {
         postDataPromise( hash, value )
            .then( res => {
               // console.log( `res`, res )
            })
            .catch( err => {
               // console.log( err )
            })
      }
   }, [] )

   const handleSetAlert = ( bool ) => {
      setShowAlert( bool )
   }

   const handleClick = ( e ) => {
      setUserSelection( e.target.value )
      handleSetAlert( true )
   }

   const prototype2 = true

   if ( prototype2 ) {
      return (
         <ContentBox>
            <p className="text-center">{`Thanks`}</p>
         </ContentBox>
      )
   }

   // alert needs url hash and value! let em close it
   return (
      <>
         <Alert
            open={showAlert}
            showAlert={handleSetAlert}
            ariaLabel="Website notification"
            ariaDesc="A mask selection is required."
            msgText={[ `Patient hash is ${urlHash}`, `Patient input is ${userSelection}` ]}
            btnText="OK"
         />
         <StyledPortalWrapper>
            <FocusBox>
               <HeaderBox>{`You are eligible for enrollment in Aeroflow's new One-Click Resupply program!`}</HeaderBox>
               <ContentBox>

                  <YesBlock>
                     <StyledYesGraphic src={login_graphic} />
                     <YesButtonWithLinkBox>
                        <GreenBtn
                           className="large"
                           value="yes"
                           onClick={handleClick}
                        ><PlaylistAddCheckIcon /> {`Yes, I would like to enroll in the Aeroflow One-Click Resupply program!`} </GreenBtn>
                        <p className="text-right"><a href="#">{`Click to learn details about One-Click Resupply`}</a></p>
                     </YesButtonWithLinkBox>
                  </YesBlock>

                  <NotInterestedBox>
                     <NoBtn
                        onClick={handleClick}
                        value="no"
                     >
                        {`No, I am not currently interested in One-Click Resupply. Please don't remind me again.`}
                     </NoBtn>
                     <MaybeBtn
                        onClick={handleClick}
                        value="maybe"
                     >
                        {`Not right now. I want to go thru the normal resupply process but I am open to learning more about One-Click Resupply in the future.`}
                     </MaybeBtn>
                  </NotInterestedBox>

               </ContentBox>
               <HeaderLogoBox><span></span></HeaderLogoBox>
            </FocusBox>

         </StyledPortalWrapper >
      </>
   )
}

export default StealthPrototype
