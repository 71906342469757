import React from 'react'
import PropTypes from 'prop-types'
import LaunchIcon from '@material-ui/icons/Launch'
import moment from 'moment'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

/* eslint-disable react/no-array-index-key */

const OrderItemMobile = ( props ) => {

   const { items } = props

   return (
      <div className="my-6 inline-flex flex-col justify-center max-w-md w-full sm:whitespace-nowrap border-0 border-t border-solid border-gray-500 pt-5">
         {items.map( ( item, i ) => {

            const { item_name, status, url, ship_date } = item

            const shipped = status === `Shipped`

            return (
               <div
                  key={i}
                  className="mb-8 mt-4 px-2 md:px-4 pt-2 sm:pt-4"
               >
                  <div className="flex flex-col sm:items-start">
                     <div className="flex items-center self-start sm:self-auto">
                        {
                           status === `Cancelled` ?
                              <CancelScheduleSendIcon htmlColor="red" />
                              :
                              <AssignmentTurnedInIcon htmlColor="green" />
                        }
                        <p className="bold text-sm md:text-base ml-3 text-black" >
                           {`${item_name}`}
                        </p>
                     </div>
                     <div className="flex flex-col w-11/12 sm:flex-row items-end sm:items-auto self-end sm:self-auto">
                        <div className={`flex py-2 sm:py-0 ${!shipped ? `w-full` : ``}`}>
                           <p className={`${shipped ? `green` : `error w-full border-0 border-b border-solid border-gray-500 border-gray-500 pb-10 flex justify-end`} pr-1 text-base`}>
                              {status}
                           </p>
                           <p className="text-base text-black ">{
                              !shipped ?
                                 ``
                                 :
                                 `on ` +
                           moment( ship_date ).format( `LL` )
                           }
                           </p>
                           {` `}
                        </div>
                        {shipped &&
                           <div className="w-full border-0 border-b border-solid border-gray-500">
                              <a href={url} target="blank" className="flex items-center justify-end mb-10 text-base">
                                 {`Track `}
                                 <LaunchIcon fontSize="small" />
                              </a>
                           </div>
                        }
                     </div>
                  </div>
               </div>
            )
         })}
      </div>
   )
}

OrderItemMobile.propTypes = {
   items: PropTypes.array
}

export default OrderItemMobile