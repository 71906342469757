import styled from 'styled-components'

export const StyledFormGroup = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   margin-top: 10px;
`

export const StyledField = styled.div`
   display: flex;
   flex-direction: column;
`

// export const StyledLabel = styled.label`
//    margin-bottom: 3px;
// `

// export const StyledDOBInput60w = styled.input`
//    width: 60px;
//    border: 1px solid #727475;
//    text-align: center;
//    padding: 8px;
// `

// export const StyledDOBInput100w = styled.input`
//    width: 100px;
//    border: 1px solid #727475;
//    text-align: center;
// `