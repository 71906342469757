import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Amplify, { Auth } from 'aws-amplify'
import { getUserSessionPwd, mapAttributesToLogin } from './utils'
import { submitUserLogin, setSessionLoading } from 'modules/auth/actions'
import { amplifyConfig } from './constants'
import { withRouter } from 'react-router-dom'
import { hasQueryParamByKey } from 'utils/url'

/* eslint-disable no-console */

class SessionManager extends Component {
   componentDidMount = () => {

      Amplify.configure( amplifyConfig )

      const {location: { search } } = this.props
      const searchHasHashKey = hasQueryParamByKey( search, [ `sgh`, `txt` ] )

      if ( searchHasHashKey ){
         return this.signOutOfIdentityServices()
      } else {
         this.props.setLoading( true )

         this.getCurrentAuthenticatedUser()
      }
   }
   getCurrentAuthenticatedUser = async ( ) => {

      // @NOTE if current session then we can auto login
      try {
         let currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
         if ( currentAuthenticatedUser ) {
            const { attributes: { birthdate, phone_number, email } } = currentAuthenticatedUser
            const loginPayload = mapAttributesToLogin( birthdate, phone_number, email )
            this.props.autoSubmitLogin( loginPayload )

         }
      } catch ( err ){
         // if no session they will just log in as normal
         this.props.setLoading( false )
      }

   }
   componentDidUpdate = ( prevProps ) => {
      // persistLogin is set on remember me checkbox checked on login form
      if ( !prevProps.persistLogin && this.props.persistLogin ) {
         this.initPersistentLoginSession()
      }
      // if a user logs out we need to clean up session artifacts
      if ( prevProps.isAuthenticated && !this.props.isAuthenticated ){
         this.signOutOfIdentityServices()
      }
   }
   signOutOfIdentityServices = () => {

      try {
         Auth.signOut()
         // even after auth sign out some user info persists in storage - we manually remove
         if ( localStorage.getItem( `session ` ) !== null ){
            localStorage.removeItem( `session` )
         }
         const localStorageKeys = Object.keys( localStorage )
         for ( let i = 0; i < localStorageKeys.length; i++ ){

            if ( localStorageKeys[i].indexOf( `CognitoIdentityServiceProvider` ) !== -1 ){
               localStorage.removeItem( localStorageKeys[i] )
            }
         }

      } catch ( err ){
         console.log( err )
      }

   }
   initPersistentLoginSession = async ( ) => {

      const { patient_dob, email, phone } = this.props.user
      const pwd = getUserSessionPwd( this.props.user )
      const _phone = phone ? `+1${phone.replace( /-/gi, `` )}` : ``

      const sessionLoginType = localStorage.getItem( `session` )
      let username
      if ( sessionLoginType === `phone` ){
         username = _phone
      }
      if ( sessionLoginType === `email` ){
         username = email
      }

      const signIn = async () => {
         try {
            const signIn = await Auth.signIn({
               password: pwd,
               username: username
            })

            if ( signIn ){
               console.log( `session sign in` )
            }

         } catch ( err ){
            console.log( err )
         }

      }

      try {
         const signUp = await Auth.signUp({
            username: username,
            password: pwd,
            attributes: {
               email: email,
               birthdate: patient_dob,
               phone_number: _phone
            }
         })

         if ( signUp ){
            signIn()
         }
      } catch ( err ) {

         if ( err.code === `UsernameExistsException` ){
            signIn()
         }
      }

   }

   render(){
      return (
         null
      )
   }
}

export const mapDispatchToProps = ( dispatch ) => {
   return {
      autoSubmitLogin: ( values ) => {

         return dispatch( submitUserLogin( values ) )
      },
      setLoading: ( bool ) => {

         return dispatch( setSessionLoading( bool ) )
      }
   }
}

export const mapStateToProps = state => {
   return {
      persistLogin: state.auth.persistLogin,
      user: state.auth.user,
      isAuthenticated: state.auth.isAuthenticated
   }
}

SessionManager.propTypes = {
   location: PropTypes.shape({
      search: PropTypes.string
   }),
   autoSubmitLogin: PropTypes.func,
   setLoading: PropTypes.func.isRequired,
   isAuthenticated: PropTypes.bool,
   persistLogin: PropTypes.bool,
   user: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
      patient_dob: PropTypes.string
   })
}

const ConnectedSessionManager = connect( mapStateToProps, mapDispatchToProps )( SessionManager )
export default withRouter( ConnectedSessionManager )