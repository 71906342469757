import * as types from './actionTypes'
import axios from 'axios'
import moment from 'moment'
import { resetClaims } from 'modules/claims/actions'
import { getRootUrlPath } from 'utils/url'
import { sendSessionStreamLoginFailEvent } from 'utils/session'
import { getPatientOrderHistory } from 'modules/mostRecentOrder/utils'

export const setUser = ( user ) => {
   return {
      type: types.SET_USER,
      user: user
   }
}

export const updateUser = ( key, val ) => {
   return {
      type: types.UPDATE_USER,
      key: key,
      val: val
   }
}

export const setPersistLogin = ( bool ) => {
   return {
      type: types.SET_PERSIST_LOGIN,
      val: bool
   }
}

export const authenticateUser = ( bool ) => {
   return {
      type: types.AUTHENTICATE_USER,
      isAuthenticated: bool
   }
}

export const setDisplayMessage = ( obj ) => {
   return {
      type: types.SET_DISPLAY_MESSAGE,
      displayMessage: obj
   }
}

export const setLoginError = ( str ) => {
   return {
      type: types.SET_LOGIN_ERROR,
      val: str
   }
}

export const setSessionLoading = ( bool ) => {
   return {
      type: types.SET_SESSION_LOADING,
      bool: bool
   }
}

export const resetAuth = () => {
   return {
      type: types.RESET_AUTH
   }
}

export const setUserSubmittedDoctor = ( dataObj ) => {
   return {
      type: types.SET_USER_SUBMITTED_DOCTOR,
      doctorData: dataObj
   }
}

export const setUserAuthorizePCustomerToken = ( str ) => {
   return {
      type: types.SET_USER_AUTHORIZE_P_CUSTOMER_TOKEN,
      str
   }
}

export const setAuthorizePMethods = ( collection ) => {
   return {
      type: types.SET_USER_AUTHORIZE_P_METHODS,
      collection
   }
}

export const setOrderHistory = ( orderHistory ) => {
   return {
      type: types.SET_ORDER_HISTORY,
      orderHistory: orderHistory
   }
}

export const setOrderHistoryError = ( bool ) => {
   return {
      type: types.SET_ORDER_HISTORY_ERROR,
      bool: bool
   }
}

export const submitUserLogin = ( credentials ) => {

   return async ( dispatch ) => {

      const { dob, email, phone, remember, patientHash, isUroCurated, profitCenterPK } = credentials

      const _dob = moment( dob ).format( `YYYY-MM-DD` )

      const payload = {}

      payload.dob = _dob

      if ( email ) {
         payload.email = email
      }
      if ( phone ) {
         payload.phone = phone
      }

      if ( patientHash ){
         payload.patient_hash = patientHash
      }

      if ( isUroCurated ){
         payload.isUroCurated = true
      }

      if ( profitCenterPK ){
         payload.profitCenterPK = profitCenterPK
      }

      const loginUrl = `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/deprecated-login `

      try {
         const loginData = await axios.post( loginUrl, payload )

         const { data } = loginData

         const storeData = Object.assign({}, data.data, {
            patient_dob: _dob,
            patientHash
         })

         dispatch( setUser( storeData ) )

         getPatientOrderHistory( storeData ).then( function({ status, data }){
            if ( status === 200 && data && data.data ){
               dispatch( setOrderHistory( data.data ) )
            } else {
               // if order history is empty we will set the error to true so that the order history will not be displayed
               dispatch( setOrderHistoryError( true ) )
            }
         })
            .catch( ( ) => {
               dispatch( setOrderHistoryError( true ) )
            })

         // @NOTE setPersistLogin updates auth reducer -
         // that will trigger session management if true in SessionManager component life cycle
         if ( remember ){
            let authKeyQs
            // const dobQs = `&dob=${_dob}`

            if ( email ) {
               authKeyQs = `email=${email}`
            }
            if ( phone ) {
               authKeyQs = `phone=${phone}`
            }
            localStorage.setItem( `session`, authKeyQs.slice( 0, 5 ) )
            dispatch( setPersistLogin( true ) )
         } else {
            dispatch( setPersistLogin( false ) )
         }

         dispatch( setSessionLoading( false ) )

         dispatch( authenticateUser( true ) )

      } catch ( err ) {

         dispatch( authenticateUser( false ) )
         dispatch( setSessionLoading( false ) )

         let errorMsg = ``
         const hasErrorFormat = ( err && err.response && err.response.data )

         if ( hasErrorFormat && err.response.data && err.response.data.errors.length > 0 ) {
            errorMsg += ` ${err.response.data.errors[0]}`
         }

         if ( !hasErrorFormat ) {
            errorMsg = `Login failed.`
         }

         dispatch( setLoginError( errorMsg ) )

         const date = new Date( )
         const yearDate = date.getFullYear()
         const dayDate = date.getDate()
         const hoursDate = date.getHours()
         const monthDate = date.getMonth() + 1
         const minutesDate = date.getMinutes()
         const secondsDate = date.getSeconds()

         const year = yearDate < 10 ? `0${yearDate}` : yearDate
         const day = dayDate < 10 ? `0${dayDate}` : dayDate
         const month = monthDate < 10 ? `0${monthDate}` : monthDate

         const hours = hoursDate < 10 ? `0${hoursDate}` : hoursDate
         const minutes = minutesDate < 10 ? `0${minutesDate}` : minutesDate
         const seconds = secondsDate < 10 ? `0${secondsDate}` : secondsDate

         const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

         const streamData = {
            date: time,
            type: `login`,
            email: credentials.email ? credentials.email : ``,
            phone: credentials.phone ? credentials.phone : ``,
            dob: _dob,
            baseUrl: window.location.href,
            customerId: 0
         }
         // capture data on this session err
         sendSessionStreamLoginFailEvent( streamData )

      }
   }
}

// logout user is a 1 off action to clear the redux store and send user to portal login page
export const logoutUser = ( history, sessionMessage ) => {
   return ( dispatch ) => {

      const rootPath = getRootUrlPath( window.location.pathname )

      sessionStorage.setItem( `logout_msg`, sessionMessage )
      dispatch( resetAuth() )
      dispatch( resetClaims() )
      // @note if not root path (i.e. for uro curated) we reload window on logout. Otherwise they go back to their portal home page login screen
      if ( rootPath ){
         return history.replace( `/${rootPath}` )
      }

      if ( !rootPath ){
         window.location.reload()
      }


   }
}