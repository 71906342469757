import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function DoctorSearchActionBlock({ searchResults, zipDisplay, setManualDisplay, handleBack, forceUpdate, doctor, searchError }) {

   return (
      <>
         {
            ( searchResults || searchError ) && !zipDisplay && (
               <div className="flex flex-col items-center">
                  <p className={`${searchResults?.length > 0 && `mt-5`} font-bold`}>{`Don't see your doctor?`}</p>
                  <p
                     className="mt-3 font-light underline text-midnight1 decoration-midnight1 cursor-pointer"
                     onClick={() => { setManualDisplay( true ) }}
                  >
                     {`Add your doctor manually`}
                  </p>
               </div>
            )
         }
         {
            doctor && !zipDisplay && !forceUpdate &&
            <div className="mt-10">
               <button
                  className="sleep-btn bg-teal2 border-none text-teal"
                  onClick={handleBack}
               >
                  {`Cancel`}
               </button>
            </div>
         }
      </>
   )
}

DoctorSearchActionBlock.propTypes = {
   searchResults: PropTypes.array,
   zipDisplay: PropTypes.bool,
   setManualDisplay: PropTypes.func,
   handleBack: PropTypes.func,
   forceUpdate: PropTypes.bool,
   doctor: PropTypes.object,
   searchError: PropTypes.string
}

const mapStateToProps = state => {
   return {
      doctor: state.doctor.doctor
   }
}

const ConnectedDoctorSearchActionBlock = connect( mapStateToProps, {})( DoctorSearchActionBlock )

export default withRouter( ConnectedDoctorSearchActionBlock )