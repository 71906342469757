import React from 'react'
import { getQueryParam } from 'utils/getQueryParams'
import { IoMdNotificationsOutline, IoIosAlert } from "react-icons/io"
import { PhoneLink } from 'components/AppShell/FooterLinks'

export default function PatientDeficiencieDisplay( deficiencies ) {

   const themeKey = getQueryParam( `theme` )


   if ( deficiencies?.length === 0 || !deficiencies ) return <p>{`No deficiencies`}</p>

   return (
      <div className="bg-white px-1 pt-1 pb-4 w-11/12 mt-4 md:w-1/2" id={themeKey ? themeKey : ``}>
         <div className="flex items-center justify-around bg-teal px-4 py-4 md:justify-center">
            <div>
               <IoMdNotificationsOutline className="text-4xl text-white md:mr-4" />
            </div>
            <div>
               <h1 className="text-lg text-white text-center ">{`Order Status Notifications`}</h1>
            </div>
         </div>
         <div className="p-2">
            {deficiencies?.deficiencies?.map( def => {
               return (
                  <div key={def?.pk}>
                     <div className="flex items-center">
                        <div className="mr-2">
                           <IoIosAlert className="text-4xl text-plum" />
                        </div>
                        <div>
                           <h1 className="text-base">{def?.message_name}</h1>
                        </div>
                     </div>
                     <p key={def?.pk}>{def?.message_text}</p>
                     <h1 className="text-base">{`Contact your Doctor`}</h1>
                     <p>{def?.md_name}</p>
                     <PhoneLink number={def?.md_phone} />
                  </div>
               )
            })}
         </div>
      </div>
   )
}