import React, {Fragment } from 'react'
import { connect } from 'react-redux'
import SuppliesListDesktop from './SuppliesListDesktop'
import SuppliesListMobile from './SuppliesListMobile'
import NoItemsFoundBlock from 'components/NoItemsFoundBlock'
import Loader, { SmallLoader } from 'components/Loader'
import Media from 'react-media'
import PropTypes from 'prop-types'
import { getEstimatedShippingDate } from 'utils/time'
import { setEstimatedShipDate } from '../actions'

const SuppliesList = ( props ) => {

   const { items, allowQuantityChanges, suppliesRequestStatus, type, orderHistory, orderHistoryError, sizeError, handleSetEstimatedShipDate, firstName, lastName } = props

   const isAddOnSupplyList = ( type === `addon` )
   const addOnItemsKeys = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === true })
   const regularItemsKeys = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === false })
   // to get latest can ship date we need to only pass items where item.eligible === `Immediately`

   const itemsByTypeKeys = isAddOnSupplyList ? addOnItemsKeys : regularItemsKeys

   const eligibleItemsKeys = itemsByTypeKeys.filter( itemKey => {
      return items[itemKey].eligibleMessage === `Immediately`
   })

   const latestCanShipDate = new Date( Math.max( ...Object.keys( eligibleItemsKeys ).map( itemKey => {
      return new Date( `${items[eligibleItemsKeys[itemKey]].Can_Ship_Date}T08:00:00-05:00` )

   }) ) )

   const estimatedShipDate = getEstimatedShippingDate( latestCanShipDate )

   handleSetEstimatedShipDate( estimatedShipDate )

   // loading
   if ( suppliesRequestStatus === `processing` ){
      return (
         <div className="flex justify-center"><Loader /></div>
      )
   }

   // if status is idle something went wrong, shouldn't be possible
   if ( suppliesRequestStatus === `idle` || !suppliesRequestStatus ){
      return null
   }

   // items returned
   if ( suppliesRequestStatus === `complete` ){
      const itemsAvailable = Boolean( itemsByTypeKeys && Object.keys( itemsByTypeKeys ).length )

      return (
         <>
            <div className="flex items-center justify-center text-lg sm:text-xl text-center my-4 md:my-6 bold">
               {`${firstName} ${lastName}'s Products`}
            </div>
            {
               ( !orderHistory || orderHistory.length === 0 ) && !orderHistoryError && !props.hasEligibleSupplies &&
               <div className="flex items-center justify-center m-auto">
                  <span className="my-40 md:my-12 pb-4">
                     <SmallLoader size={15} loadingMsg="Loading Most Recent Order...." />
                  </span>
               </div>
            }
            {
               itemsAvailable ?
                  (
                     <div>
                        <Media
                           queries={{
                              small: `(max-width: 599px)`,
                              medium: `(min-width: 600px)`
                           }}
                        >
                           {matches => {
                              return (
                                 <Fragment>

                                    {
                                       matches.small &&
                                          <SuppliesListMobile
                                             type={type}
                                             estimatedShipDate={estimatedShipDate}
                                             itemKeys={itemsByTypeKeys}
                                             allowQuantityChanges={allowQuantityChanges}
                                             sizeError={sizeError}
                                          />

                                    }
                                    {
                                       matches.medium &&
                                          <SuppliesListDesktop
                                             type={type}
                                             estimatedShipDate={estimatedShipDate}
                                             itemKeys={itemsByTypeKeys}
                                             allowQuantityChanges={allowQuantityChanges}
                                             sizeError={sizeError}
                                          />

                                    }
                                 </Fragment>
                              )
                           }}
                        </Media>
                     </div>
                  )
                  :
                  <>
                     <NoItemsFoundBlock />
                     <div className="px-10">
                     </div>
                  </>
            }
            {
               ( !orderHistory || orderHistory.length === 0 ) && !orderHistoryError && props.hasEligibleSupplies &&
               <div className="flex items-center justify-center m-auto">
                  <span className="my-8 sm:my-12 pt-3 sm:py-4">
                     <SmallLoader size={15} loadingMsg="Loading Recent Order History...." />
                  </span>
               </div>
            }
         </>
      )
   }
}

export const mapDispatchToProps = ( dispatch ) => {
   return {
      handleSetEstimatedShipDate: ( date ) => {
         return dispatch( setEstimatedShipDate( date ) )
      }
   }
}

const mapStateToProps = ( state ) => {
   return {
      items: state.claims.supplies,
      orderHistory: state.auth.orderHistory,
      orderHistoryError: state.auth.orderHistoryError
   }
}

SuppliesList.propTypes = {
   items: PropTypes.object,
   suppliesRequestStatus: PropTypes.string,
   allowQuantityChanges: PropTypes.bool
}

const ConnectedSuppliesList = connect( mapStateToProps, mapDispatchToProps )( SuppliesList )

export default ConnectedSuppliesList
