import React from 'react'
import PropTypes from 'prop-types'
import LocalShippingSharpIcon from '@material-ui/icons/LocalShippingSharp'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { getEstimatedShippingDate } from 'utils/time'

const ShippingMessageH6 = styled.h6`
   box-shadow: 3px 4px 6px 4px #aaa;
   font-size: 24px;
   padding: 20px;
   border: 3px solid green;
   border-radius: 5px;
   background-color: #97e63e26;
   margin-bottom: 24px;
   svg{
      margin: 0 auto;
      font-size: 50px;
      display: block;
   }
`

export const ShippingMessage = ( props ) => {

   const { order: {items }} = props

   // @NOTE order.items should only have eligible items

   const latestCanShipDate = new Date( Math.max( ...Object.keys( items ).map( itemKey => { return new Date( items[itemKey].Can_Ship_Date ) }) ) )


   const estimatedShipDate = getEstimatedShippingDate( latestCanShipDate )

   const canShipMessage = `The estimated shipping date for your order is ${estimatedShipDate}. Your order will be processed within 1 to 2 business days.`

   return (
      <div className="mt-12" >
         <ShippingMessageH6 className="h6 text-center">
            <LocalShippingSharpIcon />
            <strong>
               {canShipMessage}
            </strong>
         </ShippingMessageH6>
      </div>
   )
}

const mapStateToProps = ( state ) => {

   return {
      order: state.claims.order
   }
}


ShippingMessage.propTypes = {
   canShipDate: PropTypes.string.isRequired,
   order:PropTypes.object
}

export default connect( mapStateToProps )( ShippingMessage )