import React from 'react'
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'
import PropTypes from 'prop-types'
import OrderItemMobile from './OrderItemMobile'


function RecentOrderMobile({ mostRecentOrder }) {

   const { order_number, order_date, items } = mostRecentOrder.order

   return (
      <div>
         <div className="flex flex-col sm:flex-row items-center justify-center whitespace-nowrap">
            <span className="text-base sm:text-lg bold underlined">
               {`Order #: ${order_number}`}
            </span>
            <span className="text-xs md:text-sm flex items-center mt-1 sm:mt-0 sm:ml-3">
               <div className="flex items-center mr-1">
                  <EventIcon fontSize="small" />
               </div>
               {` ${moment( order_date ).format( `LL` )}`}
            </span>
         </div>
         <div className="flex flex-col items-center pb-2">
            <OrderItemMobile items={items} />
         </div>
      </div>
   )
}

RecentOrderMobile.propTypes = {
   mostRecentOrder: PropTypes.object
}

export default RecentOrderMobile
