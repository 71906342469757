import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import styled from 'styled-components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const StyledDialogActions = styled( DialogActions )`
   display: flex;
   flex-direction: column;
   button{
      margin: 8px;
   }
`
/* eslint-disable react/no-array-index-key */

export const Alert = ( props ) => {

   const {
      ariaLabel,
      open,
      toggleShowAlert,
      headerText,
      subText,
      ariaDesc,
      headerInfoIcon,
      msgText,
      buttonOneText,
      buttonOneFunctionCallback,
      buttonTwoFunctionCallback,
      buttonTwoText,
      disableBackdropClick,
      disableEscapeKeyDown
   } = props

   const hideAlert = ( ) => {
      return toggleShowAlert()
   }

   return (
      <Dialog
         open={open}
         onClose={hideAlert}
         aria-labelledby={ariaLabel}
         aria-describedby={ariaDesc}
         disableBackdropClick={disableBackdropClick}
         disableEscapeKeyDown={disableEscapeKeyDown}
      >
         <DialogContent>
            <DialogContentText
               className="text-center"
            >
               {headerInfoIcon &&
               <InfoOutlinedIcon
                  style={{
                     fontSize: 60,
                     color: `rgb(20, 63, 105)`
                  }}
               />
               }
               {headerText}
            </DialogContentText>

            {msgText.map( ( msg, i ) => {
               return (
                  <DialogContentText
                     key={i}
                  >
                     {msg}
                  </DialogContentText>
               )
            })}

            {subText &&
               <DialogContentText>
                  <span className="bold">{subText}</span>
               </DialogContentText>
            }

         </DialogContent>

         <StyledDialogActions>

            {buttonOneText &&
            <Button
               onClick={buttonOneFunctionCallback}
               style={{
                  backgroundColor: `#143F69`,
                  color: `#FFFFFF`,
                  width: `100%`
               }}
            >
               {buttonOneText}
            </Button>
            }

            { buttonTwoFunctionCallback && Boolean( buttonTwoText ) &&
            <Button
               onClick={buttonTwoFunctionCallback}
               style={{
                  backgroundColor: `#fff`,
                  color: `#143F69`,
                  width: `100%`
               }}
            >
               {buttonTwoText}
            </Button>
            }
         </StyledDialogActions>
      </Dialog>

   )
}

Alert.propTypes = {
   headerInfoIcon: PropTypes.bool,
   headerText: PropTypes.string,
   subText: PropTypes.string,
   open: PropTypes.bool.isRequired,
   toggleShowAlert: PropTypes.func.isRequired,
   ariaLabel: PropTypes.string,
   ariaDesc: PropTypes.string,
   msgText: PropTypes.array.isRequired,
   buttonOneText: PropTypes.string.isRequired,
   buttonOneFunctionCallback: PropTypes.string,
   buttonTwoFunctionCallback: PropTypes.func,
   buttonTwoText: PropTypes.string,
   disableBackdropClick: PropTypes.bool,
   disableEscapeKeyDown: PropTypes.bool
}

export default Alert