import React, { useEffect, useState } from 'react'
import AppShell from 'components/AppShell'
import theme from 'styles/theme'
import Loader from 'components/Loader'
import { getPatientOrderHistory } from "../mostRecentOrder/utils"
import OrderStatusDisplay from './components/orderStatusDisplay'
import { getPatientDeficiencies } from './utils/utils'
import { reportToSentry } from 'utils/reportToSentry'
import PatientDeficiencieDisplay from './components/patientDeficienciesDisplay'
import { getQueryParam } from 'utils/getQueryParams'


export const OrderStatus = () => {
   const [ orderHistory, setOrderHistory ] = useState( null )
   const [ showLoader, setShowLoader ] = useState( true )
   const [ showNoResults, setShowNoResults ] = useState( false )
   const [ deficiencies, setPatientDeficiencies ] = useState( [] )
   const currentQueryParams = new URLSearchParams(
      window.location.search
   )
   const themeKey = getQueryParam( `theme` )
   const hash = currentQueryParams.get( `sgh` ) || currentQueryParams.get( `txt` ) || currentQueryParams.get( `hash` )

   useEffect( () => {
      const user = {
         patient_id: ``,
         unique_hash: hash,
         theme: themeKey
      }
      getPatientOrderHistory( user ).then( data => {
         if ( data ) setOrderHistory( data.data.data )
         if ( data.data.data.length === 0 ) setShowNoResults( true )
         setShowLoader( false )
      })
         .catch( error => {
            console.error( error )
            setShowNoResults( true )
            setShowLoader( false )
         })
   }, [] )

   useEffect( () => {
      getPatientDeficiencies( hash ).then( data => {
         if ( data ) setPatientDeficiencies( data.data )
      })
         .catch( error => {
            console.error( error )
            reportToSentry( new Error( `Error in getPatientDeficiencies: ${error}` ) )
         })
   }, [ ] )



   if ( showNoResults || hash === null ) {
      return (
         <AppShell theme={theme}>
            <div className="mt-8 px-3 sm:p-0">{`An error occurred when loading your information. Please call us at 844-276-5588 so we can help troubleshoot.`}</div>
         </AppShell>
      )
   }


   if ( showLoader )
      return (
         <AppShell theme={theme}>
            <div className="mt-8"><Loader /></div>
         </AppShell> )

   return (
      <AppShell theme={theme}>
         { deficiencies?.length > 0 &&
            <PatientDeficiencieDisplay deficiencies={deficiencies} />
         }
         <OrderStatusDisplay orderHistory={orderHistory} themeKey={themeKey} />

      </AppShell>
   )
}


export default OrderStatus

