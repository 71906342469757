import React from 'react'
import PropTypes from 'prop-types'
import { getUniqueIdPrefix } from './utils'


/** Error handling works in two ways
 * a) pass a error function and message through props.validator to work on onChange
 * b) pass a the error message to be displayed through the prop error */
const TextInput = ({
   name,
   value,
   type = `text`,
   required = false,
   label,
   className = `input`,
   errorClassName = `input-error`,
   labelClassName = `input-label`,
   errorMessageClassName = `text-error px-3`,
   asteriskClassName = `font-semibold text-error pl-2`,
   errorMessage = ``,
   formatter,
   validator,
   reference,
   onChange,
   disabled = false,
   ...rest
}) => {
   const [ validationError, setValidationError ] = React.useState( errorMessage )
   const uniqueId = `${getUniqueIdPrefix()}-${name}`

   React.useEffect( () => {
      setValidationError( errorMessage )
   }, [ errorMessage ] )

   const onHandleChange = event => {
      if ( formatter?.function && !formatter?.onBlur ) {
         event.currentTarget.value = formatter.function( event.currentTarget.value )
      }

      if ( validator && validator.function && !validator.function( event.currentTarget.value ) ) {
         setValidationError( validator.failureMessage || `Invalid Entry` )
      } else {
         setValidationError( `` )
      }

      onChange( event )
   }

   const onHandleBlur = event => {
      if ( formatter?.function && formatter?.onBlur ) {
         event.currentTarget.value = formatter.function( event.currentTarget.value )
      }
   }

   // sets error, disabled, or normal className on input
   const inputClassName = ( validationError ) ? errorClassName : (
      ( disabled ) ? `input-disabled ${className}` : className
   )

   return (
      <div className="flex flex-col">
         {label && (
            <label htmlFor={uniqueId} className={labelClassName}>
               {label}
               {required && <span className={asteriskClassName}>{`*`}</span>}
            </label>
         )}

         <input
            id={uniqueId}
            name={name}
            type={type}
            value={value}
            onChange={onHandleChange}
            className={inputClassName}
            required={required}
            ref={reference}
            disabled={disabled}
            onBlur={onHandleBlur}
            {...rest}
         />

         {validationError && <p className={errorMessageClassName}>{validationError}</p>}
      </div>
   )
}
TextInput.propTypes = {
   name: PropTypes.string,
   value: PropTypes.string,
   onChange: PropTypes.func,
   type: PropTypes.string,
   required: PropTypes.bool,
   label: PropTypes.string,
   className: PropTypes.string,
   errorClassName: PropTypes.string,
   labelClassName: PropTypes.string,
   errorMessageClassName: PropTypes.string,
   asteriskClassName: PropTypes.string,
   errorMessage: PropTypes.string,
   formatter: PropTypes.shape({
      function: PropTypes.func,
      onBlur: PropTypes.bool
   }),
   validator: PropTypes.shape({
      function: PropTypes.func,
      failureMessage: PropTypes.string
   }),
   reference: PropTypes.any,
   disabled: PropTypes.bool
}

export default TextInput
