import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextInputHOC from 'components/Forms/components/TextInputHOC'
import { Dialog } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import EditContactInfoModalActionsBox from './EditContactInfoModalActionsBox'
import { setUser } from 'modules/auth/actions'
import { phoneNumberMask } from 'components/Forms/formatters.js'
import { validateField } from 'components/Forms/ContactShippingSplitForm/validateFieldHOC'

const EditContactInfo = ( props ) => {
   const { closeModal, open, phone, email, altPhone, handleSetUser, user } = props

   const [ updatedContactInfo, setUpdatedContactInfo ] = useState({})
   const [ formFieldErrors, setFormFieldErrors ] = useState({})
   const [ formError, setFormError ] = useState( `` )

   const changeFieldValToFormatted = ( key, formattedVal ) => {

      return setUpdatedContactInfo( Object.assign({}, updatedContactInfo, {
         [key]: formattedVal
      }) )
   }

   const addError = ( name, msg ) => {

      return setFormFieldErrors( Object.assign({}, formFieldErrors, {
         [name]: msg
      }) )
   }

   const errorChecker = ( name ) => {
      if ( formFieldErrors[name] ) {
         const newErrors = Object.assign({}, formFieldErrors )
         delete newErrors[name]
         setFormFieldErrors( newErrors )
      }
   }

   useEffect( () => {
      setUpdatedContactInfo({
         phone: phone || ``,
         altPhone: altPhone || ``,
         email: email || ``
      })
   }, [] )

   const handleInputChange = ( e ) => {
      errorChecker( e.target.name )

      // format phone!
      let formattedVal
      if ( e.target.name === `phone` || e.target.name === `altPhone` ) {
         formattedVal = phoneNumberMask( e.target.value )
      }

      const newContactInfo = Object.assign({}, updatedContactInfo, {
         [e.target.name]: formattedVal || e.target.value
      })

      return setUpdatedContactInfo( newContactInfo )
   }

   // when the form submit btn is clickes
   const handleUserUpdate = () => {

      const { phone, email, altPhone } = updatedContactInfo

      if ( Object.keys( formFieldErrors ).length ){
         return setFormError( `Please correct errors` )
      }

      const newUser = Object.assign({}, user, {
         email: email,
         phone: phone,
         alt_patient_phone: altPhone
      })

      handleSetUser( newUser )
      closeModal()
   }

   const handleValidation = ( e ) => {
      setFormError( `` )

      return validateField( e, changeFieldValToFormatted, addError )
   }

   return (
      <Dialog
         open={open}
         fullWidth
         onBackdropClick={closeModal}
      >
         <MuiDialogTitle
            id="customized-dialog-title"
         >
            <p className="text-teal text-xl">{`Edit your contact information`}</p>
         </MuiDialogTitle>

         <MuiDialogContent dividers>
            <TextInputHOC
               id="phone"
               name={`phone`}
               placeholder={`Phone`}
               handleChange={handleInputChange}
               label="Phone"
               inputType="tel"
               handleBlur={handleValidation}
               error={formFieldErrors.phone}
               value={updatedContactInfo.phone}
            />

            <TextInputHOC
               id="altPhone"
               name={`altPhone`}
               placeholder={`Alternate Phone`}
               handleChange={handleInputChange}
               label="Alternate Phone"
               handleBlur={handleValidation}
               error={formFieldErrors.altPhone}
               value={updatedContactInfo.altPhone}
               inputType="tel"
            />

            <TextInputHOC
               id="email"
               name={`email`}
               placeholder={`Email`}
               handleChange={handleInputChange}
               label="Email"
               value={updatedContactInfo.email}
               handleBlur={handleValidation}
               error={formFieldErrors.email}
            />

            {formError && <p className="text-center error">{formError}</p>}
         </MuiDialogContent>

         <EditContactInfoModalActionsBox
            closeModal={closeModal}
            handleSetUser={handleUserUpdate}
            hasInvalidFields={formError || Object.keys( formFieldErrors ).length}
         />

      </Dialog>
   )
}

export const mapStateToProps = state => {
   return {
      user: state.auth.user
   }
}

export const mapDispatchToProps = dispatch => {
   return {
      handleSetUser: ( user ) => {

         return dispatch( setUser( user ) )
      }
   }
}

EditContactInfo.propTypes = {
   closeModal: PropTypes.func.isRequired,
   open: PropTypes.bool,
   setDisplayMessage: PropTypes.func.isRequired,
   email: PropTypes.string,
   altPhone: PropTypes.string,
   phone: PropTypes.string,
   handleSetUser: PropTypes.func.isRequired,
   user: PropTypes.object
}

export default connect( mapStateToProps, mapDispatchToProps )( EditContactInfo )
