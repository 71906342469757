export const validators = new Map()
   .set( `confirm`, ( value, values ) => {
      return value !== values.password ? `Passwords must match.` : null
   })
   .set( `email`, value => {
      const trimmed = ( value || `` ).trim()

      if ( !trimmed ) return `An email address is required.`
      if ( !trimmed.includes( `@` ) ) return `A valid email address is required.`

      return null
   })
   .set( `firstName`, value => {
      return !( value || `` ).trim() ? `A first name is required.` : null
   })
   .set( `lastName`, value => {
      return !( value || `` ).trim() ? `A last name is required.` : null
   })
   .set( `phone`, value => {
      if ( !value || value.length < 10 ) {
         return `A phone number must contain 10 numeric characters`
      }

      return null
   })

export const validatePhone = ( value ) => {
   return !value || value.length < 12 ? `Please Enter a valid phone number` : undefined
}

export const validateMonth = ( value ) => {
   return !value || value.length !== 2 ? `Month Field must be 2 characters` : undefined
}

export const validateDay = ( value ) => {
   return !value || value.length !== 2 ? `Day Field must be 2 characters` : undefined
}

export const validateYear = ( value ) => {
   return !value || value.length !== 4 ? `Year Field must be 4 characters` : undefined
}

export const validateEmail = ( value ) => {
   return value && !( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i ).test( value ) ?
      `Invalid email address` : undefined
}

export const isValidEmail = ( value ) => {
   return ( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i ).test( value )
}

export const isValidPhone = ( value ) => {
   if ( !value ) {
      return true
   } else {
      return value.match( /^[2-9]\d{2}-\d{3}-\d{4}$/ )
   }
}

export const informedValidPhone = ( value ) => {
   return value && value.match( /^[2-9]\d{2}-\d{3}-\d{4}$/ ) ? undefined : `Please enter a valid phone number.`
}

export const FormatterController = {
   prefaceDigitWithZero: ( val ) => {
      return `0${val}`
   },
   phoneFormatter: ( val ) => {
      // trim out `(`, `)`, ` `, and `-`
      let _val = val.replace( /[- )(]/g, `` )
      // @NOTE if we want to check for all digits use _val.match( reg )
      // let reg = /^\d+$/
      // don't allow 1 as first number, strip
      if ( _val.length === 1 && _val === `1` ){
         return ``
      }

      const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
      const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
      const c = _val.substring( 6 ) ? `-${_val.substring( 6 )}` : ``

      // pattern `(888) 555-1212`
      return `${a}${b}${c}`

   },
   ccFormatter: ( val ) => {
      let reg = /[^\d]+/gi
      if ( val ) {
         let _strval = val.replace( reg, `` )
         let _val = _strval.replace( /-/g, `` )

         const _valLength = _val.length

         const isAmex = _val.startsWith( `37` ) || _val.startsWith( `34` )

         if ( isAmex ){
            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 10 )
            const c = _val.substring( 10, 15 )

            if ( _valLength > 10 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }

         if ( !isAmex ){

            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 8 )
            const c = _val.substring( 8, 12 )
            const d = _val.substring( 12, 16 )

            if ( _valLength > 12 ){
               return `${a}-${b}-${c}-${d}`
            }

            if ( _valLength > 8 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }
      }

      return ``
   }
}