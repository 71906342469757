import update from 'immutability-helper'

const INITIAL_STATE = {
   doctor: null,
   doctorInput : {
      zipCode: ``,
      range: 20,
      lastName: ``
   }
}

const doctor = ( state = INITIAL_STATE, action ) => {
   switch ( action.type ) {
   case `doctor/RESET_DOCTOR_INPUT`: {
      return update({
         doctorInput: {
            $set: INITIAL_STATE.doctorInput
         }
      })
   }
   case `doctor/SET_DOCTOR_INPUT`: {
      return update( state,
         {
            doctorInput: {
               $set: action.doctorInput
            }
         }
      )
   }
   case `doctor/SET_DOCTOR`: {
      return update( state,
         {
            doctor: {
               $set: action.doctor
            }
         }
      )
   }
   default: {
      return state
   }
   }
}

export default doctor