export const ranges = [ 1, 5, 10, 20, 50 ]

export const AF_SUPPORT_PHONE_NUMBER = `(866) 616-1687`

export const regexErrors = {
   first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   last_name: `Enter a valid last name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   name: `Enter a valid name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   phone: `Enter a valid phone number`,
   zip: `Zip code must contain 5 numbers`,
}