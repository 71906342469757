import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import AgreementCheckboxLabel from './AgreementCheckboxLabel'
import { StyledDiv } from './styled'

export const TermsCheckboxGroup = ( props ) => {

   const { onSetDisplayTerms, toggleAgreed, agreed } = props

   return (
      <StyledDiv agreed={agreed}>

         <FormControlLabel
            control={
               <Checkbox
                  disableRipple
                  color="primary"
                  checked={agreed}
                  onChange={toggleAgreed}
                  value={agreed}
               />}
            label={<AgreementCheckboxLabel setDisplayTerms={onSetDisplayTerms} />}
         />

      </StyledDiv>
   )
}

TermsCheckboxGroup.propTypes = {
   onSetDisplayTerms:PropTypes.func.isRequired,
   toggleAgreed: PropTypes.func.isRequired,
   agreed: PropTypes.bool.isRequired
}

export default TermsCheckboxGroup