import * as React from "react"
import { useTranslateStatus } from "../utils/useTranslateStatus"
import moment from "moment"
import LaunchIcon from '@material-ui/icons/Launch'
import { isValidUrl } from "../utils/utils"
import { Info } from "@material-ui/icons"
import { Modal } from "components/Modal"
import PropTypes from 'prop-types'
import { EmailLink, PhoneLink } from "components/AppShell/FooterLinks"
import { getQueryParam } from "utils/getQueryParams"

export default function ShippingInformation({ mostRecentOrder }) {

   const [ shipDate, setShipDate ]= React.useState( null )
   const [ dateOfService, setDateOfService ] = React.useState( null )
   const [ open, setOpen ] = React.useState( false )

   const themeKey = getQueryParam( `theme` )


   const handleTooltipClose = () => {
      setOpen( false )
   }

   const handleTooltipOpen = () => {
      setOpen( true )
   }


   React.useEffect( () => {
      if ( mostRecentOrder?.items[0].ship_date ) {
         setShipDate( moment( mostRecentOrder?.items[0].ship_date ).format( `L` ) )
      }

      if ( mostRecentOrder?.items[0].last_shipdate ) {
         setShipDate( moment( mostRecentOrder?.items[0].last_shipdate ).format( `L` ) )
      }

      if ( mostRecentOrder?.items[0].dos && translatedStatus.displayDos ) {
         setDateOfService( moment( mostRecentOrder?.items[0].dos ).format( `L` ) )
      }
   }, [ mostRecentOrder ] )

   const translatedStatus = useTranslateStatus( mostRecentOrder )

   if ( mostRecentOrder?.data?.items?.length === 0 ) return ( <div>{`No order found`}</div> )

   const isUrlValid = isValidUrl( mostRecentOrder?.items[0].url )


   return (
      <div className="bg-white md:px-12 px-4 py-4">
         <h1 className="text-lg">{`Shipping Information`}</h1>
         <div className="flex flex-col">
            <div className="flex justify-between mt-4">
               <div className="w-1/2 items-start">
                  <p className="font-bold">{`Status:`}</p>
               </div>
               <div className="w-1/2 items-start">
                  <p>{translatedStatus.status}
                     {
                        dateOfService && !shipDate ? ` ${dateOfService}` : ``
                     }
                  </p>
               </div>
            </div>
            {
               shipDate &&
            <div className="flex justify-between mt-4">
               <div className="w-1/2 items-start">
                  <p className="font-bold">{`Ship Date:`}</p>
               </div>
               <div className="w-1/2 items-start">
                  <p>{shipDate}</p>
               </div>
            </div>
            }
            {
               mostRecentOrder?.first_name && mostRecentOrder?.last_name &&
            <div className="flex justify-between mt-4">
               <div className="w-1/2 items-start">
                  <p className="font-bold">{`Shipping To:`}</p>
               </div>
               <div className="flex flex-col mb-4 w-1/2 items-start">
                  <div className="flex">
                     <p className="mr-2">{`${mostRecentOrder?.first_name} ${mostRecentOrder?.last_name}`}</p>
                  </div>
                  {mostRecentOrder?.street &&
                  <>
                     <div>
                        <p>{mostRecentOrder?.street}</p>
                     </div>
                     <div className="flex-col md:flex md:flex-row">
                        <div className="flex">
                           <p>{mostRecentOrder?.city}</p>
                           <p className="mx-2">{mostRecentOrder?.state}</p>
                        </div>
                        <p>{mostRecentOrder?.zip}</p>
                     </div>
                  </>
                  }
               </div>
            </div>
            }
            {
               translatedStatus.status != `Order cancelled` &&
                  <div className="flex justify-between mt-4">
                     <div className="w-1/2 items-start">
                        <p className="font-bold">{`Tracking:`}</p>
                     </div>
                     <div className="w-1/2 items-start">
                        {
                           isUrlValid ?
                              <a
                                 href={mostRecentOrder?.items[0].url} target="_blank" rel="noopener noreferrer"
                                 className="flex items-center text-base"
                              >
                                 {`Track `}
                                 <LaunchIcon fontSize="small" />
                              </a>
                              :
                              <p className="flex">{translatedStatus.message}
                                 {
                                    dateOfService ? ` ${dateOfService}` : ``
                                 }
                                 {
                                    translatedStatus.displayTootip &&
                                    <Info
                                       onClick={handleTooltipOpen} className="ml-2" fontSize="small"
                                    />
                                 }
                              </p>
                        }
                     </div>
                  </div>
            }
         </div>
         <Modal isOpen={open} handleModalClose={handleTooltipClose} className={`w-11/12`}>
            {
               `If you have additional questions please call us at `
            }
            {
               themeKey === `urology` ? <PhoneLink number={`844-276-5588`} /> : <PhoneLink number={`866-456-6755`} />
            }
            {
               ` or send us an email to `
            }
            {
               themeKey === `urology` ? <EmailLink email={`info@aeroflowurology.com`} /> : <EmailLink email="info@aeroflowdiabetes.com" />
            }
         </Modal>
      </div>
   )
}


ShippingInformation.propTypes = {
   mostRecentOrder: PropTypes.shape({
      data: PropTypes.shape({
         items: PropTypes.arrayOf(
            PropTypes.shape({
               ship_date: PropTypes.string,
               url: PropTypes.string,
               dos: PropTypes.string,
               last_shipdate: PropTypes.string
            })
         )
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      items: PropTypes.arrayOf(
         PropTypes.shape({
            ship_date: PropTypes.string,
            url: PropTypes.string,
            dos: PropTypes.string,
            last_shipdate: PropTypes.string
         })
      )
   })
}