import React from 'react'
import PropTypes from 'prop-types'

const SelectInput = ({
   name,
   value,
   onChange,
   type = `text`,
   required = false,
   label,
   className = `input`,
   labelClassName = `input-label`,
   children,
   error = ``,
   ...rest
}) => {
   return (
      <>
         {label && (
            <label htmlFor={name} className={labelClassName}>
               {label}
            </label>
         )}
         <select
            id={name}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className={className}
            required={required}
            {...rest}
         >
            {children}
         </select>
         {error && <p className="error small-text text-center">{error}</p>}
      </>
   )
}
SelectInput.propTypes = {
   name: PropTypes.string.isRequired,
   value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ).isRequired,
   onChange: PropTypes.func,
   type: PropTypes.string,
   required: PropTypes.bool,
   label: PropTypes.string,
   className: PropTypes.string,
   labelClassName: PropTypes.string,
   errorMessageClassName: PropTypes.string,
   children: PropTypes.node.isRequired,
   errorMessage: PropTypes.string,
   asteriskClassName: PropTypes.string,
   error: PropTypes.string
}

export default SelectInput
