import React from 'react'
import { moduleData } from 'constants/moduleData'
import OptOutForm from './components/OptOutForm'
import orderOnlineIllustration from 'resources/img/Order_Online_Illustration.svg'
import styled from 'styled-components'
import MobileWrapper from '../../components/Styled/MoblieWrapper'

const StyledH2 = styled.h2`
  color: ${props => { return props.isMobile ? `#4abce5` : `#000` }};
`

const MainWrapper = styled.div`
   margin: 0 auto;


   @media (min-width: 600px) {
      width: 800px;
      max-width: 1632px;
      display: flex;
      flex-direction: row;
      align-items: center;
   }
`

const OptOutWrapper = styled.div`
   @media (min-width: 600px) {
      width: 66%;
   }
`

const ModuleWrapper = styled.div`
   width: 100%;
   font-size: 0.75rem;
   font-weight: 400;
   line-height: 1.66;
   padding: 0 10px;

   @media (min-width: 600px) {
      width: 500px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: 60px;
      margin: 0 auto 60px;
      text-align: center;
   }
`
export const OptOut = () => {
   return (
      <div className="w-full">
         <MainWrapper>
            <div>
               <img
                  className="max-w-full"
                  src={orderOnlineIllustration}
                  alt="Order Online Illustration"
               />
            </div>

            <MobileWrapper>
               <StyledH2 className="mb-5">
                  {`Not ready for your supplies?`}
               </StyledH2>

               <p className="mb-9">
                  {`Please let us know when you'd like to be reminded of your supplies.`}
               </p>

               <OptOutWrapper>
                  <OptOutForm />
               </OptOutWrapper>
            </MobileWrapper>
         </MainWrapper>

         <ModuleWrapper>
            <div className="flex flex-row content-between">
               <span>{moduleData.urology.pfName}</span>
               <span className="px-2">{`|`}</span>
               <span >{moduleData.urology.pfPhone}</span>
               <span className="px-2">{`|`}</span>
               <span>{moduleData.urology.website}</span>
            </div>
         </ModuleWrapper>
      </div>
   )

}

export default OptOut