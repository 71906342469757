import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { getInternalZip } from '../utils/utils'

function useRetrieveZip( doctorInput, setDoctorInput ) {

   const [ loading, setLoading ] = useState( true )

   useEffect( () => {
      const searchParams = new URLSearchParams( window.location.search )
      const hash = searchParams.get( `sgh` ) || searchParams.get( `txt` )
      if ( hash ) {
         getInternalZip( hash )
            .then( ( zipData ) => {
               if ( zipData?.meta.status === `OK` ) setDoctorInput( Object.assign({}, doctorInput, {
                  zipCode: zipData.data.zip_code
               }) )
               else reportToSentry( new Error( `Doctor Block: Error getting patient zip from internal` ), {
                  hash: JSON.stringify( hash ),
                  response: JSON.stringify( zipData )
               })
               setLoading( false )
            })
            .catch( error => {
               setLoading( false )
               reportToSentry( new Error( `Doctor Block: Error getting patient zip from internal`, {
                  cause: error
               }), {
                  hash: JSON.stringify( hash )
               })
            })
      } else setLoading( false )

   }, [] )

   return loading
}

export default useRetrieveZip