import urology_logo from 'resources/img/urology_logo.svg'
// const defaultConfirmSectionHeadline = 'Shipping Address'

export const moduleData = {
   urology: {
      pfPk: 3,
      pfName: `Aeroflow Urology`,
      pfAddress: `65 Beale Rd, Arden, NC 28704`,
      pfPhone: `844-276-5588`,
      pfEmail: `info@aeroflowurology.com`,
      referralSourceId: 12960,
      specialistType: `Continence Care`,
      referralSourceName: `UROLOGY RESUPPLY PORTAL`,
      logo: urology_logo,
      website: `aeroflowurology.com`,
      confirmSectionContent: `Please confirm the shipping address below.  If needed, please make any edits to shipping address, phone number, or email address prior to submitting your order.`,
      claimSectionContent: `Select the free products you’d like to pre-order for next month. While you may place your order now, items may take up to 2 weeks to ship. If you need sizing or product changes, please let our Continence Care Specialists know by calling `,
      claimSectionChangesContent: `If there are any changes needed to product size or type, please call a specialist directly at 844.276.5588.  Thank you!`,
      introHeadline: `It's time to order your Continence Care Supplies`,
      introContent: ` Login with the information below to view your insurance-covered supplies, view or change your shipping address and confirm your order.`,
      failedLoginText: `We're sorry. We cannot find a patient with the provided combination. If you have any questions or concerns please call 844-276-5588 Monday - Friday, 8 a.m. to 6 p.m. EST`
   },
   cgm: {
      pfName: `Aeroflow Diabetes`,
      pfAddress: `65 Beale Rd, Arden, NC 28704`,
      pfEmail: `info@aeroflowdiabetes.com`,
      pfPhone: `(866)-456-6755`
   },
   home: {
      pfName: `Aeroflow`,
      pfAddress: `3165 Sweeten Creek Asheville, NC 28803`,
      pfPhone: `(888) 345-1780`
   }
}

export const getDisplayKey = ( path, theme = `` ) => {
   if ( path.indexOf( `urology` ) === 1 ) {
      return `urology`
   }
   if ( path.indexOf( `opt-out` ) === 1 ) {
      return `urology`
   }
   if ( path.indexOf( `track` ) === 1 ) {
      return `urology`
   }
   if ( path.indexOf( `continence-care-curated-shopping` ) === 1 ) {
      return `urology`
   }
   if ( path.indexOf( `doctor-lookup` ) === 1 ) {
      return `urology`
   }

   if ( theme === `urology` ) return `urology`
   if ( theme === `cgm` ) return `cgm`

   return `home`
}

export const footerInfo = {
   urology: [
      moduleData.urology.pfName,
      moduleData.urology.pfAddress,
      moduleData.urology.pfPhone,
      moduleData.urology.pfEmail
   ],
   cgm: [
      moduleData.cgm.pfName,
      moduleData.cgm.pfAddress,
      moduleData.cgm.pfPhone,
      moduleData.cgm.pfEmail
   ],
   home: [
      moduleData.home.pfName,
      moduleData.home.pfAddress
   ]
}