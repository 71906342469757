/**
 * @params collection {object|array}
 * @return boolean
 */

export const collectionHasItems = ( collection ) => {
   if ( Array.isArray( collection ) && !collection.length ) {

      return false
   } else if ( typeof collection === `object` && !Object.keys( collection ).length ) {

      return false
   } else if (
      ( Array.isArray( collection ) && collection.length ) ||
      ( typeof collection === `object` && Object.keys( collection ).length )
   ) {
      return true
   } else {
      return false
   }

}