import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Footer from './Footer'
import { footerInfo, getDisplayKey } from 'constants/moduleData'
import ModuleRouteInterceptor from 'routing/ModuleRouteInterceptor'
import { getQueryParam } from 'utils/getQueryParams'

const AppShell = props => {
   const { children, location: { pathname } } = props
   const themeKey = getQueryParam( `theme` )
   const displayKey = getDisplayKey( pathname, themeKey )

   return (
      <>
         <ModuleRouteInterceptor />
         {children}
         <Footer
            displayInfo={footerInfo[displayKey]}
            pathname={pathname}
         />
      </>
   )
}

AppShell.propTypes = {
   children: PropTypes.node,
   location: PropTypes.shape({
      pathname: PropTypes.string
   })
}

export default withRouter( AppShell )