import * as React from "react"
import PropTypes from 'prop-types'
import ProductDisplay from "./productDisplay"
import ShippingInformation from "./shippingInformation"
import { getQueryParam } from "utils/getQueryParams"
import { PhoneLink } from "components/AppShell/FooterLinks"

export default function OrderStatusDisplay({ orderHistory }) {

   const themeKey = getQueryParam( `theme` )

   let serializedOrderStatus = orderHistory

   if ( !themeKey || themeKey === `urology` && orderHistory.length > 0 ) {
      serializedOrderStatus = Object.assign({}, {
         "ordernum": orderHistory[0]?.order.order_number,
         "first_name": orderHistory[0]?.order.first_name,
         "last_name": orderHistory[0]?.order.last_name,
         "orderdate": orderHistory[0]?.order.orderdate,
         "street": orderHistory[0]?.order.street,
         "street2": ``,
         "city": orderHistory[0]?.order.city,
         "state": orderHistory[0]?.order.state,
         "zip": orderHistory[0]?.order.zip,
         "items": orderHistory[0]?.order.items
      })
   }

   // Output: { newKeyName: 'value', otherKey: 'other value' }
   if ( orderHistory.length === 0 ) return (
      <div className="mt-8 px-3 sm:p-0">
         {`An error occurred when loading your information. Please try refreshing your browser or call us at `}
         {
            <PhoneLink number={`844-276-5588`} />
         }
         {` so we can help troubleshoot.`}
      </div>
   )

   return (
      <div id={themeKey ? themeKey : ``}>
         <h1 className="text-center">{`Where’s My Order?`}</h1>
         <p className="w:full sm:w-3/4 text-center mb-4 mx-auto px-4 max-w-6xl text-black">
            {`Click the link(s) below to track your most recent order from ${themeKey === `urology` ? `Aeroflow Urology` : `Aeroflow Diabetes`}. Please note that some orders are shipped in multiple packages. If this is the case, you will see tracking details for each package.`}
         </p>
         <div className="flex flex-col items-center md:items-baseline md:justify-evenly md:flex-row" >
            <div className="md:w-1/2 w-11/12">
               <ShippingInformation mostRecentOrder={serializedOrderStatus} />
            </div>
            <div className="mt-4 md:w-1/3 w-11/12">
               <ProductDisplay mostRecentOrder={serializedOrderStatus} />
            </div>
         </div>

      </div>
   )
}

OrderStatusDisplay.propTypes = {
   mostRecentOrder: PropTypes.object,
   orderHistory: PropTypes.array
}
