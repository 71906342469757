
export const claimSuppliesInitialState = {
   address: ``,
   address2: ``,
   state: ``,
   city: ``,
   zip: ``,
   phone: ``,
   email: ``
}

export const noEligibleItemHeadline = `No items currently eligible for resupply.`
export const noEligibleItemsClaimSectionContent = `Please see below for your most recent order information and your next eligibility date!`

export const GLOVE_NO_SIZE_HCPCID = 21856

export const GLOVE_SIZE_OPTIONS = [
   {
      option: `Select Size`,
      value: `-`,
      hcpcid: GLOVE_NO_SIZE_HCPCID
   },
   {
      option: `Small`,
      value: `SM`,
      hcpcid: 23858
   },
   {
      option: `Medium`,
      value: `MD`,
      hcpcid: 23829
   },
   {
      option: `Large`,
      value: `LG`,
      hcpcid: 23798
   },
   {
      option: `XL`,
      value: `XL`,
      hcpcid: 23859
   }
]