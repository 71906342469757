import axios from 'axios'

async function getTrackingInfo( hash ) {
   const url = `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/order-tracking`

   return await axios.get( url, {
      headers:{
         'Authorization': `PatientHash ${hash}`
      }
   })
}


export {getTrackingInfo}