import React from 'react'
import styled from 'styled-components'

// export const TextWrapper = styled.div`
//    display: flex;
//    flex-direction: row;
//    justify-content: center;
// `

export const StyledDiv = styled.div`
   background-color: ${props => { return props.agreed ? `inherit` : `#fedede3b` }};
   border: ${props => { return props.agreed ? `inherit` : `2px solid #F44336` }};
   max-width: 420px;
   margin: 0 auto;
   padding: 15px 10px;
   span.MuiCheckbox-root:hover{
      background: none;
   }
   .MuiIconButton-label {
      background-color: white;
      margin-bottom: 75px;
   }
`