import { reportToSentry } from 'utils/reportToSentry'
export const updateUserAddress = async ( user ) => {

   if ( !user || !user.unique_hash || !user.street || !user.city || !user.state || !user.zip_code ) {
      reportToSentry( `updateUserAddress missing required user information`, {
         missingFields: Object.keys( user ).filter( ( key ) => {
            return !user[key]
         })
      })

      return Promise.reject( `not enough information` )
   }

   const url = process.env.NODE_ENV !== `production` ? `https://patients.aeroflow.ninja/update-address` : `https://patients.aeroflowapi.io/update-address`

   const requestInit = {
      headers: {
         Authorization: `PatientHash ${user.unique_hash}`
      },
      method: `post`,
      body: JSON.stringify({
         addressType: `shipping`,
         street1: user.street,
         city: user.city,
         state: user.state.toUpperCase(),
         zip: user.zip_code.slice( 0, 5 ) // trim zipCode to be just 5 digits
      })
   }
   const res = await fetch( url, requestInit ).catch( () => {
      return Promise.reject({
         status: `exception`
      })
   })

   if ( res.status === 200 ) {
      return {
         status: `success`
      }
   }

   // We did not get a success response
   return Promise.reject({
      status: `failed`
   })
}