import React from 'react'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'

export const PageNotFound = ( props ) => {
   return (
      <Container
         className="pt-12"
      >
         <div className="paper">
            <div className="p-8">
               <h2 className="text-3xl font-extrabold text-afBlue-dark text-center">
                  {`We're sorry. The requested page could not be found`}
               </h2>
            </div>
            <div className="p-8">
               <h6 className="af-h6 text-center">
                  <Link to="/">
                     {`Click to go to Aeroflow Resupply home.`}
                  </Link>
               </h6>
            </div>
         </div>
      </Container>
   )
}

export default PageNotFound