
import moment from 'moment'

const validOneDigitMonths = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
const validOneDigitDays = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
const validTwoDigitMonths = [ `01`, `02`, `03`, `04`, `05`, `06`, `07`, `08`, `09`, `10`, `11`, `12` ]

const regexPatterns = {
   zip: [{
      type: `generic`,
      regex: /^\d{5}$|^\d{5}-\d{4}$/
   }],
   phone: [{
      type: `generic`,
      regex: /^[2-9]\d{2}-\d{3}-\d{4}$/
   }],
   email: [{
      type: `generic`,
      regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i
   }],
   card_number: [
      {
         type: `visa`,
         regex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
      },
      {
         type: `mastercard`,
         regex: /^(?:5[1-5][0-9]{14})$/
      },
      {
         type: `amex`,
         regex: /^(?:3[47][0-9]{13})$/
      },
      {
         type: `discover`,
         regex: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
      }
   ],
   ccv: [
      {
         type: `generic`,
         regex: /^[0-9]{3,4}$/
      }
   ],
   first_name: [{
      type: `generic`,
      regex: /^[a-z\-'s]{2,}/
   }],
   last_name: [{
      type: `generic`,
      regex: /^[a-z\-'s]{2,}/
   }]
}

const regexErrors = {
   email: `Enter a valid email`,
   dd: `Enter a valid 1 or 2 digit day of month (1-31)`,
   card_number: `Enter a valid card number`,
   expmm: `Enter a valid 1 or 2 digit month (1-12)`,
   expyyyy: `Enter valid expiration year`,
   ccv: `Enter a valid ccv`,
   first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   last_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   phone: `Enter a valid phone number`,
   dob_yyyy: `Enter a 4 digit birth year (1999)`,
   zip: `Please enter a valid 5 or hyphen-separated 9 digit zip code ( i.e. 01230-2222 )`
}

// if any of the regex patterns match isValid will be true upon return
const applyRegexCollectionToVal = ( val, regexEntries ) => {

   let isValid = false

   for ( let pattern in regexEntries ){
      // valid if any matches
      if ( regexEntries[pattern].regex.test( val ) ){
         isValid = true
      }
   }

   return isValid
}

// @NOTE for ux reasons only format when it really would benefit the user - and during an onBlur NOT onChange :)
export const FormatterController = {
   prefaceDigitWithZero: ( val ) => {
      return `0${val}`
   },
   phoneFormatter: ( val ) => {
      let reg = /[^\d]+/
      if ( val ) {
         if ( val[0] === `1` ) {
            return null
         }
         let _val = val.replace( reg, `` )
         if ( _val.length > 3 && _val.length < 7 ) {
            if ( _val[3] !== `-` ) {
               _val = `${val.substring( 0, 3 )}-${_val.substring( 3 )}`
            }
         }
         if ( _val.length >= 7 ) {
            if ( _val[3] !== `-` && _val[7] !== `-` ){
               _val = _val.replace( reg, `` )
               _val = `${_val.substring( 0, 3 )}-${_val.substring( 3, 6 )}-${_val.substring( 6 )}`
            }

         }

         return _val
      }
   },
   ccFormatter: ( val ) => {
      let reg = /[^\d]+/gi
      if ( val ) {



         let _strval = val.replace( reg, `` )
         // take out hyphens
         // re-add hyphens
         let _val = _strval.replace( /-/g, `` )

         // divide into chunks
         const _valLength = _val.length

         const isAmex = _val.startsWith( `37` ) || _val.startsWith( `34` )

         if ( isAmex ){
            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 10 )
            const c = _val.substring( 10, 15 )

            if ( _valLength > 10 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }

         if ( !isAmex ){

            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 8 )
            const c = _val.substring( 8, 12 )
            const d = _val.substring( 12, 16 )

            if ( _valLength > 12 ){
               return `${a}-${b}-${c}-${d}`
            }

            if ( _valLength > 8 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }
      }
   }
}

export const validationNames = {
   "zip": `zip`,
   "email": `email`,
   "email-login-input": `email`,
   "altPhone": `phone`,
   "phone": `phone`,
   "phone-login-input": `phone`,
   "card_number": `card_number`,
   "expmm": `expmm`,
   "dd": `dd`,
   "dob-dd-input": `dd`,
   "dob-mm-input": `expmm`,
   "dob-yyyy-input": `dob_yyyy`,
   "expyyyy": `expyyyy`,
   "ccv": `ccv`,
   "first_name": `first_name`,
   "last_name": `last_name`
}

export const inputNameCast = ( name ) => {
   return validationNames[ name ]
}

// phone-login-radio
// email-login-radio
// dob-mm-input
// dob-dd-input
// dob-yyyy-input

// if validation passes but a formatter is required we pass back a special string `USE_FORMATTER_[val]`
export const ValidationController = {
   email: ( val /* setter*/ ) => {

      const valid = applyRegexCollectionToVal( val, regexPatterns.email )

      return valid ? `` : regexErrors.email
   },
   card_number: ( val ) => {
      const regex = /-/g
      const valSansHyphens = val.replace( regex, `` )
      const valid = applyRegexCollectionToVal( valSansHyphens, regexPatterns.card_number )

      return valid ? `` : regexErrors.card_number
   },
   expmm: ( val ) => {

      if ( validOneDigitMonths.includes( val ) ) {

         return `FORMATTER_USE_prefaceDigitWithZero`

      }

      return validTwoDigitMonths.includes( val ) ? `` : regexErrors.expmm

   },
   dd: ( val ) => {

      const isValidOneDigitDay = validOneDigitDays.includes( val )

      if ( isValidOneDigitDay ) {

         return `FORMATTER_USE_prefaceDigitWithZero`

      }

      return ( val > 0 && val < 32 ) ? `` : regexErrors.dd

   },
   dob_yyyy: ( val ) => {

      const thisYear = moment().format( `YYYY` )
      const minRange = moment().subtract( 123, `years` )
         .format( `YYYY` )

      return ( val > thisYear || val < minRange ) ? regexErrors.dob_yyyy : ``
   },
   expyyyy: ( val ) => {

      const thisYear = moment().format( `YYYY` )
      const maxRange = moment().add( 20, `years` )
         .format( `YYYY` )

      return ( val < thisYear || val > maxRange ) ? regexErrors.expyyyy : ``

   },
   ccv: ( val ) => {

      const valid = applyRegexCollectionToVal( val, regexPatterns.ccv )

      return valid ? `` : regexErrors.ccv
   },
   first_name: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.first_name )

      return valid ? `` : regexErrors.first_name
   },
   last_name: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.last_name )

      return valid ? `` : regexErrors.last_name
   },
   zip: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.zip )

      return valid ? `` : regexErrors.zip
   },
   phone: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.phone )

      return valid ? `` : regexErrors.phone
   }
}