import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TermsModal from './TermsModal'
import TermsCheckboxGroup from './TermsCheckboxGroup'
import OrderButton from 'components/OrderButton'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
// import { TextWrapper } from './styled'

export const AgreementBlock = props => {

   const { displayAgreeHelper, agreed, toggleAgreed, handleSubmit, addressConfirmed } = props

   const [ displayTerms, setDisplayTerms ] = useState( false )
   const [ buttonIsDisabled, setButtonIsDisabled ] = useState( false )

   const handleOrderClick = () => {
      if ( agreed && addressConfirmed ) setButtonIsDisabled( true )

      handleSubmit()
   }

   useEffect( () => {
      setButtonIsDisabled( false )
   }, [] )


   return (
      <React.Fragment>

         <TermsModal
            open={displayTerms}
            closeModal={() => {
               return setDisplayTerms( false )
            }}
         />

         <div className="flex flex-row justify-center items-center text-afBlue-dark">

            {displayAgreeHelper &&
            <ReportProblemOutlinedIcon
               className="mr-5 warning"
            />
            }

            <p>
               {`Please check the box below to continue`}
            </p>

         </div>

         <TermsCheckboxGroup
            toggleAgreed={toggleAgreed}
            agreed={agreed}
            onSetDisplayTerms={() => { setDisplayTerms( !displayTerms ) }}
         />

         <p className="text-center vertical-middle help-text p-4">
            {`Before placing your order, please confirm that all contact information is correct.`}
         </p>

         <div className="text-center">
            <OrderButton
               muted={!agreed}
               buttonIsDisabled={buttonIsDisabled}
               onClick={handleOrderClick}
            >
               {`Place Order`}
            </OrderButton>
         </div>

      </React.Fragment>
   )
}

AgreementBlock.propTypes = {
   displayAgreeHelper: PropTypes.bool.isRequired,
   agreed: PropTypes.bool.isRequired,
   toggleAgreed: PropTypes.func.isRequired,
   handleSubmit: PropTypes.func.isRequired,
   addressConfirmed: PropTypes.bool
}

export default AgreementBlock