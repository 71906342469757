import { BASE_URL } from 'constants/urls'

export const trackNoItemsFoundAnalytic = () => {

   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `noItemsFound`
      })
   }
}

export const trackPageRefreshedAnalytic = () => {
   // eslint-disable-next-line no-console
   console.log( window.location.pathname )
   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `pageRefreshed`
      })
   }
}

export const trackNoEligibleItemsAnalytic = () => {

   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `noEligibleItemsFound`
      })
   }
}

export const packageTrackingLinkClick = () => {

   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `packageTrackingLinkClick`
      })
   }
}

export const trackDontAllowAloneAnalytic = () => {

   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `allowAlonePopUpFired`
      })
   }
}

export const trackCuratedAddressHasSpace = () => {
   if ( window.location.origin === BASE_URL ) {
      window.setDataLayer({
         'event': `curatedAddressHasSpace`
      })
   }
}