import React, { useState, useRef } from 'react'
import SelectInput from 'components/Forms/components/SelectInput'
import TextInput from 'components/Forms/components/TextInput'
import { ranges, regexErrors } from '../utils/constants'
import { Formatters, Validators } from '../utils/utils'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setDoctorInput } from '../actions'
import PropTypes from 'prop-types'

function LocationChangeForm({ setZipDisplay, doctorInput, setDoctorInput }) {

   const [ editedDoctorInput, setEditedDoctorInput ] = useState({
      range: doctorInput.range,
      zipCode: doctorInput.zipCode
   })

   const [ zipError, setZipError ] = useState( `` )
   const zipRef = useRef( null )


   const handleChange = ( e ) => {
      setZipError( `` )
      setEditedDoctorInput( Object.assign({}, editedDoctorInput, {
         [e.target.name]: e.target.value
      }) )
   }

   const onSave = () => {
      if ( !editedDoctorInput?.zipCode?.length ) {
         setZipError( regexErrors.zip )

         return zipRef?.current?.focus()
      } else if ( zipError ) return zipRef?.current?.focus()
      setDoctorInput( Object.assign({}, doctorInput, editedDoctorInput ) )
      setZipDisplay( false )
   }

   const onBlur = ( e ) => {
      if ( !Validators.zipCode( e.target.value ) ) return setZipError( regexErrors.zip )
   }

   return (
      <div className="flex flex-col gap-1">
         <div className="styled-select-arrow-container">
            <SelectInput
               name="range"
               label="Location"
               type="select"
               className="sleep-input"
               value={editedDoctorInput.range}
               onChange={handleChange}
            >
               {
                  ranges.map( range => {
                     return <option key={range} value={range}>{`Within ${range} mile${ range > 1 ? `s` : ``}`}</option>
                  })
               }
            </SelectInput>
         </div>
         <TextInput
            name="zipCode"
            value={editedDoctorInput.zipCode}
            label="Zip Code"
            reference={zipRef}
            required
            asteriskClassName="text-requiredRed"
            errorClassName="sleep-input border-requiredRed"
            errorMessageClassName="max-w-xs text-requiredRed text-center text-sm font-light mx-auto"
            className="sleep-input"
            onChange={handleChange}
            formatter={{
               function: Formatters.zipCode
            }}
            onBlur={onBlur}
            errorMessage={zipError}
         />
         <div className="mt-5">
            <button
               className="sleep-btn bg-teal"
               onClick={onSave}
            >
               {`Submit`}
            </button>
            {
               doctorInput.zipCode &&
          <button
             className="sleep-btn bg-teal2 border-none text-teal"
             onClick={() => { setZipDisplay( false ) }}
          >
             {`Back`}
          </button>
            }
         </div>
      </div>
   )
}

LocationChangeForm.propTypes = {
   setZipDisplay: PropTypes.func,
   doctorInput: PropTypes.shape({
      range: PropTypes.number,
      zipCode: PropTypes.string
   }),
   setDoctorInput: PropTypes.func
}

const mapStateToProps = state => {
   return {
      doctorInput: state.doctor.doctorInput
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      setDoctorInput: ( doctorInput ) => {
         return dispatch( setDoctorInput( doctorInput ) )
      }
   }
}

const ConnectedLocationChangeForm = connect( mapStateToProps, mapDispatchToProps )( LocationChangeForm )

export default withRouter( ConnectedLocationChangeForm )

