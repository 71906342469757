import React from 'react'
import PropTypes from 'prop-types'
import HighlightMatchStr from './components/HighlightMatchStr'
import { getFormattedNpiAddress, getTaxonomyText } from 'components/Forms/utils'
import styled from 'styled-components'

const StyledUl = styled.ul`
   list-style-type: none;
   padding: 0;
   margin: 0;
`

const StyledLi = styled.li`
   border: 1px solid #E6E6E6;
   border-top-width: 0;
   border-right-width: 0;
   border-left-width: 0;
   margin: 3px;
   padding-top: 2px;
   padding-bottom: 3px;
   &:hover {
     background-color: white;
     font-weight: 600;
   }
`
/* eslint-disable react/no-array-index-key */
export const TypeaheadInput = ( props ) => {

   const { suggestions, setValue, showTaxonomy, showAddress, onlyShowNameCity, value, validZipCode, handleBlur, handleDoctorSelect, isDisabled } = props

   const handleChange = ( e ) => {
      if ( !validZipCode ){
         e.preventDefault()

         return alert( `please enter a valid zip code first` )
      }

      setValue( e.target.value )
   }

   // const handleFocus = ( e ) => {
   //    if ( !isValidZip && !noZipShow ){
   //       e.preventDefault()
   //       noZipShow = true

   //       return alert( `please enter a zip code first` )
   //    }

   // }

   //  const handleBlur = ( /* e */ ) => {
   //     console.log( `blur` )

   //  }

   const handleClick = ( e ) => {
      handleDoctorSelect( e )
   }

   return (
      <>
         <input
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            disabled={isDisabled}
         />

         <div>
            <StyledUl>
               {suggestions && suggestions.map( ( suggestion ) => {
                  const {
                     basic: {
                        first_name,
                        last_name,
                        credential
                     },
                     addresses, // array {address_purpose: MAILING, LOCATION, city, state, phone} find first where address is location - if no location grab first of all entries
                     taxonomies, // array [{desc: Nurse Practitioner}, ...]
                     number // NPI number used for selecting the doctor
                  } = suggestion

                  const formattedNpiAddressesArray = getFormattedNpiAddress( addresses )

                  const taxonomyDescriptions = showTaxonomy ? getTaxonomyText( taxonomies ) : ``

                  const [{ city, state, telephone_number, address_1, address_2 }] = formattedNpiAddressesArray

                  return (
                     <StyledLi key={number} onClick={handleClick} id={number}>
                        <HighlightMatchStr str={last_name} subStr={value} />{`, ${first_name} - `}
                        {onlyShowNameCity && `${city}`}
                        {!onlyShowNameCity && credential || ``}
                        {showTaxonomy && !onlyShowNameCity &&
                        <>
                           <br />
                           {taxonomyDescriptions}
                        </>
                        }
                        {showAddress && !onlyShowNameCity &&
                        <>
                           <br />
                           {`${address_1}, ${address_2}`}
                        </>
                        }
                        {!onlyShowNameCity &&
                           <>
                              <br />
                              {`${city}, ${state}, ${telephone_number} `}
                           </>
                        }
                     </StyledLi>
                  )
               })}
            </StyledUl>
         </div>

      </>
   )
}

TypeaheadInput.propTypes = {
   suggestions: PropTypes.array.isRequired,
   setValue: PropTypes.func.isRequired,
   value: PropTypes.string.isRequired,
   showAddress: PropTypes.bool.isRequired,
   showTaxonomy: PropTypes.bool.isRequired,
   onlyShowNameCity: PropTypes.bool.isRequired,
   validZipCode: PropTypes.bool,
   handleBlur: PropTypes.func,
   handleDoctorSelect: PropTypes.func.isRequired,
   isDisabled: PropTypes.bool
}

export default TypeaheadInput