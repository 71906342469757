import moment from 'moment'
import { convertMS } from './utils'
import {
   regexErrors,
   regexPatterns,
   validOneDigitMonths,
   validOneDigitDays,
   validTwoDigitMonths
} from './constants'

// if any of the regex patterns match isValid will be true upon return
const applyRegexCollectionToVal = ( val, regexEntries ) => {

   let isValid = false

   for ( let pattern in regexEntries ){
      // valid if any matches
      if ( regexEntries[pattern].regex.test( val ) ){
         isValid = true
      }
   }

   return isValid
}

// @NOTE for ux reasons only format when it really would benefit the user - and during an onBlur NOT onChange :)
export const FormatterController = {
   prefaceDigitWithZero: ( val ) => {
      return `0${val}`
   },
   phoneFormatter: ( val ) => {

      // trim out `(`, `)`, ` `, and `-`
      let _val = val.replaceAll( /[- )(]/g, `` )

      // @NOTE if we want to check for all digits use _val.match( reg )
      // let reg = /^\d+$/

      // don't allow 1 as first number, strip
      if ( _val.length === 1 && _val === `1` ){
         return ``
      }

      const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
      const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
      const c = _val.substring( 6 ) ? `-${_val.substring( 6 )}` : ``

      // pattern `(888) 555-1212`
      return `${a}${b}${c}`

   },
   ccFormatter: ( val ) => {
      let reg = /[^\d]+/gi
      if ( val ) {

         let _strval = val.replaceAll( reg, `` )
         let _val = _strval.replaceAll( `-`, `` )

         const _valLength = _val.length

         const isAmex = _val.startsWith( `37` ) || _val.startsWith( `34` )

         if ( isAmex ){
            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 10 )
            const c = _val.substring( 10, 15 )

            if ( _valLength > 10 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }

         if ( !isAmex ){

            const a = _val.substring( 0, 4 )
            const b = _val.substring( 4, 8 )
            const c = _val.substring( 8, 12 )
            const d = _val.substring( 12, 16 )

            if ( _valLength > 12 ){
               return `${a}-${b}-${c}-${d}`
            }

            if ( _valLength > 8 ){
               return `${a}-${b}-${c}`
            }

            if ( _valLength > 4 ){
               return `${a}-${b}`
            }

            if ( _valLength > 0 ){
               return a
            }

         }
      }
   }
}

// if validation passes but a formatter is required we pass back a special string `USE_FORMATTER_[val]`
export const ValidationController = {
   address: ( val ) => {
      if ( val.length === 1 ){
         return regexErrors.address
      }

      const valid = applyRegexCollectionToVal( val, regexPatterns.address )

      return valid ? `` : regexErrors.address
   },
   city: ( val ) => {
      if ( val.length === 1 ){
         return regexErrors.city
      }

      const valid = applyRegexCollectionToVal( val, regexPatterns.city )

      return valid ? `` : regexErrors.city
   },
   memberID: ( val ) => {
      if ( val.length < 3 ){
         return regexErrors.memberID
      }

      const valid = applyRegexCollectionToVal( val, regexPatterns.memberID )

      return valid ? `` : regexErrors.memberID
   },
   email: ( val ) => {

      const valid = applyRegexCollectionToVal( val, regexPatterns.email )

      return valid ? `` : regexErrors.email
   },
   card_number: ( val ) => {
      const valSansHyphens = val.replaceAll( `-`, `` )
      const valid = applyRegexCollectionToVal( valSansHyphens, regexPatterns.card_number )

      return valid ? `` : regexErrors.card_number
   },
   expmm: ( val ) => {

      if ( validOneDigitMonths.includes( val ) ) {

         return `FORMATTER_USE_prefaceDigitWithZero`

      }

      return validTwoDigitMonths.includes( val ) ? `` : regexErrors.expmm

   },
   dd: ( val ) => {

      const isValidOneDigitDay = validOneDigitDays.includes( val )

      if ( isValidOneDigitDay ) {

         return `FORMATTER_USE_prefaceDigitWithZero`

      }

      return ( val > 0 && val < 32 ) ? `` : regexErrors.dd

   },
   due_date: ( val ) => {

      // current date PLUS 9 months/current date MINUS 1 year.
      const today = new Date()
      const due_date = new Date( val )

      if ( due_date == `Invalid Date` ) {
         return regexErrors.due_date
      }

      const ms = ( today - due_date )
      const { day } = convertMS( ms )

      const years = ( day / 365 )

      let valid = false

      if ( years === 0 ){
         valid = true

      }

      if ( years > 0 && years < 1 ) valid = true
      if ( years < 0 && years > - 0.8 ) valid = true

      return valid ? `` : regexErrors.due_date

   },
   date_of_birth: ( dateStr ) => {
      // can't be older than 120!
      const today = new Date()
      const dob = new Date( dateStr )

      if ( dob == `Invalid Date` ) {
         return regexErrors.date_of_birth
      }

      const ms = ( today - dob )
      const { day } = convertMS( ms )
      const years = ( day / 365 )

      let valid = false

      if ( years === 0 ){
         valid = true
      }

      if ( years > 0 && years < 120 ){
         valid = true
      }

      if ( years < 0 ){
         valid = false
      }

      return valid ? `` : regexErrors.date_of_birth
   },
   dob_yyyy: ( val ) => {
      const valStr = val.toString()
      if ( valStr.length !== 4 ){

         return regexErrors.dob_yyyy
      }

      const maxRange = moment().format( `YYYY` )
      const minRange = moment().subtract( 120, `years` )
         .format( `YYYY` )

      const isInRange = parseInt( val ) <= parseInt( maxRange ) && parseInt( val ) >= parseInt( minRange )

      return isInRange ? `` : regexErrors.dob_yyyy
   },
   dueDateYYYY: ( val ) => {

      const valStr = val.toString()
      if ( valStr.length !== 4 ){

         return regexErrors.dueDateYYYY
      }

      const maxRange = moment().add( 1, `years` )
         .format( `YYYY` )
      const minRange = moment().subtract( 1, `years` )
         .format( `YYYY` )


      const isInRange = parseInt( minRange ) <= parseInt( val ) && parseInt( maxRange ) >= parseInt( val )

      return isInRange ? `` : regexErrors.dueDateYYYY
   },
   expyyyy: ( val ) => {
      // see above
      const thisYear = moment().format( `YYYY` )
      const maxRange = moment( thisYear ).add( 20, `years` )
         .format( `YYYY` )

      return ( val < thisYear || val > maxRange ) ? regexErrors.expyyyy : ``

   },
   ccv: ( val ) => {

      const valid = applyRegexCollectionToVal( val, regexPatterns.ccv )

      return valid ? `` : regexErrors.ccv
   },
   first_name: ( val ) => {
      if ( val.length === 1 ){
         return regexErrors.first_name
      }
      const valid = applyRegexCollectionToVal( val, regexPatterns.first_name )

      return valid ? `` : regexErrors.first_name
   },
   last_name: ( val ) => {
      if ( val.length === 1 ){
         return regexPatterns.last_name
      }
      const valid = applyRegexCollectionToVal( val, regexPatterns.last_name )

      return valid ? `` : regexErrors.last_name
   },
   zip: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.zip )

      return valid ? `` : regexErrors.zip
   },
   weight: ( val ) => {

      const weight = parseInt( val )
      const valid = ( weight && ( weight >= 1 && weight <= 999 ) )

      return valid ? `` : regexErrors.weight
   },
   phone: ( val ) => {
      const valid = applyRegexCollectionToVal( val, regexPatterns.phone )

      return valid ? `` : regexErrors.phone
   }
}