import React from 'react'
import PropTypes from 'prop-types'

export const PortalLinkWrapper = props => {
   return (
      <div className="paper">
         <div className="p-6 mb-4">
            {props.children}
         </div>
      </div>
   )
}

PortalLinkWrapper.propTypes = {
   children: PropTypes.node
}