import React from 'react'
import qs from 'qs'
import { getHeaders } from 'utils/url'
import axios from 'axios'
import TypeaheadInput from './TypeaheadInput'
import { filterOutOtherNames } from 'components/Forms/utils'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { SelectDoctorState, AdminCheckbox, AdminTypeaheadResultsLimit } from './components/adminControls.js'
import styled from 'styled-components'

const TypeaheadBox = styled.div`
   width: 1200px;
   min-height: 700px;
   margin: 0 auto;
   display: flex;
   flex-direction: row;
`

const WrapperDiv = styled.div`
   padding: 15px;
   border: 4px solid coral;
   flex: 2;
   margin-bottom: 20px;
`

const InfoDiv = styled.div`
  display: ${props => { return props.showInfo ? `block` : `none` }};
`

const WarningP = styled.p`
  display: ${props => { return props.showWarning ? `block` : `none` }};
`

const TypeaheadDiv = styled( WrapperDiv )`
   padding: 10px 100px 100px 100px;
   flex: 8;
   border: 4px solid slategray;
`
// @NOTE - that ^ fn utils file will need to be moved to src/modules/doctorLookup when testing is over
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */

export class TypeaheadController extends React.Component {
   constructor( props ) {
      super( props )
      this.state = {
         suggestions: [],
         resultsLimit: 30,
         doctorState: ``,
         totalSessionAWSLambdaRequests: 0,
         showTaxonomy: false,
         showAddress: false,
         onlyShowNameCity: false,
         showInfo: false,
         showWarning: false,
         searchString: ``
      }
   }
   componentDidMount = () => {
      this.initialServicePing()
   }
   initialServicePing = async () => {
      const queryParams = qs.stringify({
         last_name: `xyz*`,
         state: `AK`,
         limit: 1,
         aftk: `Token bpfpAbodtrtGVxpfQckRxZzH6L7zi4DY`
      })

      try {
         const _HEADERS = getHeaders()

         await axios({
            method: `get`,
            url: `https://xjt7uszd09.execute-api.us-east-1.amazonaws.com/default/afDocLookup?${queryParams}`,
            headers: _HEADERS
         })
      } catch ( err ) {
         console.log( err )
      }
   }
   handleGetSuggestions = async ( lastNameStr ) => {

      const _HEADERS = getHeaders()

      const queryParams = qs.stringify({
         last_name: `${lastNameStr}*`,
         state: this.state.doctorState,
         limit: this.state.resultsLimit,
         aftk: `Token bpfpAbodtrtGVxpfQckRxZzH6L7zi4DY`
      })
      // create qs - add wildcard to lastNameStr
      if ( lastNameStr.length > 1 ) {
         try {

            let doctorInfo = await axios({
               method: `get`,
               withCredentials: true,
               url: `https://xjt7uszd09.execute-api.us-east-1.amazonaws.com/default/afDocLookup?${queryParams}`,
               headers: _HEADERS
            })
            // await ajax call - set suggestions

            if ( doctorInfo && doctorInfo.data ){

               if ( doctorInfo.data && doctorInfo.data.error ){
                  return alert( `client not authorized` )
               }

               const basicLastNamesOnlyResults = filterOutOtherNames( doctorInfo.data.results, lastNameStr.toUpperCase() )

               this.setState({
                  suggestions: basicLastNamesOnlyResults
                  // totalSessionAWSLambdaRequests: this.state.totalSessionAWSLambdaRequests + 1
               })
            }
         } catch ( err ) {
            // console.log(err)
         }
      } else {
         this.setState({
            suggestions: []
         })
      }
   }
   setDashboardControl = ( val, field ) => {

      if ( field === `onlyShowNameCity` && val ) {
         return this.setState({
            [field]: val,
            showTaxonomy: false,
            showAddress: false
         })
      }

      if ( field === `doctorState` ) {
         return this.setState({
            [field]: val,
            searchString: ``,
            suggestions: []
         })
      }

      if ( field === `showTaxonomy` || field === `showAddress` && val ) {
         return this.setState({
            [field]: val,
            onlyShowNameCity: false
         })
      }

      return this.setState({
         [field]: val
      })
   }
   handleShow = ( type ) => {

      return this.setState({
         [type]: !this.state[type]
      })
   }
   setSearchString = ( val ) => {

      this.setState({
         searchString: val
      }, () => {
         this.handleGetSuggestions( val )
      })
   }
   render(){
      const { onlyShowNameCity, showAddress, showTaxonomy, doctorState, suggestions, resultsLimit, showInfo, showWarning, searchString } = this.state

      return (
         <TypeaheadBox>

            <TypeaheadDiv>

               <div
                  className="bg-grey ph-48 pb-248"
               >

                  <div
                     className="flex-row pt-48 pb-48"
                  >
                     <AdminCheckbox
                        updateController={this.setDashboardControl}
                        field="showTaxonomy"
                        label="Show taxonomy"
                        value={showTaxonomy}
                     />
                     <AdminCheckbox
                        updateController={this.setDashboardControl}
                        field="showAddress"
                        label="Show address"
                        value={showAddress}
                     />

                     <AdminCheckbox
                        updateController={this.setDashboardControl}
                        field="onlyShowNameCity"
                        label="Only show city"
                        value={onlyShowNameCity}
                     />

                     <AdminTypeaheadResultsLimit
                        value={resultsLimit}
                        updateController={this.setDashboardControl}
                        field="resultsLimit"
                        label="Results Limit (200 max)"
                     />
                  </div>
                  <p
                     className="slate-grey"
                  >{`Typeahead Lookup Tool`}
                     <span
                        style={{
                           fontSize: 10,
                           color: `coral`
                        }}
                     >
                        {` *Interactivity and styles will be added to typeahead to allow for selection after UX`}
                     </span>
                  </p>

                  <SelectDoctorState
                     updateController={this.setDashboardControl}
                     label="Select Doctor State"
                     field="doctorState"
                     value={doctorState}
                  />
                  <p>{`Enter Doctor Last Name`}</p>
                  <TypeaheadInput
                     value={searchString}
                     suggestions={suggestions}
                     setValue={( val ) => {
                        this.setSearchString( val )
                     }}
                     showTaxonomy={showTaxonomy}
                     showAddress={showAddress}
                     onlyShowNameCity={onlyShowNameCity}
                  />
                  <div>
                     {/* <p
               style={{
                  display: `none`
               }}
            >
               {`total session requests : ${this.state.totalSessionAWSLambdaRequests}`}
            </p>*/}
                  </div>
               </div>
            </TypeaheadDiv>

            <WrapperDiv>
               <InfoIcon color="primary" className="info" onClick={() => { return this.handleShow( `showInfo` ) }} />
               <WarningIcon color="error" className="warning" onClick={() => { return this.handleShow( `showWarning` ) }} />
               <InfoDiv
                  showInfo={showInfo}
               >
                  <p
                     className="green"
                  >
                     {`Typeahead Data Visibility Controls - for testing`}
                  </p>
                  <p>{`Control display data returned from the provider lookup tool.`}<a href="https://npiregistry.cms.hhs.gov/registry/help-api" target="blank"> {` - NPI registry`} </a></p>
                  <p><em>{`All of these inputs are optional. If you don't select any checkboxes a default display will render for results`}</em></p>
               </InfoDiv>
               <div>
                  <WarningP
                     showWarning={showWarning}
                     className="coral"
                  > {`* To search by partial last name the api doesn't allow only provider "last_name" api field matches to be returned. 
               It also searches for and includes "other_last_names" api field search string matches. 
               "other_last_names" api field contains former last names including maiden names.
               Our code removes those results before displaying on the front end to avoid confusion. That is why results limit of x may return less than x results.`}</WarningP>
               </div>
            </WrapperDiv>
         </TypeaheadBox>
      )
   }
}

export default TypeaheadController