import React from 'react'
import PropTypes from 'prop-types'

export const OrderSummarySection = ( props ) => {
   return (
      <div
         className="mb-3 pb-5 text-center border-0 border-b border-dashed border-afBlue-secondary"
      >
         {props.children}
      </div>
   )
}

OrderSummarySection.propTypes = {
   children: PropTypes.node
}