import React from 'react'
import { Formatters } from '../utils/utils'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function CurrentDoctor({ setSearchActive, headerStyle, containerStyle, doctor }) {

   return (
      <div className="flex flex-col justify-center items-center gap-5 text-center">
         <h1 className={headerStyle ?? `text-3xl font-light text-center mb-3 mt-0`}>{`Your Doctor`}</h1>
         <div className={containerStyle}>
            <div className="flex flex-col items-center">
               <p className="text-xl capitalize mb-2 text-black">{`${doctor?.firstName?.toLowerCase()} ${doctor?.lastName?.toLowerCase()}`}</p>
               <p className="text-lg font-light text-black">{Formatters.phone( doctor?.phone )}</p>
            </div>
            <p
               className="underline text-teal text-lg cursor-pointer hover:opacity-80 font-bold"
               onClick={() => { setSearchActive( true ) }}
            >
               {`Edit`}
            </p>
         </div>
      </div>
   )
}

CurrentDoctor.propTypes = {
   setSearchActive: PropTypes.func,
   headerStyle: PropTypes.string,
   containerStyle: PropTypes.string,
   doctor: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string
   })
}

const mapStateToProps = state => {
   return {
      doctor: state.doctor.doctor
   }
}

const ConnectedCurrentDoctor = connect( mapStateToProps, {})( CurrentDoctor )

export default withRouter( ConnectedCurrentDoctor )