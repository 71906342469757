import React from 'react'
import PropTypes from 'prop-types'
// import InputLabel from '@material-ui/core/InputLabel'
import { Text } from 'informed'
import { informedValidPhone } from 'components/Forms/validators'
import { phoneNumberMask } from 'components/Forms/formatters'
// import styled from 'styled-components'

// const StyledText = styled( Text )`
//   /* border-color: #727475; */
//   text-align: center;
//   padding: 16px;
// `

const InputPhone = ( props ) => {
   const { errors } = props

   return (
      <React.Fragment>
         <label>
            <p className="text-afBlue-dark">
               <span className="font-bold" >
                  {`Phone Number:`}
               </span>
            </p>
            <Text
               className="p-2 w-full text-center text-base"
               placeholder="XXX-XXX-XXXX"
               field="phone"
               type="tel"
               mask={phoneNumberMask}
               validate={informedValidPhone}
               validateOnChange
               size="20"
               maxLength="12"
            />
         </label>
         <div>
            {errors &&
					<p className="error">{errors}</p>
            }
         </div>
      </React.Fragment>
   )
}

InputPhone.propTypes = {
   errors: PropTypes.string
}

export default InputPhone
