import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import RecentOrderHeader from './components/RecentOrderHeader'
import RecentOrderGridDisplay from './components/RecentOrderDisplay'

export const MostRecentOrder = ( props ) => {

   const { orderHistory, collapsible } = props

   // we want the history to be open initially if items are available
   const [ isCollapsed, setIsCollapsed ] = useState( true )

   const handleShowOrderHistory = () => {
      setIsCollapsed( !isCollapsed )
   }

   // Filter out orders with no date and then find the most recent order
   const mostRecentOrder = useMemo( () => {
      if ( !orderHistory || orderHistory.length === 0 ) {
         return null
      }
      const filteredOrders = orderHistory.filter( order => {
         return order.order?.order_date
      })

      return filteredOrders.length === 0 ? null : (
         filteredOrders.reduce( ( a, b ) => {
            const dateOne = new Date( a.order.order_date )
            const dateTwo = new Date ( b.order.order_date )

            return dateOne > dateTwo ? a : b
         })
      )
   }, [ orderHistory ] )


   if ( !mostRecentOrder ){
      return null
   }

   return (
      <div className={`${!collapsible && `mb-6 sm:mb-12`}`}>
         <RecentOrderHeader
            collapsible={collapsible}
            isCollapsed={isCollapsed}
            handleShowOrderHistory={handleShowOrderHistory}
         />
         {
            ( !collapsible || ( !isCollapsed && collapsible ) ) &&
            <>
               <RecentOrderGridDisplay items={mostRecentOrder.order.items} mostRecentOrder={mostRecentOrder} />
            </>
         }
      </div>
   )

}

MostRecentOrder.propTypes = {
   orderHistory: PropTypes.arrayOf(
      PropTypes.shape({
         order_number: PropTypes.number,
         order_date: PropTypes.string
      })
   ),
   collapsible: PropTypes.bool
}

export const mapStateToProps = ( state ) => {
   return {
      orderHistory: state.auth.orderHistory
   }
}

export default connect( mapStateToProps )( MostRecentOrder )
