import { moduleData, getDisplayKey } from 'constants/moduleData'
import { getOrderCanShipDate } from 'modules/claims/utils'

/** takes an aggregate of data and returns an order serialized to POST to submit order endpoint */
export const orderFormatter = ( orderMeta ) => {

   const {
      order: { items },
      showMedicareChecklist,
      history: { location: { pathname } },
      threeMonthPromoAccepted,
      user: {
         state,
         zip_code,
         email,
         phone,
         street_2,
         city,
         alt_patient_phone,
         first_name,
         last_name,
         street,
         patient_id
      }
   } = orderMeta

   const _email = ( email == `0` ) ? null : email

   const {
      referralSourceName,
      referralSourceId,
      pfPk: profitCenterPk
   } = moduleData[ getDisplayKey( pathname ) ]

   const canShipDate = getOrderCanShipDate( items )

   // query each item for qty - if 0 then do not add to qtyProducts
   // since we still add items that are medicareChecklist reason === no this

   let qtyProducts = 0
   // @TODO map may not be best to use here as we aren't assigning
   Object.keys( items ).map( itemKey => {
      if ( items[itemKey].qty > 0 ){
         qtyProducts++
      }
   })

   const formattedItems = Object.keys( items ).map( itemKey => {
      const thisItem = Object.assign({}, items[itemKey] )
      delete thisItem.canShipDate
      thisItem.selectedQty = thisItem.qty

      // @NOTE this is kind of hacky but it has to be this way to place an order for PT without medicare checklist flag
      // yes we simply set the medicare checklist reasons to true, and it's been like that
      if ( thisItem.medicare_checklist === null ) {
         thisItem.medicare_checklist = {
            hcpc: thisItem.hcpc_id,
            reason: {
               quality_question_response: true,
               quantity_question_response: true
            }
         }
      }

      // size isn't recognized by api but will go through. we need it to display size post order on thank you page
      if ( thisItem.size === undefined ) {
         delete thisItem.size
      }

      // if it's an add on item we will set allow_alone to 1 even though when the options are fetched it may be a 0
      // this is a biz unit request that is easier to implement here since this code base is slated for deprecation in 3-6 months
      if ( thisItem.add_on ){
         thisItem.allow_alone = true
      }

      if ( thisItem.allow_alone === 1 ) thisItem.allow_alone = true
      else thisItem.allow_alone = false

      return thisItem
   })

   const order = {
      three_month_supply: threeMonthPromoAccepted,
      canShipDate,
      zip_code,
      email: _email,
      items: formattedItems,
      address: street,
      address2: street_2,
      city,
      phone,
      state,
      patientId: patient_id,
      profit_center_pk: profitCenterPk,
      first_name,
      last_name,
      referral_source_id: referralSourceId,
      referral_source_name: referralSourceName,
      showMedicareChecklist,
      qty_products: qtyProducts,
      alt_patient_phone
   }

   return order

}