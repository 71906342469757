
export const stepSchema = {
   0: {
      name: `initial`,
      buttonTwoText: `No, I'd rather not`,
      headerText: ``,
      headerInfoIcon: true,
      subText: `Would you like to maximize your insurance benefit?`,
      msgText: [
         `Our records indicate that your insurance may permit a 3 month supply of your urological products or urinary catheters. With the end of the year coming up, it is very likely that your deductible will reset.`,
         `With your permission, we will place a 3 month supply of catheters for you now, so that you can maximize your insurance benefits prior to your deductible resetting.`
      ],
      buttonOneText: `Yes, I'll get the 3 month supply`
   },
   1: {
      name: `confirmDecline`,
      buttonTwoText: `I still don't want the 3 month supply`,
      headerText: `Are you sure?`,
      headerIcon: null,
      msgText: [
         `Are you sure that you don't want to receive a 90 day supply before your year end deductible resets`
      ],
      buttonOneText: `I've decided to get the 3 month supply`
   },
   2: {
      name: `thanks`,
      buttonTwoText: null,
      headerText: `Thank you for confirming!`,
      headerIcon: null,
      msgText: [
         `Our customer service specialists will confirm your insurance coverage and notify you if there are any issues`,
         `Please be aware you need to proceed with placing your resupply order at this time to receive the promotion`,
         `If you receive incontinence supplies in addition to urinary catheters, these will be shipped as your regular 1 month supply as normal, per insurance guidelines`
      ],
      buttonOneText: `Close`
   }
}