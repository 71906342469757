import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox } from 'informed'
import InputRadioLoginType from './fields/RadioLoginType'
import InputEmail from './fields/InputEmail'
import InputPhone from './fields/InputPhone'
import { connect } from 'react-redux'
import FieldgroupDOB from './FieldgroupDOB'
import { submitUserLogin, setLoginError } from 'modules/auth/actions'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LoginFailedDialog from './LoginFailedDialog'

const StyledCircularProgress = styled( CircularProgress )`
   margin-left: 15px;
`

const LoginForm = ( props ) => {

   const { uroCurated, patientHash, hideRememberMe, dobLabel, profitCenterPk } = props

   const [ loginType, setLoginType ] = useState( `phone` )
   const [ loading, setLoading ] = useState( false )

   const postLogin = async ( values ) => {

      try {
         // need to ensure submitLogin stores remember in redux and doesn't pass remember to login endpoint
         const login = await props.submitLogin( values )
         // @REVIEW do we need this - check login endpoint responses
         // pre-review comment - if login succeeds we get redirected to `/[rootPath]` else we need to kill loading spinner
         if ( login && login.hasOwnProperty( `displayMessage` ) && login.displayMessage.type === `error` ) {

            return setLoading( false )
         }
      } catch ( err ) {

         return setLoading( false )
      }
   }

   const handleSetLoginType = ( e ) => {

      return setLoginType( e.target.value )
   }


   const handleSubmit = ( values ) => {

      if ( uroCurated ) {
         values.isUroCurated = true
         values.patientHash = patientHash
      }

      if ( profitCenterPk ) {
         values.profitCenterPK = profitCenterPk
      }

      postLogin( values )
   }

   const closeModal= () => {
      return props.setError( `` )
   }

   return (
      <div className="bg-white py-3.5 my-3.5">
         <Form
            onSubmit={handleSubmit}
            className="m-auto max-w-sm"
         >
            {({ formState }) => {

               return (
                  <React.Fragment>
                     <InputRadioLoginType
                        onToggle={handleSetLoginType}
                        loginType={loginType}
                     />
                     <div className="m-4">
                        {loginType === `phone` &&
									<InputPhone errors={formState.errors.hasOwnProperty( `phone` ) ? formState.errors.phone : null} />}
                        {loginType === `email` &&
									<InputEmail errors={formState.errors.hasOwnProperty( `email` ) ? formState.errors.email : null} />}
                     </div>
                     <FieldgroupDOB errors={formState.errors} label={dobLabel} />

                     {!hideRememberMe &&
                     <div className="m-4">

                        <FormControlLabel
                           control={
                              <Checkbox
                                 className="w-auto cursor-pointer"
                                 field="remember"
                              />}
                           label={`Remember Me`}
                        />

                     </div>
                     }
                     <button
                        className="w-full py-2 bg-teal hover:opacity-90 cursor-pointer"
                        type="submit"
                     >
                        {`Login `}
                        {loading && <StyledCircularProgress size={24} color="secondary" />}
                     </button>
                  </React.Fragment>
               )
            }}
         </Form>
         <LoginFailedDialog
            open={props.loginError}
            onClose={closeModal}
            msg={props.loginError}
         />
      </div>
   )
}

LoginForm.propTypes = {
   submitLogin: PropTypes.func.isRequired,
   setError: PropTypes.func.isRequired,
   loginError: PropTypes.bool.isRequired,
   uroCurated: PropTypes.bool,
   patientHash: PropTypes.string,
   hideRememberMe: PropTypes.bool,
   dobLabel: PropTypes.string,
   profitCenterPk: PropTypes.number
}

const mapStateToProps = state => {
   return {
      loginError: state.auth.loginError
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      submitLogin: ( values ) => {
         return dispatch( submitUserLogin( values ) )
      },
      setError: ( bool ) => {
         return dispatch( setLoginError( bool ) )
      }
   }
}

const ConnectedLoginForm = connect( mapStateToProps, mapDispatchToProps )( LoginForm )

export default ConnectedLoginForm