import { Route } from 'react-router-dom'
import React from 'react'
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes( route ) {
   return (
      <Route
         path={route.path}
         render={props => {
            return (
            // pass the sub-routes down to keep nesting
               <route.component {...props} routes={route.routes} />
            )
         }}
      />
   )
}

export default RouteWithSubRoutes