import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateSizeSupplyItem, toggleItemSelectedForOrderStatus } from 'modules/claims/actions'
import { GLOVE_SIZE_OPTIONS } from 'modules/claims/constants'
import { hasGloves } from 'modules/claims/utils'
import Alert from './../Alert'
import EligibilityCell from './EligibilityCell'
import EligibleProductNameCell, { IneligibleProductNameCell } from './ProductNameCell'
import QuantityCell from './QuantityCell'
import ShippingCell from './ShippingCell'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'

/**
  *
  * @type {Function Class}
  * @description SupplyItem renders different grid area content based on eligible/ineligible item flag
  * -- mobile/desktop displays are different and controlled by SuppliesList to render a different parent component
  */

export const SupplyItem = ( props ) => {
   const [ showAlert, setShowAlert ] = useState( false )
   const [ gloveSizeBoltOnValue, setGloveSizeBoltOnValue ] = useState( `-` )

   const {
      mobileItem,
      item,
      allowQuantityChanges,
      toggleClaimItemCheckbox,
      estimatedShipDate,
      type,
      sizeError,
      item : {
         eligible,
         description,
         quantitySelected,
         quantityOptions,
         itemKey,
         add_on,
         hcpc_id,
         eligibleMessage
      }
   } = props

   useEffect( () => {

      const isAddOnGlove = add_on && GLOVE_SIZE_OPTIONS.map( opt => { return opt.hcpcid }).includes( hcpc_id )

      const hcpcidMatch = GLOVE_SIZE_OPTIONS.find( elem => {
         return elem.value === gloveSizeBoltOnValue
      })

      isAddOnGlove && props.setSizeSupplyItem( itemKey, hcpcidMatch?.hcpcid )

   }, [ gloveSizeBoltOnValue, itemKey ] )

   const handleSetAlert = ( bool ) => {
      setShowAlert( bool )
   }

   const handleGloveSizeChange = ( e ) => {

      const { target: { name, value} } = e
      if ( name && name === `glove-size-select` ){
         setGloveSizeBoltOnValue( value )
      }

   }
   if ( eligibleMessage === `Immediately` ) {
      return (
         <React.Fragment>
            <Alert
               open={showAlert}
               showAlert={handleSetAlert}
               ariaLabel="Website notification"
               ariaDesc="A mask selection is required."
               msgText={[ `Mask selection required.` ]}
               btnText="OK"
            />

            <div className="cell item product">
               <EligibleProductNameCell
                  item={item}
                  handleSetAlert={handleSetAlert}
                  toggleClaimItemCheckbox={() => { return toggleClaimItemCheckbox( item.itemKey ) }}
               />
            </div>

            <div className="cell item quantity">
               <QuantityCell
                  allowQuantityChanges={allowQuantityChanges}
                  quantitySelected={quantitySelected}
                  itemKey={itemKey}
                  quantityOptions={quantityOptions}
                  mobileItem={mobileItem}
               />
            </div>


            <div className="cell item eligible">
               {( !type || type !== `addon` ) &&
                  <EligibilityCell
                     itemKey={itemKey}
                     eligibleImmediately
                     mobileItem={mobileItem}
                  />
               }
               {( type && type === `addon` && hasGloves( item.hcpcid ) ) &&
               <FormGroup className={`flex-20 ${sizeError && `border border-solid red border-red-500`}`} >
                  <Select
                     onChange={handleGloveSizeChange}
                     name="glove-size-select"
                     value={gloveSizeBoltOnValue}
                  >
                     {GLOVE_SIZE_OPTIONS.map( opt => {
                        return (
                           <MenuItem value={opt.value} key={opt.value}>{opt.option}</MenuItem>
                        )
                     })}
                  </Select>
               </FormGroup>

               }
            </div>


            <div className="cell item shipping">

               <ShippingCell
                  eligible={type === `addon` ? false : eligibleMessage}
                  estimatedShipDate={add_on ? `Pending` : estimatedShipDate}
                  mobileItem={mobileItem}
               />
            </div>

         </React.Fragment>
      )
   } else {

      return (
         <React.Fragment>

            <div className="cell item product pointer-events-none">
               <IneligibleProductNameCell
                  name={description}
               />
            </div>

            <div className="cell item">
               <QuantityCell
                  mobileItem={mobileItem}
                  eligible={eligible}
               />
            </div>

            <div className="cell item eligible">
               <EligibilityCell
                  mobileItem={mobileItem}
                  eligible={eligible}
               />
            </div>

            <div className="cell item shipping">
               <ShippingCell
                  eligible={type === `addon` ? false : eligible}
                  mobileItem={mobileItem}
                  estimatedShipDate={estimatedShipDate}
               />
            </div>
         </React.Fragment>
      )
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      toggleClaimItemCheckbox: ( itemKey ) => {
         return dispatch( toggleItemSelectedForOrderStatus( itemKey ) )
      },
      setSizeSupplyItem: ( itemKey, hcpcidMatch ) => {
         return dispatch( updateSizeSupplyItem( itemKey, hcpcidMatch ) )
      }
   }
}

SupplyItem.propTypes = {
   allowQuantityChanges: PropTypes.bool,
   toggleClaimItemCheckbox: PropTypes.func.isRequired,
   estimatedShipDate: PropTypes.string,
   type: PropTypes.oneOf( [ `addon` ] ),
   item: PropTypes.shape({
      itemKey: PropTypes.string,
      eligible: PropTypes.string,
      name: PropTypes.string,
      quantitySelected: PropTypes.number,
      add_on: PropTypes.bool,
      hcpcid: PropTypes.number,
      eligibleMessage: PropTypes.string
   })
}

const ConnectedSupplyItem = connect( null, mapDispatchToProps )( SupplyItem )

export default ConnectedSupplyItem

