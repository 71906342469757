import React from 'react'
import PropTypes from 'prop-types'
import SupplyItem from './SupplyItem'
import { SuppliesListDesktopComponentWrapper } from './styled'
import { hasGloves } from '../utils'
import { useSelector } from 'react-redux'

// @NOTE supplies list desktop is a css grid implementation

export const SuppliesListDesktop = ( props ) => {

   const { itemKeys, allowQuantityChanges, estimatedShipDate, type, sizeError } = props

   const items = useSelector( ( state ) => { return state.claims.supplies })

   const hasGlove = itemKeys.find( itemKey => {
      return hasGloves( items[itemKey].hcpc_id )
   })

   return (
      <SuppliesListDesktopComponentWrapper>

         <div className="cell heading-item-a">
            <span className="text-sm">{`Product`}</span>
         </div>

         <div className="cell heading-item-b">
            <span className="text-sm">{allowQuantityChanges ? `Quantity` : ``}</span>
         </div>

         <div className="cell heading-item-c">
            {( !type || type !== `addon` ) && <span className="text-sm">{`Next Order Date`}</span>}
            {( type && type === `addon` && hasGlove ) && <span className="text-sm">{`Size`}</span>}
         </div>

         <div className="cell heading-item-d">
            {( !type || type !== `addon` ) &&<span className="text-sm">{`Estimated Ship Date`}</span>}
         </div>

         {itemKeys.map( ( itemKey ) => {

            return (
               <SupplyItem
                  estimatedShipDate={estimatedShipDate}
                  type={type}
                  item={items[itemKey]}
                  key={itemKey}
                  allowQuantityChanges={allowQuantityChanges}
                  sizeError={sizeError}
               />
            )
         })}

      </SuppliesListDesktopComponentWrapper>
   )
}

SuppliesListDesktop.propTypes = {
   itemKeys: PropTypes.array,
   sizeError: PropTypes.string,
   estimatedShipDate: PropTypes.string,
   allowQuantityChanges: PropTypes.object,
   type: PropTypes.oneOf( [ `addon` ] )
}

export default SuppliesListDesktop