import React from 'react'
import PropTypes from 'prop-types'

export const HighlightMatchStr = ( props ) => {
   const { str, subStr } = props

   const upperSubStr = subStr.toUpperCase()
   if ( str.toUpperCase().includes( upperSubStr ) ){
      // it's a match - there can be weird stuff happening here so this guardrail is needed
      const [ pre, post ] = str.split( upperSubStr )

      return <span>{`${pre || ``}`}<strong>{`${upperSubStr}`}</strong>{`${post || ``}`}</span>
   } else {
      return <span>{str}</span>
   }

}

HighlightMatchStr.propTypes = {
   str: PropTypes.string.isRequired,
   subStr: PropTypes.string.isRequired
}

export default HighlightMatchStr