import React, { useState } from 'react'
import TextInput from 'components/Forms/components/TextInput'
import ZipDisplay from './ZipDisplay'
import ManualDoctorForm from './ManualDoctorForm'
import useDoctorSearch from '../hooks/useDoctorSearch'
import DoctorResults from './DoctorResults'
import DoctorSearchActionBlock from './DoctorSearchActionBlock'
import SearchDoctorHeading from './SearchDoctorHeading'
import { setDoctor, setDoctorInput } from '../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

function DoctorSearch({ setSearchActive, onSuccess, forceUpdate, headerStyle, containerStyle, setDoctor, setDoctorInput, doctorInput }) {
   const [ zipDisplay, setZipDisplay ] = useState( !doctorInput.zipCode )
   const [ manualDisplay, setManualDisplay ] = useState( false )
   const [ submitLoading, setSubmitLoading ] = useState( false )

   const { searchResults, searchLoading, searchError } = useDoctorSearch( doctorInput )

   const handleBack = () => {
      setDoctorInput( Object.assign({}, doctorInput, {
         fullName: ``
      }) )
      setSearchActive( false )
   }

   const handleSuccess = ( newDoctor ) => {
      setDoctor( Object.assign({}, newDoctor ) )
      if ( onSuccess ) onSuccess( newDoctor )
      handleBack()
   }

   if ( manualDisplay ) return <ManualDoctorForm setManualDisplay={setManualDisplay} handleSuccess={handleSuccess} />

   return (
      <div className="flex flex-col w-full max-w-md mx-auto text-center">
         <SearchDoctorHeading headerStyle={headerStyle} />
         <div className={containerStyle}>
            <>
               {
                  !zipDisplay &&
                  <TextInput
                     name="fullName"
                     label="Enter Your Doctor's Name"
                     className="sleep-input"
                     value={doctorInput.fullName}
                     disabled={zipDisplay}
                     onChange={( e ) => {
                        setDoctorInput( Object.assign({}, doctorInput, {
                           [e.target.name]: e.target.value
                        }) )
                     }}
                  />
               }
               <ZipDisplay
                  zipDisplay={zipDisplay}
                  setZipDisplay={setZipDisplay}
               />
               <DoctorResults
                  zipDisplay={zipDisplay}
                  searchError={searchError}
                  searchLoading={searchLoading}
                  searchResults={searchResults}
                  handleSuccess={handleSuccess}
                  submitLoading={submitLoading}
                  setSubmitLoading={setSubmitLoading}
               />
               <DoctorSearchActionBlock
                  forceUpdate={forceUpdate}
                  searchResults={searchResults}
                  zipDisplay={zipDisplay}
                  searchError={searchError}
                  setManualDisplay={setManualDisplay}
                  handleBack={handleBack}
               />
            </>
         </div>

      </div>
   )
}

DoctorSearch.propTypes = {
   setSearchActive: PropTypes.func,
   onSuccess: PropTypes.func,
   forceUpdate: PropTypes.bool,
   headerStyle: PropTypes.string,
   containerStyle: PropTypes.string,
   setDoctor: PropTypes.func,
   setDoctorInput: PropTypes.func,
   doctorInput: PropTypes.shape({
      range: PropTypes.number,
      zipCode: PropTypes.string,
      fullName: PropTypes.string
   })
}

const mapStateToProps = state => {
   return {
      doctorInput: state.doctor.doctorInput
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      setDoctor: ( doctor ) => {
         return dispatch( setDoctor( doctor ) )
      },
      setDoctorInput: ( doctorInput ) => {
         return dispatch( setDoctorInput( doctorInput ) )
      }
   }
}

const ConnectedDoctorSearch = connect( mapStateToProps, mapDispatchToProps )( DoctorSearch )

export default withRouter( ConnectedDoctorSearch )