import { v4 as uuidv4 } from 'uuid'
const SESSION_UUID_KEY = `resupplySessionID`
const TEST_KEY = `__storage_test__`

export const isStorageAvailable = ( type ) => {

   try {
      const storage = window[type]
      storage.setItem( TEST_KEY, TEST_KEY )
      storage.removeItem( TEST_KEY )

      return true

   } catch ( e ) {
      return false
   }

}


export const UUIDSession = {
   create: () => {

      return uuidv4()
   },
   store: ( id ) => {

      return isStorageAvailable( `sessionStorage` ) ? sessionStorage.setItem( SESSION_UUID_KEY, id ) : window[SESSION_UUID_KEY] = id
   },
   retrieve: () => {

      return isStorageAvailable( `sessionStorage` ) ? sessionStorage.getItem( SESSION_UUID_KEY ) : window[SESSION_UUID_KEY]
   },
   delete: () => {

      return isStorageAvailable( `sessionStorage` ) ? sessionStorage.removeItem( SESSION_UUID_KEY ) : delete window[ SESSION_UUID_KEY ]
   }

}