import { useEffect } from 'react'
import Home from 'modules/home'
import Urology from 'modules/urology'
import OptOut from 'modules/optOut'
import PageNotFound from 'modules/pageNotFound'
import SessionStreamView from 'modules/sessionStreamView'
import TypeaheadController from 'components/Forms/Fields/Typeahead'
import StealthPrototype from 'modules/stealthPrototype'
import DoctorLookup from 'modules/doctorLookupOffical'
import Track from 'modules/track'
import MedicareCheckListController from 'modules/standaloneMedicareChecklist/MedicareCheckListController'
import { DoctorBlock } from 'modules/doctorBlock'
import EmailCollection from 'modules/emailCollection/EmailCollection'
import OrderStatus from "../modules/orderStatus"
import AppointmentInfo from "../modules/appointmentInfo"

// @NOTE the following is to business test typeahead and will be removed in place of other route
const SleepRedirect = props => {
   useEffect( () => {
      window.location.href = `https://aeroflowsleep.com/resupply/claim`
   }, [] )

   return null
}

const AlokaiRedirect = props => {
   useEffect( () => {
      window.location.href = `https://resupply.aeroflowurology.com?${window.location.search}`
   }, [] )

   return null

}

const UroCuratedRedirect = props => {
   useEffect( () => {
      window.location.href = `https://aeroflowurology.com/customer/account/login/`
   }, [] )

   return null
}

// @NOTE uncomment OptOut for dev and before production deploy of opt out feature
export const ROUTES = [
   {
      path: `/`,
      exact: true,
      component: Home
   },
   {
      path: `/urology*`,
      exact: false,
      component: AlokaiRedirect
   },
   {
      path: `/sleep`,
      exact: false,
      component: SleepRedirect
   },
   {
      path: `/urology/claim`,
      exact: true,
      component: Urology
   },
   {
      path: `/urology/claim/checklist`,
      exact: true,
      component: Urology
   },
   {
      path: `/urology/claim/confirm`,
      exact: true,
      component: Urology
   },
   {
      path: `/urology/claim/confirm-address`,
      exact: true,
      component: Urology
   },
   {
      path: `/urology/claim/thank-you`,
      exact: true,
      component: Urology
   },
   {
      path: `/doctor-lookup`,
      exact: true,
      component: DoctorBlock
   },
   {
      path: `/doctor-lookup-beta`,
      exact: true,
      component: TypeaheadController
   },
   {
      path: `/opt-out`,
      exact: true,
      component: OptOut
   },
   {
      path: `/session-stream-view`,
      exact: true,
      component: SessionStreamView
   },
   {
      path: `/continence-care-curated-shopping`,
      component: UroCuratedRedirect,
      exact: true
   },
   {
      path: `/prototype`,
      component: StealthPrototype
   },
   {
      path: `/doctor-block`,
      component: DoctorLookup,
      exact: true
   },
   {
      path: `/track`,
      component: Track,
      exact: true
   },
   {
      path: `/order-status`,
      component: OrderStatus,
      exact: true
   },
   {
      path: `/prototype`,
      component: StealthPrototype
   },
   {
      path: `/mcr`,
      component: MedicareCheckListController
   },
   {
      path: `/email-collect`,
      component: EmailCollection
   },
   {
      path: `/appointment-info`,
      component: AppointmentInfo
   },
   {
      path: `*`,
      component: PageNotFound,
      exact: true
   }
]
