import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel'

const StyledDialogActions = styled( DialogActions )`
   justify-content: center;
`
/* eslint-disable react/no-array-index-key */

export const Alert = ( props ) => {
   const {
      hideProblemIcon,
      headerText,
      open,
      showAlert,
      ariaLabel,
      ariaDesc,
      msgText,
      btnText,
      alertStateKey,
      nextFunctionCallback,
      nextButtonText,
      showCancelIcon
   } = props

   const hideAlert = ( ) => {
      return showAlert( false, alertStateKey )
   }

   return (
      <Dialog
         open={open}
         onClose={hideAlert}
         aria-labelledby={ariaLabel}
         aria-describedby={ariaDesc}
      >
         <DialogContent>
            <DialogContentText
               className="text-center"
            >
               {showCancelIcon && (
                  <p className="text-right text-teal">
                     <CancelIcon onClick={hideAlert} />
                  </p>
               )}
            </DialogContentText>
            <DialogContentText
               className="text-center"
            >
               {!hideProblemIcon &&
               <ReportProblemOutlinedIcon
                  style={{
                     fontSize: 60,
                     color: `#FFBA45`
                  }}
               />
               }
               {headerText}
            </DialogContentText>

            {msgText.map( ( msg, i ) => {
               return (
                  <DialogContentText key={i}>
                     {msg}
                  </DialogContentText>
               )
            })}
         </DialogContent>

         <StyledDialogActions
            className="text-center"
         >
            {btnText &&
            <button
               className="curated-btn w-48"
               onClick={hideAlert}
            >
               {btnText}
            </button>
            }
            { nextFunctionCallback && Boolean( nextButtonText ) &&
            <button
               className="curated-btn w-48"
               onClick={nextFunctionCallback}
            >
               {nextButtonText}
            </button>
            }
         </StyledDialogActions>
      </Dialog>

   )
}

Alert.propTypes = {
   hideProblemIcon: PropTypes.bool,
   headerText: PropTypes.string,
   open: PropTypes.bool.isRequired,
   showAlert: PropTypes.func.isRequired,
   ariaLabel: PropTypes.string,
   ariaDesc: PropTypes.string,
   msgText: PropTypes.array.isRequired,
   btnText: PropTypes.string.isRequired,
   alertStateKey: PropTypes.string,
   nextFunctionCallback: PropTypes.func,
   nextButtonText: PropTypes.string,
   showCancelIcon: PropTypes.bool
}

export default Alert