import { currentYear, monthMaxDays, oneHundredTwentyYearsBefore } from 'utils/time'
import { invalidMessages } from 'constants/messages'

export const validateField = {
   month: ( val, prevVal, name, handleError ) => {
      if ( parseInt( val ) > 12 || parseInt( val ) < 0 ) {
         handleError({
            [name]: invalidMessages[name]
         })

         return `error`
      } else {
         return val
      }

   },
   day: ( val, prevVal, name, handleError, monthSelected ) => {
      const intVal = parseInt( val )
      let maxVal = monthSelected ? monthMaxDays[monthSelected] : 31
      if ( intVal < 0 || intVal > maxVal ) {
         handleError({
            [name]: invalidMessages[name]
         })

         return `error`
      } else {
         return val
      }

   },
   year: ( val, prevVal, name, handleError ) => {
      const intVal = parseInt( val )

      if ( val.length > 4 ) {
         return prevVal
      }

      if ( val.length === 4 && intVal < oneHundredTwentyYearsBefore || intVal > currentYear ) {
         handleError({
            [name]: invalidMessages[name]
         })

         return `error`
      }

      return val
   }
}