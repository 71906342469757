import update from 'immutability-helper'

const INITIAL_STATE = {
   supplies: {},
   suppliesCount: 0,
   suppliesRequestStatus: `idle`,
   showMedicareChecklist: false,
   suppliesLoading: false,
   order: {},
   serializedOrder: {},
   orderSuccess: false,
   orderSuccessResponseData: ``,
   threeMonthPromoEligible: false,
   threeMonthPromoAccepted: false,
   estimatedShipDate: ``
}

const auth = ( state = INITIAL_STATE, action ) => {
   switch ( action.type ) {
   case `claims/RESET_CLAIMS`: {
      return INITIAL_STATE
   }
   case `claims/SET_ORDER_SUMMARY_FROM_SUBMIT_ORDER_RESPONSE`: {
      return update( state,
         {
            orderSuccessResponseData: {
               $set: action.orderReturnStringified
            }
         }
      )
   }
   case `claims/SET_ESTIMATED_SHIP_DATE`: {
      return update( state,
         {
            estimatedShipDate: {
               $set: action.estimatedShipDate
            }
         })
   }
   case `claims/SET_ORDER`: {
      return update( state,
         {
            order: {
               $set: action.order
            }
         }
      )
   }
   case `claims/UPDATE_ORDER_ITEMS`: {
      return update( state,
         {
            order: {
               $merge: {
                  items: action.items
               }
            }
         }
      )
   }
   case `claims/SET_SERIALIZED_ORDER`: {
      return update( state,
         {
            serializedOrder: {
               $set: action.serializedOrder
            }
         }
      )
   }
   case `claims/SET_ORDER_SUCCESS`: {
      return update( state,
         {
            orderSuccess: {
               $set: action.orderSuccess
            }
         }
      )
   }
   case `claims/SET_SUPPLIES`: {
      return update( state,
         {
            supplies: {
               $set: action.supplies
            }
         }
      )
   }
   case `claims/UPDATE_SUPPLIES`: {

      const [ firstItem ] = state.supplies

      if ( firstItem ){
         return update( state,
            {
               supplies: {
                  $unshift: [ action.supply ]
               }
            }
         )
      }

      // *********** this case will only fire if no state.supplies items - so push an item
      return update( state,
         {
            supplies: {
               $set: [ action.supply ]
            }
         }
      )


   }
   case `claims/SET_SUPPLIES_LOADING_STATUS`: {
      return update( state,
         {
            suppliesLoading: {
               $set: action.status
            }
         }
      )
   }
   case `claims/SET_SUPPLIES_COUNT`: {
      return update( state,
         {
            suppliesCount: {
               $set: action.suppliesCount
            }
         }
      )
   }
   case `claims/SET_THREE_MONTH_PROMO_ELIGIBLE`: {
      return update( state, {
         threeMonthPromoEligible: {
            $set: action.bool
         }
      })
   }
   case `claims/SET_THREE_MONTH_PROMO_ACCEPTED`: {
      return update( state, {
         threeMonthPromoAccepted: {
            $set: action.bool
         }
      })
   }
   case `claims/INCREMENT_SUPPLIES_COUNT`: {
      const { suppliesCount } = state

      return update( state,
         {
            suppliesCount: {
               $set: suppliesCount + 1
            }
         }
      )
   }
   case `claims/SET_SHOW_MEDICARE_CHECKLIST`: {
      return update( state,
         {
            showMedicareChecklist: {
               $set: action.show
            }
         }
      )
   }
   case `claims/UPDATE_QUANTITY_SUPPLY_ITEM`: {
      return update( state,
         {
            supplies: {
               [action.itemKey]: {
                  $merge: {
                     quantitySelected: action.quantitySelected
                  }
               }
            }
         }
      )
   }
   case `claims/UPDATE_SIZE_SUPPLY_ITEM`: {
      return update( state,
         {
            supplies: {
               [action.itemKey]: {
                  $merge: {
                     hcpcid: action.hcpcid
                  }
               }
            }
         }
      )
   }
   case `claims/UPDATE_QUANTITY_ORDER_ITEM`: {
      return update( state,
         {
            order: {
               items: {
                  [action.itemKey]: {
                     $merge: {
                        qty: action.quantitySelected
                     }
                  }
               }
            }
         }
      )
   }
   case `claims/TOGGLE_ITEM_SELECTED_FOR_ORDER_STATUS`: {
      // toggle itemSelected on the given item

      const { itemSelected } = state.supplies[action.itemKey]

      return update( state,
         {
            supplies: {
               [action.itemKey]: {
                  itemSelected: {
                     $set: !itemSelected
                  }
               }
            }
         }
      )
   }
   case `claims/UPDATE_MEDICARE_CHECKLIST_ORDER_ITEM`: {

      return update( state,
         {
            order: {
               items: {
                  [action.itemKey]: {
                     medicare_checklist: {
                        reason: {
                           $merge: {
                              [action.reasonKey]: action.val
                           }
                        }
                     }
                  }
               }
            }
         }
      )
   }
   case `claims/SET_SUPPLIES_RESQUEST_STATUS`: {
      return update( state,
         {
            suppliesRequestStatus: {
               $set: action.suppliesRequestStatus
            }
         }
      )
   }
   default: {
      return state
   }
   }
}

export default auth