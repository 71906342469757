
// probably don't need to use this file window.aeroflowBrowser gives same result
export const getBrowser = () => {
   let browser = ( function( agent ) {
      switch ( true ) {
      case agent.indexOf( `edge` ) > -1: return `edge`
      case agent.indexOf( `edg` ) > -1: return `chromium based edge (dev or canary)`
      case agent.indexOf( `opr` ) > -1 && Boolean( window.opr ): return `opera`
      case agent.indexOf( `chrome` ) > -1 && Boolean( window.chrome ): return `chrome`
      case agent.indexOf( `trident` ) > -1: return `ie`
      case agent.indexOf( `firefox` ) > -1: return `firefox`
      case agent.indexOf( `safari` ) > -1: return `safari`
      default: return `other`
      }
   })( window.navigator.userAgent.toLowerCase() )

   return browser

}

export const navigationType =() => {
   let result
   let p
   if ( window.performance.navigation ) {
      result=window.performance.navigation
      if ( result==255 ){ result=4 } // 4 is my invention!
   }
   if ( window.performance.getEntriesByType( `navigation` ) ){
      p=window.performance.getEntriesByType( `navigation` )[0].type
      if ( p==`navigate` ){ result=0 }
      if ( p==`reload` ){ result=1 }
      if ( p==`back_forward` ){ result=2 }
      if ( p==`prerender` ){ result=3 } // 3 is my invention!
   }

   return result
}