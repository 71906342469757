import React, {useEffect, useState} from 'react'
import {getTrackingInfo} from './trackingUtils'
import AppShell from 'components/AppShell'
import theme from 'styles/theme'
import Package from './package'
import Loader from 'components/Loader'


export const Track = ( ) => {
   const [ order, setOrder ] = useState( null )
   const [ showLoader, setShowLoader ] = useState( true )
   const [ showNoResults, setShowNoResults ] = useState( false )
   const currentQueryParams = new URLSearchParams(
      window.location.search
   )
   const hash = currentQueryParams.get( `sgh` ) || currentQueryParams.get( `txt` ) || currentQueryParams.get( `hash` )

   useEffect( () => {
      getTrackingInfo( hash ).then( data => {
         setOrder( data.data.data.Invoices[0].Packages )
         setShowLoader( false )
      })
         .catch( ( err ) => {
            if ( err.isAxiosError ) {
               setShowLoader( false )
               setShowNoResults( true )
            }
         })
   }, [] )

   if ( showLoader )
      return (
         <AppShell theme={theme}>
            <div className="mt-8"><Loader /></div>
         </AppShell> )


   if ( showNoResults || hash === null )
      return ( <AppShell theme={theme}>
         <div className="mt-8 px-3 sm:p-0">{`An error occurred when loading your information. Please call us at 844-276-5588 so we can help troubleshoot.`}</div></AppShell> )


   return (
      <AppShell theme={theme}>

         <h2 className="text-black mb-2 text-center">{`Your Order Has Shipped!`}</h2>
         <p className="w:full sm:w-3/4 text-center mb-4 p-4 max-w-6xl text-black">
            {`Click the link(s) below to track your recent order. Please note that your order could ship and arrive in multiple packages. If this is the case, you will see tracking details for each shipment.`}
         </p>

         {order !== null && order.map( ( _package, i ) => {
            if ( _package.tracking_available ) return (
               <div className="sm:w-2/3 max-w-5xl border border-black">
                  <h3 className="text-left w-full pl-6 sm:pl-3 text-black m-2">{`Package ${_package.package_number}`}</h3>

                  <Package packageNumber={i} invoice={_package} key={_package.package_number} />
               </div>
            )
         })}

      </AppShell>
   )
}


export default Track

