import React from 'react'
import ReactJson from 'react-json-view'
import { getSessionStreamData } from 'utils/session'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
/* eslint-disable no-console */

export const StyledInputsWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`

export const StyledFieldgroup = styled.div`
   padding-right: 20px;
   padding-left: 20px;
   margin: 20px;
`

export const StyledLogoutDiv = styled.div`
   position: absolute;
   top: 0;
   right: 0;
   padding: 0 16px 0 0;
`

export const StyledFilterButton = styled.button`
   background-color: ${props => { return props.isActive ? `DODGERBLUE` : `ROYALBLUE` }};
`

export class SessionStreamView extends React.Component {
   constructor( props ){
      super( props )
      this.state = {
         data: {},
         token: ``,
         active: ``,
         loading: false,
         dd: ``,
         mm: ``,
         yyyy: ``
      }
   }
   setToken = ( e ) => {
      this.setState({
         token: e.target.value
      })
   }
   logout = ( ) => {
      this.setState({
         data: {},
         token: ``
      })
      window.location.reload()
   }

   handleTokenChange = ( e ) => {
      e.preventDefault()
      this.setState({
         token: e.target.value
      })
   }
   getData = async ( issueType ) => {

      if ( !this.state.token ){
         return window.alert( `enter your secure token to fetch data` )
      }

      this.setState({
         active: issueType,
         loading: true
      })

      try {
         let data = await getSessionStreamData( issueType, this.state.token )

         const { Items } = data.data.content

         const getDateArray = ( date ) => {

            const [ dateSansMilliseconds ] = date.split( ` ` )

            const dateArray = dateSansMilliseconds.split( `-` )

            return dateArray
         }

         const getThisDayItems = ( dd, mm, yyyy ) => {
            return Items.filter( i => {

               const [ day, month, year ] = getDateArray( i.date.S )

               return ( dd === day && mm === month && yyyy === year )
            })
         }
         const fetchedItems = getThisDayItems( this.state.dd, this.state.mm, this.state.yyyy )

         const formattedDataSwitchToDDMMYYYY = fetchedItems.map( ii => {
            return Object.assign({}, ii, {
               date: ii.date.S.slice( 3, 5 ) + `-` + ii.date.S.slice( 0, 2 ) + `-` + ii.date.S.split( `-` )[2]
            })
         })

         this.setState({
            data: formattedDataSwitchToDDMMYYYY.reverse(),
            loading: false
         })
      } catch ( err ) {
         console.log( err )
         this.setState({
            loading: false
         })
      }

   }
   handleChange = ( e ) => {
      e.preventDefault()
      this.setState({
         [e.target.id]: e.target.value
      })
   }
   render(){
      const { token, loading, active, data } = this.state

      return (
         <>
            {!token || token.length < 24 ?
               <div>
                  <label>
                     {`You must enter your secret token prior to data retrieval`}
                     <input
                        type="text"
                        value={token}
                        onChange={this.handleTokenChange}
                     />
                  </label>
               </div>
               :
               <div>
                  <StyledInputsWrapper>
                     <StyledFieldgroup>
                        <label>{`month (2 char)`}</label>
                        <input
                           type="text" onChange={this.handleChange}
                           id="mm" value={this.state.mm}
                        />
                     </StyledFieldgroup>
                     <StyledFieldgroup>
                        <label>{`day (2 char)` }</label>
                        <input
                           type="text" onChange={this.handleChange}
                           id="dd" value={this.state.dd}
                        />
                     </StyledFieldgroup>
                     <StyledFieldgroup>
                        <label>{`year (4 char)`}</label>
                        <input
                           type="text" onChange={this.handleChange}
                           id="yyyy" value={this.state.yyyy}
                        />
                     </StyledFieldgroup>
                  </StyledInputsWrapper>
                  <div>
                     <StyledLogoutDiv>
                        <button className="small" onClick={this.logout}>
                           {`Logout`}
                        </button>
                     </StyledLogoutDiv>
                     <div className="flex flex-row content-around">

                        <StyledFilterButton
                           onClick={() => { return this.getData( `login` ) }}
                           className="small"
                           isActive={active === `login`}
                        >
                           {`Login fails`}
                        </StyledFilterButton>

                        <StyledFilterButton
                           onClick={() => { return this.getData( `no-eligible-items` ) }}
                           className="small"
                           isActive={active === `no-eligible-items`}
                        >
                           {`No eligible items`}
                        </StyledFilterButton>

                        <StyledFilterButton
                           onClick={() => { return this.getData( `no-items-found` ) }}
                           className="small"
                           isActive={active === `no-items-found`}
                        >
                           {`No items found`}
                        </StyledFilterButton>

                     </div>


                     <div className="p-8">
                        {loading && <CircularProgress size={24} color="secondary" />}
                        {Object.keys( data ).length > 0 && !loading &&
                  <ReactJson src={data} />}
                     </div>
                  </div>
               </div>
            }
         </>

      )
   }
}

export default SessionStreamView