import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setDisplayMessage } from 'modules/auth/actions'

// this component adds and removes event listeners to set/hide a display message when no network detected
export const NetworkListener = props => {
   const [ isOnline, setNetwork ] = useState( window.navigator.onLine )

   const updateNetwork = () => {
      setNetwork( window.navigator.onLine )
   }

   useEffect( () => {
      if ( isOnline ){
         props.setNetworkMessage({
            type: ``,
            msg: ``
         })
      }

      if ( !isOnline ){
         props.setNetworkMessage({
            type: `error`,
            msg: `No network detected. Please check your internet connection.`
         })
      }

      window.addEventListener( `offline`, updateNetwork )
      window.addEventListener( `online`, updateNetwork )

      return () => {
         window.removeEventListener( `offline`, updateNetwork )
         window.removeEventListener( `online`, updateNetwork )
      }
   })

   return null
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      setNetworkMessage: ( obj ) => {

         return dispatch( setDisplayMessage( obj ) )
      }
   }
}

NetworkListener.propTypes = {
   setNetworkMessage: PropTypes.func.isRequired
}


export default connect( null, mapDispatchToProps )( NetworkListener )