import React from 'react'
import { footerInfo, getDisplayKey } from 'constants/moduleData'
import { PhoneLink, EmailLink } from './AppShell/FooterLinks'

export const NoItemsFoundBlock = ( ) => {
   const pathName = window.location.pathname
   const displayKey = getDisplayKey( pathName )
   const displayInfo = footerInfo[displayKey]

   return (
      <div className="paper mb-6 p-4">
         <p className="text-xl text-center text-afBlue-dark">
            {
               `We cannot find any supplies for you at this time. 
              If you need help with your order please call ${displayInfo[0]} at `
            }
            <PhoneLink number={displayInfo[2]} />
            {
               ` or send us an email to `
            }
            <EmailLink email={displayInfo[3]} />
            {`. You can also click the chat icon at the bottom right of your screen to talk to a support specialist online. `}
         </p>
      </div>
   )
}

export default NoItemsFoundBlock