import React from 'react'
import { RecentOrderDesktopWrapper } from '../styling'
import PropTypes from 'prop-types'
import OrderItemDesktop from './OrderItemDesktop'

function RecentOrderGridDesktop({ items }) {
   return (
      <RecentOrderDesktopWrapper>

         <div className="cell px-5">
            <span className="text-sm">{`Product`}</span>
         </div>

         <div className="cell px-5 self-stretch">
            <span className="text-sm">{`Status`}</span>
         </div>

         <div className="cell px-5 self-stretch">
            <span className="text-sm">{`Ship Date`}</span>
         </div>

         <div className="cell px-5 self-stretch">
            <span className="text-sm">{`Tracking`}</span>
         </div>

         {
            items.map( ( item, i ) => {

               return (
                  <OrderItemDesktop
                     item={item}
                     // eslint-disable-next-line react/no-array-index-key
                     key={i}
                  />
               )
            })
         }

      </RecentOrderDesktopWrapper>
   )
}

RecentOrderGridDesktop.propTypes = {
   items: PropTypes.array
}

export default RecentOrderGridDesktop
