import React, { Fragment } from 'react'
import RecentOrderGridDesktop from './RecentOrderDesktop'
import Media from 'react-media'
import PropTypes from 'prop-types'
import RecentOrderMobile from './RecentOrderMobile'

function RecentOrderGridDisplay({ mostRecentOrder }) {

   const { items } = mostRecentOrder.order

   return (
      <div>

         <Media
            queries={{
               small: `(max-width: 599px)`,
               medium: `(min-width: 600px)`
            }}
         >
            {matches => {
               return (
                  <Fragment>

                     {
                        matches.small &&
                        <RecentOrderMobile mostRecentOrder={mostRecentOrder} />
                     }
                     {
                        matches.medium &&
                        <RecentOrderGridDesktop items={items} />
                     }
                  </Fragment>
               )
            }}
         </Media>
      </div>
   )
}

RecentOrderGridDisplay.propTypes = {
   mostRecentOrder: PropTypes.object
}

export default RecentOrderGridDisplay
