import React from 'react'
import PropTypes from 'prop-types'
import { PhoneLink, EmailLink } from './FooterLinks'
import { PipeSpacer } from 'components/PipeSpacer'
import styled from 'styled-components'

// @NOTE we have a skinny footer on home page and not much content - safe to fix position
// otherwise on mobile fixed position causes usability issues as it takes up lots of screen real estate
const FooterWrapper = styled.div`
      width: 100%;
      height: auto;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 15px;
      color: #143F69;
      background-color: aliceblue;
      margin-top: 25px;
      border-top: 1px dashed #b7d3ea;
      @media (max-width: 600px) {
         position: ${props => { return ( props.pathname === `/` ) ? `fixed` : `relative` }};
         position: ${props => { return ( props.pathname === `/` ) ? 0 : `-200px` }};
      }
`

const Footer = ( props ) => {
   const { displayInfo, pathname } = props

   return (
      <FooterWrapper pathname={pathname}>
         <div>

            <div>
               <p className="text-center m-0">
                  {displayInfo[0]}
                  <PipeSpacer color={`rgba(0, 0, 0, 0.3)`} />
                  {displayInfo[1]}
               </p>
            </div>

            {Boolean( displayInfo[2] ) && Boolean( displayInfo[3] ) &&
               <div>

                  <p className="text-center m-0 pt-3">
                     <PhoneLink color="#143F69" number={displayInfo[2]} />
                     <PipeSpacer color={`rgba(0, 0, 0, 0.3)`} />
                     <EmailLink color="#143F69" email={displayInfo[3]} />
                  </p>
               </div>
            }
         </div>
      </FooterWrapper>
   )
}

Footer.propTypes = {
   displayInfo: PropTypes.array,
   pathname: PropTypes.string
}

export default Footer