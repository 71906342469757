import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConfirmModal from './ConfirmModal'
import { connect } from 'react-redux'
import ItemIsRequired from './ItemIsRequired'
import FormGroup from '@material-ui/core/FormGroup'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { updateQuantityOrderItem, updateMedicareChecklistOrderItem } from 'modules/claims/actions'

class ItemsChecklist extends Component {
   constructor( props ) {
      super( props )
      this.state = {
         open: false
      }
   }
   handleChange = ( val, type, itemKey ) => {
      const { claimPageItemsPreOrder } = this.props

      this.props.updateItemMedicareChecklist(
         val,
         type,
         itemKey
      )
      if ( val === `false` ) {
         this.props.updateItemQuantity(
            itemKey,
            0
         )
      } else {
         // since we update state.claims.supplies before we package up state.claims.order we can
         // refer to the selectedQty of the original item
         this.props.updateItemQuantity(
            itemKey,
            claimPageItemsPreOrder[itemKey].quantitySelected
         )
      }

      // if a false is provided give let them know item won't be ordered
      if ( val === `false` ) {
         return this.setState({
            open: true
         })
      }
   }
   closeModal = () => {
      this.setState({
         open: false
      })
   }
   render() {
      const { items, order, replacementTypeText } = this.props
      if ( items && Object.keys( items ).length > 0 ) {
         return (
            <React.Fragment>
               <ConfirmModal
                  open={this.state.open}
                  closeModal={this.closeModal}
                  replacementTypeText={replacementTypeText}
               />
               <FormGroup>
                  <Table>
                     <TableBody>
                        {Object.keys( this.props.items ).map( ( item ) => {

                           return (
                              <ItemIsRequired
                                 // eslint-disable-next-line react/no-array-index-key
                                 key={item}
                                 item={items[item]}
                                 order={order}
                                 handleChange={this.handleChange}
                              />
                           )
                        })}
                     </TableBody>
                  </Table>
               </FormGroup>
            </React.Fragment>
         )
      }

      return null
   }
}

export const mapStateToProps = ( state ) => {
   return {
      claimPageItemsPreOrder: state.claims.supplies
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      updateItemQuantity: ( itemKey, val ) => {
         dispatch( updateQuantityOrderItem( itemKey, val ) )
      },
      updateItemMedicareChecklist: ( val, reason, itemKey ) => {
         dispatch( updateMedicareChecklistOrderItem( val, reason, itemKey ) )
      }
   }
}

ItemsChecklist.propTypes = {
   claimPageItemsPreOrder: PropTypes.array,
   updateItemMedicareChecklist: PropTypes.func.isRequired,
   updateItemQuantity: PropTypes.func.isRequired,
   order: PropTypes.object,
   items: PropTypes.object,
   replacementTypeText: PropTypes.string.isRequired
}

export default connect( mapStateToProps, mapDispatchToProps )( ItemsChecklist )