import React from 'react'
import urologySwoosh from 'resources/img/urology_swoosh_logo.svg'
import styled from 'styled-components'

const StyledImg = styled.img`
   width: 100px;
	max-height: 50px;
	@media (max-width: 600px) {
		max-height: 24px;
	}
`

const LogoContainer = ( props ) => {
   return (
      <div>
         <StyledImg
            src={urologySwoosh}
            alt="Aeroflow Urology"
         />
      </div>
   )
}

export default LogoContainer