import React from 'react'
import PropTypes from 'prop-types'
import { noEligibleItemsClaimSectionContent } from 'modules/claims/constants'
import styled from 'styled-components'
import { PhoneLink } from 'components/AppShell/FooterLinks'
import { getDisplayKey, moduleData } from 'constants/moduleData'

const UserName = styled.b`
   text-transform: lowercase;
   display: inline-block;
   &::first-letter {
      text-transform: capitalize;
   }
`

const Heading = ( props ) => {
   return (
      <h2 className="h2 text-center">
         {props.children}
      </h2>
   )
}

Heading.propTypes = {
   children: PropTypes.node
}

const Secondary = ( props ) => {
   return (
      <p className="text-center af-dark-blue" >
         {props.children}
      </p>
   )
}

Secondary.propTypes = {
   children: PropTypes.node
}

const Greeting = ({firstName, lastName, notEligible = false}) => {
   return (
      <>
         {notEligible
            ? <>{`No items currently eligible for `}<UserName>{firstName}</UserName> <UserName>{lastName}</UserName></>
            : <>{`Reorder `}<UserName>{firstName}</UserName> <UserName>{lastName}</UserName>{`'s supplies`}</>
         }
      </>
   )
}


const ClaimText = ( props ) => {

   const { hasEligibleSupplies, claimSectionHeadline, claimSectionContent, firstName, lastName } = props
   const profitCenter = getDisplayKey( window.location.pathname )

   return (
      <React.Fragment>
         <div className="p-4">
            <Heading>
               {!hasEligibleSupplies && <Greeting firstName={firstName} lastName={lastName} notEligible />}
               {hasEligibleSupplies && ( claimSectionHeadline || <Greeting firstName={firstName} lastName={lastName} /> )}
            </Heading>
         </div>
         <div className="p-4">
            <Secondary>
               {!hasEligibleSupplies && noEligibleItemsClaimSectionContent}
               {hasEligibleSupplies && <i>{claimSectionContent} {<PhoneLink number={moduleData[profitCenter].pfPhone} color="#000" />}</i>}
            </Secondary>
         </div>
         <div className="p-4">
            <Secondary>
               <i>{`If you need sizing or product changes, please let our Continence Care Specialists know by calling`} {<PhoneLink number={moduleData[profitCenter].pfPhone} color="#000" />}</i>
            </Secondary>
         </div>
      </React.Fragment>
   )
}

ClaimText.propTypes = {
   claimSectionContent: PropTypes.string,
   claimSectionHeadline: PropTypes.string,
   claimSectionChangesContent: PropTypes.string,
   hasEligibleSupplies: PropTypes.bool,
   firstName: PropTypes.string,
   lastName: PropTypes.string
}


export const ClaimTextAddOns = ( props ) => {

   return (
      <React.Fragment>
         <div className="p-4">
            <Heading>
               {`Additional Supplies Available Through Your Insurance`}
            </Heading>
         </div>
         <div className="p-4">
            <Secondary>
               {`The items below represent additional items covered through your Medicaid insurance. Selecting these items means that we will send a new prescription to your doctor to add these to your plan of care. These items will ship to you when we receive documentation from your doctor.`}
            </Secondary>
            <Secondary>
               <span className="font-bold">{`Your currently prescribed items above will still ship on the day that they are due.`}</span>
            </Secondary>
         </div>
      </React.Fragment>
   )
}

Greeting.propTypes = {
   firstName: PropTypes.string,
   lastName: PropTypes.string,
   notEligible: PropTypes.bool
}

export default ClaimText
