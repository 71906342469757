import { reportToSentry } from "utils/reportToSentry"

export function useTranslateStatus( order ) {

   const status = order?.items[0]?.status
   // if for some reason there is not a status we want ti reutrn an empty string
   if ( !status ) return ``

   switch ( status ) {
   case `Awaiting Processing`:
      return {
         status: `Order received and under review`,
         message: `Order must be corrected before shipment.`,
         displayTootip: true,
         displayDos: false
      }
   case `Awaiting Fulfillment`:
      return {
         status: `Your order is in process and will ship on `,
         message: `Tracking will be available after your order ships on `,
         displayTootip: false,
         displayDos: true
      }
   case `Shipped`:
      return {
         status: `Order shipped and on the way to you`,
         message: ``,
         displayTootip: false,
         displayDos: true
      }
   case `Delivered`:
      return {
         status: `Order has been delivered`,
         message: ``,
         displayTootip: false,
         displayDos: false
      }
   case `Cancelled`:
      return {
         status: `Order cancelled`,
         message: ``,
         displayTootip: false,
         displayDos: false
      }
   default:

      reportToSentry( `useTranslateStatus Status ${status} not found or not matched.` )

      return {
         status: status,
         message: ``,
         displayTootip: false
      }
   }
}

