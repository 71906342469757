import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { updateQuantitySupplyItem } from 'modules/claims/actions'

const QuantityOptionsSelect = ( props ) => {
   // we assign the value of maxQuantity on the response payload to quantitySelected before supply items are put in redux store
   const { quantityOptions, changeQuantity, quantitySelected } = props

   return (
      <Select
         input={<Input name="qty" value={quantitySelected} />}
         onChange={( e ) => {
            return changeQuantity( e.target.value )
         }}
      >
         {quantityOptions.map( quantityInt => {
            return (
               <MenuItem key={quantityInt} value={quantityInt}>{quantityInt}</MenuItem>
            )
         })}
      </Select>
   )
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
   return {
      changeQuantity: ( quantity ) => {
         return dispatch( updateQuantitySupplyItem( ownProps.itemKey, quantity ) )
      }
   }
}

QuantityOptionsSelect.propTypes = {
   quantityOptions: PropTypes.array,
   changeQuantity: PropTypes.func.isRequired,
   quantitySelected: PropTypes.number
}

const ConnectedQuantityOptionsSelect = connect( null, mapDispatchToProps )( QuantityOptionsSelect )

export default ConnectedQuantityOptionsSelect