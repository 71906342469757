import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Alert from './AFDialog'
import { stepSchema } from './config'

/** eslint-disable no-console */

const UroThreeMonthPromotion = ( props ) => {

   const [ activeStep, setActiveStep ] = useState( 0 )
   const { showPromotion, setShowPromotion, handlePromotionAccept } = props

   const handleToggleShowAlert = () => {

      setShowPromotion( !showPromotion )
   }

   const handleButtonOneFunctionCallback = ( ) => {
      if ( activeStep === 0 || activeStep === 1 ){
         // done!
         handlePromotionAccept( true )
         setActiveStep( 2 )
      }

      if ( activeStep == 2 ){
         handleToggleShowAlert()
      }

   }

   const handleButtonTwoFunctionCallback = ( ) => {

      if ( activeStep === 0 ){
         setActiveStep( 1 )
      }
      if ( activeStep === 1 ){
         handleToggleShowAlert()
      }
   }

   return (
      <div>
         <Alert
            alertStateKey="uroThreeMMSupply"
            ariaLabel="Website Promotion"
            open={showPromotion}
            toggleShowAlert={handleToggleShowAlert}
            headerText={stepSchema[activeStep].headerText}
            subText={stepSchema[activeStep].subText}
            ariaDesc={`Urology 3 months supply opt-in promotion form`}
            headerInfoIcon={stepSchema[activeStep].headerInfoIcon}
            msgText={[ ...stepSchema[activeStep].msgText ]}
            buttonOneText={stepSchema[activeStep].buttonOneText}
            buttonOneFunctionCallback={handleButtonOneFunctionCallback}
            buttonTwoFunctionCallback={handleButtonTwoFunctionCallback}
            buttonTwoText={stepSchema[activeStep].buttonTwoText}
            disableBackdropClick
            disableEscapeKeyDown
         />
      </div>
   )
}

UroThreeMonthPromotion.propTypes = {
   handlePromotionAccept: PropTypes.func.isRequired,
   showPromotion: PropTypes.func.isRequired,
   setShowPromotion: PropTypes.func.isRequired
}

export default UroThreeMonthPromotion