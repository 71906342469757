
export const getReplacementTypeText = ( rootPath ) => {
   let replacementTypeText

   switch ( rootPath ) {
   case `urology`: {
      replacementTypeText = `Urological`
      break
   }
   default: {
      replacementTypeText = ``
   }
   }

   return replacementTypeText

}