import React from 'react'
import PropTypes from 'prop-types'
import SupplyItem from './SupplyItem'
import { SuppliesListMobileComponentWrapper } from './styled'
import { useSelector } from 'react-redux'

/* eslint-disable react/no-array-index-key */
// @NOTE supplies list mobile is a flex box implementation

export const SuppliesListMobile = ( props ) => {
   const { itemKeys, allowQuantityChanges, estimatedShipDate, type, sizeError } = props

   const items = useSelector( ( state ) => { return state.claims.supplies })

   return (
      <SuppliesListMobileComponentWrapper>
         {itemKeys.map( ( item ) => {
            return (
               <SupplyItem
                  sizeError={sizeError}
                  key={item}
                  type={type}
                  item={items[item]}
                  estimatedShipDate={estimatedShipDate}
                  allowQuantityChanges={allowQuantityChanges}
                  mobileItem
               />
            )
         })
         }
      </SuppliesListMobileComponentWrapper>
   )
}

SuppliesListMobile.propTypes = {
   itemKeys: PropTypes.array,
   estimatedShipDate: PropTypes.string,
   allowQuantityChanges: PropTypes.bool,
   type: PropTypes.oneOf( [ `addon` ] ),
   sizeError: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] )
}

export default SuppliesListMobile