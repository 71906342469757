import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { productOrderNameSizeQuantity } from 'modules/claims/utils.js'
import { hasGloves } from 'modules/claims/utils'
import { GLOVE_SIZE_OPTIONS } from 'modules/claims/constants'
/* eslint-disable react/no-array-index-key */

export const OrderSummary = ( props ) => {
   const { order: { items }, estimatedShipDate } = props

   const addOnItems = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === true })
   const regularItems = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === false })

   return (
      <>
         <h6 className="af-h6">{`Order details:`}</h6>
         {regularItems?.length > 0 &&
         <div className="mt-2">
            <div className="border-0 border-b border-dashed border-afBlue-secondary mb-3 pb-5 text-center">
               <h6 className="af-h6">
                  {`These items will ship on ${estimatedShipDate}.`}
               </h6>
               {regularItems.map( ( item, i ) => {
                  const { name, size, qty } = items[item]

                  // this is the cleanest hook to segment non mask items

                  if ( qty > 0 ) {
                     return (
                        <p className="py-1 m-0 text-afBlue-dark" key={i}>
                           {productOrderNameSizeQuantity( name, size, qty )}
                        </p>
                     )
                  } else {
                     return null
                  }
               })}
            </div>
         </div>
         }
         {addOnItems?.length > 0 &&
         <div className="mt-8">
            <div className="border-0 border-b border-dashed border-afBlue-secondary mb-3 pb-5 text-center">
               <h6 className="af-h6">
                  {`Additional Items Requested`}
               </h6>
               <p className="py-1 m-0 text-afBlue-dark" >
                  {`These items WILL NOT ship today, Aeroflow Urology will fax a new order to your doctor, and when we receive your paperwork back, the below items will ship at that time.`}
               </p>
               {addOnItems.map( ( item, i ) => {
                  const { name, size, qty } = items[item]

                  //

                  if ( qty > 0 ) {
                     const hasGlove = hasGloves( items[item].hcpcid )

                     const stubSize = hasGlove && GLOVE_SIZE_OPTIONS.find( elem => {
                        return elem.hcpcid === items[item].hcpcid
                     })

                     return (
                        <p className="py-1 m-0 text-afBlue-dark" key={i}>
                           {productOrderNameSizeQuantity( name, size || stubSize?.value, qty )}
                        </p>
                     )
                  } else {
                     return null
                  }
               })}
            </div>
         </div>
         }
      </>
   )
}

const mapStateToProps = ( state ) => {

   return {
      order: state.claims.order,
      estimatedShipDate: state.claims.estimatedShipDate
   }
}

OrderSummary.propTypes = {
   order: PropTypes.shape({
      items: PropTypes.oneOfType( [ PropTypes.object, PropTypes.array ] )
   }),
   estimatedShipDate: PropTypes.string
}

export default connect( mapStateToProps )( OrderSummary )