import React from 'react'
import PropTypes from 'prop-types'

function SearchDoctorHeading({ headerStyle }) {

   return (
      <>
         <h1 className={`${headerStyle ?? `text-3xl font-light`} text-center mb-3 mt-0`}>{`Search for your Doctor`}</h1>
         <p className="font-light text-center mb-10">{`Please provide your doctor's information. We will send a request to your doctor to approve your healthcare supplies.`}</p>
      </>
   )
}

SearchDoctorHeading.propTypes = {
   headerStyle: PropTypes.string
}

export default SearchDoctorHeading