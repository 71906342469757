import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import DOBErrorBlock from './DOBErrorBlock'
import InputDateResponsive from './fields/InputDateResponsive'

export const FieldgroupDOB = props => {

   const { errors, label } = props

   let dobLabel = ``
   if ( label === `` || !label ) {
      dobLabel = `Patient's Date of Birth:`
   } else {
      dobLabel = label
   }

   return (
      <div className="m-4">
         <InputLabel>
            <p className="text-afBlue-dark">
               <span className="font-bold">
                  {dobLabel}
               </span>
            </p>
            <div>
               <InputDateResponsive field="dob" />
            </div>
         </InputLabel>
         <div>
            <DOBErrorBlock errors={errors} />
         </div>
      </div>
   )
}

FieldgroupDOB.propTypes = {
   errors: PropTypes.shape({
      month: PropTypes.string,
      day: PropTypes.string,
      year: PropTypes.string
   }),
   label: PropTypes.string
}

export default FieldgroupDOB