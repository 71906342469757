import React from 'react'
import PropTypes from 'prop-types'
import { getRootUrlPath } from 'utils/url'

export const AgreementCheckboxLabel = props => {
   const { setDisplayTerms } = props

   const profitCenter = getRootUrlPath( window.location.pathname )

   return (
      <React.Fragment>
         {profitCenter === `urology` ?
            `I authorize Aeroflow Healthcare to contact me by phone, email, or SMS. By checking this box, I confirm I am not receiving these supplies from any other provider and I agree to Aeroflow's `
            :
            `I authorize Aeroflow Healthcare to contact me by text, phone and email. By checking this box, I confirm that I have not received incontinence supplies from another provider in the last 30 days. I also agree to Aeroflow's `
         }
         <a
            className="blue"
            href="#"
            onClick={setDisplayTerms}
         >
            {` Terms and Conditions.`}
         </a>
      </React.Fragment>
   )
}

AgreementCheckboxLabel.propTypes = {
   setDisplayTerms: PropTypes.func.isRequired
}

export default AgreementCheckboxLabel