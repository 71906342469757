import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SuppliesList from './components/SuppliesList'
import { updateSupplies, getSupplies, setOrder, setThreeMonthPromoAccepted } from './actions'
import ClaimText, { ClaimTextAddOns } from './components/ClaimText'
import { hasGloves, getOrderItems, getSerializedOrderItems, hasEligibleSupplies, isOneSelectedSupplyAllowAlone } from 'modules/claims/utils'
import { moduleData, getDisplayKey } from 'constants/moduleData'
import OrderButton from 'components/OrderButton'
import Alert from './components/Alert'
import UroThreeMonthPromotion from 'modules/uroThreeMonthPromotion/index.js'
import { trackDontAllowAloneAnalytic } from 'modules/analytics/utils'
import MostRecentOrder from 'modules/mostRecentOrder'
import { GLOVE_NO_SIZE_HCPCID } from './constants'

class ClaimSupplies extends Component {
   constructor( props ) {
      super()
      this.state = {
         showOnlyItemNotAllowedAlert: false,
         showThreeMonthPromo: false,
         addOnGloveSizeMissingError: ``
      }
   }
   componentDidMount = () => {
      const {
         patient_id,
         patient_dob,
         getSupplies,
         unique_hash,
         history: { location: { pathname } }
      } = this.props

      const { pfPk } = moduleData[getDisplayKey( pathname )]

      this.shouldShowThreeMonthPromo( pfPk )

      return getSupplies({
         patient_id,
         patient_dob,
         pfPk,
         unique_hash
      })

   }
   shouldShowThreeMonthPromo = ( ) => {

      const { threeMonthPromoEligible, history: { location: { pathname } }} = this.props

      const { pfPk } = moduleData[getDisplayKey( pathname )]

      if ( pfPk == 3 && threeMonthPromoEligible ){
         this.handleSetShowPromotion( true )
      }

   }

   handleSetShowPromotion = ( bool ) => {

      return this.setState({
         showThreeMonthPromo: bool
      })
   }
   componentDidUpdate = ( prevProps ) => {

      if ( !prevProps.threeMonthPromoEligible && this.props.threeMonthPromoEligible ){
         this.shouldShowThreeMonthPromo( )
      }
      // the user should never receive only promo items to resupply from internal
      if ( isOneSelectedSupplyAllowAlone( prevProps.supplies ) && !isOneSelectedSupplyAllowAlone( this.props.supplies ) ){
         trackDontAllowAloneAnalytic()
         this.setState({
            showOnlyItemNotAllowedAlert: true
         })
      }

      if (
         prevProps.addOnGloveSupplyClaimedWithSize !== this.props.addOnGloveSupplyClaimedWithSize
      ){
         // not claimed error, claimed and now has size, remove error
         if ( this.props.addOnGloveSupplyClaimedWithSize ){
            this.setState({
               addOnGloveSizeMissingError: ``
            })
         }
      }
   }
   handlePromotionAccept = () => {
      this.props.handleSetThreeMonthPromotionAccept()
   }
   handleSetAlert = ( bool, key ) => {
      this.setState({
         [key]: bool
      })
   }
   handleNext = () => {

      if ( !this.props.addOnGloveSupplyClaimedWithSize && this.props.addOnGloveSupplyClaimed
      ){
         return this.setState({
            addOnGloveSizeMissingError: `Please select glove size`
         })
      }

      const {
         supplies,
         showMedicareChecklist,
         setOrder,
         history,
         history: { location: { pathname } }
      } = this.props

      const orderItems = getOrderItems( supplies )

      // medicare items mapping needs to have same key as supply item mapping so the quantity can be referenced
      setOrder({
         items: getSerializedOrderItems( orderItems, showMedicareChecklist )
      })

      if ( showMedicareChecklist ) {
         return history.push( `${pathname}/checklist` )
      }

      if ( !showMedicareChecklist ) {
         return history.push( `${pathname}/confirm-address` )
      }

   }
   render() {
      const {
         supplies,
         allowChanges,
         suppliesClaimed,
         first_name,
         last_name,
         history: { location: { pathname } },
         // source,
         suppliesRequestStatus,
         orderHistoryError,
         estimatedShipDate
      } = this.props

      const {
         headline,
         claimSectionHeadline,
         claimSectionChangesContent
      } = moduleData[getDisplayKey( pathname )]

      const eligibleSupplies = hasEligibleSupplies( supplies )

      const oneSelectedSupplyAllowAlone = isOneSelectedSupplyAllowAlone( supplies )

      const { showThreeMonthPromo } = this.state

      const hasAddOnSupplies = Object.keys( supplies ).filter( supplyKey => { return supplies[supplyKey].add_on === true })

      const claimSectionContent = `Select the free products you’d like to pre-order. Placing your order now will ensure a smooth delivery. Your items are estimated to ship on ${estimatedShipDate}. You will receive a text message or email from Aeroflow Urology when your order has shipped.`

      return (
         <React.Fragment>

            <UroThreeMonthPromotion
               showPromotion={showThreeMonthPromo}
               setShowPromotion={this.handleSetShowPromotion}
               handlePromotionAccept={this.handlePromotionAccept}
            />

            <Alert
               alertStateKey="showOnlyItemNotAllowedAlert"
               open={this.state.showOnlyItemNotAllowedAlert}
               showAlert={this.handleSetAlert}
               ariaLabel="Website notification"
               ariaDesc="You are attempting to place an order with an item(s) that cannot be provided alone.  Please select your primary incontinence product before placing your order.
               If you have any questions, please chat with a live representative or call 844-276-5588 to speak with a Continence Care Specialist."
               msgText={[ `You are attempting to place an order with an item(s) that cannot be provided alone.  Please select your primary incontinence product before placing your order.   
               If you have any questions, please chat with a live representative or call 844-276-5588 to speak with a Continence Care Specialist.` ]}
               btnText="OK"
            />
            {headline &&
               <div className="ma-24">
                  <h6 className="h6 text-center">
                     {headline}
                  </h6>
               </div>}

            <div className="paper">
               <Container
                  className="p-4"
                  id="claims-container"
               >
                  {suppliesRequestStatus === `complete` && supplies.length !== 0 &&
                  <ClaimText
                     hasEligibleSupplies={eligibleSupplies}
                     claimSectionHeadline={claimSectionHeadline}
                     claimSectionContent={claimSectionContent}
                     claimSectionChangesContent={claimSectionChangesContent}
                     firstName={first_name}
                     lastName={last_name}
                  />}
                  { !orderHistoryError && !eligibleSupplies && <MostRecentOrder collapsible={false} /> }
                  <SuppliesList
                     suppliesRequestStatus={suppliesRequestStatus}
                     hasEligibleSupplies={eligibleSupplies}
                     allowQuantityChanges={allowChanges}
                     firstName={first_name}
                     lastName={last_name}
                  />
                  { !orderHistoryError && eligibleSupplies && <MostRecentOrder collapsible /> }
               </Container>
            </div>

            {hasAddOnSupplies.length > 0 &&
               <div className="paper">
                  <Container
                     className="p-4"
                     id="claims-container"
                  >
                     <ClaimTextAddOns />
                     <SuppliesList
                        type="addon"
                        sizeError={this.state.addOnGloveSizeMissingError}
                        suppliesRequestStatus={suppliesRequestStatus}
                        hasEligibleSupplies={eligibleSupplies}
                        allowQuantityChanges={allowChanges}
                     />
                  </Container>
               </div>
            }

            <Grid container spacing={0} justify="center" >
               <Grid item xs={12} >
                  <Grid container justify="center" spacing={0}>
                     <Grid xs={6} item>
                        <div className="my-8 mx-auto text-center">

                           <OrderButton
                              disabled={Boolean( !suppliesClaimed || !eligibleSupplies || !oneSelectedSupplyAllowAlone )}
                              onClick={this.handleNext}
                           >
                              {`Next`}
                           </OrderButton>

                           {this.state.addOnGloveSizeMissingError &&
                              <p className=" text-red-500">{this.state.addOnGloveSizeMissingError}</p>
                           }
                        </div>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </React.Fragment>
      )
   }
}

const mapStateToProps = state => {

   const suppliesClaimed = Object.keys( state.claims.supplies ).find( itemKey => {
      const {supplies} = state.claims

      return supplies[itemKey].itemSelected
   })

   const addOnGloveSupplyClaimedWithSize = Object.keys( state.claims.supplies ).find( itemKey => {
      const {supplies} = state.claims

      return supplies[itemKey].itemSelected && supplies[itemKey].add_on && hasGloves( supplies[itemKey].hcpcid ) && supplies[itemKey].hcpcid !== GLOVE_NO_SIZE_HCPCID
   })

   const addOnGloveSupplyClaimed = Object.keys( state.claims.supplies ).find( itemKey => {
      const {supplies} = state.claims

      return supplies[itemKey].itemSelected && supplies[itemKey].add_on && hasGloves( supplies[itemKey].hcpcid )
   })

   return {
      addOnGloveSupplyClaimedWithSize,
      suppliesClaimed: Boolean( suppliesClaimed ),
      supplies: state.claims.supplies,
      patient_id: state.auth.user.patient_id,
      patient_dob: state.auth.user.patient_dob,
      first_name: state.auth.user.first_name,
      last_name: state.auth.user.last_name,
      unique_hash: state.auth.user.unique_hash,
      showMedicareChecklist: state.claims.showMedicareChecklist,
      threeMonthPromoEligible: state.claims.threeMonthPromoEligible,
      suppliesRequestStatus: state.claims.suppliesRequestStatus,
      orderHistoryError: state.auth.orderHistoryError,
      estimatedShipDate: state.claims.estimatedShipDate,
      addOnGloveSupplyClaimed
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      getSupplies: ( info ) => {

         return dispatch( getSupplies( info ) )
      },
      updateSupplies: ( supply ) => {
         return dispatch( updateSupplies( supply ) )
      },
      setOrder: ( order ) => {
         return dispatch( setOrder( order ) )
      },
      handleSetThreeMonthPromotionAccept: ( ) => {
         return dispatch( setThreeMonthPromoAccepted( true ) )
      }
   }
}

ClaimSupplies.propTypes = {
   updateSupplies: PropTypes.func.isRequired,
   patient_id: PropTypes.number,
   patient_dob: PropTypes.string,
   first_name: PropTypes.string,
   last_name: PropTypes.string,
   setOrder: PropTypes.func.isRequired,
   getSupplies: PropTypes.func.isRequired,
   unique_hash: PropTypes.string.isRequired,
   suppliesClaimed: PropTypes.bool,
   supplies: PropTypes.object,
   showMedicareChecklist: PropTypes.bool,
   history: PropTypes.shape({
      location: PropTypes.shape({
         pathname: PropTypes.string
      }),
      push: PropTypes.func.isRequired
   }),
   allowChanges: PropTypes.bool,
   source: PropTypes.string,
   threeMonthPromoEligible: PropTypes.bool,
   handleSetThreeMonthPromotionAccept: PropTypes.func,
   suppliesRequestStatus: PropTypes.string,
   orderHistoryError: PropTypes.bool,
   addOnGloveSupplyClaimedWithSize: PropTypes.object,
   addOnGloveSupplyClaimed: PropTypes.object
}

const ConnectedClaimSupplies = connect( mapStateToProps, mapDispatchToProps )( ClaimSupplies )

export default withRouter( ConnectedClaimSupplies )