import React, { useState } from 'react'
import CurrentDoctor from './CurrentDoctor'
import DoctorSearch from './DoctorSearch'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function DoctorDisplay({ onSuccess, forceUpdate, headerStyle, containerStyle, doctor }) {

   const [ searchActive, setSearchActive ] = useState( !doctor || ( !doctor.firstName && !doctor.lastName ) || !doctor.phone || forceUpdate )

   if ( !searchActive ) return (
      <CurrentDoctor
         setSearchActive={setSearchActive}
         headerStyle={headerStyle}
         containerStyle={containerStyle}
      />
   )

   return (
      <DoctorSearch
         headerStyle={headerStyle}
         containerStyle={containerStyle}
         setSearchActive={setSearchActive}
         onSuccess={onSuccess}
         forceUpdate={forceUpdate}
      />
   )
}

DoctorDisplay.propTypes = {
   onSuccess: PropTypes.func,
   forceUpdate: PropTypes.bool,
   headerStyle: PropTypes.string,
   containerStyle: PropTypes.string,
   doctor: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string
   })
}

const mapStateToProps = state => {
   return {
      doctor: state.doctor.doctor
   }
}

const ConnectedDoctorDisplay= connect( mapStateToProps, {})( DoctorDisplay )

export default withRouter( ConnectedDoctorDisplay )