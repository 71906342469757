import React from 'react'
import PropTypes from 'prop-types'
import { IoMdCloseCircle } from 'react-icons/io'

const Modal = ({ isOpen, handleModalClose, title, subHeading, subHeadingClassname, children, requireBtnConfirm = false }) => {

   if ( !isOpen ) return null

   return (
      <div className="fixed inset-0 z-50 overflow-auto bg-charcoal bg-opacity-70" onClick={handleModalClose}>
         <div className="min-w-screen h-screen animated fadeIn faster left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none">
            <div className="w-full max-w-xs md:max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white">
               { !requireBtnConfirm &&
                  <IoMdCloseCircle className="text-afBlue-dark cursor-pointer text-3xl absolute right-2 top-2 hover:opacity-80" onClick={handleModalClose} />
               }
               <div className="text-center w-full max-w-lg">
                  <h1>{title}</h1>
               </div>
               <h2 className={subHeadingClassname ?? `text-xl text-black pl-4`}>{subHeading}</h2>
               {children}
            </div>
         </div>
      </div>
   )
}

Modal.propTypes = {
   handleModalClose: PropTypes.func,
   isOpen: PropTypes.bool,
   openModal: PropTypes.bool,
   title: PropTypes.string,
   subHeading: PropTypes.string,
   children : PropTypes.node,
   requireBtnConfirm: PropTypes.bool,
   subHeadingClassname: PropTypes.string
}

export default Modal
