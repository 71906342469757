import React from 'react'
import LocationChangeForm from './LocationChangeForm'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

function ZipDisplay({ zipDisplay, setZipDisplay, doctorInput }) {

   const { range, zipCode } = doctorInput

   if ( zipDisplay ) return <LocationChangeForm setZipDisplay={setZipDisplay} />

   return (
      <div>
         <div className="flex flex-col base:flex-row justify-between px-5 my-3">
            <p className="font-light text-black">{`Within ${range} mile${range > 1 ? `s` : ``} from ${zipCode}`}</p>
            <p
               className="underline text-teal decoration-teal hover:opacity-80 cursor-pointer font-bold"
               onClick={() => { setZipDisplay( true ) }}
            >
               {`Change`}
            </p>
         </div>
      </div>
   )
}

ZipDisplay.propTypes = {
   zipDisplay: PropTypes.bool,
   setZipDisplay: PropTypes.func,
   doctorInput: PropTypes.shape({
      range: PropTypes.number,
      zipCode: PropTypes.string
   })
}

const mapStateToProps = state => {
   return {
      doctorInput: state.doctor.doctorInput
   }
}

const ConnectedZipDisplay = connect( mapStateToProps, {})( ZipDisplay )

export default withRouter( ConnectedZipDisplay )