import update from 'immutability-helper'

const INITIAL_STATE = {
   user: {},
   isAuthenticated: false,
   displayMessage: {},
   persistLogin: false,
   sessionLoading: false,
   loginError: false,
   userSubmittedDoctorData: {},
   authorizePCustomerToken: ``,
   authorizePPaymentMethods: [],
   orderHistory: [],
   orderHistoryError: false
}

const auth = ( state = INITIAL_STATE, action ) => {
   switch ( action.type ) {
   case `auth/RESET_AUTH`: {
      return INITIAL_STATE
   }
   case `auth/SET_USER`: {
      return update( state,
         {
            user: {
               $set: action.user
            }
         }
      )
   }
   case `auth/UPDATE_USER`: {
      return update( state,
         {
            user: {
               [action.key]: {
                  $set: action.value
               }
            }
         }
      )
   }
   case `auth/AUTHENTICATE_USER`: {
      return update( state,
         {
            isAuthenticated: {
               $set: action.isAuthenticated
            }
         }
      )
   }
   case `auth/SET_PERSIST_LOGIN`: {
      return update( state,
         {
            persistLogin: {
               $set: action.val
            }
         }
      )
   }
   case `auth/SET_SESSION_LOADING`: {
      return update( state,
         {
            sessionLoading: {
               $set: action.bool
            }
         }
      )
   }
   case `auth/SET_DISPLAY_MESSAGE`: {
      return update( state, {
         displayMessage: {
            $set: action.displayMessage
         }
      })
   }
   case `auth/SET_LOGIN_ERROR`: {
      return update( state, {
         loginError: {
            $set: action.val
         }
      })
   }

   case `auth/SET_USER_SUBMITTED_DOCTOR`: {
      return update( state, {
         userSubmittedDoctorData: {
            $set: action.doctorData
         }
      })
   }
   case `auth/SET_USER_AUTHORIZE_P_CUSTOMER_TOKEN`: {
      return update( state, {
         authorizePCustomerToken: {
            $set: action.str
         }
      })
   }
   case `auth/SET_AUTHORIZE_P_METHODS`: {
      return update( state, {
         authorizePPaymentMethods: {
            $set: action.collection
         }
      })
   }
   case `auth/SET_ORDER_HISTORY`: {
      return update( state,
         {
            orderHistory: {
               $set: action.orderHistory
            }
         }
      )
   }
   case `auth/SET_ORDER_HISTORY_ERROR`: {
      return update( state,
         {
            orderHistoryError: {
               $set: action.bool
            }
         }
      )
   }
   default: {
      return state
   }
   }
}

export default auth