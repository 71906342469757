import React from 'react'
import PropTypes from 'prop-types'
import CallIcon from '@material-ui/icons/Call'
import EmailIcon from '@material-ui/icons/Email'

const iconStyle = {
   fontSize: `1rem`,
   verticalAlign: `middle`
}

export const LinkWrapper = ( props ) => {
   const { link, color, children } = props

   return (
      <a
         style={{
            color: `${color}`,
            cursor: `pointer`
         }}
         href={link}
      >
         {children}
      </a>
   )
}

LinkWrapper.propTypes = {
   link: PropTypes.string,
   color: PropTypes.string,
   children: PropTypes.node
}

export const PhoneLink = ( props ) => {
   const { number, color } = props
   const link = `tel:+1-${number}`

   return (
      <LinkWrapper color={color} link={link} >
         <CallIcon style={iconStyle} />
         {` 1-${number}`}
      </LinkWrapper>
   )
}

PhoneLink.propTypes = {
   number: PropTypes.string.isRequired,
   color: PropTypes.string
}

export const EmailLink = ( props ) => {
   const { email, color } = props
   const link = `mailto:${email}`

   return (
      <LinkWrapper color={color} link={link} >
         <EmailIcon style={iconStyle} />
         {` ${email}`}
      </LinkWrapper>
   )
}

EmailLink.propTypes = {
   email: PropTypes.string,
   color: PropTypes.string
}