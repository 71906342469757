import * as React from 'react'
import Loader from 'components/Loader'
import postDemographics from './fetch'
import {validateEmail} from "../../components/Forms/validators"
import {reportToSentry} from "../../utils/reportToSentry"

export default function EmailCollection() {

   const [ success, setSuccess ] = React.useState( false )
   const [ loading, setLoading ] = React.useState( false )
   const [ experienceType, setExperienceType ] = React.useState( `` )
   const [ emailAddress, setEmailAddress ] = React.useState( `` )
   const [ isValidEmail, setIsValidEmail ] = React.useState( false )
   const [ errorMessage, setErrorMessage ] = React.useState( `` )
   const [ patientHash, setPatientHash ] = React.useState( `` )
   const [ termsChecked, setTermsChecked ] = React.useState( false )

   const urlParams = new URLSearchParams( window.location.search )

   React.useEffect( () => {
      const type = urlParams.get( `type` )

      if ( type === `uro` ) setExperienceType( `Urology` )
      else if ( type ===`cgm` ) setExperienceType( `Diabetes` )
      else setExperienceType( `` )

   }, [] )

   React.useEffect( () => {
      const hash = urlParams.get( `sgh` ) || urlParams.get( `txt` )

      if ( !hash ) {
         return window.location.href = `/404`
      }

      setPatientHash( hash )
   }, [] )

   function handleFormSubmit( event ) {
      event.preventDefault()
      if ( !termsChecked ) return setErrorMessage( `Please agree to the terms and conditions.` )

      setLoading( true )

      postDemographics( patientHash, {
         emailAddress
      }).then( ( data ) => {
         if ( data.status >= 200 && data.status < 300 ) {
            setLoading( false )
            setSuccess( true )
         } else {
            reportToSentry( `something went wrong in postDemographics`, data )
            setLoading( false )
            setErrorMessage( `There was an error. Please try again.` )
         }
      })
         .catch( err => {
            reportToSentry( `there was an error in postDemographics`, err )
            setLoading( false )
            setErrorMessage( `There was an error. Please try again.` )
         })
   }

   function handleOnBlur( ) {
      const isValid = validateEmail( emailAddress )

      if ( isValid === `Invalid email address` || emailAddress.length === 0 ) {
         if ( isValidEmail ) setIsValidEmail( false )
         setErrorMessage( `Please enter a valid email address` )
      } else {
         if ( errorMessage ) setErrorMessage( `` )
         setIsValidEmail( true )
      }
   }

   if ( success ) {
      return (
         <div className="max-w-lg p-4 mx-auto">
            <h3 className="text-center">{`Thank you!`}</h3>
            <p className="text-center my-3">{`Your email address will be used to receive important updates on your Aeroflow ${experienceType} order. This email address will also be used to access your Aeroflow ${experienceType} account online in the future.`}</p>
         </div>
      )
   }

   return (
      <div className="max-w-lg p-4 mx-auto">
         <form onSubmit={handleFormSubmit}>
            <p className="text-center my-3">{`Enter your email address to receive important updates on your Aeroflow ${experienceType} order. This email address will also be used to access your Aeroflow ${experienceType} account online in the future.`}</p>
            <label htmlFor="email" className="af-dark-blue font-weight-700">{`Email Address:`}</label>
            <input
               type="email"
               name="email"
               value={emailAddress}
               placeholder="example@example.com"
               onChange={( event ) => { return setEmailAddress( event.target.value ) }}
               onBlur={handleOnBlur}
               autoComplete="off"
            />
            {errorMessage && <p className="text-requiredRed text-center mt-4">{errorMessage}</p>}
            <div className="my-2 p-2 text-center flex">
               <div>
                  <input
                     type="checkbox"
                     id="terms"
                     name="terms"
                     onChange={() => { return setTermsChecked( !termsChecked ) }}
                     checked={termsChecked}
                  />
               </div>
               <label htmlFor="terms">
                  <span className="my-6">
                     <span className="max-w-md text-center mx-auto text-sm">{`By checking this box, I provide my consent for Aeroflow Healthcare to send me recurring text messages and calls, including with marketing, and sent via an automatic dialer or prerecorded voice, at the number provided. Consent is not a condition of purchase of any goods or services. Reply “STOP” to stop at any time. Message and data rates may apply.`}
                        <span className="text-center text-sm">{` I also agree to Aeroflow’s `} <a href="/terms-and-conditions" target="_blank" className="text-peach underline">{`Terms and Conditions`}</a></span>
                     </span>
                  </span>
               </label>
            </div>
            <div className="w-1/3 mx-auto mt-4">
               <button className="sleep-btn bg-afBlue" disabled={!isValidEmail || loading || !termsChecked}>{loading ? <Loader height="2rem" width="2rem" rings={3} /> : `Submit`}</button>
            </div>

         </form>
      </div>
   )
}