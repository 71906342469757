import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ItemsChecklist from './components/ItemsChecklist'
import Container from '@material-ui/core/Container'
import { Form } from 'informed'
import { withRouter } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel } from '@material-ui/core'
import AllMedicareReasonsNegativeModal from './components/AllMedicareReasonsNegativeModal'
import { collectionHasItems } from 'utils/collections'
import { getRootUrlPath } from 'utils/url'
import { getReplacementTypeText } from './utils'

class MedicareChecklist extends Component {
   constructor( props ) {
      super( props )
      this.state = {
         checklistConfirmed: false,
         allChecklistsReasonsAreCheckedNo: false
      }
   }
   componentDidMount = () => {
      const { order, history } = this.props
      const { items } = order

      if ( !items || !collectionHasItems( items ) ) {
         history.replace( `` )
      }

   }
   handleCheck = ( ) => {
      const { checklistConfirmed } = this.state
      this.setState({
         checklistConfirmed: !checklistConfirmed
      })
   }
   closeAllMedicareReasonsNegativeModal = () => {
      this.setState({
         allChecklistsReasonsAreCheckedNo: false
      })
   }
   handleSubmit = () => {
      const { history, order: { items } } = this.props

      // if all medicare reasons are denied then we shift the qty of the item to 0 in the redux store
      // if they try to submit and ^ is the case then we don't let them proceed to confirm b/c no items for order
      let sumItemsSelectedQty = 0
      for ( const item in items ) {
         sumItemsSelectedQty = ( sumItemsSelectedQty + items[item].qty )
      }

      if ( sumItemsSelectedQty === 0 ) {
         return this.setState({
            allChecklistsReasonsAreCheckedNo: true
         })
      } else {
         return history.replace( `confirm-address` )
      }
   }
   render() {
      const { order, order: { items }, history: { location: { pathname }} } = this.props
      const { checklistConfirmed } = this.state

      const rootPath = getRootUrlPath( pathname )
      const replacementTypeText = getReplacementTypeText( rootPath )

      const complyText = `In order to comply with Medicare Guidelines Aeroflow Healthcare is required to verify that replacement of your ${replacementTypeText} supplies is reasonable and necessary.`

      return (
         <React.Fragment>
            <AllMedicareReasonsNegativeModal
               open={this.state.allChecklistsReasonsAreCheckedNo}
               closeModal={this.closeAllMedicareReasonsNegativeModal}
            />
            <Form onSubmit={this.handleSubmit}>
               <div className="paper">
                  <Container>
                     <div className="p-8">
                        <h2 className="page-heading">
                           <div className="mv-16 mh-0">
                              {`Get your supplies quickly`}
                           </div>
                        </h2>
                        <p className="text-afBlue-dark">
                           <span>
                              {complyText}
                           </span>
                        </p>
                        {order &&
                           <ItemsChecklist
                              items={items}
                              order={order}
                              replacementTypeText={replacementTypeText}
                           />
                        }
                     </div>
                  </Container>
               </div>
               <div className="m-4 text-center">
                  <FormControlLabel
                     className="mr-5 ml-3"
                     control={
                        <Checkbox
                           checked={checklistConfirmed}
                           onChange={() => { this.handleCheck() }}
                        />}
                     label={`By checking this box I verify that the answers above have been answered truthfully.`}
                  />
               </div>
               <div className="my-4 mx-auto text-center">
                  <button
                     className={`bg-teal py-3 w-32 ${this.state.checklistConfirmed && `hover:opacity-90`} cursor-pointer`}
                     type="submit"
                     disabled={!this.state.checklistConfirmed}
                  >
                     {`Next`}
                  </button>
               </div>
            </Form>
         </React.Fragment>
      )
   }
}

const mapStateToProps = ( state ) => {
   return {
      order: state.claims.order
   }
}

MedicareChecklist.propTypes = {
   order: PropTypes.shape({
      items: PropTypes.object
   }).isRequired,
   history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: PropTypes.shape({
         pathname: PropTypes.string
      })
   })
}

const ConnectedMedicareChecklist = connect( mapStateToProps )( MedicareChecklist )
export default withRouter( ConnectedMedicareChecklist )