import Moment from 'moment'
import { getRootUrlPath } from 'utils/url'
import { GLOVE_SIZE_OPTIONS } from './constants'
import axios from 'axios'

export const productOrderNameSizeQuantity = ( name, size, quantitySelected ) => {

   let _quantitySelected = quantitySelected

   return `${size ? String( name ) + `(` + size + `)` : name} ${_quantitySelected ? `(Qty. ${quantitySelected})` : ``}`
}
// filter items array to array of items the user has selected via checkbox
export const getOrderItems = ( supplies ) => {
   const selectedItems = {}
   Object.keys( supplies ).forEach( supplyKey => {
      if ( supplies[supplyKey]?.itemSelected ) selectedItems[supplyKey] = supplies[supplyKey]
   })

   return selectedItems
}

// inspect a collection (object) of supplies and return the earliest canShipDate
export const getOrderCanShipDate = ( orderItems ) => {

   let orderCanShipDate

   for ( const item in orderItems ) {

      const { Can_Ship_Date, qty } = orderItems[item]
      if ( !orderCanShipDate && ( qty > 0 ) ) {
         orderCanShipDate = Can_Ship_Date
      }
      if ( ( qty > 0 ) && Moment( orderCanShipDate ).diff( Moment( Can_Ship_Date ) ) < 0 ) {
         orderCanShipDate = Can_Ship_Date
      }
   }

   return orderCanShipDate
}


// returns an array of items with proper metadata to post submission to endpoint
export const getSerializedOrderItems = ( orderItems, showMedicareChecklist ) => {
   const serializedOrderItems = {}
   Object.keys( orderItems ).forEach( itemKey => {
      const { hcpc_id, quantitySelected, name, size, Can_Ship_Date, allow_alone, add_on } = orderItems[itemKey]
      const thruInsurance = true
      const dmeid = String( hcpc_id )
      const qty = quantitySelected

      // if showMedicareChecklist flag quantity response will be needed for all items
      const reasonObj = {
         quantity_question_response: `true`,
         quality_question_response: null
      }

      // if not urology patient only add quality response question
      // @NOTE api endpoint needs to be updated to allow only quantity response
      // currently it expects both reasons or no medicareChecklist field at all
      if ( getRootUrlPath( window.location.pathname ) !== `urology` ) {
         reasonObj.quality_question_response = `true`
      }

      const medicare_checklist = showMedicareChecklist ?
         {
            hcpc: hcpc_id,
            reason: reasonObj
         }
         :
         null

      serializedOrderItems[itemKey] = {
         itemKey,
         size: size,
         hcpc_id,
         dmeid,
         qty,
         name,
         thru_insurance: thruInsurance,
         medicare_checklist,
         Can_Ship_Date,
         add_on,
         allow_alone
      }

   })

   return serializedOrderItems
}

export const areAnySuppliesSelected = ( supplies ) => {
   const selectedSupplies = Object.keys( supplies ).filter( supplyKey => {
      return supplies[supplyKey].itemSelected
   })

   if ( selectedSupplies.length > 0 ){
      return true
   } else {
      return false
   }
}

export const isOneSelectedSupplyAllowAlone = ( supplies ) => {
   // if no supplies selected we don't have to worry bout it
   if ( !areAnySuppliesSelected( supplies ) ){
      return true
   }

   const selectedSuppliesAllowedAlone = Object.keys( supplies ).filter( supplyKey => {

      return Boolean( ( supplies[supplyKey].itemSelected && supplies[supplyKey].add_on ) || ( supplies[supplyKey].itemSelected && supplies[supplyKey].allow_alone ) )

   })

   if ( selectedSuppliesAllowedAlone.length === 0 ) {
      return false
   } else {
      return true
   }
}

// loop through supplies to see if any are eligible

export const hasEligibleSupplies = ( supplies ) => {

   if ( !Object.keys( supplies ).length ) {
      return false
   }

   const eligibleSupply = Object.keys( supplies ).find( supplyKey => {
      return supplies[supplyKey].eligibleMessage === `Immediately`
   })

   if ( eligibleSupply ) {
      return true
   } else {
      return false
   }
}

/** users may have eligible and ineligible items on a claims page
 *  if( !hasAllEligibleItems && hasEligibleItems ) return mixed items
 *
 */
export const hasAllEligibleItems = ( supplies ) => {

   const nonEligibleSupplies = Object.keys( supplies ).filter( supplyKey => {
      return supplies[supplyKey].eligibleMessage !== `Immediately`
   })

   if ( !nonEligibleSupplies.length ) {

      return true
   }

   if ( nonEligibleSupplies.length ){

      return false
   }
}

/**
 * enroute to the claims/supplies redux store value we extend some default item properties
 * if the supply isn't eligible immediately set itemSelected to false
 * ( modules/claims/component/SupplyItem disables the checkbox for the ineligible supply)
 * @param {array} items
 * @return {array}
 */
export const formatSupplyItems = ( items ) => {

   if ( !items || !items.length ){
      return []
   }

   return items.map( item => {
      let isEligible = true

      if ( item.eligible ) {
         const eligibilityDate = Moment( item.eligible ).format( `YYYY-MM-DD` )
         if ( Moment().isBefore( eligibilityDate ) ){
            isEligible = false
         }
      }

      const { quantityOptions, realMaxQuantity } = generateQuantityOptionsConfig( item.max_qty, item.explode_factor )

      let newItem = Object.assign({}, item, {
         quantitySelected: realMaxQuantity
      },
      {
         name: item.description
      },
      {
         itemSelected: item.add_on === true ? false : isEligible
      },
      {
         quantityOptions: quantityOptions
      },
      {
         eligibleMessage: Moment().isAfter( item.eligible ) ? `Immediately` : `Not eligible`
      }
      )

      return newItem
   })
}

/**
 * since maxQuantity may have a remainder when divided by explode options we need to accommodate
 * this function processes maxQuantity in the context of explode factor and returns quantityOptions and maxQuantity for user display
 * @param {int} maxQuantity
 * @param {int} explodeFactor
 * @return { obj }
 */
export const generateQuantityOptionsConfig = ( maxQuantity, explodeFactor ) => {
   const remainder = maxQuantity % explodeFactor
   const options = []
   let _maxQuantity = maxQuantity

   // if more than 1/2 of explodeFactor remainder round down
   if ( remainder > 0 && ( explodeFactor / remainder > 0.5 ) ) {
      _maxQuantity = ( _maxQuantity - remainder )
   }

   // if 1/2 or less of explodeFactor remainder round up
   if ( remainder > 0 && ( explodeFactor / remainder <= 0.5 ) ) {
      _maxQuantity = ( _maxQuantity + remainder )
   }

   for ( let i = _maxQuantity; i >= explodeFactor; i-- ) {
      let qty = i - explodeFactor
      if ( qty % explodeFactor === 0 ) {
         options.push( i )
      }
   }

   return {
      quantityOptions: options,
      realMaxQuantity: _maxQuantity
   }
}

export const getSupplies = ( info ) => {

   return new Promise ( ( resolve, reject ) => {
      const url = `${process.env.REACT_APP_RESUPPLY_BASE_ENDPOINT}${info.patient_id}&dob=${info.patient_dob}&profitCenterId=${info.pfPk}`

      return axios.get( url )
         .then( response => { return resolve( response.data ) })
         .catch( error => { return reject( error ) })
   })
}

export const hasGloves = ( itemHcpcid ) => {
   return GLOVE_SIZE_OPTIONS.map( entry => {
      return entry.hcpcid
   }).includes( itemHcpcid )
}
