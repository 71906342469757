import React from 'react'
import ReactDOM from 'react-dom'
import './styles/_typography.scss'
import './index.css'
import * as Sentry from '@sentry/browser'
import { Integrations } from "@sentry/tracing"
import App from './App'
import { SENTRY_DSN_KEY, SENTRY_IGNORE_MESSAGES } from 'constants/config'
import { SENTRY_DISABLED_MSG } from 'constants/messages'

/* eslint-disable no-console */

if ( window.location.hostname !== `localhost` ) {
   try {
      Sentry.init({
         dsn: SENTRY_DSN_KEY,
         integrations: [ new Integrations.BrowserTracing() ],
         tracesSampleRate: 1.0,
         beforeSend: ( event ) => {
            if ( SENTRY_IGNORE_MESSAGES.find( message => { return message === event?.message }) ) return null

            return event
         }
      })
   } catch ( err ) {
      console.log( `sentry error`, JSON.stringify( err ) )
   }
} else {
   console.log( SENTRY_DISABLED_MSG )
}


// trident is ie - we display a message in index.html of non support and don't mount react
if ( window.aeroflowBrowser !== `trident` ) {
   ReactDOM.render( <App />, document.getElementById( `root` ) )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register()