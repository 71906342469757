import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConfirmPageFormsController from 'components/Forms/ContactShippingSplitForm/ConfirmPageFormsController'
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { withRouter } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { collectionHasItems } from 'utils/collections'
import { orderFormatter } from './utils'
import { submitOrder, setShowMedicareChecklist, updateOrderItems } from 'modules/claims/actions'
import { logoutUser, setDisplayMessage } from 'modules/auth/actions'
import { isValidPhone } from 'components/Forms/validators'

class ConfirmOrder extends Component {
   constructor( props ) {
      super( props )
      this.state = {
         displayAgreeHelper: false,
         agreed: false
      }
   }
   componentDidMount() {

      const { isAuthenticated, history, order: { items } } = this.props

      if ( !items ) {
         history.replace( `/` )
      }

      if ( !collectionHasItems( items ) ) {
         history.replace( `/` )
      }

      if ( !isAuthenticated ) {
         history.replace( `/` )
      }

      ValidatorForm.addValidationRule( `isPhone`, isValidPhone )

      ValidatorForm.addValidationRule( `isZip`, ( value ) => {
         return value && value.match( /^\d{5}$|^\d{5}-\d{4}$/ )
      })
   }
   componentWillUnmount(){
      ValidatorForm.removeValidationRule( `isPhone` )
      ValidatorForm.removeValidationRule( `isZip` )
   }
   componentDidUpdate( prevProps ){
      const {
         history,
         orderSuccess
      } = this.props


      if ( !prevProps.orderSuccess && orderSuccess ){

         history.replace( `/${this.props.source}/claim/thank-you` )
      }
   }

   handleSubmit = ( ) => {

      const serializedOrder = orderFormatter( this.props )

      return this.props.handleSubmitOrder( serializedOrder )


   }

   render() {

      return (
         <Container
            className="pb-12"
         >
            <div className="m-5">
               <h6 className="af-h6 mb-4">
                  {`Confirm ${this.props.user.first_name} ${this.props.user.last_name}'s Order Details.`}
               </h6>
               <ConfirmPageFormsController handleSubmit={this.handleSubmit} />
            </div>
         </Container>
      )
   }
}

export const mapStateToProps = ( state ) => {

   return {
      user: state.auth.user,
      displayMessage: state.auth.displayMessage,
      isAuthenticated: state.auth.isAuthenticated,
      order: state.claims.order,
      supplies: state.claims.supplies,
      patient_id: state.auth.user.patient_id,
      patient_id_hash: state.auth.user.patient_id_hash,
      showMedicareChecklist: state.claims.showMedicareChecklist,
      orderSuccess: state.claims.orderSuccess,
      threeMonthPromoAccepted: state.claims.threeMonthPromoAccepted
   }
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
   return {
      handleSubmitOrder: ( serializedOrder ) => {
         return dispatch( submitOrder( serializedOrder ) )
      },
      handleSetShowMedicareChecklist: ( bool ) => {
         return dispatch( setShowMedicareChecklist( bool ) )
      },
      handleSetItems: ( items ) => {
         return dispatch( updateOrderItems( items ) )
      },
      forceLogout: () => {
         return dispatch( logoutUser( ownProps.history, `Login credentials do not match this session.` ) )
      },
      removeLoginFailedMsg: () => {

         return dispatch( setDisplayMessage({
            type: ``,
            msg: ``
         }) )
      }
   }
}

ConfirmOrder.propTypes = {
   user: PropTypes.shape({
      payer_id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string
   }),
   handleSetShowMedicareChecklist: PropTypes.func,
   handleSetItems: PropTypes.func.isRequired,
   serializeOrder: PropTypes.func.isRequired,
   orderSuccess: PropTypes.bool,
   isAuthenticated: PropTypes.bool,
   order: PropTypes.shape({
      items: PropTypes.oneOfType( [ PropTypes.object, PropTypes.array ] )
   }),
   location: PropTypes.shape({
      pathname: PropTypes.string
   }),
   history: PropTypes.shape({
      replace: PropTypes.func.isRequired
   }).isRequired,
   source: PropTypes.string.isRequired,
   supplies: PropTypes.oneOfType( [ PropTypes.object, PropTypes.array ] ),
   patient_id_hash: PropTypes.string,
   forceLogout: PropTypes.func.isRequired,
   removeLoginFailedMsg: PropTypes.func.isRequired,
   displayMessage: PropTypes.shape({
      msg: PropTypes.string
   }),
   handleSubmitOrder: PropTypes.func.isRequired
}

const ConnectedConfirmOrder = connect( mapStateToProps, mapDispatchToProps )( ConfirmOrder )
export default withRouter( ConnectedConfirmOrder )