import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { validateField } from './validators'
import moment from 'moment'
import {
   StyledFormGroup,
   StyledField
   //  StyledLabel,
   //  StyledDOBInput60w,
   //  StyledDOBInput100w
} from './styled'
import { oneHundredTwentyYearsBefore } from 'utils/time'

/* eslint-disable no-console */

export class InputDateFieldgroup extends React.Component {
   constructor( props ){
      super( props )

      this.monthInput = createRef()
      this.dayInput = createRef()
      this.yearInput = createRef()

      this.state = {
         month: ``,
         day: ``,
         year: ``
      }
   }
   handleChange = ( e ) => {
      const { handleError } = this.props
      const { name, value } = e.target

      // validateField.name
      const _value = validateField[name]( value, this.state[name], name, handleError, this.state.month )

      // if value is error we don't set it to state
      if ( _value !== `error` ){
         handleError({})

         return this.setState({
            [name]: _value
         }, () => {
            if ( _value.length > 1 && _value !== `error` && ( name !== `year` ) ) {
               this.autoFocusNextInput( name )
            }
         })
      }
   }
   componentDidUpdate( prevProps, prevState ){
      // since the dob fields are standalone we need to pass valid values to the parent component

      // if dob is different
      if ( `${prevState.year}-${prevState.month}-${prevState.day}` !== `${this.state.year}-${this.state.month}-${this.state.day}` ) {
         const regex = /\d{4}-\d{2}-\d{2}/gi
         // previous invalid, current valid -> remove error and submit value
         if ( !`${prevState.year}-${prevState.month}-${prevState.day}`.match( regex ) &&
            `${this.state.year}-${this.state.month}-${this.state.day}`.match( regex )
         ) {
            this.props.handleError({})

            return this.props.onDateCompletion( `${this.state.year}-${this.state.month}-${this.state.day}` )
         }

         // previous valid current invalid
         if ( `${prevState.year}-${prevState.month}-${prevState.day}`.match( regex ) &&
            !`${this.state.year}-${this.state.month}-${this.state.day}`.match( regex )
         ) {
            return this.props.handleError({
               form: `Please enter a valid birth date.`
            })
         }

         // valid current entry
         if ( `${this.state.year}-${this.state.month}-${this.state.day}`.match( regex ) ){
            return this.props.onDateCompletion( `${this.state.year}-${this.state.month}-${this.state.day}` )
         }
      }
   }
   handleBlur = ( e ) => {

      const { target: { name, value } } = e

      if ( name === `month` || name === `day` ){
         if ( value && value.length === 1 ){
            this.setState({
               [name]: `0${value}`
            })
         }
      }

   }
   autoFocusNextInput = ( name ) => {

      if ( name === `month` ){
         this.dayInput.current.focus()
      }

      if ( name === `day` ){
         this.yearInput.current.focus()
      }
   }
   render(){
      const { month, day, year } = this.state

      return (
         <StyledFormGroup>

            <StyledField>
               <label className="mb-1.5">
                  {`Month`}
               </label>
               <input
                  className="w-16 p-2 text-center text-base content-center"
                  type="number"
                  min="1"
                  max="12"
                  pattern="\d*"
                  ref={this.monthInput}
                  name="month"
                  value={month}
                  placeholder="MM"
                  onChange={this.handleChange}
                  maxLength={2}
                  required
                  onBlur={this.handleBlur}
               />
            </StyledField>

            <StyledField>
               <label className="mb-1.5">
                  {`Day`}
               </label>
               <input
                  className="w-16 p-2 text-center text-base "
                  type="number"
                  min="1"
                  max="31"
                  pattern="\d*"
                  ref={this.dayInput}
                  name="day"
                  value={day}
                  placeholder="DD"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  maxLength={2}
                  required
               />
            </StyledField>

            <StyledField>
               <label className="mb-1.5">
                  {`Year`}
               </label>
               <input
                  className="w-24 p-2 text-center text-base"
                  type="number"
                  min={oneHundredTwentyYearsBefore}
                  pattern="\d*"
                  max={moment().format( `YYYY` )}
                  ref={this.yearInput}
                  name="year"
                  value={year}
                  placeholder="YYYY"
                  onChange={this.handleChange}
                  maxLength={4}
                  onBlur={this.handleBlur}
                  required
               />
            </StyledField>

         </StyledFormGroup>
      )
   }
}

InputDateFieldgroup.propTypes = {
   onDateCompletion: PropTypes.func.isRequired,
   handleError: PropTypes.func.isRequired
}

export default InputDateFieldgroup