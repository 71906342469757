import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ROUTES } from 'routing/routeConfig'
import RouteWithSubRoutes from 'routing/RouteWithSubroutes'
import { Provider } from 'react-redux'
import Template from 'modules/app/Template'
import configureStore from './store'
import NetworkListener from 'components/NetworkListener'
import SessionManager from 'modules/auth/SessionManager'
import MedChatController from 'resources/thirdparty/MedChatController'
import ScrollRestorer from 'components/ScrollRestorer'
import { trackPageRefreshedAnalytic } from 'modules/analytics/utils'
import { navigationType } from 'utils/browser'
import SessionTokenizer from 'modules/customAnalytics/SessionTokenizer'

const svgLoader = (
   <svg
      className="animate-spin mx-auto h-10 w-10 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24"
   >
      <circle
         className="opacity-25" cx="12" cy="12"
         r="10" stroke="currentColor" strokeWidth="4"
      ></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
   </svg>
)

/* eslint-disable react/no-array-index-key */

const App = props => {

   useEffect( () => {
      // this tracks to see if clients are refreshing on certain pages
      window.addEventListener( `beforeunload`, ( ) => {
         if ( window.location.pathname === `/urology/claim` || window.location.pathname === `/urology/claim/confirm` ) {
            const result = navigationType()
            if ( result === 1 ) {
               trackPageRefreshedAnalytic()
            }
         }
      })
   }, [] )


   return (
      <Provider store={configureStore()} >
         <Router>
            <NetworkListener />
            <SessionTokenizer />
            <MedChatController />
            <SessionManager />
            <ScrollRestorer />
            <Template>
               <Suspense
                  fallback={<div><svgLoader /></div>}
               >
                  <Switch>
                     {ROUTES.map( ( route, i ) => {
                        return (
                           <RouteWithSubRoutes key={i} {...route} />
                        )
                     })}
                  </Switch>
               </Suspense>
            </Template>
         </Router>
      </Provider>
   )
}

export default App
