import * as types from './actionTypes'

export const setDoctor = ( doctor ) => {
   return {
      type: types.SET_DOCTOR,
      doctor
   }
}

export const setDoctorInput = ( doctorInput ) => {
   return {
      type: types.SET_DOCTOR_INPUT,
      doctorInput
   }
}

export const resetDoctorInput = () => {
   return {
      type: types.RESET_DOCTOR_INPUT
   }
}