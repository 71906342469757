import { AF_SUPPORT_PHONE_NUMBER } from '../utils/constants'
import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { searchDoctorFetch } from '../utils/utils'

function useDoctorSearch( doctorInput ) {
   const [ searchResults, setSearchResults ] = useState( null )
   const [ searchLoading, setSearchLoading ] = useState( false )
   const [ searchError, setSearchError ] = useState( `` )

   const defaultError = `Oops... we encountered an issue searching for your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

   useEffect( () => {

      const controller = new AbortController()

      setSearchError( `` )
      setSearchResults( null )

      if ( doctorInput?.fullName?.length >= 2 ) {
         setSearchLoading( true )
         searchDoctorFetch( doctorInput, controller )
            .then( data => {
               setSearchLoading( false )
               if ( data?.meta?.status === `OK` && data?.data ) return setSearchResults( data.data )
               setSearchError( defaultError )

               return reportToSentry( new Error( `Doctor Search: Error looking up doctor` ), {
                  doctorInput: JSON.stringify( doctorInput )
               })

            })
            .catch( ( error ) => {
               if ( error.name !== `AbortError` ) {
                  setSearchLoading( false )
                  setSearchError( defaultError )
                  reportToSentry( new Error( `Doctor Search: Error looking up doctor`, {
                     cause: error
                  }), {
                     doctorInput: JSON.stringify( doctorInput )

                  })
               }
            })
      } else {
         setSearchError( `` )
         setSearchLoading( false )
      }

      return () => { controller.abort() }
   }, [ doctorInput ] )

   return {
      searchResults,
      searchLoading,
      searchError
   }
}

export default useDoctorSearch