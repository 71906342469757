export const SET_SUPPLIES = `claims/SET_SUPPLIES`
export const SET_SUPPLIES_COUNT = `claims/SET_SUPPLIES_COUNT`
export const INCREMENT_SUPPLIES_COUNT = `claims/INCREMENT_SUPPLIES_COUNT`
export const SET_SHOW_MEDICARE_CHECKLIST = `claims/SET_SHOW_MEDICARE_CHECKLIST`
export const UPDATE_QUANTITY_SUPPLY_ITEM = `claims/UPDATE_QUANTITY_SUPPLY_ITEM`
export const TOGGLE_ITEM_SELECTED_FOR_ORDER_STATUS = `claims/TOGGLE_ITEM_SELECTED_FOR_ORDER_STATUS`
export const SET_ORDER = `claims/SET_ORDER`
export const UPDATE_MEDICARE_CHECKLIST_ORDER_ITEM = `claims/UPDATE_MEDICARE_CHECKLIST_ORDER_ITEM`
export const SET_SUPPLIES_LOADING_STATUS = `claims/SET_SUPPLIES_LOADING_STATUS`
export const UPDATE_SUPPLIES = `claims/UPDATE_SUPPLIES`
export const SET_ORDER_SUCCESS = `claims/SET_ORDER_SUCCESS`
export const SET_SERIALIZED_ORDER = `claims/SET_SERIALIZED_ORDER`
export const UPDATE_QUANTITY_ORDER_ITEM = `claims/UPDATE_QUANTITY_ORDER_ITEM`
export const RESET_CLAIMS = `claims/RESET_CLAIMS`
export const SET_ORDER_SUMMARY_FROM_SUBMIT_ORDER_RESPONSE = `claims/SET_ORDER_SUMMARY_FROM_SUBMIT_ORDER_RESPONSE`
export const SET_THREE_MONTH_PROMO_ELIGIBLE = `claims/SET_THREE_MONTH_PROMO_ELIGIBLE`
export const SET_THREE_MONTH_PROMO_ACCEPTED = `claims/SET_THREE_MONTH_PROMO_ACCEPTED`
export const UPDATE_ORDER_ITEMS = `claims/UPDATE_ORDER_ITEMS`
export const SET_SUPPLIES_RESQUEST_STATUS = `claims/SET_SUPPLIES_RESQUEST_STATUS`
export const UPDATE_SIZE_SUPPLY_ITEM = `claims/UPDATE_SIZE_SUPPLY_ITEM`
export const SET_ESTIMATED_SHIP_DATE = `claims/SET_ESTIMATED_SHIP_DATE`