import { differenceInCalendarDays } from 'date-fns'
import moment from 'moment'

export const monthMaxDays = {
   '01': 31,
   '02': 29,
   '03': 31,
   '04': 30,
   '05': 31,
   '06': 30,
   '07': 31,
   '08': 31,
   '09': 30,
   '10': 31,
   '11': 30,
   '12': 31
}

export const currentYear = moment().format( `YYYY` )

export const oneHundredTwentyYearsBefore = moment().subtract( 120, `years` )
   .format( `YYYY` )

export const todayDate = moment().format( `YYYY-MM-DD` )


export const isPediatricPatient = ( patientDOB ) => {
   const eighteenYearsAgo = moment().subtract( 18, `years` )
      .format( `YYYY-MM-DD` )

   const dob = moment( patientDOB )

   if ( dob.isAfter( eighteenYearsAgo ) ) {

      return true
   }

   return false
}

export const isFutureDateInputDate = ( inputDate ) => {

   const mDate = moment( inputDate )

   if ( mDate && moment.isMoment( mDate ) ) {
      if ( mDate.isAfter( todayDate ) ) {
         return true
      } else {
         return false
      }
   } else {
      return false
   }
}

/**
 * parses minute of hour and returns the next quarter hour denoted by minutes
 */
export const getQuarterHour = () => {
   const mm = moment().format( `mm` )
   const minsInt = parseInt( mm )
   if ( minsInt >= 0 && minsInt < 15 ) {
      return 15
   }
   if ( minsInt >= 15 && minsInt < 30 ) {
      return 30
   }
   if ( minsInt >= 30 && minsInt < 45 ) {
      return 45
   }
   if ( minsInt >= 45 ) {
      return 60
   }
}

/**
 * parses minute of hour and returns the half hour denoted by minutes
 */
export const getHalfHour = () => {
   const mm = moment().format( `mm` )
   const minsInt = parseInt( mm )

   if ( minsInt >= 0 && minsInt < 30 ) {

      return 30
   }
   if ( minsInt >= 30 && minsInt < 60 ) {

      return 60
   }
}

export const getNextHour = () => {

   return moment().endOf( `hour` )
      .add( 1, `s` )
}

export const getTimeOptions = ( start, inc, length ) => {

   let options = []
   let timeCursor

   for ( let i = 0; i < length; i++ ) {

      if ( !timeCursor ) {
         const time = moment( start ).format( `hh:mm` )
         options.push( time )
         timeCursor = moment( start )
      }

      if ( timeCursor ) {
         const time = moment( timeCursor )
         const timePlus = time.add( inc, `m` )
         const timePlusFormatted = timePlus.format( `hh:mm` )
         options.push( timePlusFormatted )
         timeCursor = timeCursor.add( inc, `m` )
      }
   }

   return options
}

/**
 * @param {integer} explodeFactor (represents minute durations for select i.e for 30 mins the return would increment options by 30 mins)
 * @param {*} length (represent the length of the options array returned)
 */
export const generateTimeRangeOptions = ( explodeFactor, length ) => {

   if ( !explodeFactor || explodeFactor > 60 || ( explodeFactor % 15 !== 0 ) || explodeFactor === 45 ) {
      console.error( `This function only accepts explodeFactor of 15, 30, 60` )

      return []
   }

   let startTime
   const nextHour = getNextHour()

   if ( explodeFactor === 15 ) {
      const quarterHour = getQuarterHour()

      switch ( quarterHour ) {
      case 15: {
         startTime = moment().startOf( `h` )
            .add( 30, `m` )
         break
      }
      case 30: {
         startTime = moment().startOf( `h` )
            .add( 45, `m` )
         break
      }
      case 60: {
         startTime = nextHour
         break
      }
      default: {
         startTime = nextHour
      }
      }
   }

   if ( explodeFactor === 30 ) {
      const halfHour = getHalfHour()

      switch ( halfHour ) {
      case 30: {
         startTime = nextHour
         break
      }
      case 60: {
         startTime = nextHour.add( 30, `m` )
         break
      }
      default: {
         startTime = nextHour
      }
      }

   }

   if ( explodeFactor === 60 ) {
      startTime = nextHour
   }

   const timeOptions = getTimeOptions( startTime, explodeFactor, length )

   return timeOptions
}

// returns int (1-7 - representing Monday-Sunday)
export const getDayOfWeekInt = ( date ) => {

   let int

   try {
      int = moment( date ).format( `E` )
   } catch ( err ){
      int = undefined
   }

   return int

}

export const getAddDays = ( dayIntStringOfWeek ) => {

   if ( dayIntStringOfWeek === `6` ) {
      return 2
   }

   if ( dayIntStringOfWeek === `7` ) {
      return 1
   }

   return 0

}

// function will add the current durations provided by urology
// If shipping date is mon - fri add 2 days
// If shipping date is saturday add 4 days
// If shipping date is sunday add 3 days

export const getEstimatedShippingDate = ( date ) => {

   const dayIntStringOfWeek = getDayOfWeekInt( date )
   // adjust for sat/sun can ship dates
   const addDaysInt = getAddDays( dayIntStringOfWeek )

   return moment(
      date
   ).add( addDaysInt, `d` )
      .format( `MM/DD/YYYY` )
}

// This function checks if the date passed in is in the past.
// If it is, it returns true. Otherwise, it returns false.
export const isPastDate = ( date ) => {
   return differenceInCalendarDays( date, new Date() ) < 0
}