import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

function RecentOrderHeader({ collapsible, isCollapsed, handleShowOrderHistory }) {

   if ( !collapsible ) {
      return (
         <div className="flex items-center justify-center text-lg sm:text-xl text-center my-4 md:my-6 bold">
            {`Most Recent Order`}
         </div>
      )
   }

   return (
      <div className="flex items-center justify-center my-6 md:my-8 text-base sm:text-lg text-center cursor-pointer hover:opacity-80" onClick={handleShowOrderHistory}>
         {`Previous Order`}
         {
            isCollapsed ?
               <AddIcon className="ml-2" />
               :
               <RemoveIcon className="ml-2" />
         }
      </div>
   )
}

RecentOrderHeader.propTypes = {
   collapsible: PropTypes.bool,
   isCollapsed: PropTypes.bool,
   handleShowOrderHistory: PropTypes.func
}

export default RecentOrderHeader
