import React from 'react'
import PropTypes from 'prop-types'

export const ShippingSummary = ( props ) => {
   const { name, address, address2, city, state, zip } = props

   return (
      <div
         className="text-center"
      >
         <h6 className="af-h6">
            {`Shipping Address:`}
         </h6>

         <p className="py-1 px-2 m-0 text-afBlue-dark">
            {name}
         </p>

         <p className="py-1 px-2 m-0 text-afBlue-dark">
            {address}
         </p>

         {Boolean( address2 ) && address2 !== `null` &&
               <p className="py-1 px-2 m-0 text-afBlue-secondary">
                  {address2}
               </p>}

         <p className="py-1 px-2 m-0 text-afBlue-dark">
            {`${city}, ${state}  ${zip}`}
         </p>
      </div>
   )
}

ShippingSummary.propTypes = {
   name: PropTypes.string,
   address: PropTypes.string,
   address2: PropTypes.string,
   city: PropTypes.string,
   state: PropTypes.string,
   zip: PropTypes.string
}

export default ShippingSummary