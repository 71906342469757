import React from 'react'
import PropTypes from 'prop-types'
import { StyledFieldWrapper, StyledErrorWrapper, StyledInput, RequiredSpan } from './styled'

export const NumberInputHOC = ( props ) => {

   const {
      id,
      name,
      value,
      handleChange,
      label,
      error,
      handleBlur,
      inputType,
      columnLabelTop,
      columnLabelBottom,
      placeholder,
      inputClassName,
      required,
      inputRef,
      maxLength
   } = props

   return (

      <StyledFieldWrapper
         columnLabelTop={columnLabelTop}
         columnLabelBottom={columnLabelBottom}
      >

         <StyledInput
            error={error}
            type={inputType}
            className={inputClassName}
            id={id}
            // size={size}
            onBlur={handleBlur}
            onChange={handleChange}
            name={name}
            value={value}
            placeholder={placeholder}
            ref={inputRef}
            maxLength={maxLength}
         />
         <label htmlFor={id}>{label}  <RequiredSpan>{required && `*`}</RequiredSpan></label>
         <StyledErrorWrapper>
            {error}
         </StyledErrorWrapper>
      </StyledFieldWrapper>
   )
}

NumberInputHOC.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   defaultChecked: PropTypes.bool,
   handleChange: PropTypes.func.isRequired,
   checked: PropTypes.bool,
   label: PropTypes.string,
   displayError: PropTypes.bool,
   columnLabelBottom: PropTypes.bool,
   columnLabelTop: PropTypes.bool,
   size: PropTypes.number,
   placeholder: PropTypes.string,
   inputClassName: PropTypes.string,
   error: PropTypes.string,
   handleBlur: PropTypes.func.isRequired,
   inputType: PropTypes.string,
   required: PropTypes.bool,
   inputRef: PropTypes.string,
   maxLength: PropTypes.string
}

export default NumberInputHOC