import React from 'react'
import Moment from 'react-moment'
import PropTypes from 'prop-types'

export const EligibilityCell = ( props ) => {
   const { mobileItem, eligible, eligibleImmediately } = props

   return (
      <React.Fragment>
         {mobileItem && <span className="eligibility-field">{`Eligible to Order: `}</span>}

         <span>
            {eligibleImmediately ?
               <span className="green">{`Immediately`}</span>
               :
               <Moment format="MM/DD/YYYY">{eligible}</Moment>
            }
         </span>
      </React.Fragment>
   )
}

EligibilityCell.propTypes = {
   mobileItem: PropTypes.bool,
   eligible: PropTypes.bool,
   eligibleImmediately: PropTypes.bool
}

export default EligibilityCell