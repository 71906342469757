import React from 'react'
import PropTypes from 'prop-types'
import { getUniqueIdPrefix } from './utils'

const RadioInput = (
   {
      label,
      name,
      required,
      labelClassName,
      ...inputProps
   }) => {
   const uniqueId = `${getUniqueIdPrefix()}-${name}`

   return (
      <div className="text-center">
         <label htmlFor={uniqueId} className={`text-sm font-bold ml-2 cursor-pointer ${labelClassName}`}>
            {label}
         </label>
         <input
            id={uniqueId}
            name={name ?? uniqueId}
            label={label}
            required={required}
            type="radio"
            {...inputProps}
         />

         {required && <span className="text-error font-normal">{`*`}</span>}

      </div>
   )
}

RadioInput.propTypes = {
   label: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   required: PropTypes.bool,
   labelClassName: PropTypes.string
}

export default RadioInput