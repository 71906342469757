export const getFormattedNpiAddress = ( addresses ) => {
   return addresses.filter( address => {

      return address.address_purpose === `LOCATION`
   })
}

/**
 * takes results and makes sure result.basic.last_name contains the search string
 * @param {Array} results
 * @param {String} lastNameStr
 * @returns {Array}
 */
export const filterOutOtherNames = ( results, lastNameStr ) => {

   const returnResults = []

   for ( let i = 0; i < results.length; i++ ){
      if ( results[i].basic.last_name.indexOf( lastNameStr ) !== -1 ){
         returnResults.push( results[i] )
      }
   }

   return returnResults
}

export const getTaxonomyText = ( taxonomies ) => {
   if ( !taxonomies ){
      return ``
   }

   const taxonomyStrings = []

   for ( let i = 0; i < taxonomies.length; i++ ){
      if ( taxonomyStrings.indexOf( taxonomies[i].desc ) === -1 ){
         taxonomyStrings.push( taxonomies[i].desc )
      }
   }

   return taxonomyStrings.join( `, ` )
}