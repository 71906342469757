import update from 'immutability-helper'
import { INITIAL_STATE } from './constants/config'

const uroCuratedOfficial = ( state = INITIAL_STATE, action ) => {
   switch ( action.type ) {
   case `uroCuratedOfficial/SET_ACTIVE_STEP`: {
      return update( state,
         {
            activeStep: {
               $set: action.activeStep
            }
         }
      )
   }
   case `uroCuratedOfficial/SET_SELECTED_BASE_PRODUCT`: {
      return update( state, {
         selectedBaseProduct: {
            $set: action.selectedBaseProduct
         }
      })
   }
   case `uroCuratedOfficial/SET_SELECTED_BASE_PRODUCT_QUANTITY`: {
      return update( state, {
         selectedBaseProductQuantity: {
            $set: action.selectedBaseProductQuantity
         }
      })
   }
   case `uroCuratedOfficial/SET_DOCTOR_CONFIRMED`: {

      return update( state, {
         doctorConfirmed: {
            $set: action.bool
         }
      })
   }
   case `uroCuratedOfficial/SET_BUTTON_LOADING`: {

      return update( state, {
         buttonLoading: {
            $set: action.bool
         }
      })
   }
   case `uroCuratedOfficial/SET_SELECTED_BASE_PRODUCT_WITH_META`: {

      return update( state, {
         selectedBaseProductWithMeta: {
            $set: action.selectedBaseProductWithMeta
         }
      })
   }
   case `uroCuratedOfficial/SET_HEIGHT`: {
      return update( state, {
         heightInches: {
            $set: action.height
         }
      })
   }
   case `uroCuratedOfficial/SET_SIZING_DISPLAY_FORM`: {
      return update( state, {
         sizingDisplayForm: {
            $set: action.sizingDisplayForm
         }
      })
   }
   case `uroCuratedOfficial/SET_PRODUCT_SIZE`: {
      return update( state, {
         productSize: {
            $set: action.productSize
         }
      })
   }
   case `uroCuratedOfficial/SET_WEIGHT`: {
      return update( state, {
         weight: {
            $set: action.weight
         }
      })
   }
   case `uroCuratedOfficial/SET_SUPPLEMENTAL_ITEMS`: {
      return update( state, {
         supplementalItems: {
            $set: action.supplementalItems
         }
      })
   }
   case `uroCuratedOfficial/SET_SELECTED_SUPPLEMENTAL_ITEMS`: {
      return update( state, {
         selectedSupplementalItems: {
            $set: action.selectedSupplementalItems
         }
      })
   }
   case `uroCuratedOfficial/RESET_SELECTED_SUPPLEMENTAL_ITEMS`: {
      return update( state, {
         selectedSupplementalItems: {
            $set: []
         }
      })
   }
   case `uroCuratedOfficial/SET_PA_REQUIRED_KEY`: {
      return update( state, {
         paRequiredKey: {
            $set: action.key
         }
      })
   }
   case `uroCuratedOfficial/REMOVE_SUPPLEMENTAL_ITEM`: {
      return update( state, {
         selectedSupplementalItems: {
            $splice: [ [ action.index, 1 ] ]
         }
      })
   }
   case `uroCuratedOfficial/CREATE_ORDER`: {
      return update( state, {
         order: {
            $set: action.order
         }
      })
   }
   case `uroCuratedOfficial/SET_ORDER_RESPONSE`: {
      return update( state, {
         orderResponse: {
            $set: action.response
         },
         orderSuccess: {
            $set: action.orderSuccess
         }
      })
   }
   case `uroCuratedOfficial/SET_ITEMS`: {
      return update( state, {
         items: {
            $set: action.items
         }
      })
   }
   case `uroCuratedOfficial/SET_RECOMMENDED_SIZE`: {
      return update( state, {
         recommendedSize: {
            $set: action.recommendedSize
         }
      })
   }
   case `uroCuratedOfficial/SET_SIZE_UP`: {
      return update( state, {
         sizeUp: {
            $set: action.sizeUp
         }
      })
   }
   case `uroCuratedOfficial/SET_SIZE_DOWN`: {
      return update( state, {
         sizeDown: {
            $set: action.sizeDown
         }
      })
   }
   case `uroCuratedOfficial/SET_BLADDER_LENGTH`: {
      return update( state, {
         bladderControlPadLength: {
            $set: action.bladderControlPadLength
         }
      })
   }
   case `uroCuratedOfficial/SET_EXPERIENCE_TYPE`: {
      return update( state, {
         experienceType: {
            $set: action.expType
         }
      })
   }
   case `uroCuratedOfficial/SET_WARNING_SCREEN_SHOWN`: {
      return update( state, {
         warning_screen_shown: {
            $set: action.bool
         }
      })
   }
   case `uroCuratedOfficial/SET_WANT_TO_ADD_CHOSEN`: {
      return update( state, {
         want_to_add_chosen: {
            $set: action.bool
         }
      })
   }
   case `uroCuratedOfficial/SET_PROCEED_WITHOUT_ADDING_CHOSEN`: {
      return update( state, {
         proceed_without_adding_chosen: {
            $set: action.bool
         }
      })
   }
   default: {
      return state
   }
   }
}

export default uroCuratedOfficial