export const steps = {
   1: `base-product-select`,
   2: `product-sizing`,
   3: `supplemental-products-select`,
   4: `doctor-needed`,
   5: `confirm`,
   6: `thank-you`
}

export const INITIAL_STATE = {
   isMobile: true,
   activeStep: `base-product-select`,
   selectedProductTypeName: ``,
   selectedBaseProduct: {},
   selectedBaseProductQuantity: 0,
   selectedBaseProductWithMeta: {},
   selectedProductTypeCategory: ``,
   bladderControlPadLength: ``,
   productSize: ``,
   recommendedSize: ``,
   productSizeUp: ``,
   productSizeDown: ``,
   heightInInches: 0,
   weight: ``,
   items: [],
   formattedSupplementalItems: [],
   supplementalItems: [],
   selectedSupplementalItems: [],
   order: {},
   orderResponse: {},
   sizingDisplayForm: `height-weight`,
   paRequiredKey: {},
   doctorConfirmed: false,
   hash: ``, // this will need to change based on hash
   buttonLoading: false,
   experienceType: `adult`,
   warning_screen_shown: false,
   want_to_add_chosen: false,
   procceed_without_adding_chosen: false
}