import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const ConfirmModal = ( props ) => {
   const { open, closeModal, replacementTypeText } = props

   return (
      <Dialog open={open}>
         <MuiDialogTitle id="customized-dialog-title">
            <p className="text-teal text-xl">{`Warning`}</p>
         </MuiDialogTitle>
         <MuiDialogContent dividers>
            <p className="text-black">
               {`By selecting 'no' you are confirming that you are NOT in need of this specific ${replacementTypeText} Supply item. This item will not be shipped to you.`}
            </p>
         </MuiDialogContent>
         <MuiDialogActions>
            <Button onClick={closeModal} color="primary">
               {`I Understand`}
            </Button>
         </MuiDialogActions>
      </Dialog>
   )
}

ConfirmModal.propTypes = {
   open: PropTypes.bool.isRequired,
   closeModal: PropTypes.func.isRequired,
   replacementTypeText: PropTypes.string.isRequired
}

export default ConfirmModal

