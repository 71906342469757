import React from 'react'
import PropTypes from 'prop-types'

const OrderSummaryHeading = ( props ) => {

   const { addOnItemsLength, regularItemsLength } = props

   const onlyAddOnItems = ( addOnItemsLength > 0 && regularItemsLength === 0
   )

   return (
      <div>
         <h6 className="af-h6 text-center mb-8">
            {onlyAddOnItems ? `Thank you for requesting Additional Items available through your insurance!`: `Thank You for Your Order!`}
         </h6>

         {!onlyAddOnItems &&
         <h6 className="af-h6 text-center mb-24">
            {`Once your order has shipped, you will receive a tracking email where you can view real time updates for your shipment.`}
         </h6>
         }
      </div>
   )
}

OrderSummaryHeading.propTypes = {
   addOnItemsLength: PropTypes.number.isRequired,
   regularItemsLength: PropTypes.number.isRequired
}

export default OrderSummaryHeading
