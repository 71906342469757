import React from 'react'
import PropTypes from 'prop-types'
import { isValidEmail } from 'components/Forms/validators'

export const ContactSummary = ( props ) => {
   const { phone, email, displayContactInfo, altPhone } = props

   return (
      <React.Fragment>
         {displayContactInfo &&
            <React.Fragment>

               <h6 className="af-h6">
                  {`Contact Information:`}
               </h6>

               <p className="py-1 px-2 m-0 text-afBlue-dark">
                  {`Phone: ${phone}`}
               </p>

               { altPhone && altPhone !== `NO` &&
                  <p className="py-1 px-2 m-0 text-afBlue-dark">
                     {`Alternate Phone: ${altPhone}`}
                  </p>
               }
               <p className="py-1 px-2 m-0 text-afBlue-dark">
                  {`Email: ${isValidEmail( email ) ? email : `no valid email on file`}`}
               </p>

            </React.Fragment >
         }
      </React.Fragment>
   )
}

ContactSummary.propTypes = {
   phone: PropTypes.string,
   email: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
   displayContactInfo: PropTypes.bool,
   altPhone: PropTypes.string
}

export default ContactSummary