import React from 'react'
// import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

/**
 * the order button is aware of whether on order has been placed in a given app lifecycle
 * once it has been placed the button is disabled and help text displayed in case ppl back navigate
 * @param {*} props
 */
export const OrderButton = ( props ) => {
   const { orderSuccess, children, disabled, onClick, muted, buttonIsDisabled } = props
   // primary

   return (
      <React.Fragment>
         <button
            className="bg-teal py-3 w-40 hover:opacity-90 cursor-pointer"
            style={muted ?
               {
                  color: `#F3F7FE`
               }
               :
               {}
            }
            onClick={onClick}
            disabled={orderSuccess || Boolean( disabled ) || buttonIsDisabled}
            type="button"
         >
            {children}
         </button>
         {orderSuccess && <p className="help-text">{`Order already placed.`}</p>}
      </React.Fragment>
   )
}

OrderButton.propTypes = {
   orderSuccess: PropTypes.bool,
   children: PropTypes.node,
   disabled: PropTypes.bool,
   onClick: PropTypes.func,
   type: PropTypes.string,
   muted: PropTypes.bool,
   buttonIsDisabled: PropTypes.bool
}

const mapStateToProps = state => {

   return {
      orderSuccess: state.claims.orderSuccess
   }
}

export default connect( mapStateToProps )( OrderButton )

