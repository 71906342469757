import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export function StyledFieldWrapper( props ) {
   return (
      <div className="box-border flex my-3 mx-1 py-0.5 px-1 flex-col">
         { props.children }
      </div>
   )
}

export function RequiredSpan( props ) {
   return (
      <span className="text-requiredRed"> { props.children } </span>
   )
}

export function StyledErrorWrapper( props ) {
   return (
      <span className="text-requiredRed text-xs leading-4 min-h-16 mt-0.5 mb-0 mx-1">
         { props.children }
      </span>
   )
}

export function NewStyledInput( props ) {
   return (
      <input />
   )
}

export const StyledInput = styled.input`
&[type="number"]::-webkit-inner-spin-button,
&[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
   color: #9D67AB;
 }
 ::-moz-placeholder { /* Firefox 19+ */
   color: #9D67AB;
 }
 :-ms-input-placeholder { /* IE 10+ */
   color: #9D67AB;
 }
 :-moz-placeholder { /* Firefox 18- */
   color: #9D67AB;
 }
 outline: none;
border: ${props => { return props.error ? `1px solid #DE322E` : `1px solid #5D9CD4` }}; // TODO: replace the functionality of the color from false to act onFocus/onBlur
border-radius: 6px;
height: 40px;
font-size: 20px;
text-align: center;
box-shadow: 3px 5px 11px -8px rgba(0,0,0,0.62);
-webkit-box-shadow: 3px 5px 11px -8px rgba(0,0,0,0.62);
-moz-box-shadow: 3px 5px 11px -8px rgba(0,0,0,0.62);
`

StyledFieldWrapper.propTypes = {
   children: PropTypes.node.isRequired
}

RequiredSpan.propTypes = {
   children: PropTypes.node.isRequired
}

StyledErrorWrapper.propTypes = {
   children: PropTypes.node.isRequired
}