export default async function postUpcomingAppointment({body}) {
   const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/upcoming-doctor-appointment`, {
      method: `POST`,
      body: JSON.stringify( body ),
      headers: {
         Authorization: `PatientHash ${body.patient_id}`
      }
   })

   return response.json()
}