import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getRootUrlPath } from 'utils/url'

const ItemIsRequired = ( props ) => {
   const {
      item,
      item: { name, medicare_checklist, hcpc_id, itemKey },
      handleChange
   } = props

   const profitCenter = getRootUrlPath( window.location.pathname )

   return (
      <React.Fragment>
         {profitCenter !== `urology` ?
            <TableRow>

               <TableCell>
                  {`Is your current ${name} in need of replacement for reasons relating to hygienic safety, damage, loss, or a decline in machine performance?`}
               </TableCell>

               <TableCell>
                  <FormControl>
                     <RadioGroup
                        row
                        className="flex-no-wrap"
                        value={medicare_checklist?.reason?.quality_question_response ?? true}
                        onChange={( e ) => {

                           return handleChange( e.target.value, `quality`, itemKey, item )
                        }}
                        name={`${hcpc_id}_quality_question_response`}
                     >
                        <FormControlLabel
                           value="true"
                           control={<Radio color="primary" required />}
                           label="Yes"
                           labelPlacement="top"
                        />
                        <FormControlLabel
                           value="false"
                           control={<Radio color="primary" required />}
                           label="No"
                           labelPlacement="top"
                        />
                     </RadioGroup>
                  </FormControl>
               </TableCell>
            </TableRow>
            :
            null}
         <TableRow>
            <TableCell>
               {`Is your supply of ${name} at or approaching zero?`}
            </TableCell>
            <TableCell>
               <FormControl>
                  <RadioGroup
                     className="flex-no-wrap"
                     value={medicare_checklist?.reason?.quantity_question_response ?? true}
                     row
                     onChange={( e ) => {
                        return handleChange( e.target.value, `quantity`, itemKey, item )
                     }}
                     name={`${hcpc_id}_quantity_question_response`}
                  >
                     <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="top"
                     />
                     <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="top"
                     />
                  </RadioGroup>
               </FormControl>
            </TableCell>
         </TableRow>
      </React.Fragment>
   )
}

ItemIsRequired.propTypes = {
   item: PropTypes.shape({
      hcpcid: PropTypes.number,
      itemKey: PropTypes.string,
      medicare_checklist: PropTypes.shape({
         reason: PropTypes.shape({
            quality_question_response: PropTypes.string,
            quantity_question_response: PropTypes.string
         })
      }),
      name:PropTypes.string
   }),
   handleChange: PropTypes.func.isRequired
}

export default ItemIsRequired