import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getDisplayKey } from 'constants/moduleData'
import { removeHashFromSearch } from 'utils/url'

class ModuleRouteInterceptor extends React.Component {
   componentDidMount(){
      const { location: { pathname }, history, isAuthenticated } = this.props

      const module = getDisplayKey( pathname )
      const moduleIsNotHome = ( module !== `home` )
      const moduleBasePath = `/${module}`

      if ( module && moduleIsNotHome ) {

         // users may hit the tracking URL without being authenticated
         if ( !isAuthenticated && pathname !== `/track/` ) {
            const forwardedParams = removeHashFromSearch( window.location.search )

            return history.push({
               pathname: moduleBasePath,
               search: forwardedParams
            })
         }

         // if a user is authenticated and tries to back nav keep them on claims page
         // also if a user has persistent login this will push them to claims page rather than display login form
         if ( isAuthenticated && ( pathname === moduleBasePath ) ) {

            return history.push( `${moduleBasePath}/claim` )
         }
      }
   }
   componentDidUpdate( prevProps ){
      const { history, isAuthenticated, location: { pathname} } = this.props

      if ( !prevProps.isAuthenticated && isAuthenticated ) {
         const module = getDisplayKey( pathname )

         const moduleIsNotHome = ( module !== `home` )

         if ( moduleIsNotHome ){
            const moduleBasePath = `/${module}`
            history.push( `${moduleBasePath}/claim` )
         }
      }
   }
   render() {
      return null
   }
}

const mapStateToProps = ( state ) => {
   return {
      isAuthenticated: state.auth.isAuthenticated
   }
}

ModuleRouteInterceptor.propTypes = {
   isAuthenticated: PropTypes.bool,
   history: PropTypes.shape({
      push: PropTypes.func.isRequired
   }),
   location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
   })
}

const ConnectedModuleRouteInterceptor = connect( mapStateToProps )( ModuleRouteInterceptor )
export default withRouter( ConnectedModuleRouteInterceptor )