
// Regex error must be added for input to properly validate. Re-use first! Don't change any of the key/value pairs above unless instructed by form SOP coordinator.
// @NOTE whatever you do don't change these keys!!
export const regexErrors = {
   memberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
   email: `Enter a valid email`,
   dd: `Please enter a day between 01 - 31`,
   card_number: `Enter a valid card number`,
   expmm: `Please enter a month between 01 - 12`,
   expyyyy: `Enter valid expiration year`,
   ccv: `Enter a valid ccv`,
   first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   last_name: `Enter a valid last name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   phone: `Enter a valid phone number`,
   dob_yyyy: `Please enter a valid 4 digit year`,
   zip: `Zip code must contain 5 numbers`,
   address: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) numbers (0-9) or spaces. Must be more than 1 letter`,
   city: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) or spaces. Must be more than 1 letter`,
   memberId: `Must be longer than 3 characters. No special characters allowed except for dashes (-)`,
   dueDateYYYY: `Please enter a valid 4 digit year within 1 year of the current year`,
   weight: `Please enter a weight between 1 and 999 lbs.`,
   date_of_birth: `Please enter a valid date by month (01 - 12), day (01 - 31), and year within the past 120 years`,
   due_date: `Please enter a valid date by month (01 - 12), day (01 - 31). Must be prior to 9 months from current date and no later than 1 year in the past.`,
   state: `Please enter a state`,
   insuranceType: `Please select insurance type.`,
   hdyhau: `Please select how you heard about us.`,
   password: `Your password must contain lower case, upper case and at least one number or special character.`,
   passwordConfirmation: `Your passwords must match.`,
   terms: `You must accept the terms & conditions to continue`
}

// if a regex is needed these are official! If you need to add 1 go for it. Fields can validate without a regex pattern, i.e. date fields
export const regexPatterns = {
   zip: [{
      type: `generic`,
      regex: /(^\d{5}$)$/
   }],
   phone: [{
      type: `generic`,
      regex: /^\(([2-9]\d{2})\) \d{3}-\d{4}$/
   }],
   email: [{
      type: `generic`,
      regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i
   }],
   card_number: [
      {
         type: `visa`,
         regex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
      },
      {
         type: `mastercard`,
         regex: /^(?:5[1-5][0-9]{14})$/
      },
      {
         type: `amex`,
         regex: /^(?:3[47][0-9]{13})$/
      },
      {
         type: `discover`,
         regex: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
      }
   ],
   ccv: [
      {
         type: `generic`,
         regex: /^[0-9]{3,4}$/
      }
   ],
   first_name: [{
      type: `generic`,
      regex: /^[a-zA-Z- ]+$/
   }],
   address: [{
      type: `generic`,
      regex: /^[0-9a-zA-Z- .]+$/
   }],
   city: [{
      type: `generic`,
      regex: /^[a-zA-Z- .]+$/
   }],
   memberID: [{
      type: `generic`,
      regex: /^[0-9a-zA-Z-]+$/
   }],
   last_name: [{
      type: `generic`,
      regex: /^[a-zA-Z- ]+$/
   }],
   password: [{
      type: `magento-2-copy`,
      regex: /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*[a-z])(?=.*[$%&])).{8,255}$/
   }]
}

// a key on the left represents a dom input element where input text="[text]"
// the value on the right indicates what key the validator will use to pipe the input to the appropriate validator
export const validationNames = {
   "date_of_birth": `date_of_birth`,
   "due_date": `due_date`,
   "addressLine1": `address`,
   "addressLine2": `address`,
   "address": `address`,
   "zip": `zip`,
   "zipCode": `zip`,
   "username": `email`,
   "email-login-input": `email`,
   "altPhone": `phone`,
   "phoneNumber": `phone`,
   "phone": `phone`,
   "phone-login-input": `phone`,
   "card_number": `card_number`,
   "expmm": `expmm`,
   "dd": `dd`,
   "state": `state`,
   "dob-dd-input": `dd`,
   "dobDay": `dd`,
   "dd-dd-input": `dd`,
   "dob-mm-input": `expmm`,
   "dd-mm-input": `expmm`,
   "dobMonth": `expmm`,
   "dob-yyyy-input": `dob_yyyy`,
   "dobYear": `dob_yyyy`,
   "dd-yyyy-input": `dueDateYYYY`,
   "expyyyy": `expyyyy`,
   "ccv": `ccv`,
   "lastName": `last_name`,
   "firstName": `first_name`,
   "first_name": `first_name`,
   "last_name": `last_name`,
   "city": `city`,
   "weight": `weight`,
   "memberID": `memberID`,
   "insuranceType": `insuranceType`,
   "howDidYouHearAboutUs": `hdyhau`,
   "password": `password`,
   "passwordConfirmation": `passwordConfirmation`,
   "terms": `terms`
}

export const validOneDigitMonths = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
export const validOneDigitDays = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
export const validTwoDigitMonths = [ `01`, `02`, `03`, `04`, `05`, `06`, `07`, `08`, `09`, `10`, `11`, `12` ]
export const months = [
   {
      value: `01`,
      text: `January`
   },
   {
      value: `02`,
      text: `Febuary`
   },
   {
      value: `03`,
      text: `March`
   },
   {
      value: `04`,
      text: `April`
   },
   {
      value: `05`,
      text: `May`
   },
   {
      value: `06`,
      text: `June`
   },
   {
      value: `07`,
      text: `July`
   },
   {
      value: `08`,
      text: `August`
   },
   {
      value: `09`,
      text: `September`
   },
   {
      value: `10`,
      text: `October`
   },
   {
      value: `11`,
      text: `November`
   },
   {
      value: `12`,
      text: `December`
   }
]

export const usaStates = [ // USA
   {
      value: `AL`,
      text: `Alabama`
   },
   {
      value: `AK`,
      text: `Alaska`
   },
   {
      value: `AZ`,
      text: `Arizona`
   },
   {
      value: `AR`,
      text: `Arkansas`
   },
   {
      value: `CA`,
      text: `California`
   },
   {
      value: `CO`,
      text: `Colorado`
   },
   {
      value: `CT`,
      text: `Connecticut`
   },
   {
      value: `DE`,
      text: `Delaware`
   },
   {
      value: `DC`,
      text: `District of Columbia`
   },
   {
      value: `FL`,
      text: `Florida`
   },
   {
      value: `GA`,
      text: `Georgia`
   },
   {
      value: `HI`,
      text: `Hawaii`
   },
   {
      value: `ID`,
      text: `Idaho`
   },
   {
      value: `IL`,
      text: `Illinois`
   },
   {
      value: `IN`,
      text: `Indiana`
   },
   {
      value: `IA`,
      text: `Iowa`
   },
   {
      value: `KS`,
      text: `Kansas`
   },
   {
      value: `KY`,
      text: `Kentucky`
   },
   {
      value: `LA`,
      text: `Louisiana`
   },
   {
      value: `ME`,
      text: `Maine`
   },
   {
      value: `MD`,
      text: `Maryland`
   },
   {
      value: `MA`,
      text: `Massachusetts`
   },
   {
      value: `MI`,
      text: `Michigan`
   },
   {
      value: `MN`,
      text: `Minnesota`
   },
   {
      value: `MS`,
      text: `Mississippi`
   },
   {
      value: `MO`,
      text: `Missouri`
   },
   {
      value: `MT`,
      text: `Montana`
   },
   {
      value: `NE`,
      text: `Nebraska`
   },
   {
      value: `NV`,
      text: `Nevada`
   },
   {
      value: `NH`,
      text: `New Hampshire`
   },
   {
      value: `NJ`,
      text: `New Jersey`
   },
   {
      value: `NM`,
      text: `New Mexico`
   },
   {
      value: `NY`,
      text: `New York`
   },
   {
      value: `NC`,
      text: `North Carolina`
   },
   {
      value: `ND`,
      text: `North Dakota`
   },
   {
      value: `OH`,
      text: `Ohio`
   },
   {
      value: `OK`,
      text: `Oklahoma`
   },
   {
      value: `OR`,
      text: `Oregon`
   },
   {
      value: `PA`,
      text: `Pennsylvania`
   },
   {
      value: `PR`,
      text: `Puerto Rico`
   },
   {
      value: `RI`,
      text: `Rhode Island`
   },
   {
      value: `SC`,
      text: `South Carolina`
   },
   {
      value: `SD`,
      text: `South Dakota`
   },
   {
      value: `TN`,
      text: `Tennessee`
   },
   {
      value: `TX`,
      text: `Texas`
   },
   {
      value: `UT`,
      text: `Utah`
   },
   {
      value: `VT`,
      text: `Vermont`
   },
   {
      value: `VA`,
      text: `Virginia`
   },
   {
      value: `WA`,
      text: `Washington`
   },
   {
      value: `WV`,
      text: `West Virginia`
   },
   {
      value: `WI`,
      text: `Wisconsin`
   },
   {
      value: `WY`,
      text: `Wyoming`
   }
]
