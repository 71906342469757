import React, { useState } from 'react'
import { SmallLoader } from 'components/Loader'
import DoctorOption from './DoctorOption'
import { updateDoctor } from '../utils/utils'
import { reportToSentry } from 'utils/reportToSentry'
import { AF_SUPPORT_PHONE_NUMBER } from '../utils/constants'
import PropTypes from 'prop-types'

function DoctorResults({ searchLoading, searchResults, searchError, zipDisplay, handleSuccess, submitLoading, setSubmitLoading }) {

   const [ error, setError ] = useState( `` )

   const defaultError = `Oops... we encountered an error attempting to update your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

   const submitSelectDoctor = async ( selectedDoctor ) => {
      setSubmitLoading( true )
      setError( `` )
      const searchParams = new URLSearchParams( window.location.search )
      const updateDoctorResponse = await updateDoctor({
         doctorFirstName: selectedDoctor.first_name,
         doctorLastName: selectedDoctor.last_name,
         doctorPhone: selectedDoctor.telephone_number,
         doctorNPI: selectedDoctor.npi,
         profitCenterPK: `3`
      }, `PatientHash ${searchParams.get( `sgh` ) || searchParams.get( `txt` )}` )
         .catch( ( error ) => {
            reportToSentry( new Error( `Update Doctor Failed`, {
               cause: error
            }) )
            setSubmitLoading( false )

            return setError( defaultError )
         })

      setSubmitLoading( false )

      if ( updateDoctorResponse?.meta?.status === `OK` ) {
         return handleSuccess({
            firstName: selectedDoctor.first_name,
            lastName: selectedDoctor.last_name,
            phone: selectedDoctor.telephone_number
         })
      }

      setError( defaultError )
   }

   if ( zipDisplay ) return null

   if ( searchError ) return (
      <div className="my-10">
         <p className="text-requiredRed font-light">{searchError}</p>
      </div>
   )

   if ( searchLoading || submitLoading ) return (
      <div className="my-10">
         <SmallLoader loadingMsg={searchLoading ? `Loading doctor results...` : `Submitting your new doctor information...`} center />
      </div>
   )

   if ( !searchResults ) return null

   if ( !searchResults.length ) return (
      <div className="my-10">
         <p className="font-light text-black">{`No doctors fit this search criteria...`}</p>
      </div>
   )

   return (
      <>
         <div className="flex flex-col gap-7 max-h-96 overflow-auto px-5 my-10 mx-auto">
            {
               searchResults.map( ( doctorResult ) => {
                  return (
                     <DoctorOption
                        key={doctorResult.npi}
                        doctorResult={doctorResult}
                        selectDoctor={submitSelectDoctor}
                     />
                  )
               })
            }
         </div>
         {
            error &&
            <p className="text-requiredRed font-light">{error}</p>
         }
      </>
   )
}

DoctorResults.propTypes = {
   searchLoading: PropTypes.bool,
   searchResults: PropTypes.array,
   searchError: PropTypes.string,
   zipDisplay: PropTypes.bool,
   handleSuccess: PropTypes.func,
   submitLoading: PropTypes.string,
   setSubmitLoading: PropTypes.string
}

export default DoctorResults