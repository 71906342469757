import * as React from 'react'
import { RadioInput } from "../../components/Forms/components"
import postUpcomingAppointment from "./fetch"
import 'react-day-picker/dist/style.css'
import { StyledDayPicker } from './styled'
import AppShell from 'components/AppShell'
import { isPastDate } from 'utils/time'
import { PhoneLink } from 'components/AppShell/FooterLinks'
import { reportToSentry } from 'utils/reportToSentry'
import { moduleData } from 'constants/moduleData'
import { getPatientDoctor } from 'modules/doctorBlock/utils/utils'
import moment from 'moment'
import Loader from 'components/Loader'

export default function AppointmentInfo() {

   const [ hasAppointment, setHasAppointment ] = React.useState( null )
   const [ displayError, setDisplayError ] = React.useState( false )
   const [ displayCalendar, setDisplayCalendar ] = React.useState( false )
   const [ selectedDay, setSelectedDay ] = React.useState( null )
   const [ showThankyou, setShowThankyou ] = React.useState( false )
   const [ loading, setLoading ] = React.useState( true )
   const [ doctorName, setDoctorName ] = React.useState( `your healthcare provider` )
   const [ doctorPhone, setDoctorPhone ] = React.useState( `` )

   const currentQueryParams = new URLSearchParams(
      window.location.search
   )
   const hash = currentQueryParams.get( `sgh` ) || currentQueryParams.get( `txt` ) || currentQueryParams.get( `hash` )
   const themeKey = currentQueryParams.get( `theme` )

   const profitCenterPk = themeKey === `urology` ? 3 : 34

   React.useEffect( () => {
      getPatientDoctor( `PatientHash ${hash}` ).then( currentDoctor => {

         if ( currentDoctor ) {
            setDoctorName( `Dr. ${currentDoctor.first_name} ${currentDoctor.last_name}` )
            setDoctorPhone( currentDoctor.phone )
         }
         setLoading( false )
      })
         .catch( error => {
            reportToSentry( new Error( `error getting patient doctor on the appointment information page`, {
               cause: error
            }) )
            setLoading( false )
         })


   }, [] )


   const handleRadioChange = ( e ) => {
      if ( e.target.value === `false` ) {
         setDisplayCalendar( false )
         setSelectedDay( null )

         return setHasAppointment( false )
      }

      setHasAppointment( true )
      setDisplayCalendar( true )
   }

   const handleSubmit = ( e ) => {
      e.preventDefault()
      setLoading( true )
      const body =
        {
           "patient_id": hash,
           "upcoming_md_apt_date": selectedDay,
           "profit_center_pk": profitCenterPk
        }
      postUpcomingAppointment({
         body
      }).then( response => {
         if ( response.meta.status === `Created` ) {
            setShowThankyou( true )

            return setLoading( false )
         }
         reportToSentry( new Error( `There was an error posting the appointment info where the status was not created.` ) )
         setDisplayError( true )
         setLoading( false )
      })
         .catch( error => {
            reportToSentry( new Error( `There was an error posting the appointment info.`, {
               message: error
            }) )
            setDisplayError( true )
            setLoading( false )
         })
   }
   const prettyDate = moment( selectedDay ).format( `MM/DD/YYYY` )

   if ( displayError ) return <div><p>{`There was an error proccessing your request. Please try again.`}</p></div>
   if ( showThankyou ) return (
      selectedDay ?
         <div className="mt-16 w-10/12 md:w-1/2" id={themeKey ? themeKey : ``}>
            <h1>{`Doctor’s Appointment Confirmation`}</h1>
            <p>{`We’ll reach out to your ${doctorName}'s office ${themeKey === `urology` ? `around the time of your appointment on ${prettyDate} to confirm your prescription` : `to ask for updated clinical record`}. The current phone number we have on file for this office is ${doctorPhone}. Don’t forget to discuss any new continence care needs during your visit.
                    If you have any questions, please reach out to our ${themeKey === `urology` ? `Continence` : `Customer`} Care Specialists at `}
            <PhoneLink color="#143F69" number={moduleData[themeKey].pfPhone} />
            </p></div>
         :
         <div className="mt-16 w-10/12 md:w-1/2" id={themeKey ? themeKey : ``}>
            <h1>{`Doctor’s Approval Needed`}</h1>
            <p>
               {`${doctorName}'s office has indicated that you will need an appointment before moving forward. Call ${doctorName} at `}
               <PhoneLink color="#143F69" number={doctorPhone} />
               {` today to schedule your appointment. Your free supplies will not be sent until ${doctorName} has approved them. We cannot move forward without you scheduling an appointment. If you have any questions, please reach out to our ${themeKey === `urology` ? `Continence ` : `Customer `
               }Care Specialists at `}
               <PhoneLink color="#143F69" number={moduleData[themeKey].pfPhone} />
            </p>
         </div>

   )
   if ( loading ) return <Loader />

   return (
      <AppShell theme={themeKey ? themeKey : ``}>
         <div className="flex flex-col md:w-1/2" id={themeKey ? themeKey : ``}>
            <h1 className="w-10/12 self-center">{`Appointment Information`}</h1>
            <form onSubmit={handleSubmit} className="flex flex-col">
               <p className="w-10/12 self-center">{`We have sent ${doctorName} ${themeKey === `urology` ? `a prescription request` : `a request for your updated clinicals`}. Please schedule your required appointment with ${doctorName} to confirm your needs. Select “Yes” to provide us with the date your appointment is scheduled for so we can follow up with ${doctorName}. If you do not have an appointment scheduled, please select “No.”`}</p>
               <div className="flex w-10/12 self-center mt-4">
                  <RadioInput
                     label={`Yes`}
                     name="hasAppointment"
                     value="true"
                     id="hasAppointment"
                     checked={hasAppointment}
                     onChange={( e ) => { return handleRadioChange( e ) }}
                  />
                  <RadioInput
                     label={`No`}
                     name="hasAppointment"
                     value="false"
                     id="hasAppointment"
                     checked={hasAppointment === false}
                     onChange={( e ) => { return handleRadioChange( e ) }}
                  />
               </div>
               {displayCalendar &&
                        <div className="mt-4">
                           <StyledDayPicker
                              mode="single"
                              hidden={isPastDate}
                              selected={selectedDay}
                              onSelect={setSelectedDay}
                              modifiersStyles={{
                                 selected: {
                                    backgroundColor: `rgb(34, 85, 101)`
                                 }
                              }}
                           />
                        </div>}
               <button
                  type="submit"
                  disabled={( hasAppointment && !selectedDay ) || ( hasAppointment === null ) || loading}
                  className="mt-4 w-1/2 mx-auto py-2 bg-teal hover:opacity-90 cursor-pointer"
               >
                  {`Submit`}
               </button>
            </form>
         </div>
      </AppShell>
   )
}