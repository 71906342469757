import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
   setDisplayAddressVerificationBox
} from 'modules/addressValidator/actions'

// This component is used by edit contact parent and edit address parent
// special logic if source===edit-address to handle the 2 step address validation
// the parent src/components/Forms/ContactShippingSplitForm/index.js file updates the inputs
// -- based off of updates to the redux form

// @NOTE - when the opportunity arises a refactor here would remove some of the component specific uses, i.e. address confirmation functionality, and incorporate in a different way

export const ModalActionsBox = ( props ) => {
   const {
      hasErrors,
      closeModal,
      handleVerifyAddress,
      displayAddressVerificationBox,
      loading,
      source,
      toggleDisplayAddressVerificationBox,
      hasRequiredFields,
      handleClickWithErrors,
      verifiedAddressConfirmed
   } = props

   return (
      <div className="mt-8 mb-4">
         <div
            className="flex flex-row justify-around w-full"
         >
            <button
               className="curated-btn-previous cursor-pointer hover:opacity-70"
               variant="contained"
               onClick={( e ) => {
                  e.preventDefault()
                  if ( source === `edit-address` ){
                     toggleDisplayAddressVerificationBox( false )
                  }
                  closeModal()
               }}
            >
               {`Cancel`}
            </button>

            <button
               className={`curated-btn ${!loading && `cursor-pointer hover:opacity-90`}`}
               disabled={loading}
               onClick={( e ) => {
                  e.preventDefault()

                  if ( hasErrors || !hasRequiredFields ){
                     // pass click event back up to give user details of why submit is a no go
                     let errStr = ``
                     if ( hasErrors ){
                        errStr += `Please fix the form errors. `
                     }

                     if ( !hasRequiredFields ){
                        errStr += `Please fill in all fields.`

                     }

                     return handleClickWithErrors( errStr )
                  }

                  if ( source === `edit-address` ){
                     // if we're not displaying the verified address block step 2 is to handle verification
                     // TODO - I am not sure if I need this logic here, I may just need to call the func
                     if ( !displayAddressVerificationBox ){
                        handleVerifyAddress()
                     }
                  }
               }}
            >
               {!verifiedAddressConfirmed ? `Confirm` : `Update`}
            </button>
         </div>
      </div>
   )
}

ModalActionsBox.propTypes = {
   hasErrors: PropTypes.bool,
   closeModal: PropTypes.func.isRequired,
   source: PropTypes.string.isRequired,
   handleVerifyAddress: PropTypes.func,
   displayAddressVerificationBox: PropTypes.bool,
   loading: PropTypes.bool,
   toggleDisplayAddressVerificationBox: PropTypes.func.isRequired,
   user: PropTypes.object,
   hasRequiredFields: PropTypes.bool,
   handleClickWithErrors: PropTypes.func.isRequired,
   verifiedAddressConfirmed: PropTypes.bool
}

export const mapStateToProps = state => {
   return {
      verifiedAddressConfirmed: state.addressValidator.verifiedAddressConfirmed,
      user: state.auth.user
   }
}

export const mapDispatchToProps = dispatch => {
   return {
      toggleDisplayAddressVerificationBox: ( bool ) => {

         return dispatch( setDisplayAddressVerificationBox( bool ) )
      }
   }
}

export default connect( mapStateToProps, mapDispatchToProps )( ModalActionsBox )