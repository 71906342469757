import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import { withRouter } from 'react-router-dom'
import { moduleData, getDisplayKey } from 'constants/moduleData'
import OrderSummary from 'components/ConfirmOrder/OrderSummary'
import ShippingSummary from 'components/Forms/ContactShippingSplitForm/ShippingSummary.js'
import styled from 'styled-components'
import ShippingMessage from './ShippingMessage'
import OrderSummaryHeading from './OrderSummaryHeading'

const StyledBox = styled.div`
   width: 320px;
   @media (min-width: 600px) {
		width: 580px;
	}
`

class ThankYou extends Component {
   componentDidMount(){
      const { isAuthenticated, orderSuccess, history } = this.props
      if ( !isAuthenticated || !orderSuccess ) {
         return history.push( `/` )
      }
   }

   render() {
      const {
         user: {
            first_name,
            last_name,
            city,
            state,
            zip_code,
            street,
            street_2
         },
         order: { items },
         location: { pathname },
         isAuthenticated,
         serializedOrder: {
            canShipDate
         },
         orderSuccess
      } = this.props

      if ( !isAuthenticated || !orderSuccess ) {
         return null
      }

      const { pfName, pfPhone, specialistType, pfPk } = moduleData[getDisplayKey( pathname )]

      const addOnItems = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === true })
      const regularItems = Object.keys( items ).filter( itemKey => { return items[itemKey].add_on === false })

      return (
         <Container
            className="pt-6"
         >

            {regularItems?.length > 0 &&
               <ShippingMessage
                  canShipDate={canShipDate}
                  pfPk={pfPk}
                  pfName={pfName}
               />
            }
            <div className="paper p-6" >

               <OrderSummaryHeading
                  regularItemsLength={regularItems.length ?? 0}
                  addOnItemsLength={addOnItems.length ?? 0}
               />

               <OrderSummary useSerialized />

               <ShippingSummary
                  name={`${first_name} ${last_name}`}
                  address={street}
                  address2={street_2}
                  city={city}
                  state={state}
                  zip={zip_code}
               />

               <p className="text-center" >
                  {`If we run into any issues with processing your order, we will contact you via email and / or phone. `}
                  <span className="font-bold">{ `Please look for the number 844.276.5588 on your caller ID.`}</span>
               </p>
            </div>

            <StyledBox className="pt-6 pb-12 m-auto">
               <p className="help-text">
                  {`If you have any questions or concerns regarding your supplies please contact us directly to speak with your dedicated ${specialistType} Specialist. `}
               </p>
               <br />
               <p className="help-text text-center">{pfName}</p>
               <p className="help-text text-center">{pfPhone}</p>
               <p className="help-text text-center">{`Monday - Friday, 8 a.m. to 6 p.m. EST.`}</p>
            </StyledBox>

         </Container>
      )
   }
}

const mapStateToProps = ( state ) => {
   return {
      isAuthenticated: state.auth.isAuthenticated,
      serializedOrder: state.claims.serializedOrder,
      orderSuccess: state.claims.orderSuccess,
      order: state.claims.order,
      user: state.auth.user
   }
}

ThankYou.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string
   }),
   isAuthenticated: PropTypes.bool.isRequired,
   order: PropTypes.shape({
      items: PropTypes.array
   }),
   history: PropTypes.shape({
      push: PropTypes.func.isRequired
   }),
   orderSuccess: PropTypes.string.isRequired,
   serializedOrder: PropTypes.shape({
      canShipDate: PropTypes.string.isRequired
   }).isRequired,
   user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip_code: PropTypes.string,
      street: PropTypes.string,
      street_2: PropTypes.string
   })
}

const ConnectedThankYou = connect( mapStateToProps )( ThankYou )

export default withRouter( ConnectedThankYou )