import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withRouter } from 'react-router-dom'
import theme from 'styles/theme'
import aeroflow_logo from 'resources/img/aeroflowlogorgbcolor.svg'
import urology_logo from 'resources/img/urology_logo.svg'
import cgm_logo from 'resources/img/diabetes_logo.svg'
import { getDisplayKey } from 'constants/moduleData'
import { connect } from 'react-redux'
import { logoutUser } from 'modules/auth/actions'
import styled from 'styled-components'
import DisplayMessage from 'components/DisplayMessage'
import { getQueryParam } from 'utils/getQueryParams'
import '../../index.css'

const StyledSpan = styled.span`
   display: block;
   padding-right: 8px;
   cursor: pointer;
   @media (min-width: 600px) {
    padding-right: 16px;
   }
`

const StyledBox = styled.div`
   min-height: 70;
   font-size: 0.75rem;
   font-family: Open Sans;
   font-weight: 400;
   line-height: 1rem;
   color: slategray;
   display: flex;
   flex-direction: row;
   justify-content: ${props => { return props.themeKey ===`urology` ? `center` : `space-between` }};
   align-items: center;
   padding: 5px 7px;
   @media (min-width: 600px) {
    padding: 20px 30px;
    margin-left: 20px;
    justify-content: ${props => { return props.themeKey ===`urology` ? `center` : `space-between` }};
   }
`

const Template = ( props ) => {
   const { history: { location: { pathname } }, isAuthenticated } = props
   const displayKey = getDisplayKey( pathname )

   const themeKey = getQueryParam( `theme` )

   // assign logo based on pathname
   let templateHeaderImg = aeroflow_logo

   if ( displayKey === `urology` || themeKey === `urology` ) {
      templateHeaderImg = urology_logo
   }

   if ( themeKey === `cgm` ) {
      templateHeaderImg = cgm_logo
   }

   return (
      <MuiThemeProvider theme={theme}>
         <CssBaseline />
         <div className="paper">
            <StyledBox themeKey={themeKey}>

               <img
                  src={templateHeaderImg}
                  alt="Aeroflow Healthcare"
                  style={{
                     maxWidth: `${260}px`,
                     margin: `0 auto`
                  }}
               />
               {isAuthenticated &&
                  <StyledSpan
                     className="transform ease-in-out duration-150 hover:scale-110"
                     onClick={() => {
                        return props.logout()
                     }}
                  >
                     {`Logout`}
                  </StyledSpan>}
            </StyledBox>
         </div>
         <DisplayMessage />
         <div className="flex flex-col justify-center items-center">
            {props.children}
         </div>
      </MuiThemeProvider>
   )
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
   return {
      logout: () => {
         dispatch( logoutUser( ownProps.history, `You have logged out successfully.` ) )
      }
   }
}

Template.propTypes = {
   history: PropTypes.shape({
      location: PropTypes.shape({
         pathname: PropTypes.string
      })
   }),
   isAuthenticated: PropTypes.bool,
   logout: PropTypes.func,
   children: PropTypes.node
}

const mapStateToProps = ( state ) => {
   return {
      isAuthenticated: state.auth.isAuthenticated
   }
}

const ConnectedTemplate = connect( mapStateToProps, mapDispatchToProps )( Template )

export default withRouter( ConnectedTemplate )
