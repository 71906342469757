import { DayPicker } from 'react-day-picker'
import styled from 'styled-components'

export const StyledDayPicker = styled( DayPicker )`
    font-family: 'Inter', sans-serif;
   .rdp-months {
        justify-content: center;
   }
    .rdp-cell {
        padding: 8px;
    }
    @media ( max-width: 768px ) { 
        padding: .5rem;
    }

    @media (max-width: 425px) {
        padding: .5rem;
        width: 300px;

        .rdp-months {
            display: block;
            padding: 0;
        }

        .rdp-cell {
            padding: 0;
        }
    }
`