import React from 'react'
import PropTypes from 'prop-types'
import NumberInputHOC from 'modules/doctorLookupOffical/NumberInputHOC'

const inputConfig = {
   id: `zip`,
   name: `zip`,
   placeholder: `Zip`,
   label: `Enter Your Doctor's Zip`,
   inputType: `number`,
   type: `number`,
   columnLabelTop: true,
   required: true,
   hasRef: true,
   nextRef: null
}

const ZipCodeInput = props => {
   const { handleZipCodeChange, handleZipBlur, zip, error } = props

   const handleChange = ( e ) => {
      handleZipCodeChange( e )
   }

   return (
      <div>
         <NumberInputHOC
            id={inputConfig.id}
            name={inputConfig.name}
            label={inputConfig.label}
            required={inputConfig.required}
            type={inputConfig.type}
            value={zip}
            handleChange={handleChange}
            handleBlur={handleZipBlur}
            error={error}
            maxLength={`5`}
         />
      </div>
   )
}

ZipCodeInput.propTypes = {
   handleZipCodeChange: PropTypes.func.isRequired,
   handleZipBlur: PropTypes.func.isRequired,
   zip: PropTypes.string.isRequired,
   error: PropTypes.string
}

export default ZipCodeInput