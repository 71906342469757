export const BASE_URL = `https://aeroflowresupply.com`
export const DEV_BASE_URL = `https://dev.aeroflowresupply.com`
export const LOCAL_BASE_URL = `http://localhost:3000`
export const PORTAL_BASE_URL = `https://portal.aeroflow.online`

export const SESSION_STREAM_EVENT_ENDPOINT_URL = `https://cjs9vai903.execute-api.us-east-1.amazonaws.com/default/setItemResupplyUrologySessionStream`
export const GET_SESSION_STREAM_EVENT_ENDPOINT_URL = `https://u8i8likfh1.execute-api.us-east-1.amazonaws.com/default/getResupplySessionStream`
// export const LOCAL_SUBMIT_ORDER_ENDPOINT = `http://local.portal.aeroflow.online:8000/api/rest/v1/resupply-order/`
export const LIVE_SUBMIT_ORDER_ENDPOINT = `https://portal.aeroflow.online/api/rest/v1/resupply-order/`
// @TODO ENV VARS
// @NOTE if we're pinging aws lambda via api gateway the headers will be the same depending on env no matter what the call is (at least for now…)

export const LOCAL_SUBMIT_ORDER_HEADERS = {
   "Access-Control-Allow-Origin": `http://localhost:3000`
}

export const DEV_SUBMIT_ORDER_HEADERS = {
   "Access-Control-Allow-Origin": `https://dev.aeroflowresupply.com`
}

export const LOCAL_MAINTENANCE_MODE_HEADERS = {
   "Access-Control-Allow-Origin": `http://localhost:3000`
}

export const DEV_MAINTENANCE_MODE_HEADERS = {
   "Access-Control-Allow-Origin": `https://dev.aeroflowresupply.com`
}

export const LIVE_MAINTENANCE_MODE_HEADERS = {
   "Access-Control-Allow-Origin": `https://aeroflowresupply.com`
}

export const LIVE_SUBMIT_ORDER_HEADERS = {
   'Content-Type': `application/json`
}

export const medChatApiKeys = {
   urology: `1mHzVJjmGkqHbtqUWQoy_A`
}

export const MED_CHAT_BASE_URL = `https://medchatapp.com/widget/widget.js?api-key=`