import TextInput from 'components/Forms/components/TextInput'
import { Formatters, Validators, updateDoctor } from '../utils/utils'
import { SmallLoader } from 'components/Loader'
import { AF_SUPPORT_PHONE_NUMBER, regexErrors } from '../utils/constants'
import React, { useState, useRef } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import PropTypes from 'prop-types'

function ManualDoctorForm({ setManualDisplay, handleSuccess }) {

   const [ doctorInput, setDoctorInput ] = useState({
      lastName: ``,
      firstName: ``,
      phone: ``
   })
   const [ doctorInputErrors, setDoctorInputErrors ] = useState({})
   const [ submitLoading, setSubmitLoading ] = useState( false )
   const [ error, setError ] = useState( `` )

   const firstNameRef = useRef( null )
   const lastNameRef = useRef( null )
   const phoneRef = useRef( null )

   const defaultError = `Oops... we encountered an error attempting to update your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

   const handleChange = ( e ) => {
      setError( `` )
      const updateDoctorErrors = doctorInputErrors
      delete updateDoctorErrors[e.target.name]
      setDoctorInputErrors( Object.assign({}, doctorInputErrors, updateDoctorErrors ) )
      setDoctorInput( Object.assign({}, doctorInput, {
         [e.target.name]: e.target.value
      }) )
   }

   const handleBlur = ( e ) => {
      if ( e.target.name?.toLowerCase()?.includes( `name` ) && !Validators.name( e.target.value ) ) {
         return setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
            [e.target.name]: regexErrors.name
         }) )
      }

      if ( e.target.name === `phone` && !Validators.phone( e.target.value ) ) {
         return setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
            phone: regexErrors.phone
         }) )
      }
   }

   const submitUpdateDoctor = async () => {
      if ( !doctorInput.firstName.length || !doctorInput.lastName.length || !doctorInput.phone.length ) {
         setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
            ...( !doctorInput.firstName.length ? {
               firstName: regexErrors.name
            } : {}),
            ...( !doctorInput.lastName.length ? {
               lastName: regexErrors.name
            } : {}),
            ...( !doctorInput.phone.length ? {
               phone: regexErrors.phone
            } : {})
         }) )

         if ( !doctorInput.firstName.length ) return firstNameRef?.current?.focus()
         if ( !doctorInput.lastName.length ) return lastNameRef?.current?.focus()
         if ( !doctorInput.phone.length ) return phoneRef?.current?.focus()
      }

      if ( Object.keys( doctorInputErrors ).length ) setError( `Please resolve input errors` )

      setSubmitLoading( true )
      const searchParams = new URLSearchParams( window.location.search )
      const updateDoctorResponse = await updateDoctor({
         doctorFirstName: doctorInput.firstName,
         doctorLastName: doctorInput.lastName,
         doctorPhone: doctorInput.phone,
         profitCenterPK: `3`
      }, `PatientHash ${searchParams.get( `sgh` ) || searchParams.get( `txt` )}` )
         .catch( ( error ) => {
            reportToSentry( new Error( `Update Doctor Failed`, {
               cause: error
            }) )
            setSubmitLoading( false )

            return setError( defaultError )
         })

      setSubmitLoading( false )

      if ( updateDoctorResponse?.meta?.status === `OK` ) return handleSuccess( doctorInput )

      setError( defaultError )
   }

   if ( submitLoading ) return (
      <div className="my-10">
         <SmallLoader loadingMsg="Submitting your new doctor information..." center />
      </div>
   )

   return (
      <div className="flex flex-col w-full max-w-md mx-auto px-5 gap-3">
         <h1 className="text-3xl font-light text-center mt-3 mb-5">{`Add your Doctor Manually`}</h1>
         <TextInput
            name="firstName"
            label="First Name"
            className="sleep-input"
            errorClassName="sleep-input border-requiredRed"
            asteriskClassName="text-requiredRed"
            required
            errorMessageClassName="max-w-xs text-requiredRed text-center text-sm font-light mx-auto"
            reference={firstNameRef}
            value={doctorInput.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={doctorInputErrors.firstName}
         />
         <TextInput
            name="lastName"
            label="Last Name"
            className="sleep-input"
            errorClassName="sleep-input border-requiredRed"
            asteriskClassName="text-requiredRed"
            required
            reference={lastNameRef}
            errorMessageClassName="max-w-xs text-requiredRed text-center text-sm font-light mx-auto"
            value={doctorInput.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={doctorInputErrors.lastName}
         />
         <TextInput
            name="phone"
            label="Phone Number"
            required
            className="sleep-input"
            errorClassName="sleep-input border-requiredRed"
            asteriskClassName="text-requiredRed"
            reference={phoneRef}
            errorMessageClassName="max-w-xs text-requiredRed text-center text-sm font-light mx-auto"
            value={doctorInput.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            formatter={{
               function: Formatters.phone
            }}
            errorMessage={doctorInputErrors.phone}
         />
         <div className="mt-5">
            <button
               className="sleep-btn bg-teal"
               disabled={submitLoading}
               onClick={submitUpdateDoctor}
            >
               {`Add Doctor`}
            </button>
            <button
               className="sleep-btn bg-teal2 border-none text-teal"
               disabled={submitLoading}
               onClick={() => { return setManualDisplay( false ) }}
            >
               {`Back`}
            </button>
         </div>
         {
            error &&
            <p className="text-requiredRed font-light text-center">{error}</p>
         }
      </div>
   )
}

ManualDoctorForm.propTypes = {
   setManualDisplay: PropTypes.func,
   handleSuccess: PropTypes.func
}

export default ManualDoctorForm