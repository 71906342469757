import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const getFlexDirection = ( columnLabelTop /* columnLabelBottom */ ) => {

   return columnLabelTop ? `column-reverse` : `column`
}

const StyledFieldWrapper = styled.div`
   border: ${props => { return props.displayError ? `2px solid red` : `none` }};
   box-sizing: border-box;
   padding: 16px 0 4px 16px;
   display: flex;
   flex-direction: ${props => { return getFlexDirection( props.columnLabelTop, props.columnLabelBottom ) }};
`

const StyledErrorWrapper = styled.span`
   width: 100%;
   text-align: center;
`

const StyledInput = styled.input`
border: ${props => { return props.error ? `1px solid red` : `1px solid #666` }};
border-radius: 16px;
`

export const TextInputHOC = ( props ) => {

   const {
      id,
      name,
      value,
      handleChange,
      label,
      error,
      displayError,
      handleBlur,
      inputType,
      columnLabelTop,
      columnLabelBottom,
      placeholder,
      inputClassName
   } = props

   return (
      <StyledFieldWrapper
         columnLabelTop={columnLabelTop}
         columnLabelBottom={columnLabelBottom}
      >
         <label htmlFor={id}>{label}</label>
         <StyledInput
            error={displayError}
            type={inputType}
            id={id}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            className={inputClassName}
         />
         {error && <StyledErrorWrapper className="error small-text">
            {error}
         </StyledErrorWrapper>
         }
      </StyledFieldWrapper>
   )
}

TextInputHOC.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   defaultChecked: PropTypes.bool,
   handleChange: PropTypes.func.isRequired,
   checked: PropTypes.bool,
   label: PropTypes.string,
   displayError: PropTypes.bool,
   handleBlur: PropTypes.func,
   inputType: PropTypes.string,
   columnLabelTop: PropTypes.bool,
   columnLabelBottom: PropTypes.bool,
   placeholder: PropTypes.string,
   inputClassName: PropTypes.string,
   error: PropTypes.string
}

export default TextInputHOC