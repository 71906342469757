/* eslint-disable no-console */
import * as Validators from './validators'
import React, { useState, useEffect } from 'react'
import { getDoctorsInfo } from './utils'
import TypeaheadInput from 'components/Forms/Fields/Typeahead/TypeaheadInput'
import { filterOutOtherNames } from 'components/Forms/utils'
import ZipCodeInput from './ZipCodeInput'
import {SmallLoader} from 'components/Loader'

// @TODO the cancel token axios bit isn't working - but performance is pretty good
// need to debounce axios still
// but not a game-stopper

const DoctorLookup = ( props ) => {

   const [ zip, setZip ] = useState()
   const [ mode, setMode ] = useState( `single-zip` )
   const [ isSearchLastNameDisabled, setIsSearchLastNameDisabled ] = useState( true )
   const [ searchString, setSearchString ] = useState()
   const [ suggestions, setSuggestions ] = useState()
   const [ resultsLoading, setResultsLoading ] = useState( false )
   const [ isDoctorsNearBy, setIsDoctorsNearBy ] = useState( false )
   const [ selectedDoctor, setSelectedDoctor ] = useState()
   const [ displaySelectedDoctor, setDisplaySelectedDoctor ] = useState( false )
   const [ formInputErrors, setFormInputErrors ] = useState({})

   // @TODO remove this cold start lambda "wake up" when we set something up on aws side - hopefully pre production :)
   // for now it really is a fire and forget it - just want to "wake" the lambda up, the request otherwise serves no purpose
   useEffect( () => {
      getDoctorsInfo( parseInt( `28801` ), `xy` )
   }, [] )

   useEffect( () => {

      if ( mode === `single-zip` ){
         handleGetSuggestions( searchString )
      }

      if ( mode === `multi-zip` ){
         handleGetSuggestionsExpandedZipCodes( searchString )
      }
   }, [ searchString ] )

   const handleChange = ( e ) => {
      errorChecker( e.target.name )
      if ( e.target.name === `zip` ) {
         setSuggestions( [] )
         setSearchString( `` )
         setZip(
            e.target.value
         )
      }
   }

   const addError = ( name, msg ) => {
      return setFormInputErrors( Object.assign({}, formInputErrors, {
         [name]: msg
      }) )
   }

   const handleSetSearchString = ( val ) => {

      setSearchString( val )
   }

   // zip handler
   const handleBlur = ( e ) => {

      if ( e.target.value.length < 5 ) {
         // display errors
         setIsSearchLastNameDisabled( true )
         const errorMessage = Validators.ValidationController[e.target.name]( e.target.value )
         addError( e.target.name, errorMessage )
      } else {
         errorChecker( e.target.name )
         setIsSearchLastNameDisabled( !isSearchLastNameDisabled )
      }
   }

   // error checker simply sees if there's an error where key is input name - if so clear it out
   const errorChecker = ( name ) => {
      if ( formInputErrors[name] ) {
         const newErrors = Object.assign({}, formInputErrors )
         delete newErrors[name]
         setFormInputErrors( newErrors )
      }
   }

   // @TODO this will be removed from production
   // it's to give the stakeholders a sense of the unavoidable performance issues for typeahead with multi zip codes as part of search
   const toggleLocationProximity = ( ) => {
      if ( mode === `single-zip` ){ setMode( `multi-zip` ) }

      if ( mode === `multi-zip` ){ setMode( `single-zip` ) }
   }


   const handleGetSuggestions = async ( lastNameStr ) => {

      if ( lastNameStr && lastNameStr.length > 1 ) {
         setResultsLoading( true )

         // @TODO - debounce typeahead
         // @NOTE - axios debounce has been started by not finished
         try {
            const doctorInfo = await getDoctorsInfo( parseInt( zip ), lastNameStr )

            setResultsLoading( false )

            if ( doctorInfo && doctorInfo?.data?.data ) {

               if ( doctorInfo.data.data?.length === 0 ){
                  // no doc found
                  setIsDoctorsNearBy( true )
                  setSuggestions( [] )
               } else {

                  const basicLastNamesOnlyResults = filterOutOtherNames( doctorInfo.data.data, lastNameStr.toUpperCase() )
                  setSuggestions( basicLastNamesOnlyResults )
                  setIsDoctorsNearBy( false )
               }
            } else setSuggestions( [] )

         } catch ( error ) {
            console.log( error )
            setResultsLoading( false )
            setSuggestions( [] )
         }
      } else {
         setSuggestions( [] )
         setSelectedDoctor( null )
      }
   }

   // @REVIEW need to implement the advanced search in some way with expanded zip code search endpoint which is ready and below you can see it at work.
   const handleGetSuggestionsExpandedZipCodes = async ( ) => {
      // return null
      if ( suggestions ) {
         if ( suggestions.length < 1 ) {
            console.log( `no doctors found - checking nearby zip codes` )
            setResultsLoading( true )
            try {
               const doctorInfo = await getDoctorsInfo( zip, searchString, true )

               setResultsLoading( false )

               if ( doctorInfo && doctorInfo.data ) {
                  const basicLastNamesOnlyResults = filterOutOtherNames( doctorInfo.data.data, searchString.toUpperCase() )

                  if ( basicLastNamesOnlyResults.length === 0 ) {
                     setIsDoctorsNearBy( true )
                     console.log( `no nearby doctors` )
                  } else {
                     setIsDoctorsNearBy( false )
                  }

                  setSuggestions( basicLastNamesOnlyResults )
               } else {
                  setSuggestions( [] )
                  setIsDoctorsNearBy( false )
               }

            } catch ( error ) {
               console.log( error )
               setResultsLoading( false )
               setSuggestions( [] )
               setIsDoctorsNearBy( false )
            }
         }
      }
   }

   const handleDoctorSelect = ( e ) => {
      // now that we got the number to match we need to find that number in the suggustions and copy it
      const doctorMatch = suggestions.find( _doctor => {
         return _doctor.number.toString() === e.target.id
      })

      if ( doctorMatch ) {
         let selectedDoctor = Object.assign({}, {
            firstName: doctorMatch.basic.first_name,
            lastName: doctorMatch.basic.last_name,
            doctorPhone: doctorMatch.addresses[0].telephone_number,
            npi: doctorMatch.number,
            address: doctorMatch.addresses[0].address_1,
            city: doctorMatch.addresses[0].city
         }
         )

         setSelectedDoctor( selectedDoctor )
         setIsSearchLastNameDisabled( false )
         setDisplaySelectedDoctor( true )
      }
   }

   return (
      <div className="w-80 my-8 mx-auto">
         <div className="w-100 m-auto">
            <label className="flex flex-row justify-around items-center border border-solid border-red-500 p-3">
               <input
                  name="expanded-proximity"
                  type="checkbox"
                  checked={mode === `multi-zip`}
                  onChange={toggleLocationProximity}
                  style={{
                     width: `24px`
                  }}
               />
               <span>{`Include nearby zip codes`}</span>
            </label>
            <span className="small-text italic text-xs">
               {`Note due to the way the live npi registry api forces results in batches performance of the typeahaed with this checked will suffer.`}
            </span>
         </div>
         {
            !displaySelectedDoctor ?
               <>
                  <ZipCodeInput
                     handleZipCodeChange={handleChange}
                     handleZipBlur={handleBlur}
                     zip={zip}
                     error={formInputErrors.zip}
                  />

                  <>
                     <div className="mt-4">
                        <label htmlFor="doctorLastName">{`Enter Your Doctors LAST name`}</label>
                        <TypeaheadInput
                           validZipCode={zip && !formInputErrors.zip}
                           value={searchString}
                           suggestions={suggestions}
                           setValue={handleSetSearchString}
                           showAddress
                           showTaxonomy={false}
                           onlyShowNameCity={false}
                           handleBlur={() => { return null }}
                           handleDoctorSelect={handleDoctorSelect}
                           isDisabled={false}
                        />
                     </div>
                  </>

                  {
                     resultsLoading &&
                <SmallLoader />
                  }
                  {
                     isDoctorsNearBy && searchString.length > 1 &&
                 <div>
                    <p>{`No matching results.`}</p>
                    <p className="text-center"><button onClick={handleGetSuggestionsExpandedZipCodes} >{`include nearby zips`}</button></p>
                 </div>
                  }
               </>
               :
               <>
                  <h3>{`You selected:`}</h3>
                  <p>{`${selectedDoctor.firstName} ${selectedDoctor.lastName}`}</p>
                  <p>{`${selectedDoctor.address} ${selectedDoctor.city}`}</p>
                  <p>{`${selectedDoctor.doctorPhone}`}</p>
                  <button type="button" className="small" onClick={() => { setDisplaySelectedDoctor( !displaySelectedDoctor ) }}>{`Search Again`}</button>
               </>
         }
      </div>
   )
}

DoctorLookup.propTypes = {}

export default DoctorLookup