import qs from 'qs'
import {
   LOCAL_MAINTENANCE_MODE_HEADERS,
   DEV_MAINTENANCE_MODE_HEADERS,
   LIVE_MAINTENANCE_MODE_HEADERS,
   BASE_URL,
   DEV_BASE_URL,
   LOCAL_BASE_URL
} from 'constants/urls'

export const isLastCharMatch = ( str, matchChar ) => {
   return str.charAt( str.length - 1 ) === matchChar
}

export const removeEscapeChars = ( str ) => {
   return str.replace( /\\/g, `` )
}

/**
 * if `?` separator is present in querystring remove
 * @param {str} urlStr
 * @return {str}
 */
export const removeQsSeparator = ( urlStr ) => {

   return ( urlStr.charAt( 0 ) === `?` ) ?
      urlStr.substring( 1, ( urlStr.length ) )
      :
      urlStr
}

/**
 * detect if a given query param is in urlStr from history.location.search
 * @param {str} urlStr
 * @param {arr} keys - querystring param keys [strings]
 */
export const hasQueryParamByKey = ( urlStr, keys ) => {
   const queryParamsObj = qs.parse( removeQsSeparator( urlStr ) )

   let hasParam = false

   for ( const key in keys ){

      if ( queryParamsObj[keys[key]] ){
         hasParam = true
      }
   }

   return hasParam

}

export const getHashFromSearch = ( searchStr ) => {

   const qsObj = qs.parse( removeQsSeparator( searchStr ) )

   if ( qsObj.sgh ){
      return qsObj.sgh
   }

   if ( qsObj.txt ){
      return qsObj.txt
   }

   return ``
}

export const removeHashFromSearch = ( params ) => {
   const urlParams = new URLSearchParams( params )
   urlParams.delete( `sgh` )
   urlParams.delete( `txt` )

   return urlParams.toString()
}

/**
 * enable redirect if forSlash is the last char in url to route without forSlash as last char
 * @param { str } url
 * @param { func } history - from react-router-dom
 */
export const redirectIfLastCharForslash = ( url, history ) => {
   if ( isLastCharMatch( url, `/` ) ) {

      return history.replace( url.slice( 0, ( url.length - 1 ) ) )
   }
}

export const getRootUrlPath = ( path ) => {
   const rootPath = [ `urology` ].find( root => {

      return path.includes( root )
   })

   return rootPath
}

// @TODO - ENV VARS
export const getHeaders = ( token ) => {
   let _HEADERS

   if ( window.location.origin === BASE_URL ) {
      _HEADERS = LIVE_MAINTENANCE_MODE_HEADERS
   }

   if ( window.location.origin === DEV_BASE_URL ) {
      _HEADERS = DEV_MAINTENANCE_MODE_HEADERS
   }

   if ( window.location.origin === LOCAL_BASE_URL ) {
      _HEADERS = LOCAL_MAINTENANCE_MODE_HEADERS
   }

   if ( token ) {
      _HEADERS.Authorization = token
   }

   return _HEADERS
}
