import React from 'react'
import PropTypes from 'prop-types'

export const PipeSpacer = ( props ) => {
   return (
      <span
         style={{
            color: `${props.color}`
         }}
      >
         {` | `}
      </span>
   )
}

PipeSpacer.propTypes = {
   color: PropTypes.string
}