import React from 'react'
import PropTypes from 'prop-types'

export const ShippingCell = ( props ) => {
   const { mobileItem, estimatedShipDate, eligible } = props

   return (
      <React.Fragment>
         {mobileItem && <span className="shipping-field">{`Estimated Ship Date: `}</span>}
         <span>
            {`${eligible !== `Immediately`? `` : estimatedShipDate }`}
         </span>

      </React.Fragment >
   )
}

ShippingCell.propTypes = {
   mobileItem: PropTypes.bool,
   estimatedShipDate: PropTypes.string,
   eligible: PropTypes.string
}

export default ShippingCell