import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { UUIDSession } from './utils/session'
import { postSessionAnalytic } from './utils/ajax'

export class SessionTokenizer extends Component {
   constructor( props ){
      super( props )

      if ( this.props.isAuthenticated ){
         this.dispatchSessionAnalytic()
      }

      if ( !this.props.isAuthenticated ){
         this.deleteSessionUUID()
      }
   }
   componentDidUpdate( prevProps ){
      const { location, isAuthenticated } = this.props

      const prevPath = prevProps.location?.pathname
      const thisPath = location?.pathname

      if ( thisPath !== prevPath ){

         if ( isAuthenticated ){
            this.dispatchSessionAnalytic()
         }
      }

      if ( prevProps.isAuthenticated !== isAuthenticated ){

         if ( isAuthenticated ){
            this.dispatchSessionAnalytic()
         }
         if ( !isAuthenticated ){
            this.deleteSessionUUID()
         }
      }
   }
   deleteSessionUUID = () => {
      UUIDSession.delete()
   }
   dispatchSessionAnalytic = async () => {

      let session_id = UUIDSession.retrieve()

      if ( !session_id ) {
         session_id = UUIDSession.create()
         UUIDSession.store( session_id )
      }

      const page = this.props.location.pathname
      const domain = window?.location?.host
      const { unique_hash: patient_hash } = this.props.user
      let page_entry

      // create page_entry timestamp
      let date
      try {
         date = new Date().toISOString()
            .slice( 0, 19 )
            .replace( `T`, ` ` )
      } catch ( err ){
         // eslint-disable-next-line no-console
         console.log( `err`, err )
      }

      if ( date ){
         page_entry = date
      }

      if ( !page_entry || !patient_hash || !domain || !page || !session_id ) return

      try {
         postSessionAnalytic({
            page_entry,
            patient_hash,
            domain,
            page,
            session_id
         })
      } catch ( err ){

         // eslint-disable-next-line no-console
         console.log( `err`, err )
      }
   }
   render() {

      return null
   }
}

SessionTokenizer.propTypes = {
   user: PropTypes.object,
   isAuthenticated: PropTypes.bool.isRequired,
   location:PropTypes.shape({
      pathname: PropTypes.string
   })
}

export const mapStateToProps = state => {
   return {
      user: state.auth.user,
      activeStep: state.uroCuratedOfficial.activeStep,
      isAuthenticated: state.auth.isAuthenticated,
      sizingDisplayForm: state.uroCuratedOfficial.sizingDisplayForm
   }
}

const ConnectedSessionTokenizer = connect( mapStateToProps )( SessionTokenizer )
export default withRouter( ConnectedSessionTokenizer )
