import axios from 'axios'

export const getPatientOrderHistory = ( user ) => {
   const { unique_hash, theme, patientId } = user

   let url = `${process.env.REACT_APP_RESUPPLY_ORDER_HISTORY_ENDPOINT}?patientID=${patientId}`

   if ( theme === `cgm` ) {
      url = `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/order-status?patient_hash=${unique_hash}`
   }


   return axios.get( `${url}`, {
      headers: {
         'Authorization': `PatientHash ${unique_hash}`
      }
   })

}