import { configureStore, getDefaultMiddleware } from 'redux-starter-kit'
import reducer, { rootEpic } from './reducers'
import { createEpicMiddleware } from 'redux-observable'

const epicMiddleware = createEpicMiddleware()
const middleware = [ ...getDefaultMiddleware(), epicMiddleware ]

const reducerStore = () => {
   const store = configureStore(
      {
         reducer: reducer,
         middleware: middleware
      }
   )


   epicMiddleware.run( rootEpic )

   return store
}

export default reducerStore