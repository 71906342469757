import { BASE_ADDRESS_UPDATE_URL, BASE_ADDRESS_VERIFY_URL } from './constants'
import * as types from './actionTypes'
import axios from 'axios'

/* eslint-disable no-console */

export const resetAddressVerification = () => {
   return {
      type: types.RESET_ADDRESS_VERIFICATION
   }
}

export const setAddressVerificationLoading = ( bool ) => {
   return {
      type: types.ADDRESS_VERIFICATION_LOADING,
      bool
   }
}

export const setVerifiedAddressConfirmed = ( bool ) => {
   return {
      type: types.VERIFIED_ADDRESS_CONFIRMED,
      bool
   }
}

export const setAddressVerified = ( status ) => {

   return {
      type: types.ADDRESS_VERIFIED,
      status
   }
}

export const setAddressVerificationData = ( data ) => {

   return {
      type: types.ADDRESS_VERIFICATION_DATA,
      data
   }
}

export const setDisplayAddressVerificationBox = ( bool ) => {
   return {
      type: types.DISPLAY_ADDRESS_VERIFICATION_BOX,
      bool
   }
}


// middleware (thunk)
export const getUpdatedAddressVerificationData = ( avPk, headers ) => {

   return async ( dispatch, getState ) => {

      const { auth: { user: { patient_id: patientID } } } = getState()

      try {
         let addressVerificationData = await axios.get( `${BASE_ADDRESS_VERIFY_URL}?pk=${avPk}&patientID=${patientID}`, {
            headers: headers,
            withCredentials: true
         })

         if ( addressVerificationData ) {
            // if we have a verified address we set that to check verified address by default
            dispatch( setAddressVerificationData( addressVerificationData.data ) )
            if ( addressVerificationData.data.verified_address1 ) {
               dispatch( setAddressVerified( `verified` ) )
            } else {
               dispatch( setAddressVerified( `unverified` ) )
            }

         }

         return dispatch( setAddressVerificationLoading( false ) )
      } catch ( err ) {

         return dispatch( setAddressVerificationLoading( false ) )
      }
   }
}

export const updateAddress = ( userCredentials, userEnteredAddress ) => {
   return async ( dispatch ) => {
      dispatch( setAddressVerificationLoading( true ) )
      dispatch( setDisplayAddressVerificationBox( true ) )
      const { address1, address2, city, state, zip } = userEnteredAddress
      const { dob, phone, email, patientID } = userCredentials

      const originalAddress1 = address2 ? `${address1} ${address2}` : address1

      const headers = {}
      headers[`DOB-Crosscheck`] = dob

      if ( phone ) {
         headers[`Phone-Crosscheck`] = phone
      } else {
         // use email
         headers[`Email-Crosscheck`] = email
      }

      const requestPayload = {
         patientID: patientID,
         originalAddress1: originalAddress1,
         originalCity: city,
         originalState: state,
         originalZip: zip,
         whoRequested: `aeroflowresupply.com`
      }

      try {
         let updatedAddressAvPk = await axios.post( `${BASE_ADDRESS_UPDATE_URL}`,
            requestPayload,
            {
               headers: headers
            }
         )

         if ( updatedAddressAvPk ) {

            return setTimeout( () => {
               return dispatch( getUpdatedAddressVerificationData( updatedAddressAvPk.data.aavPK, headers, patientID ) )
            }, 5000 )

         }
      } catch ( err ) {

         return dispatch( setAddressVerificationLoading( false ) )
      }
   }
}