import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledRadioGroup = styled( RadioGroup )`
   flex-direction: row;
   margin-top: 2px;
   margin-bottom: 2px;
`

const StyledRadioGroupLabel = styled( StyledRadioGroup )`
   justify-content: center;
`

const InputRadioLoginType = ( props ) => {
   const { onToggle, loginType } = props

   return (
      <FormControl component="fieldset">
         <StyledRadioGroupLabel>
            <p className="text-afBlue-dark font-bold m-0" >
               {`Login With:`}
            </p>
         </StyledRadioGroupLabel>
         <StyledRadioGroup
            row
            name="loginType"
            value={loginType}
            onChange={onToggle}
         >
            <FormControlLabel
               value="phone"
               control={<Radio />}
               label="Phone Number"
               labelPlacement="bottom"
            />

            <FormControlLabel
               value="email"
               control={<Radio />}
               label="Email"
               labelPlacement="bottom"
            />
         </StyledRadioGroup>

      </FormControl>
   )
}

InputRadioLoginType.propTypes = {
   onToggle: PropTypes.func.isRequired,
   loginType: PropTypes.string.isRequired
}

export default InputRadioLoginType