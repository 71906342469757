import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
   max-width: 600px;
`
const Wrapper = ( props ) => {
   return (
      <StyledDiv className="ma-auto text-center pv-12 ph-18" >
         {props.children}
      </StyledDiv>
   )
}

Wrapper.propTypes = {
   children: PropTypes.node
}

export default Wrapper