export const OPT_OUT_OPTIONS = [
   {
      value: `7`,
      label: `1 Week`
   },
   {
      value: `14`,
      label: `2 Weeks`
   },
   {
      value: `28`,
      label: `1 Month`
   }
]

export const OPT_OUT_REASONS = [
   {
      value: `I have plenty of supplies left`,
      label: `I have plenty of supplies left`
   },
   {
      value: `I’m not changing them as often as I was`,
      label: `I’m not changing them as often as I was`
   },
   {
      value: `I’m not happy with the product I receive`,
      label: `I’m not happy with the product I receive`
   },
   {
      value: `I am not at home or am in the hospital`,
      label: `I am not at home or am in the hospital`
   },
   {
      value: `My condition has improved`,
      label: `My condition has improved`
   }
]