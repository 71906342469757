import React, { useState } from 'react'
import ShippingSummary from './ShippingSummary'
import ContactSummary from './ContactSummary'
import OrderSummary from 'components/ConfirmOrder/OrderSummary.js'
import { OrderSummarySection } from 'components/ConfirmOrder/components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AgreementBlock from 'components/ConfirmOrder/AgreementBlock'
import EditContactInfo from 'components/Forms/ContactShippingSplitForm/EditContactInfo.js'
import { isValidEmail } from 'components/Forms/validators'
import { setDisplayMessage } from 'modules/auth/actions'
import { setMedChatDisplay } from 'resources/thirdparty/MedChatController'

const ConfirmPageFormsController = ( props ) => {

   const { confirmedAddress, handleSubmit } = props

   const [ displayShippingForm, setDisplayShippingForm ] = useState( false )
   const [ displayProfileForm, setDisplayProfileForm ] = useState( false )
   const [ displayAgreeHelper, setDisplayAgreeHelper ] = useState( false )
   const [ agreed, toggleAgreed ] = useState( false )

   const enforceEmail = true

   const handleSubmitOrder = ( ) => {
      if ( !agreed ) {
         return setDisplayAgreeHelper( true )
      }
      if ( !confirmedAddress ) {
         return setDisplayShippingForm( !displayShippingForm )
      } else {
         setDisplayAgreeHelper( false )
         setDisplayShippingForm( false )

         return handleSubmit()
      }
   }

   const handleToggleDisplayProfileForm = ( e ) => {
      if ( e ){
         e.preventDefault()
      }
      // handle medChat display bug
      if ( displayProfileForm ){
         // closing modal show medChat
         setMedChatDisplay( true )
      } else {
         // opening hide medChat
         setMedChatDisplay( false )
      }

      return setDisplayProfileForm( !displayProfileForm )
   }

   const handleToggleAgreed = () => {
      if ( !agreed ){
         setDisplayAgreeHelper( false )
      } else {
         setDisplayAgreeHelper( false )
      }

      return toggleAgreed( !agreed )
   }

   const {
      handleSetDisplayMessage,
      user: {
         city,
         state,
         street: address1,
         street_2: address2,
         zip_code: zip,
         first_name: firstName,
         last_name: lastName,
         phone,
         email,
         alt_patient_phone: altPhone
      }
   } = props

   // see if email is valid if enforced
   const hasValidEmail = isValidEmail( email ) || !enforceEmail

   if ( !hasValidEmail ){
      handleSetDisplayMessage({
         type: `error`,
         msg: `Action Required: Please enter email address.`
      })
   }

   if ( hasValidEmail ){
      handleSetDisplayMessage({
         type: ``,
         msg: ``
      })
   }

   return (
      <div>

         <div>
            <OrderSummary useSerialized />
         </div>

         <form>

            <OrderSummarySection>
               <ShippingSummary
                  address={address1}
                  address2={address2}
                  city={city}
                  state={state}
                  zip={zip}
                  name={`${firstName} ${lastName}`}
               />

            </OrderSummarySection>

            <OrderSummarySection>
               <ContactSummary
                  phone={phone}
                  email={email}
                  altPhone={altPhone}
                  displayContactInfo
               />

               <button
                  className={`${!hasValidEmail ? `bg-error` : `cursor-pointer hover:opacity-90`} bg-teal py-2 px-3`}
                  onClick={handleToggleDisplayProfileForm}
               >
                  {hasValidEmail ? `Change Contact Info` : `Click to enter a valid email address`}
               </button>

               <EditContactInfo
                  open={displayProfileForm}
                  closeModal={handleToggleDisplayProfileForm}
                  phone={phone}
                  email={email}
                  altPhone={altPhone}
               />

            </OrderSummarySection>

            <div className="ma-16">
               {hasValidEmail &&
                  <AgreementBlock
                     agreed={agreed}
                     handleSubmit={handleSubmitOrder}
                     toggleAgreed={handleToggleAgreed}
                     displayAgreeHelper={displayAgreeHelper}
                     addressConfirmed={confirmedAddress}
                  />
               }
            </div>

         </form>

      </div>
   )
}

const mapStateToProps = state => {
   return {
      user: state.auth.user,
      confirmedAddress: state.addressValidator.verifiedAddressConfirmed
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleSetDisplayMessage: ( obj ) => {
         return dispatch( setDisplayMessage( obj ) )
      }
   }
}

ConfirmPageFormsController.propTypes = {
   handleSetDisplayMessage: PropTypes.func.isRequired,
   handleSubmit: PropTypes.func.isRequired,
   user: PropTypes.shape({
      zip_code: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      street_2: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone: PropTypes.string,
      alt_patient_phone: PropTypes.string,
      email: PropTypes.string,
      city: PropTypes.string,
      unique_hash: PropTypes.string
   }),
   confirmedAddress: PropTypes.bool
}

export default connect( mapStateToProps, mapDispatchToProps )( ConfirmPageFormsController )
