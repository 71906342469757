import * as Validators from './validators'
const SPECIAL_FORMAT_SUBSTR = `FORMATTER_USE_`

export const validateField = ( e, formatterFn, errorFn ) => {
   const errorMsg = Validators.ValidationController[ Validators.inputNameCast( e.target.name ) ]( e.target.value )

   // format it!
   if ( errorMsg && errorMsg.includes( SPECIAL_FORMAT_SUBSTR ) ) {
      const [ , formatFunctionStr ] = errorMsg.split( SPECIAL_FORMAT_SUBSTR )
      const formattedVal = Validators.FormatterController[ formatFunctionStr ]( e.target.value )

      return formatterFn( e.target.name, formattedVal )
   }

   if ( errorMsg ){

      return errorFn( e.target.name, errorMsg )
   }
}