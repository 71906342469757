import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import styled from 'styled-components'
import { packageTrackingLinkClick } from 'modules/analytics/utils'


// using Box here - unable to get divide to work properly
// https://tailwindcss.com/docs/divide-width
const Box = styled.ul`
   list-style-type: none;
   margin: 0;
   padding: 15px 10px;
   display: inline-block;
   border-bottom: 1px solid #000000;
   
   li:last-child {
      margin-top: 14px;
   }

   &.last-item {
      border: none;
   }
`

const TrackingLink = styled.a`
   font-size: 20px;
   font-weight: bold;
   color: #225565;
`

function Package({invoice}) {

   // this was part of hoots code and not sure why he was pulling stuff from query params
   // just want to keep it in there just in case there is an issue
   //  const trackingLink = new URLSearchParams(
   //     invoice.tracking_url
   //  )
   //  const trackingNumber = trackingLink.get( `tracknumbers` )

   return (
      <div className="rounded-2xl bg-white p-3 sm:p-4 pt-0 mb-10 mx-4 sm:mx-0 w:full">

         <Media
            queries={{
               small: `(max-width: 599px)`,
               medium: `(min-width: 600px)`
            }}
         >
            {matches => {
               return (
                  <>
                     {matches.small &&
                     <>
                        {invoice && invoice.Items.map( ( i ) => {

                           return (
                              <Box key={i.package_number} className={invoice.Items[invoice.Items.length - 1].hcpc_description === i.hcpc_description ? `last-item` : ``}>
                                 <li className="flex pb-2"><div className="pr-2">{`Product: `}</div>{i.hcpc_description}</li>
                                 <li className="pb-2">{`Qty: ${i.quantity_sold}`}</li>
                                 {/* Below may be added later */}
                                 {/* <li>{`Status: ${invoice.status}`}</li> */}
                              </Box>
                           )
                        })}
                        <TrackingLink
                           mobile target="_blank" rel="noreferrer"
                           href={invoice.tracking_url}
                           onClick={() => { return packageTrackingLinkClick() }}
                        >{`Tracking Number ${invoice.tracking}`}</TrackingLink>
                     </>
                     }
                     {matches.medium &&
                     <>
                        <table className="w-full text-left border-black border-b-2 pt-4 pb-0">
                           <thead>
                              <tr>
                                 <th width="70%">{`Product`}</th>
                                 <th width="15%">{`Qty`}</th>
                                 {/* Below may be added later */}
                                 {/* <td>{`Status`}</td> */}
                              </tr>
                           </thead>
                           <tbody>
                              {invoice && invoice.Items.map( i => {
                                 return (
                                    <tr key={i.package_number} className="h-10">
                                       <td>{i.hcpc_description}</td>
                                       <td>{i.quantity_sold}</td>
                                       {/* Below may be added later */}
                                       {/* <td>{`In Progress`}</td> */}
                                    </tr>
                                 )
                              })}
                           </tbody>
                        </table>
                        <TrackingLink
                           onClick={() => { return packageTrackingLinkClick() }}
                           target="_blank" rel="noreferrer"
                           href={invoice.tracking_url}
                        >{`Tracking Number ${invoice.tracking}`}</TrackingLink>
                     </>
                     }
                  </>
               )
            }}
         </Media>
      </div>
   )
}

Package.propTypes = {
   invoice: PropTypes.array,
   packageNumber: PropTypes.number
}

export default Package
