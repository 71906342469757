import { reportToSentry } from "utils/reportToSentry"

export default async function postDemographics( hash, input ) {

   const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-demographics`, {
      headers: {
         Authorization: `PatientHash ${hash}`
      },
      body: JSON.stringify( input ),
      method: `POST`
   }).catch( err => {
      reportToSentry( `there was in error in postDemographics`, err )
   })

   return response
}