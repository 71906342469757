import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { Formatters, getPatientDoctor } from '../utils/utils'

function useRetrieveDoctor( onExistingDoctor, setDoctor ) {

   const [ loading, setLoading ] = useState( false )

   useEffect( () => {
      const controller = new AbortController()

      setLoading( true )
      const searchParams = new URLSearchParams( window.location.search )
      const hash = searchParams.get( `sgh` ) || searchParams.get( `txt` )
      getPatientDoctor( `PatientHash ${hash}` ).then( currentDoctor => {
         if ( currentDoctor ) {
            if ( onExistingDoctor && currentDoctor?.first_name && currentDoctor?.last_name && currentDoctor?.phone ) onExistingDoctor()
            setDoctor({
               firstName: currentDoctor?.first_name ?? ``,
               lastName: currentDoctor?.last_name ?? ``,
               phone: currentDoctor?.phone ? Formatters.phone( currentDoctor.phone ) : ``,
               npi: currentDoctor?.npi ?? ``
            })
         }
         setLoading( false )
      })
         .catch( error => {
            if ( error.name !== `AbortError` ) {
               setLoading( false )
               reportToSentry( new Error( `error getting patient doctor`, {
                  cause: JSON.stringify( error ?? `` )
               }) )
            }
         })


      return () => { controller.abort() }
   }, [] )

   return loading
}

export default useRetrieveDoctor