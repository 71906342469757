import ShippingSummary from 'components/Forms/ContactShippingSplitForm/ShippingSummary'
import EditAddress from 'modules/uroCuratedOfficial/modules/Checkout/EditAddress'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { updateUserAddress } from './fetch'
import Loader from 'components/Loader'
import { setDisplayMessage } from 'modules/auth/actions'
import { setVerifiedAddressConfirmed } from 'modules/addressValidator/actions'

const AddressConfirmation = ( props ) => {
   const [ displayShippingForm, setDisplayShippingForm ] = React.useState( false )
   const [ loading, setLoading ] = React.useState( false )

   const { user, history, setErrorMessage, setAddressConfirmed, verifiedAddressConfirmed } = props

   const handleToggleDisplayShippingForm = ( e ) => {
      if ( e ){
         e.preventDefault()
      }

      return setDisplayShippingForm( !displayShippingForm )
   }

   const handleNext = ( e, updatedUser ) => {

      e && e.preventDefault && e.preventDefault()

      setLoading( true )

      // if folks click the current address is correct no need to send to server
      if ( !updatedUser ){
         setLoading( false )
         setAddressConfirmed( true )

         return history.push( `/urology/claim/confirm` )
      }

      updateUserAddress( updatedUser ?? user )
         .then( data => {
            setLoading( false )

            const { status } = data
            if ( status === `success` ){
               setAddressConfirmed( true )

               return history.push( `/urology/claim/confirm` )
            }
         })
         .catch( err => {

            setLoading( false )

            const hasErrorStatus = err?.status

            if ( !hasErrorStatus ) {
               return setErrorMessage({
                  type: `info`,
                  msg: `We've encountered an unexpected error. Please try again`
               })
            }

            const { status } = err

            if ( status === `failed` ) {
               return setErrorMessage({
                  type: `info`,
                  msg: `Transaction failed. Oops, something went wrong please try again`
               })
            }
            if ( status === `exception` ) {
               return setErrorMessage({
                  type: `info`,
                  msg: `It seems the software has encountered an exception and cannot process this request. Please try again.`
               })
            }

            return setErrorMessage({
               type: `info`,
               msg: `Transaction failed. ${status ?? `Status: ${status}`}`
            })
         })
   }

   const handleCheckBoxClick = () => {
      setAddressConfirmed( !verifiedAddressConfirmed )
   }

   const handleUserConfirmedAddress = ( user ) => {
      setAddressConfirmed( true )
      handleNext( null, user )
   }

   if ( loading ) {
      return (
         <Loader />
      )
   }

   return (
      <div>
         <h1 className="text-center">{`Confirm ${user.first_name} ${user.last_name}'s Address`}</h1>
         <ShippingSummary
            address={user.street}
            address2={user.street_2}
            city={user.city}
            state={user.state}
            zip={user.zip_code}
            name={`${user.first_name} ${user.last_name}`}
         />
         <div className="flex mb-8 mt-4">
            <input
               type="checkbox" id="addressVerification"
               checked={verifiedAddressConfirmed} onClick={handleCheckBoxClick} className="w-4 h-4 cursor-pointer"
            />
            <label className="ml-2 cursor-pointer" htmlFor="addressVerification">{`I verify this is my current address`}</label>
         </div>
         <div className="flex justify-around w-64 mt-8">
            <button className={`bg-teal py-2 px-3 ${!verifiedAddressConfirmed && `hover:opacity-90 cursor-pointer` }`} disabled={verifiedAddressConfirmed} onClick={handleToggleDisplayShippingForm} >{`Edit Address`}</button>

            <button className={`bg-teal py-2 px-3 ${verifiedAddressConfirmed && `hover:opacity-90 cursor-pointer` }`} disabled={!verifiedAddressConfirmed} onClick={handleNext} >{`Next`}</button>
         </div>
         <EditAddress
            open={displayShippingForm}
            user={user}
            closeModal={handleToggleDisplayShippingForm}
            handleAddressUpdate={handleToggleDisplayShippingForm}
            handleUserConfirmedAddress={handleUserConfirmedAddress}
         />

      </div>
   )
}

AddressConfirmation.propTypes = {
   user: PropTypes.shape({
      street: PropTypes.string,
      street_2: PropTypes.string,
      city: PropTypes.string,
      zip_code: PropTypes.string,
      state: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
   }),
   history: PropTypes.shape({
      push: PropTypes.func.isRequired
   }),
   setErrorMessage: PropTypes.func,
   setAddressConfirmed: PropTypes.func,
   verifiedAddressConfirmed: PropTypes.bool
}

const mapStateToProps = ( state ) => {
   return {
      user: state.auth.user,
      verifiedAddressConfirmed: state.addressValidator.verifiedAddressConfirmed
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      setErrorMessage: ( obj ) => {
         return dispatch( setDisplayMessage( obj ) )
      },
      setAddressConfirmed: ( bool ) => {

         return dispatch( setVerifiedAddressConfirmed( bool ) )
      }
   }
}

const ConnectedAddressConfirmation = connect( mapStateToProps, mapDispatchToProps )( AddressConfirmation )
export default withRouter( ConnectedAddressConfirmation )
