import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const StyledSmallDiv = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`

const ContainerDiv = styled.div`
   min-height: 600px;
`

export const SmallLoader = ({size = 25, loadingMsg = `Searching...`, center = false }) => {
   return (
      <StyledSmallDiv className={`full-width ma-0-auto ${ center ? `justify-center` : `flex-start`}`}>
         <CircularProgress size={size} color="secondary" /><span className="pl-3">{loadingMsg}</span>
      </StyledSmallDiv>
   )
}

SmallLoader.propTypes = {
   size: PropTypes.number,
   loadingMsg: PropTypes.string,
   center: PropTypes.bool
}

const StyledDiv = styled.div`
   width: 120px;
`

const Loader = ( props ) => {
   return (
      <ContainerDiv className="flex flex-row justify-items-center items-center">
         <StyledDiv className="full-width">
            <CircularProgress size={100} color="secondary" />
         </StyledDiv>
         <StyledDiv className="full-width">
            <p>{props.loadingMsg ? props.loadingMsg : `Loading...`}</p>
         </StyledDiv>
      </ContainerDiv>
   )
}

Loader.propTypes = {
   loadingMsg: PropTypes.string
}

export default Loader
