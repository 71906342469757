import * as React from 'react'
import PropTypes from 'prop-types'

export default function ProductDisplay({ mostRecentOrder }) {
   return (
      <div className="bg-white md:px-12 px-4 py-4">
         <h1 className="text-lg">{`Product(s)`}</h1>
         <div>
            {
               mostRecentOrder?.items?.map( ( item ) => {
                  return (
                     <div className="flex" key={item.item_name}>
                        <div className="flex flex-col">
                           <ul className="mb-0">
                              <li>
                                 <p>{item.item_name || item.itemname}</p>
                                 {item?.order_qty &&
                                 <p>{`Packs: ${item?.order_qty}`}</p>
                                 }
                              </li>
                           </ul>
                        </div>
                     </div>
                  )
               }
               )
            }
         </div>
      </div>
   )
}

ProductDisplay.propTypes = {
   mostRecentOrder: PropTypes.object
}