import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import TermsAndConditions from 'components/TermsAndConditions'

export const TermsModal = props => {
   const { open, closeModal } = props

   return (
      <Dialog
         className="z-index-max"
         open={open}
      >
         <MuiDialogTitle>
            <p className="text-teal text-xl">{`TERMS AND CONDITIONS`}</p>
         </MuiDialogTitle>
         <MuiDialogContent dividers>
            <TermsAndConditions />
         </MuiDialogContent>
         <MuiDialogActions>
            <button onClick={closeModal} className="curated-btn-previous cursor-pointer hover:opacity-70">
               {`Close`}
            </button>
         </MuiDialogActions>
      </Dialog>
   )
}

TermsModal.propTypes = {
   open: PropTypes.bool.isRequired,
   closeModal: PropTypes.func.isRequired
}

export default TermsModal