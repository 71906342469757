import axios from 'axios'
import { getHeaders } from 'utils/url'

const _headers = getHeaders()

export const getRequiredFields = ( inputConfig ) => {

   const requiredInputs = inputConfig.filter( c => {
      return c.required
   })

   const nestedInputs = inputConfig.filter( c => {
      return c.type && c.type === `multi`
   })

   let nestedFields = []

   for ( let i = 0; i < nestedInputs.length; i++ ){
      nestedInputs[i].fields.map( x => {
         return nestedFields.push( x.name )
      })

   }

   const fields = requiredInputs.map( input => {
      return input.name
   })

   return [ ...nestedFields, ...fields ]
}

export const convertMS = ( milliseconds ) => {

   let day
   let hour
   let minute
   let seconds

   seconds = Math.floor( milliseconds / 1000 )
   minute = Math.floor( seconds / 60 )
   seconds = seconds % 60
   hour = Math.floor( minute / 60 )
   minute = minute % 60
   day = Math.floor( hour / 24 )
   hour = hour % 24

   return {
      day: day,
      hour: hour,
      minute: minute,
      seconds: seconds
   }
}

export const getDoctorsInfo = async ( zip, lastName, useNearByZips ) => {
   try {
      let doctorInfo = await axios({
         method: `get`,
         url: `https://doctor-lookup-v2.aeroflow.ninja/?last_name=${lastName}&postal_code=${zip}${ useNearByZips ? `&include_nearby_zipcodes=true` : ``}`,
         headers: _headers
      })

      return doctorInfo
   } catch ( error ) {
      console.error( error )
   }
}