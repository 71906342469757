export const maskNumbersOnly = value => {
   if ( !value ){
      return
   }
   let reg = /[^\d]+/

   return value.replace( reg, `` )
}

export const phoneNumberMask = value => {
   let reg = /[^\d]+/
   if ( value ) {
      if ( value[0] === `1` ) {
         return null
      }
      let val = value.replace( reg, `` )
      if ( val.length > 3 && val.length < 7 ) {
         if ( val[3] !== `-` ) {
            val = `${val.substring( 0, 3 )}-${val.substring( 3 )}`
         }
      }
      if ( val.length >= 7 ) {
         if ( val[3] !== `-` && val[7] !== `-` ){
            val = val.replace( reg, `` )
            val = `${val.substring( 0, 3 )}-${val.substring( 3, 6 )}-${val.substring( 6 )}`
         }

      }

      return val
   }
}