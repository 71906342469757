import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppShell from 'components/AppShell'
import theme from 'styles/theme'
import LoginBox from 'modules/auth/LoginForm'
import ClaimSupplies from 'modules/claims'
import ConfirmOrder from 'components/ConfirmOrder'
import Checklist from 'modules/checklist'
import { moduleData, getDisplayKey } from 'constants/moduleData'
import ThankYou from 'modules/thankYou'
import AddressConfirmation from 'modules/addressConfirmation'

const Urology = ( props ) => {

   const { location: { pathname }, isAuthenticated } = props

   const { introContent, introHeadline, pfPk } = moduleData[getDisplayKey( pathname )]

   return (
      <AppShell theme={theme}>
         {!isAuthenticated &&
          <LoginBox
             profitCenterPk={pfPk}
             headline={introHeadline}
             description={introContent}
          />}
         {isAuthenticated && pathname === `/urology/claim` &&
          <ClaimSupplies
             allowChanges
             allowQuantityChanges
             source={`urology`}
          />
         }
         {isAuthenticated && pathname === `/urology/claim/checklist` &&
          <Checklist source={`urology`} />
         }
         {isAuthenticated && pathname === `/urology/claim/confirm-address` &&
          <AddressConfirmation />
         }
         {isAuthenticated && pathname === `/urology/claim/confirm` &&
          <ConfirmOrder source={`urology`} />
         }

         {isAuthenticated && pathname === `/urology/claim/thank-you` &&
          <ThankYou source={`urology`} />
         }
      </AppShell>
   )
}

const mapStateToProps = ( state ) => {
   return {
      isAuthenticated: state.auth.isAuthenticated,
      user: state.auth.user
   }
}

Urology.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string
   }),
   history: PropTypes.shape({
      push: PropTypes.func.isRequired
   }),
   isAuthenticated: PropTypes.bool
}

const ConnectedUrology = connect( mapStateToProps )( Urology )
export default ConnectedUrology

