import React, { useEffect } from 'react'
import { SmallLoader } from 'components/Loader'
import useRetrieveZip from './hooks/useRetrieveZip'
import DoctorDisplay from './components/DoctorDisplay'
import useRetrieveDoctor from './hooks/useRetrieveDoctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { resetDoctorInput, setDoctor, setDoctorInput } from './actions'
import { AF_SUPPORT_PHONE_NUMBER } from './utils/constants'
import PropTypes from 'prop-types'

function DoctorBlock({ onSuccess, forceUpdate = false, headerStyle, containerStyle, onExistingDoctor, resetDoctorInput, setDoctor, doctorInput, setDoctorInput }) {
   const zipLoading = useRetrieveZip( doctorInput, setDoctorInput )
   const doctorLoading = useRetrieveDoctor( onExistingDoctor, setDoctor )

   const searchParams = new URLSearchParams( window.location.search )

   useEffect( () => {
      resetDoctorInput()
   }, [] )

   if ( !searchParams.get( `sgh` ) && !searchParams.get( `txt` ) ) return <p className="text-requiredRed max-w-lg my-5 text-center">{`You are not authorized to edit your doctor. If you need to change your doctor please reach out to us at ${AF_SUPPORT_PHONE_NUMBER}.`}</p>

   return (
      <div className={`my-5 px-8 py-4 flex flex-col items-center justify-center max-w-2xl mx-auto gap-5 ${!doctorLoading && !zipLoading && `bg-white shadow-lg`}`}>
         <div className="w-full mx-auto">
            {
               zipLoading || doctorLoading ?
                  <SmallLoader loadingMsg="Loading your doctor..." />
                  :
                  <DoctorDisplay
                     onSuccess={onSuccess}
                     forceUpdate={forceUpdate}
                     headerStyle={headerStyle}
                     containerStyle={containerStyle}
                  />
            }
         </div>
      </div>
   )
}

DoctorBlock.propTypes = {
   onSuccess: PropTypes.func,
   forceUpdate: PropTypes.bool,
   headerStyle: PropTypes.string,
   containerStyle: PropTypes.string,
   onExistingDoctor: PropTypes.func,
   resetDoctorInput: PropTypes.func,
   setDoctor: PropTypes.func,
   doctorInput: PropTypes.object,
   setDoctorInput: PropTypes.func
}

const mapStateToProps = state => {
   return {
      doctorInput: state.doctor.doctorInput
   }
}

const mapDispatchToProps = ( dispatch ) => {
   return {
      resetDoctorInput: () => {
         return dispatch( resetDoctorInput() )
      },
      setDoctor: ( doctor ) => {
         return dispatch( setDoctor( doctor ) )
      },
      setDoctorInput: ( doctorInput ) => {
         return dispatch( setDoctorInput( doctorInput ) )
      }
   }
}

const ConnectedDoctorBlock = connect( mapStateToProps, mapDispatchToProps )( DoctorBlock )

export default withRouter( ConnectedDoctorBlock )