import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import CancelIcon from '@material-ui/icons/Cancel'
import { setDisplayMessage } from 'modules/auth/actions'
import InfoIcon from '@material-ui/icons/Info'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import {
   LOCAL_MAINTENANCE_MODE_HEADERS,
   DEV_MAINTENANCE_MODE_HEADERS,
   LIVE_MAINTENANCE_MODE_HEADERS,
   BASE_URL,
   DEV_BASE_URL,
   LOCAL_BASE_URL
} from 'constants/urls'

// @TODO - ENV VARS for submit order headers

const Message = styled.p`
  background-color: ${props => {
      if ( props.type && props.type === `error` ){
         return `#faebe7`
      }
      if ( props.type && props.type === `info` ) {
         return `#A8FBCB`
      }
   }};
  color: ${props => {
      if ( props.type && props.type === `error` ) {
         return `#f00`
      }

      if ( props.type && props.type === `info` ) {
         return `#2D7A4D`
      }
   }};
  display: inline;
  font-size: 1.5rem;
  line-height: 1.15rem;
  font-weight: 400;
  margin: 0;
  padding: 16px;
  text-align: center;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
`

const StyledCancelIcon = styled( CancelIcon )`
  margin-left: 16px;
  vertical-align: middle;
`

const StyledErrorOutlineIcon = styled( ErrorOutlineIcon )`
  margin-right: 16px;
  vertical-align: middle;
`

const StyledInfoOutlineIcon = styled( InfoIcon )`
  margin-right: 16px;
  vertical-align: middle;
`

class DisplayMessage extends Component {
   componentDidMount(){
      // @NOTE to avoid unneeded calls to lambda uncomment below when we need to query maintenance status
      this.getMaintenanceStatus()
   }
   componentDidUpdate( prevProps ) {
      const { location: { pathname } } = this.props
      if ( prevProps.location.pathname !== pathname ) {
         return this.props.setDisplayMessage({
            type: ``,
            msg: ``
         })
      }
   }
   getMaintenanceStatus = async () => {
      let MAINTENANCE_MODE_HEADERS

      if ( window.location.origin === BASE_URL ) {
         MAINTENANCE_MODE_HEADERS = LIVE_MAINTENANCE_MODE_HEADERS
      }

      if ( window.location.origin === DEV_BASE_URL ) {
         MAINTENANCE_MODE_HEADERS = DEV_MAINTENANCE_MODE_HEADERS
      }

      if ( window.location.origin === LOCAL_BASE_URL ) {
         MAINTENANCE_MODE_HEADERS = LOCAL_MAINTENANCE_MODE_HEADERS
      }

      try {
         let maintenanceStatus = await axios({
            method: `get`,
            url: process.env.REACT_APP_MAINTENANCE_URL,
            headers: MAINTENANCE_MODE_HEADERS
         })

         if ( maintenanceStatus && maintenanceStatus.data ){
            if ( maintenanceStatus.data.data.status ){
               this.props.setDisplayMessage({
                  type: `error`,
                  msg:  `We're sorry but this website is currently undergoing maintenance. Please try again later. If you have an urgent need please contact customer service.`
               })
            }
         }
      } catch ( err ){
         // console.log( err )
      }
   }
   render() {
      const { displayMessage: {msg, type} } = this.props
      if ( !msg ) { return null }

      return (
         <Message type={type}>
            {type === `info` && <StyledInfoOutlineIcon />}
            {type === `error` && <StyledErrorOutlineIcon />}
            {msg}
            <StyledCancelIcon
               onClick={() => {
                  return this.props.setDisplayMessage({
                     type: ``,
                     msg: ``
                  })
               }}
            />
         </Message>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      setDisplayMessage: ( obj ) => {
         return dispatch( setDisplayMessage( obj ) )
      }
   }
}

const mapStateToProps = state => {
   return {
      displayMessage: state.auth.displayMessage
   }
}

DisplayMessage.propTypes = {
   message: PropTypes.string,
   setDisplayMessage: PropTypes.func,
   location: PropTypes.shape({
      pathname: PropTypes.string
   }),
   displayMessage: PropTypes.shape({
      msg: PropTypes.string,
      type: PropTypes.string
   })
}

const ConnectedDisplayMessage = connect( mapStateToProps, mapDispatchToProps )( DisplayMessage )

export default withRouter( ConnectedDisplayMessage )