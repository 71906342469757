import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import styled from 'styled-components'
import { PhoneLink } from 'components/AppShell/FooterLinks'
import { getDisplayKey, moduleData } from 'constants/moduleData'


const StyledDialogContent = styled( DialogContent )`
  text-align: center
`

const StyledReportProblemOutlinedIcon = styled( ReportProblemOutlinedIcon )`
  color:  #FF9900;
`

const LoginFailedDialog = ( props ) => {
   const { open, onClose, msg } = props

   let _msg = ``

   const profitCenter = getDisplayKey( window.location.pathname )

   const email = moduleData[profitCenter].pfEmail

   if ( msg && ( typeof msg === `string` ) && msg.includes( `Crosscheck failure` ) ){
      _msg = `Unauthorized Your login credentials do not match this session.`
   } else if ( msg && ( typeof msg === `string` ) && msg.includes( `No patient id found` ) ) {
      _msg = `We are having some difficulty locating your account. Please try to log in again, or if you are still having issues, contact us at `
   } else {
      _msg = msg
   }

   return (
      <Dialog
         open={open}
      >
         <StyledDialogContent>
            <DialogTitle>
               <StyledReportProblemOutlinedIcon
                  fontSize={`large`}
               />
            </DialogTitle>
            <DialogContentText>
               <span>{`${_msg} `}</span>
               <PhoneLink number={moduleData[profitCenter].pfPhone} color="#000" />
               <span>{` or email us at ${email}`}</span>
            </DialogContentText>
         </StyledDialogContent>
         <DialogActions>
            <Button
               onClick={onClose}
               size="medium"
               color="primary"
               variant="contained"
               type="submit"
               fullWidth
            >
               {`OK`}
            </Button>
         </DialogActions>
      </Dialog>
   )
}

LoginFailedDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   msg: PropTypes.string
}

export default LoginFailedDialog