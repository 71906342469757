import React from 'react'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import PropTypes from 'prop-types'
import LaunchIcon from '@material-ui/icons/Launch'
import moment from 'moment'

function OrderItemDesktop({ item }) {

   const { item_name, status, ship_date, url } = item

   const shipped = status === `Shipped`

   return (
      <React.Fragment>
         <div className="cell justify-start">
            <div className="flex items-center">
               {
                  status === `Cancelled` ?
                     <CancelScheduleSendIcon htmlColor="red" />
                     :
                     <AssignmentTurnedInIcon htmlColor="green" />
               }
               <p className="bold text-sm md:text-base ml-3 text-black" >
                  {item_name}
               </p>
            </div>
         </div>
         <div className="cell item">
            <p className={`${shipped ? `green` : `error`} text-base`}>
               {status}
            </p>
         </div>
         <div className="cell item">
            {
               ship_date ?
                  <p className="text-base text-black">{moment( ship_date ).format( `L` )}</p>
                  :
                  <span>{`\u00A0`}</span>

            }
         </div>
         <div className="cell item">
            {
               url && url !== `Unable to provide tracking link.` ?
                  <a href={url} target="blank" className="flex items-center text-base">
                     {`Track `}
                     <LaunchIcon fontSize="small" />
                  </a>
                  :
                  <span>{`\u00A0`}</span>
            }
         </div>
      </React.Fragment>
   )
}

OrderItemDesktop.propTypes = {
   item: PropTypes.object
}

export default OrderItemDesktop
