
// Regex error must be added for input to properly validate. Re-use first! Don't change any of the key/value pairs above unless instructed by form SOP coordinator.
// @NOTE whatever you do don't change these keys!!
export const regexErrors = {
   memberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
   email: `Enter a valid email`,
   dd: `Please enter a day between 01 - 31`,
   card_number: `Enter a valid card number`,
   expmm: `Please enter a month between 01 - 12`,
   expyyyy: `Enter valid expiration year`,
   ccv: `Enter a valid ccv`,
   first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   last_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
   phone: `Enter a valid phone number`,
   dob_yyyy: `Please enter a valid 4 digit year between 120 years ago and the current year`,
   zip: `Zip code must contain 5 numbers`,
   address: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) numbers (0-9) or spaces. Must be more than 1 letter`,
   city: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) or spaces. Must be more than 1 letter`,
   memberId: `Must be longer than 3 characters. No special characters allowed except for dashes (-)`,
   dueDateYYYY: `Please enter a valid 4 digit year within 1 year of the current year`,
   weight: `Please enter a weight between 1 and 999 lbs.`,
   date_of_birth: `Please enter a valid date by month (01 - 12), day (01 - 31), and year within the past 120 years`,
   due_date: `Please enter a valid date by month (01 - 12), day (01 - 31). Must be prior to 9 months from current date and no later than 1 year in the past.`
}

// if a regex is needed these are official! If you need to add 1 go for it. Fields can validate without a regex pattern, i.e. date fields
export const regexPatterns = {
   zip: [{
      type: `generic`,
      regex: /(^\d{5}$)$/
   }],
   phone: [{
      type: `generic`,
      regex: /^\(([2-9]\d{2})\) \d{3}-\d{4}$/
   }],
   email: [{
      type: `generic`,
      regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i
   }],
   card_number: [
      {
         type: `visa`,
         regex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
      },
      {
         type: `mastercard`,
         regex: /^(?:5[1-5][0-9]{14})$/
      },
      {
         type: `amex`,
         regex: /^(?:3[47][0-9]{13})$/
      },
      {
         type: `discover`,
         regex: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
      }
   ],
   ccv: [
      {
         type: `generic`,
         regex: /^[0-9]{3,4}$/
      }
   ],
   first_name: [{
      type: `generic`,
      regex: /^[a-zA-Z- ]+$/
   }],
   address: [{
      type: `generic`,
      regex: /^[0-9a-zA-Z- .]+$/
   }],
   city: [{
      type: `generic`,
      regex: /^[a-zA-Z- .]+$/
   }],
   memberID: [{
      type: `generic`,
      regex: /^[0-9a-zA-Z-]+$/
   }],
   last_name: [{
      type: `generic`,
      regex: /^[a-zA-Z- ]+$/
   }]
}

// a key on the left represents a dom input element where input name="[name]"
// the value on the right indicates what key the validator will use to pipe the input to the appropriate validator
export const validationNames = {
   "date_of_birth": `date_of_birth`,
   "due_date": `due_date`,
   "address": `address`,
   "zip": `zip`,
   "email": `email`,
   "email-login-input": `email`,
   "altPhone": `phone`,
   "phone": `phone`,
   "phone-login-input": `phone`,
   "card_number": `card_number`,
   "expmm": `expmm`,
   "dd": `dd`,
   "dob-dd-input": `dd`,
   "dd-dd-input": `dd`,
   "dob-mm-input": `expmm`,
   "dd-mm-input": `expmm`,
   "dob-yyyy-input": `dob_yyyy`,
   "dd-yyyy-input": `dueDateYYYY`,
   "expyyyy": `expyyyy`,
   "ccv": `ccv`,
   "lastName": `last_name`,
   "firstName": `first_name`,
   "first_name": `first_name`,
   "last_name": `last_name`,
   "city": `city`,
   "weight": `weight`,
   "memberID": `memberID`
}

export const validOneDigitMonths = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
export const validOneDigitDays = [ `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9` ]
export const validTwoDigitMonths = [ `01`, `02`, `03`, `04`, `05`, `06`, `07`, `08`, `09`, `10`, `11`, `12` ]