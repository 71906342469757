export function isValidUrl( url ) {
   try {
      new URL( url )
   } catch ( error ) {
      return false
   }

   return true
}

export const getPatientDeficiencies = async ( hash ) => {
   const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/order-status-standalone`, {
      headers: {
         'Authorization': `PatientHash ${hash}`
      }
   })

   return await response.json()

}