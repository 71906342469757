import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import { Text } from 'informed'
import { validateEmail } from 'components/Forms/validators'
import styled from 'styled-components'

const StyledText = styled( Text )`
  text-align: center;
`


const InputEmail = ( props ) => {
   const { errors } = props

   return (
      <React.Fragment>
         <InputLabel>
            <p className="af-dark-blue font-weight-700">
               <span className="font-weight-700" >
                  {`Email Address:`}
               </span>
            </p>
            <StyledText
               placeholder="example@example.com"
               field="email"
               type="email"
               validate={validateEmail}
               validateOnBlur
               size="20"
            />
         </InputLabel>
         <div className="errors">
            {errors &&
					<p className="error">{errors}</p>
            }
         </div>
      </React.Fragment>
   )
}

InputEmail.propTypes = {
   errors: PropTypes.string
}

export default InputEmail
