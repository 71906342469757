export const doAddressesMatch = ( addressObject ) => {

   const {
      original_address1,
      original_city,
      original_state,
      original_zip,
      verified_address1,
      verified_city,
      verified_state,
      verified_zip
   } = addressObject

   return original_address1 === verified_address1 &&
      original_city === verified_city &&
      original_state === verified_state &&
      original_zip === verified_zip
}