import { GET_SESSION_STREAM_EVENT_ENDPOINT_URL, SESSION_STREAM_EVENT_ENDPOINT_URL, BASE_URL } from 'constants/urls'
import axios from 'axios'
import qs from 'querystring'
/* eslint-disable no-console */
export const getSessionStreamData = async ( issueType, token ) => {

   const payload = {
      aftk: token,
      issue_type: issueType
   }

   const queryString = qs.stringify( payload )

   try {

      let apiResponse = await axios({
         method: `get`,
         url: `${GET_SESSION_STREAM_EVENT_ENDPOINT_URL}?${queryString}`
      })

      if ( apiResponse ) {

         return apiResponse
      }

   } catch ( err ) {
      console.log( err )

      return err
   }
}

export const sendSessionStreamLoginFailEvent = async ( data ) => {

   const { baseUrl, dob, type, email, date, phone } = data

   if ( baseUrl && dob && type && date && ( email || phone ) && ( window.location.origin === BASE_URL ) ){
      const payload = Object.assign({}, data, {
         aftk: `Token bpfpAbodtrtGVxpfQckRxZzH6L7zi4DY`
      })

      const queryString = qs.stringify( payload )
      try {

         let apiResponse = await axios({
            method: `post`,
            url: `${SESSION_STREAM_EVENT_ENDPOINT_URL}?${queryString}`
         })

         if ( apiResponse ){
            console.log( `session event` )
         }

      } catch ( err ) {
         console.log( err )
      }
   } else {
      console.log( `SessionStream only recorded on production site. If you are getting this message on production your queryparams are wrong.` )
   }
}

export const sendSessionStreamNoItemsEvent = async ( data ) => {

   // only fire on production
   const { baseUrl, customerId, type, date } = data

   if ( baseUrl && customerId && type && date && ( window.location.origin === BASE_URL ) ){

      const payload = Object.assign({}, data, {
         aftk: `Token bpfpAbodtrtGVxpfQckRxZzH6L7zi4DY`
      })

      const queryString = qs.stringify( payload )

      try {

         let apiResponse = await axios({
            method: `post`,
            url: `${SESSION_STREAM_EVENT_ENDPOINT_URL}?${queryString}`
         })

         if ( apiResponse ) {
            console.log( `session event` )
         }
      } catch ( err ) {
         console.log( err )
      }
   } else {
      console.log( `SessionStream only recorded on production site. If you are getting this message on production your queryparams are wrong.` )
   }
}
