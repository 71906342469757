import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'
import FormDescription from './FormDescription'
import { connect } from 'react-redux'
import LoginForm from './LoginForm'

const LoginBox = ( props ) => {
   const {
      headline,
      description,
      profitCenterPk,
      loading,
      uroCurated,
      patientHash,
      hideRememberMe,
      dobLabel
   } = props

   if ( loading ){

      return null
   }

   return (
      <Wrapper>
         <FormDescription headline={headline} description={description} />
         <LoginForm
            hideRememberMe={hideRememberMe}
            profitCenterPk={profitCenterPk}
            uroCurated={uroCurated}
            patientHash={patientHash}
            dobLabel={dobLabel}
         />
         <p className="text-black text-xs mt-2">{`*Providing your information is not a guarantee of benefit or cost information. Your Continence Care Specialist will discuss any new insurance info with you if necessary.`}</p>
      </Wrapper>
   )
}

LoginBox.propTypes = {
   profitCenterPk: PropTypes.number.isRequired,
   headline: PropTypes.string,
   description: PropTypes.string,
   loading: PropTypes.bool,
   uroCurated: PropTypes.bool,
   patientHash: PropTypes.string,
   hideRememberMe: PropTypes.bool,
   dobLabel: PropTypes.string
}

const mapStateToProps = ( state ) => {
   return {
      loading: state.auth.sessionLoading
   }
}

export default connect( mapStateToProps )( LoginBox )