import styled from 'styled-components'

export const RecentOrderDesktopWrapper = styled.div`
   @media (min-width: 600px) {
      display: grid;
      max-width: 768px;
      margin: 0 auto;
      align-items: center;
      grid-template-columns: 14fr 3fr 5fr 4fr;
      background-color: rgba(0,0,0, 0.1);
      grid-gap: 1px;
      .item{
         align-items: center;
         justify-content: center;
      }
      .cell{
         background-color: white;
         align-self: stretch;
         padding: 15px;
         margin-bottom: 0;
         display: flex;
         border: none;
         span{
            margin: auto;
         }
      }
   }
`