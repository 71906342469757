
export async function updateDoctor( body, authorization ) {
   const awsResponse = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/doctor`, {
      method: `POST`,
      body: JSON.stringify( body ),
      headers: {
         authorization: authorization
      }
   })

   return await awsResponse.json()
}

export const searchDoctorFetch = async ( doctorInput, controller ) => {
   const searchParams = new URLSearchParams({
      location: doctorInput.zipCode,
      fullname: doctorInput.fullName,
      distance: doctorInput.range.toString(),
      profit_center_pk: `3`
   })
   const response = await fetch( `${process.env.REACT_APP_DOCTOR_LOOKUP_V4_URL}?${searchParams.toString()}`, {
      headers: {
         "Content-Type": `application/json`
      },
      signal: controller.signal
   })

   return await response.json()
}

export const getInternalZip = async ( patientHash ) => {
   const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/deprecated-login`, {
      method: `POST`,
      headers: {
         'Content-Type': `application/json`
      },
      body: JSON.stringify({
         patient_hash: patientHash
      })
   })

   return await response.json()
}

export const getProductPricing = async ( authorization ) => {

   const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/product-pricing`, {
      method: `GET`,
      headers: {
         authorization: authorization
      }
   })

   if ( response?.ok ) {
      return await response?.json()
   }
   throw new Error( response?.statusText ?? `Wrongly formatted response returned` )
}

export const getPatientDoctor = async ( authorization ) => {
   const response = await getProductPricing( authorization )
   const { doctor } = response.data.patient

   return doctor
}

export const Validators = {
   name: value => {
      return Boolean( value.match( /^[a-zA-Z-' ]+$/ ) )
   },
   street: value => {
      return Boolean( value.match( /^[0-9a-zA-Z- .]+$/ ) )
   },
   city: value => {
      return Boolean( value.match( /^[a-zA-Z- .]+$/ ) )
   },
   state: value => {
      const stateRE = /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/

      return Boolean( value.match( stateRE ) )
   },
   zipCode: value => {
      return Boolean( value.match( /(^\d{5}$)$/ ) )
   },
   phone: value => {
      return Boolean( value?.match( /^\(([2-9]\d{2})\) \d{3}-\d{4}$/ ) )
   }
}

export const Formatters = {
   phone: value => {
      if ( !value ) return value
      const _val = value.replace( /[- )(]/g, `` ).replace( /[^0-9]/g, `` )

      if ( _val.length === 1 && _val === `1` ){
         return ``
      }

      const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
      const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
      const c = _val.substring( 6 ) ? `-${_val.substring( 6, 10 )}` : ``

      // pattern `(888) 555-1212`
      return `${a}${b}${c}`
   },
   zipCode: value => {
      return value.replace( /[^\d+]/g, `` ).slice( 0, 5 )
   }

}
