import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LogoContainer from './LogoContainer'
import { useMediaQuery } from 'react-responsive'
import { PhoneLink } from 'components/AppShell/FooterLinks'
import { getDisplayKey, moduleData } from 'constants/moduleData'

const StyledContainer = styled.div`
	margin-top: 15px;
	@media (max-width: 600px) {
		margin-top: 3px;
	}
`

export const FormDescription = ( props ) => {

   const isTabletDesktop = useMediaQuery({
      query: `(min-device-width: 600px)`
   })

   const profitCenter = getDisplayKey( window.location.pathname )

   const { headline, description } = props

   return (
      <StyledContainer>
         <div className="flex flex-row items-center">
            {isTabletDesktop && <LogoContainer />}
            {headline && <h2 className="page-heading text-center m-4">{headline}</h2>}
         </div>
         <div className="" >
            {description && <p className="text-black mb-2">{description}</p>}
            {`If you have any questions, please call us at :`}
            { <PhoneLink number={moduleData[profitCenter].pfPhone} color="#000" />}<br></br>
         </div>
      </StyledContainer>
   )
}

FormDescription.propTypes = {
   headline: PropTypes.string,
   description: PropTypes.string
}

export default FormDescription