
export const obfuscateStr = ( s ) => {
   const str = s.replace( /-/gi, `` )

   return str.split( `` ).reverse()
      .join( `` )
}

export const obfuscateEmail = ( email ) => {
   const [ prefix ] = email.split( `@` )

   return ( prefix[prefix.length - 1] + prefix[0] )
}

export const getUserSessionPwd = ( user ) => {
   const { patient_dob, email, phone } = user

   const dobStr = obfuscateStr( patient_dob )
   const phoneStr = phone ? obfuscateStr( phone ) : ``
   const emailStr = email ? obfuscateEmail( email ) : ``

   return ( `reA` + dobStr + phoneStr + emailStr )

}

export const formatPhone = ( phone ) => {

   const _phone = phone.slice( 2, phone.length )
   const areaCode = _phone.slice( 0, 3 )
   const localCode = _phone.slice( 3, 6 )
   const subCode = _phone.slice( 6, 10 )

   return `${areaCode}-${localCode}-${subCode}`
}

export const mapAttributesToLogin = ( birthdate, phone, email ) => {

   const aeroflowInternalUserPhone = phone ? formatPhone( phone ) : ``
   const aeroflowInternalEmail = email ? email : ``

   const payload = {
      dob: birthdate
   }

   if ( aeroflowInternalEmail ){
      payload.email = aeroflowInternalEmail
   }

   if ( aeroflowInternalUserPhone ){
      payload.phone = aeroflowInternalUserPhone
   }

   return payload

}