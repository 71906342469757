import * as types from './actionTypes'
import { formatSupplyItems, hasEligibleSupplies } from 'modules/claims/utils'
import axios from 'axios'
import { setDisplayMessage } from 'modules/auth/actions'
import { sendSessionStreamNoItemsEvent } from 'utils/session'
import moment from 'moment'
import {
   trackNoItemsFoundAnalytic,
   trackNoEligibleItemsAnalytic
} from 'modules/analytics/utils'
import { nanoid } from 'nanoid'
import {reportToSentry} from "../../utils/reportToSentry"

export const setSupplies = ( supplies ) => {
   return {
      type: types.SET_SUPPLIES,
      supplies: supplies
   }
}

export const setEstimatedShipDate = ( estimatedShipDate ) => {
   return {
      type: types.SET_ESTIMATED_SHIP_DATE,
      estimatedShipDate: estimatedShipDate
   }
}

export const updateSupplies = ( supply ) => {
   return {
      type: types.UPDATE_SUPPLIES,
      supply
   }
}

export const setOrder = ( order ) => {
   return {
      type: types.SET_ORDER,
      order: order
   }
}

export const updateOrderItems = ( items ) => {
   return {
      type: types.UPDATE_ORDER_ITEMS,
      items
   }
}

export const setSerializedOrder = ( order ) => {
   return {
      type: types.SET_SERIALIZED_ORDER,
      serializedOrder: order
   }
}

export const updateQuantitySupplyItem = ( itemKey, quantitySelected ) => {
   return {
      type: types.UPDATE_QUANTITY_SUPPLY_ITEM,
      itemKey: itemKey,
      quantitySelected: quantitySelected
   }
}

export const updateQuantityOrderItem = ( itemKey, quantitySelected ) => {
   return {
      type: types.UPDATE_QUANTITY_ORDER_ITEM,
      itemKey: itemKey,
      quantitySelected: quantitySelected
   }
}

export const toggleItemSelectedForOrderStatus = ( itemKey ) => {
   return {
      type: types.TOGGLE_ITEM_SELECTED_FOR_ORDER_STATUS,
      itemKey: itemKey
   }
}

export const setSuppliesCount = ( count ) => {
   return {
      type: types.SET_SUPPLIES_COUNT,
      suppliesCount: count
   }
}

export const incrementSuppliesCount = () => {
   return {
      type: types.INCREMENT_SUPPLIES_COUNT
   }
}

export const setShowMedicareChecklist = ( bool ) => {
   return {
      type: types.SET_SHOW_MEDICARE_CHECKLIST,
      show: bool
   }
}

export const updateMedicareChecklistOrderItem = ( val, reason, itemKey ) => {
   let reasonKey
   if ( reason === `quantity` ) {
      reasonKey = `quantity_question_response`
   }
   if ( reason === `quality` ) {
      reasonKey = `quality_question_response`
   }

   return {
      type: types.UPDATE_MEDICARE_CHECKLIST_ORDER_ITEM,
      itemKey,
      val,
      reasonKey
   }
}

export const setOrderSuccess = ( bool ) => {

   return {
      type: types.SET_ORDER_SUCCESS,
      orderSuccess: bool
   }
}

export const resetClaims = () => {
   return {
      type: types.RESET_CLAIMS
   }
}

export const setOrderSummaryFromSubmitOrderResponse = ( orderReturnStringified ) => {
   return {
      type: types.SET_ORDER_SUMMARY_FROM_SUBMIT_ORDER_RESPONSE,
      orderReturnStringified
   }
}

export const setThreeMonthPromoEligible = ( bool ) => {
   return {
      type: types.SET_THREE_MONTH_PROMO_ELIGIBLE,
      bool
   }
}

export const setThreeMonthPromoAccepted = ( bool ) => {
   return {
      type: types.SET_THREE_MONTH_PROMO_ACCEPTED,
      bool
   }
}

export const setSuppliesRequestStatus = ( status ) => {
   return {
      type: types.SET_SUPPLIES_RESQUEST_STATUS,
      suppliesRequestStatus: status
   }
}

export const updateSizeSupplyItem = ( itemKey, hcpcid ) => {

   return {
      type: types.UPDATE_SIZE_SUPPLY_ITEM,
      itemKey,
      hcpcid
   }
}

// *************** ASYNC actions (thunks) ***************
export const getSupplies = ( info ) => {
   return async ( dispatch ) => {
      const url = `${process.env.REACT_APP_RESUPPLY_BASE_ENDPOINT}${info.patient_id}&dob=${info.patient_dob}&profitCenterId=${info.pfPk}`
      dispatch( setSuppliesRequestStatus( `processing` ) )

      try {
         const data = await axios.get( url, {
            headers: {
               Authorization: `PatientHash ${info.unique_hash}`
            }
         })

         // there are certain situations where we expect the data to be null, but they still have an order history
         if ( !data?.data?.data ) return dispatch( setSuppliesRequestStatus( `complete` ) )

         const { data: { totalItems, items } } = data.data

         const hasThreeMonthSupply = items.map( item => { return item.three_month_supply }).includes( true )

         const showMedicareChecklist = items.map( item => { return item.checklist_required }).includes( true )

         dispatch( setShowMedicareChecklist( showMedicareChecklist ) )
         dispatch( setSuppliesCount( totalItems ) )
         const reduxStoreItems = formatSupplyItems( items )

         // handle custom analytic
         if ( !hasEligibleSupplies( reduxStoreItems ) ){
            dispatch( setSuppliesRequestStatus( `complete` ) )
            if ( !totalItems ){

               trackNoItemsFoundAnalytic()
            } else {

               trackNoEligibleItemsAnalytic()
            }

            const _date = moment().format( `DD-MM-YYYY hh:mm:ss` )

            // send session stream evt data w customer id

            const streamData = {
               date: _date,
               type: !totalItems ? `no-items-found` : `no-eligible-items`,
               baseUrl: window.location.href,
               email: ``,
               phone: ``,
               dob: ``,
               customerId: info.patient_id
            }

            sendSessionStreamNoItemsEvent( streamData )
         }

         const itemsByKey = {}

         reduxStoreItems.forEach( item => {
            const itemKey = nanoid()

            item.itemKey = itemKey

            itemsByKey[itemKey] = item
         })

         dispatch( setSupplies( itemsByKey ) )

         dispatch( setThreeMonthPromoEligible( hasThreeMonthSupply ) )

         return dispatch( setSuppliesRequestStatus( `complete` ) )

      } catch ( err ) {
         // there are certain situations where the error object is empty, but they still have an order history but the data cominhg back from the options call is null
         setSuppliesRequestStatus( `complete with error` )

         if ( Object.keys( err ).length > 0 ) {
            reportToSentry( new Error( `There was an error in the resupply options call`, {
               message: err
            }) )
            dispatch( setDisplayMessage({
               type: `error`,
               msg: JSON.stringify( err )
            }) )
         }

         return dispatch( setSuppliesRequestStatus( `complete` ) )
      }

   }
}

export const submitOrder = ( serializedOrder ) => {

   return async ( dispatch, getState ) => {

      // @NOTE live orders will be placed on the dev site and production, this line allows for test orders in local dev enviro
      if ( process.env.NODE_ENV !== `production` ) {
         serializedOrder.test_order = true
      }

      const { auth: { user } } = getState()

      try {
         let data = await axios.post( process.env.REACT_APP_SUBMIT_ORDER_ENDPOINT, serializedOrder, {
            headers:{
               'Content-Type': `application/json`,
               'Authorization': `PatientHash ${user.unique_hash}`
            }
         })

         if ( data && data.status && data.status >= 200 && data.status < 300 ){
            dispatch( setOrderSummaryFromSubmitOrderResponse( data.data ) )

            return dispatch( setOrderSuccess( true ) )

         } else {

            const statusCode = data && data.status ? data.status : `UNKNOWN`
            throw `There was an error processing your request - status code: ${statusCode}`

         }

      } catch ( error ) {

         const errorID = nanoid()

         const timestamp = new Date()

         const internalErrorCollectPayload = {
            time: timestamp,
            order: serializedOrder,
            user: user
         }

         // 409 codes explicitly indicate the order has already been created
         const duplicateOrderError = error?.response?.status === 409

         if ( duplicateOrderError ){
            return dispatch( setDisplayMessage({
               type: `error`,
               msg:  `Transaction cannot be completed: Duplicate order. You have already placed this order and it is being processed`
            }) )
         }

         const msgArray = []

         msgArray.push( `There was a problem processing your transaction` )

         if ( error && error.response && error.response.status ){
            msgArray.push( `Status code: ${error.response.status}` )
         } else {
            msgArray.push( `Status code: undefined` )
         }

         if ( error && error.response && error.response.headers[`content-type`] ){
            msgArray.push( `Response headers content-type: ${error.response.headers[`content-type`]}` )
         }


         msgArray.push( `Please contact a customer support specialist. Reference number ${errorID}` )

         internalErrorCollectPayload.msgDisplayedToUser = msgArray.join( `. ` )

         return dispatch( setDisplayMessage({
            type: `error`,
            msg: msgArray.join( `. ` )
         }) )
      }
   }
}
