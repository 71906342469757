import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { OPT_OUT_OPTIONS, OPT_OUT_REASONS } from 'modules/optOut/constants'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { getHashFromSearch, hasQueryParamByKey } from 'utils/url'
import { reportToSentry } from 'utils/reportToSentry'


const StyledSelect = styled.select`
  width: 320px;
`

const InputGroup = styled.div.attrs({
   className: `mb-6`
})`
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 16px;
`

export const OptOutForm = ( props ) => {

   const [ selectedDelay, setSelectedDelay ] = useState( 7 )
   const [ selectedReason, setSelectedReason ] = useState( `` )
   const [ loading, setLoading ] = useState( false )
   const [ successful, setSuccessful ] = useState( false )
   const [ showError, setShowError ] = useState( false )

   const { history, location: { pathname, search } } = props

   const urlHasHashKey = hasQueryParamByKey( search, [ `sgh`, `txt` ] )
   const hash = urlHasHashKey ? getHashFromSearch( search ) : null

   useEffect( () => {
      if ( !hash && pathname === `/opt-out` ) {
         history.replace( `/` )
      }
   }, [] )

   const handleSubmit = ( e ) => {
      e.preventDefault()
      setLoading( true )

      if ( selectedReason === `` ) {
         setLoading( false )

         return setShowError( true )
      }

      const requestInit = {
         method: `POST`,
         headers: {
            'Content-Type': `application/json`,
            'Authorization': `PatientHash ${hash}`
         },
         body: JSON.stringify({
            "daysToExtend": parseInt( selectedDelay ),
            "reasonToExtend": selectedReason
         })
      }
      fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/delay-contact/`, requestInit )
         .then( ( response ) => {
            setSuccessful( response.ok )
         })
         .catch( error => {
            console.error( error )
            reportToSentry( `Failed to delay contact - OptOutForm.js`, {
               error: JSON.stringify( error )
            })
         })
         .finally( () => { return setLoading( false ) })
   }

   const handleDelayChange = ( e ) => {
      setSelectedDelay( e.target.value )

   }

   const handleReasonChange = ( e ) => {
      if ( showError ) setShowError( false )
      setSelectedReason( e.target.value )
   }


   return (
      <>
         { successful ?
            <>
               <h1>{`Thank You!`}</h1>
               <p>{`We will contact you in ${selectedDelay} days to confirm your next shipment of supplies.`}</p>
            </>
            :
            <form onSubmit={handleSubmit}>

               <InputGroup>

                  <label htmlFor="delay">{`Remind me:`}</label>

                  <StyledSelect className="select-css cursor-pointer" onChange={handleDelayChange}>
                     {OPT_OUT_OPTIONS.map( ({ value, label }) => {
                        return (
                           <option
                              selected={selectedDelay === value}
                              name="delay"

                              key={value}
                              value={value}
                           >
                              {label}
                           </option>
                        )
                     })}
                  </StyledSelect>

               </InputGroup>

               <InputGroup>

                  <label htmlFor="reason">{`Reason:`}</label>

                  <select className="select-css cursor-pointer" onChange={handleReasonChange}>

                     <option
                        selected={selectedReason === ``}
                        value={``}
                     >
                        {`Select`}
                     </option>

                     {OPT_OUT_REASONS.map( ({ value, label }) => {
                        return (
                           <option
                              selected={selectedReason === value}
                              name="reason"

                              key={value}
                              value={value}
                           >
                              {label}
                           </option>
                        )
                     })}
                  </select>

                  {showError && <p className=" text-requiredRed">{`Please select a reason.`}</p>}

               </InputGroup>
               <div className="text-center">
                  <button type="submit" className="bg-teal py-3 w-40 cursor-pointer" disabled={loading}>{loading ? `Loading...` : `Save`}</button>
               </div>

            </form>
         }

      </>

   )
}

OptOutForm.propTypes = {
   checkboxFields: PropTypes.array.isRequired,
   history: PropTypes.shape({
      replace: PropTypes.func.isRequired
   }),
   location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
   })
}

export default withRouter( OptOutForm )
