import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import { setAddressVerified } from './actions'
import { doAddressesMatch } from './utils'

export const AddressValidationBox = props => {
   const {
      addressVerificationLoading,
      addressVerified,
      addressVerificationData,
      toggleAddressVerified
   } = props

   if ( addressVerificationLoading ){
      return (
         <div className="flex justify-center">
            <Loader loadingMsg={`Verifying address…`} />
         </div>
      )
   }

   if ( !addressVerificationData || Object.keys( addressVerificationData ).length === 0 ){
      return null
   }

   const addressMatch = doAddressesMatch( addressVerificationData )

   const handleChange = ( e ) => {

      return toggleAddressVerified( e.target.value )
   }


   if ( addressVerificationData && Object.keys( addressVerificationData ).length > 0 ){
      return (
         <>
            <div className="pl-4 mb-10 flex">
               <input
                  onChange={handleChange}
                  type="radio"
                  id="originalAddress"
                  name="originalAddress"
                  value="unverified"
                  checked={addressVerified === `unverified` || addressMatch}
                  className="w-3 mr-4"
               />
               <label className="flex-7" htmlFor="originalAddress">
                  <span className="font-bold">{`Address entered:`}</span>
                  <p>{addressVerificationData.original_address1}</p>
                  <p>{`${addressVerificationData.original_city}, ${addressVerificationData.original_state}  ${addressVerificationData.original_zip}`}</p>
               </label>
            </div>
            {addressVerificationData.verified_address1 && !addressMatch &&
         <div className="pl-4 mb-10 flex">
            <input
               type="radio"
               id="suggestedAddress"
               name="suggestedAddress"
               onChange={handleChange}
               value="verified" checked={addressVerified === `verified`}
               className="w-3 mr-4"
            />
            <label className="flex-7" htmlFor="suggestedAddress">
               <span className="font-bold mb-3">{`Suggested address: `}</span>
               <p>{addressVerificationData.verified_address1}</p>
               <p>{`${addressVerificationData.verified_city}, ${addressVerificationData.verified_state}  ${addressVerificationData.verified_zip}`}</p>
            </label>
         </div>}
         </>
      )
   }

   return null
}

const mapStateToProps = state => {
   return {
      addressVerificationLoading: state.addressValidator.addressVerificationLoading,
      addressVerified: state.addressValidator.addressVerified,
      addressVerificationData: state.addressValidator.addressVerificationData
   }
}

const mapDispatchToProps = dispatch => {
   return {
      toggleAddressVerified: ( status ) => {
         return dispatch( setAddressVerified( status ) )
      }
   }
}

AddressValidationBox.propTypes = {
   addressVerificationLoading: PropTypes.bool,
   addressVerified: PropTypes.oneOf( [ `verified`, `unverified` ] ),
   toggleAddressVerified: PropTypes.func.isRequired,
   addressVerificationData: PropTypes.shape({
      verified_address1: PropTypes.string,
      verified_state: PropTypes.string,
      verified_city: PropTypes.string,
      verified_zip: PropTypes.string,
      original_address1: PropTypes.string,
      original_state: PropTypes.string,
      original_city: PropTypes.string,
      original_zip: PropTypes.string
   })
}

export default connect( mapStateToProps, mapDispatchToProps )( AddressValidationBox )