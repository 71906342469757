import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
   typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
         `Open Sans`
      ].join( `,` ),
      button: {
         fontWeight: `800`
      }
   },
   palette: {
      primary: {
         main: `#143F69`
      },
      secondary: {
         main: `#286880`
      },
      background: {
         default: `#EBE9E9`
      },
      info: {
         main: `#C4C7CC`
      }
      // error: will use the default color
   },
   overrides: {
      MuiFormGroup: {
         root: {
            margin: `1rem 0`
         },
         row: {
            '@media (max-width:600px)': {
               flexDirection: `column`
            }
         }
      },
      MuiFormControlLabel: {
         label: {
            fontSize: `0.875rem`
         }
      },
      MuiButton:{
         label: {
            padding: `.125rem 1rem`
         }
      },
      MuiTableRow: {
         root: {
            '@media (max-width:600px)': {
               display: `flex`,
               flexDirection: `column`
            }
         }
      },
      MuiTableCell: {
         root: {
            '@media (max-width:600px)': {
               fontSize: `.75rem`,
               textAlign: `center`
            }
         }
      }
   }
})

export default theme