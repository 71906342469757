import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { incrementSuppliesCount } from 'modules/claims/actions'

/* eslint-disable no-unused-vars */

// after update supplies pushes 1 supply update supply count
export const supplyAddedToClaimEpic = ( action$ ) => {
   return action$.pipe(
      ofType( `claims/UPDATE_SUPPLIES` ),
      mergeMap( action => {
         return of( incrementSuppliesCount() )
      })
   )
}

// ********* BOILERPLATE *********

// export const Epic = ( action$ ) => {
//    return actions$.pipe(
//       ofType('/'),
//       mergeMap(action => {
//          return of( /* imported action */)
//       })
//    )
// }