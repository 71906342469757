import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { supplyAddedToClaimEpic } from 'modules/claims/epics/supplyAddedToClaimEpic'

import addressValidator from 'modules/addressValidator/reducer'
import auth from 'modules/auth/reducer'
import claims from 'modules/claims/reducer'
import uroCuratedOfficial from 'modules/uroCuratedOfficial/reducer'
import doctor from 'modules/doctorBlock/reducer'

export const rootEpic = combineEpics(
   supplyAddedToClaimEpic
)

export default combineReducers({
   claims,
   doctor,
   auth,
   addressValidator,
   uroCuratedOfficial
})
