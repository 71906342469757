import { PRODUCTION_CUSTOM_ANALYTIC_DISPATCH_ENDPOINT } from './../constants'

// postData schema
// {
//    @domain: varchar(255)
//    @patient_hash varchar(255)
//    @session_id varchar(500)
//    @page_entry datetime
//    @page varchar(500)
// }

export const postSessionAnalytic = ( postData ) => {
   if ( process.env.NODE_ENV === `production` ){
      const blob = new Blob( [ JSON.stringify( postData ) ] )

      return navigator.sendBeacon(
         PRODUCTION_CUSTOM_ANALYTIC_DISPATCH_ENDPOINT,
         blob
      )
   } else {
      return false
   }

}
