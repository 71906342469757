import React from 'react'
import PropTypes from 'prop-types'
import { USA_STATES } from 'constants/geoKeys'
import styled from 'styled-components'

const StyledLabel = styled.label`
   margin-left: 2px;
   padding-left: 0px;
   margin-right: 10px;
`

const StyledInput = styled.input`
  height: 20px;
  width: 80px;
`

const StyledCheckboxInput = styled.input`
  width: 20px;
`
/* eslint-disable react/no-array-index-key */

export const SelectDoctorState = ( props ) => {
   const { value, field, label, updateController } = props

   const handleChange = ( e ) => {
      updateController( e.target.value, field )
   }

   return (
      <div>
         <select value={value} onChange={handleChange}>
            <option
               value={``}
            >
               {label}
            </option>
            {USA_STATES.map( ( stateObj, i ) => {
               return (
                  <option
                     key={i}
                     value={stateObj.abbr}
                  >
                     {stateObj.name}
                  </option>
               )
            })}
         </select>
      </div>

   )
}

SelectDoctorState.propTypes = {
   value: PropTypes.string,
   field: PropTypes.string,
   updateController: PropTypes.func.isRequired,
   label: PropTypes.string
}

export const AdminCheckbox = ( props ) => {
   const { value, field, label, updateController } = props

   const handleChange = ( /* e */ ) => {
      updateController( !value, field )
   }

   return (
      <div>
         <StyledCheckboxInput
            type="checkbox" checked={value}
            onChange={handleChange}
            name={field}
         />
         <StyledLabel>{label}</StyledLabel>
      </div>
   )
}

AdminCheckbox.propTypes = {
   value: PropTypes.bool.isRequired,
   field: PropTypes.string,
   updateController: PropTypes.func.isRequired,
   label: PropTypes.string
}

export const AdminTypeaheadResultsLimit = ( props ) => {
   const { value, updateController, label, field } = props

   const handleChange = ( e ) => {
      updateController( e.target.value, field )
   }

   return (
      <div>
         <div>
            <StyledInput
               type="number"
               max={200} min={0}
               name={field}
               value={value}
               onChange={handleChange}
            />

            <StyledLabel>{label}</StyledLabel>
         </div>
      </div>
   )
}

AdminTypeaheadResultsLimit.propTypes = {
   value: PropTypes.number,
   field: PropTypes.string,
   updateController: PropTypes.func.isRequired,
   label: PropTypes.string
}