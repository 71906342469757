import styled from 'styled-components'

// @NOTE there is a media query plugin in SuppliesList class that sets mobile or desktop wrapper at 600px breakpoint
// TODO - this needs to be put into a tailwind class or component
export const SuppliesListDesktopComponentWrapper = styled.div`
   @media (min-width: 600px) {
      display: grid;
      max-width: 768px;
      margin: 0 auto;
      align-items: center;
      grid-template-columns: 14fr 3fr 5fr 4fr;
      background-color: rgba(0,0,0, 0.1);
      grid-gap: 1px;
      .product{
         border: none;
         margin-top: 0;
         b{
            width: 100%;
         }
      }
      .shipping{
         border: none;
      }
      .item{
         justify-content: center;
      }
      .heading-item-a {
         padding: 10px 0;
      }
      .heading-item-b{
         padding: 10px 0;
         justify-self: stretch;
      }
      .heading-item-c{
         padding: 10px 0;
         justify-self: stretch;
      }
      .cell{
         background-color: white;
         align-self: stretch;
         padding: 15px;
         margin-bottom: 0;
         display: flex;
         border: none;
         span{
            margin: auto;
         }
      }
      .product{
         grid-template-columns: 7fr 2fr 2fr 3fr;
      }
      .product b > label > span.MuiTypography-root{
         font-weight: 600
      }
   }
`

export const SuppliesListMobileComponentWrapper = styled.div`
   max-width: 480px;
   border-sizing: border-box;
   margin: 0 auto;
   border-top: 1px solid grey;
   .cell{
      display: flex;
      padding: 0;
      justify-content: right;
      margin-bottom: 10px;
   }
   .product{
      justify-content: left;
      padding-top: 25px;
   }
   .eligible{
      margin: 10px 10px 10px 30px;
   }
   .quantity{
      margin: 10px 10px 10px 30px;
   }
   .shipping{
      margin: 10px 10px 10px 30px;
      border-bottom: 1px solid grey;
      padding-bottom: 25px;
   }
   .shipping-field, .eligibility-field, quantity-field{
      margin-right: 10px;
   }
   .product b > label > span.MuiTypography-root{
      font-weight: 600
   }
`