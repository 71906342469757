import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const AllMedicareReasonsNegativeModal = ( props ) => {
   const { open, closeModal } = props

   return (
      <Dialog open={open} onBackdropClick={closeModal}>
         <MuiDialogTitle id="customized-dialog-title">
            <p className="text-teal text-xl">{`Warning`}</p>
         </MuiDialogTitle>
         <MuiDialogContent dividers>
            <p className="text-black">
               {`Your answers indicate you are not in need of any of these items. None of these items will be shipped.`}
            </p>
         </MuiDialogContent>
         <MuiDialogActions>
            <Button onClick={closeModal} color="primary">
               {`Close`}
            </Button>
         </MuiDialogActions>
      </Dialog>
   )
}

AllMedicareReasonsNegativeModal.propTypes = {
   open: PropTypes.bool.isRequired,
   closeModal: PropTypes.func.isRequired
}

export default AllMedicareReasonsNegativeModal