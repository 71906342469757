import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { BlankCellFiller } from './components'
import QuantityOptionsSelect from './../QuantityOptionsSelect'

// @NOTE if !allowQuantityChanges this field should render as blank in its grid area
// -- we will need to implement the same logic used on confirm page to not display quantity on
export const QuantityCell = ( props ) => {

   const {
      allowQuantityChanges,
      quantitySelected,
      itemKey,
      quantityOptions,
      mobileItem
   } = props

   if ( allowQuantityChanges ) {

      return (
         <div className="flex flex-row items-center justify-center">
            {mobileItem && <span className="quantity-field mr-2">{`Quantity: `}</span>}
            <span>
               <FormControl>
                  <QuantityOptionsSelect
                     quantitySelected={quantitySelected}
                     itemKey={itemKey}
                     quantityOptions={quantityOptions}
                  />
               </FormControl>
            </span >
         </div>
      )
   }

   if ( mobileItem && !allowQuantityChanges ){
      return null
   }

   return <BlankCellFiller />
}

QuantityCell.propTypes = {
   mobileItem: PropTypes.bool,
   eligible: PropTypes.bool,
   allowQuantityChanges: PropTypes.bool,
   quantitySelected: PropTypes.number,
   itemKey: PropTypes.string,
   quantityOptions: PropTypes.array
}

export default QuantityCell