import React from 'react'
import PropTypes from 'prop-types'

const DOBErrorBlock = ( props ) => {
   const { errors } = props

   return (
      <div className="errors">
         <p className="error">
            {errors.hasOwnProperty( `month` ) ? errors.month : null}
         </p>
         <p className="error">
            {errors.hasOwnProperty( `day` ) ? errors.day : null}
         </p>
         <p className="error">
            {errors.hasOwnProperty( `year` ) ? errors.year : null}
         </p>
      </div>
   )
}

DOBErrorBlock.propTypes = {
   errors: PropTypes.object
}

export default DOBErrorBlock