import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextInputHOC from 'components/Forms/components/TextInputHOC'
import { connect } from 'react-redux'
import { validateField } from 'components/Forms/ContactShippingSplitForm/validateFieldHOC'
import { Modal, ModalActionsBox } from 'components/Modal'
import AddressValidationBox from 'modules/addressValidator/AddressValidationBox'
import { SelectInput } from 'components/Forms/components'
import { USA_STATES } from 'constants/geoKeys'
import { regexErrors } from 'components/Forms/constants'
import { updateUserAddress } from 'modules/addressConfirmation/fetch'
import { setAddressVerificationLoading, setVerifiedAddressConfirmed } from 'modules/addressValidator/actions'
import { setUser } from 'modules/auth/actions'

const EditAddress = props => {
   const {
      open,
      user,
      closeModal,
      displayAddressVerificationBox,
      addressVerificationLoading,
      handleUserConfirmedAddress,
      setAddressVerificationLoading,
      handleSetUser,
      setAddressConfirmed
   } = props

   const [ updatedUserAddress, setUpdatedUserAddress ] = useState({})
   const [ formFieldErrors, setFormFieldErrors ] = useState({})
   const [ formError, setFormError ] = useState( `` )

   const { state, zip, city, address1 } = updatedUserAddress

   const changeFieldValToFormatted = ( key, formattedVal ) => {

      return setUpdatedUserAddress( Object.assign({}, updatedUserAddress, {
         [key]: formattedVal
      }) )
   }

   const addError = ( name, msg ) => {

      return setFormFieldErrors( Object.assign({}, formFieldErrors, {
         [name]: msg
      }) )
   }

   const requiredValidation = ( val, name ) => {
      if ( val === `State` && name === `state` ) {
         return setFormFieldErrors( Object.assign({}, formFieldErrors, {
            [name]: `* Required`
         }) )
      }

      const _val = val.trim()

      if ( !_val ){
         return setFormFieldErrors( Object.assign({}, formFieldErrors, {
            [name]: `* Required`
         }) )
      }
   }

   const errorChecker = ( name ) => {
      setFormError( `` )
      if ( formFieldErrors[name] ) {
         const newErrors = Object.assign({}, formFieldErrors )
         delete newErrors[name]
         setFormFieldErrors( newErrors )
      }
   }

   const handleInputChange = ( e ) => {

      const { target: { name, value } } = e

      errorChecker( name )

      if ( name === `address1` && !value.match( /^[0-9a-zA-Z- .]+$/ ) ){
         addError( name, regexErrors.address )
      }

      if ( name === `city` && !value.match( /^[a-zA-Z- .]+$/ ) ){
         addError( name, regexErrors.city )
      }


      const newAddress = Object.assign({}, updatedUserAddress, {
         [name]: value
      })

      return setUpdatedUserAddress( newAddress )
   }

   const handleUpdateUserAddress = ( ) => {
      setAddressVerificationLoading( true )
      const { email, patient_id, phone, patient_dob, unique_hash } = user

      const userCredentials = {
         dob: patient_dob,
         phone,
         email,
         patientID: patient_id,
         street: address1,
         address2: ``,
         city,
         state,
         zip_code: zip,
         unique_hash
      }

      updateUserAddress( userCredentials ).then( data => {
         if ( data.status === `success` ) {

            let newUser

            newUser = Object.assign({}, user, {
               street: userCredentials.street,
               state: userCredentials.state,
               city : userCredentials.city,
               zip_code: userCredentials.zip_code
            })

            handleSetUser( Object.assign({}, user, newUser ) )
            handleUserConfirmedAddress( newUser )
            setAddressConfirmed( true )

            setAddressVerificationLoading( false )
         }
      })
   }

   const handleRequired = ( e ) => {

      return requiredValidation( e.target.value, e.target.name )
   }

   const handleValidation = ( e ) => {

      return validateField( e, changeFieldValToFormatted, addError )
   }

   const hasRequiredFields = state && city && address1 && zip

   const handleClickWithErrors = ( errStr ) => {

      return setFormError( errStr )
   }

   return (
      <Modal
         title={`Address Verification`}
         subHeading={`Please indicate the address where you would like your supplies shipped`}
         subHeadingClassname="text-center font-light text-xl text-teal w-8/12 mx-auto"
         isOpen={open}
         handleModalClose={closeModal}
      >
         {!displayAddressVerificationBox ?
            <>
               <TextInputHOC
                  id="address1"
                  type="text"
                  handleBlur={handleRequired}
                  name={`address1`}
                  placeholder={`Street`}
                  handleChange={handleInputChange}
                  label="Street"
                  error={formFieldErrors.address1}
                  value={updatedUserAddress.address1}
               />
               <TextInputHOC
                  id="city"
                  type="text"
                  handleBlur={handleRequired}
                  name={`city`}
                  placeholder={`City`}
                  handleChange={handleInputChange}
                  label="City"
                  error={formFieldErrors.city}
                  value={updatedUserAddress.city}
               />
               <div className="pt-4 pb-1 pl-4">
                  <SelectInput
                     id="state"
                     type="text"
                     onBlur={handleRequired}
                     name={`state`}
                     placeholder={`State`}
                     onChange={handleInputChange}
                     label="State"
                     value={updatedUserAddress.state}
                     error={formFieldErrors.state}
                     required
                     className="curated-input p-2 w-full text-base text-black"
                  >
                     <option
                        value={`State`}
                     >
                        {`State`}
                     </option>
                     {USA_STATES.map( _state => {
                        const { abbr, name } = _state

                        return (
                           <option key={abbr} value={abbr}>{name}</option>
                        )
                     })}
                  </SelectInput>
               </div>
               <TextInputHOC
                  id="zip"
                  name={`zip`}
                  placeholder={`Zip Code`}
                  handleChange={handleInputChange}
                  label="Zip"
                  handleBlur={handleValidation}
                  value={updatedUserAddress.zip}
                  error={formFieldErrors.zip}
                  inputType="number"
               />
               {formError && <p className="text-center error mt-4">{formError}</p>}
            </>
            :
            <AddressValidationBox />
         }
         <ModalActionsBox
            source="edit-address"
            loading={addressVerificationLoading}
            displayAddressVerificationBox={displayAddressVerificationBox}
            handleVerifyAddress={handleUpdateUserAddress}
            displayMessage={`You updated your address.`}
            hasErrors={Object.keys( formFieldErrors ).length}
            hasRequiredFields={hasRequiredFields}
            closeModal={closeModal}
            handleClickWithErrors={handleClickWithErrors}
            handleUserConfirmedAddress={handleUserConfirmedAddress}
         />
      </Modal>
   )
}

export const mapStateToProps = state => {
   return {
      displayAddressVerificationBox: state.addressValidator.displayAddressVerificationBox,
      addressVerificationLoading: state.addressValidator.addressVerificationLoading
   }
}

const mapDispatchToProps = dispatch => {
   return {
      setAddressVerificationLoading: ( bool ) => {
         return dispatch( setAddressVerificationLoading( bool ) )
      },
      handleSetUser: ( user ) => {
         return dispatch( setUser( user ) )
      },
      setAddressConfirmed: ( bool ) => {
         return dispatch( setVerifiedAddressConfirmed( bool ) )
      }
   }
}

EditAddress.propTypes = {
   displayAddressVerificationBox: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   handleAddressUpdate: PropTypes.func.isRequired,
   verifyAddress: PropTypes.func,
   addressVerificationLoading: PropTypes.bool,
   closeModal: PropTypes.func.isRequired,
   handleUserConfirmedAddress: PropTypes.func,
   setAddressVerificationLoading: PropTypes.func,
   handleSetUser: PropTypes.func,
   setAddressConfirmed: PropTypes.func
}

export default connect( mapStateToProps, mapDispatchToProps )( EditAddress )