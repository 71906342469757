// state: addressVerificationLoading, addressVerified, addressVerificationData
import update from 'immutability-helper'

const INITIAL_STATE = {
   addressVerificationLoading: false,
   addressVerified: `unverified`,
   verifiedAddressConfirmed: false,
   addressVerificationData: {},
   displayAddressVerificationBox: false
}

export const addressValidator = ( state = INITIAL_STATE, action ) => {
   switch ( action.type ) {

   case `addressValidator/RESET_ADDRESS_VERIFICATION`: {
      return update( state,
         {
            addressVerified: {
               $set: `unverified`
            },
            addressVerificationData: {
               $set: {}
            }
         }
      )
   }
   case `addressValidator/ADDRESS_VERIFICATION_LOADING`: {
      return update( state,
         {
            addressVerificationLoading: {
               $set: action.bool
            }
         }
      )
   }
   case `addressValidator/VERIFIED_ADDRESS_CONFIRMED`: {
      return update( state,
         {
            verifiedAddressConfirmed: {
               $set: action.bool
            }
         }
      )
   }
   case `addressValidator/DISPLAY_ADDRESS_VERIFICATION_BOX`: {
      return update( state,
         {
            displayAddressVerificationBox: {
               $set: action.bool
            }
         }
      )
   }
   case `addressValidator/ADDRESS_VERIFIED`: {

      return update( state,
         {
            addressVerified: {
               $set: action.status
            }
         }
      )
   }
   case `addressValidator/ADDRESS_VERIFICATION_DATA`: {
      return update( state,
         {
            addressVerificationData: {
               $set: action.data
            }
         }
      )
   }
   default: {

      return state
   }
   }
}

export default addressValidator