import React, { useState, useEffect } from 'react'
import { asField } from 'informed'
import PropTypes from 'prop-types'
import InputDateFieldgroup from './InputDateFieldgroup'

export const InputDateResponsive = ({ fieldApi: { setValue }, ...props }) => {

   const [ date, setDate ] = useState( `` )
   const [ error, setError ] = useState({})

   useEffect( () => {
      setValue( date )

   }, [ date ] )

   const handleSetDate = ( newDate ) => {

      return setDate( newDate )
   }

   const handleSetError = ( newError ) => {

      return setError( newError )
   }

   return (
      <React.Fragment>
         <InputDateFieldgroup
            onDateCompletion={handleSetDate}
            handleError={handleSetError}
         />

         {Object.keys( error ).length > 0 && <div className="errors">

            {Object.keys( error ).map( key => {
               return (
                  <p key={key} className="error">
                     {error[ key ]}
                  </p>
               )
            })}

         </div>}
      </React.Fragment>
   )
}

InputDateResponsive.propTypes = {
   fieldState: PropTypes.shape({
      value: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] )
   }),
   fieldApi: PropTypes.shape({
      setTouched: PropTypes.func.isRequired,
      setValue: PropTypes.func.isRequired,
      getTouched: PropTypes.func.isRequired
   }),
   field: PropTypes.string.isRequired
}

export default asField( InputDateResponsive )