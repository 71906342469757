import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import UpdateIcon from '@material-ui/icons/Update'
import Checkbox from '@material-ui/core/Checkbox'
import { productOrderNameSizeQuantity } from 'modules/claims/utils.js'
import { connect } from 'react-redux'

export const IneligibleProductNameCell = ( props ) => {
   const { name } = props

   return (
      <b>
         <FormControlLabel
            control={
               <Checkbox
                  icon={<UpdateIcon />}
                  disableRipple
                  checked={false}
                  readOnly
               />
            }
            label={name}
         />
      </b>
   )
}

IneligibleProductNameCell.propTypes = {
   name: PropTypes.string
}

const EligibleProductNameCell = ( props ) => {

   const {
      handleSetAlert,
      toggleClaimItemCheckbox,
      item: {
         hcpcid,
         itemSelected,
         force,
         description,
         quantitySelected,
         size
      }
   } = props

   return (
      <b>
         <FormControlLabel
            control={
               <Checkbox
                  checked={itemSelected}
                  onChange={() => {
                     if ( !force ) {
                        return toggleClaimItemCheckbox()
                     }
                     if ( force ) {
                        handleSetAlert( true )

                        return null
                     }
                  }}
                  value={hcpcid}
               />}
            label={productOrderNameSizeQuantity( description, size, quantitySelected )}
         />
      </b>
   )
}


EligibleProductNameCell.propTypes = {
   handleSetAlert: PropTypes.func.isRequired,
   toggleClaimItemCheckbox: PropTypes.func.isRequired,
   item: PropTypes.shape({
      hcpcid: PropTypes.number,
      itemSelected: PropTypes.bool,
      force: PropTypes.string,
      description: PropTypes.string,
      quantitySelected: PropTypes.number,
      size: PropTypes.string
   })
}

export default connect( null )( EligibleProductNameCell )