export const SET_USER = `auth/SET_USER`
export const AUTHENTICATE_USER = `auth/AUTHENTICATE_USER`
export const SET_DISPLAY_FAILED_LOGIN = `auth/SET_DISPLAY_FAILED_LOGIN`
export const UPDATE_USER = `auth/UPDATE_USER`
export const SET_DISPLAY_MESSAGE = `auth/SET_DISPLAY_MESSAGE`
export const SET_LOGIN_ERROR = `auth/SET_LOGIN_ERROR`
export const RESET_AUTH = `auth/RESET_AUTH`
export const SET_PERSIST_LOGIN = `auth/SET_PERSIST_LOGIN`
export const SET_SESSION_LOADING = `auth/SET_SESSION_LOADING`
export const SET_USER_SUBMITTED_DOCTOR = `auth/SET_USER_SUBMITTED_DOCTOR`
export const SET_USER_AUTHORIZE_P_CUSTOMER_TOKEN = `auth/SET_USER_AUTHORIZE_P_CUSTOMER_TOKEN`
export const SET_USER_AUTHORIZE_P_METHODS = `auth/SET_USER_AUTHORIZE_P_METHODS`
export const SET_ORDER_HISTORY = `auth/SET_ORDER_HISTORY`
export const SET_ORDER_HISTORY_ERROR = `auth/SET_ORDER_HISTORY_ERROR`