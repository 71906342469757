import React from 'react'

export const TermsAndConditions = ( props ) => {
   const h3Style = `text-teal text-2xl text-center`

   return (
      <React.Fragment>

         <h3 className={h3Style}>{`AUTHORIZATION/CONSENT FOR CARE/SERVICE:`}</h3>

         <p>{`I have been informed of the home care options available to me and of the selection of providers from which I may choose. I authorize Aeroflow under the direction of the prescribing physician, to provide home medical equipment, supplies and services as prescribed by my physician or authorized by my insurance provider.`}</p>

         <h3 className={h3Style}>{`ASSIGNMENT OF BENEFITS/AUTHORIZATION FOR PAYMENT:`}</h3>
         <p>{`I hereby assign all benefits and payments to be made directly to Aeroflow, Inc. for any home medical equipment, supplies and services furnished to me in conjunction with my home care. I authorize Aeroflow to seek such benefits and payments on my behalf. It is understood that, as a courtesy, Aeroflow will bill payers and insurer(s) providing coverage, with a copy to Aeroflow. I understand that I am responsible for providing all necessary information and for making sure all certification and enrollment requirements are fulfilled. Any changes in the policy must be reported to Aeroflow within 10 days of the event.`}</p>

         <h3 className={h3Style}>{`RELEASE OF INFORMATION:`}</h3>
         <p>{`I hereby request and authorize Aeroflow, the prescribing physician, hospital, and any other holder of information relevant to service, to release information upon request, to Aeroflow, any payer source, physician, or any other medical personnel or agency involved with service. I also authorize Aeroflow to review medical history and payer information for the purpose of providing home health care.`}</p>

         <p>{`If Aeroflow is not currently in-network with my medical insurance carrier, I authorize Aeroflow to contact my insurance on my behalf to request that Aeroflow be allowed in the network so I can utilize their services under my in-network benefits.`}</p>

         <h3 className={h3Style}>{`FINANCIAL RESPONSIBILITY:`}</h3>
         <p>{`I understand and agree that I am responsible for the payment if any and all sums that may become due for the services provided. These sums include, but are not limited to, all deductibles, co-payments, out-of-pocket requirements, and non-covered services. If for any reason and to any extent, Aeroflow does not receive payment from my payer source, I hereby agree to pay Aeroflow for the balance in full, within 30 days of receipt of invoice. All charges not paid within 30 days of billing date shall be assessed late charges. I am liable for all charges, including collection costs and all attorneys cost. I am responsible for all charges regardless of my payer unless my agreement with my health plan holds me harmless.`}</p>

         <h3 className={h3Style}>{`RETURNED GOODS:`}</h3>
         <p>{`Due to Federal and State Pharmacy Regulations ancillary items prescribed for home health care cannot be re-dispensed and cannot be returned for credit. Sale items cannot be returned.`}</p>

         <h3 className={h3Style}>{`CONSENT:`}</h3>
         <p>{`By signing and submitting this form, I consent to receive phone calls, e-mails, texts, and pre-recorded messages from Aeroflow Inc. or one of our relevant partners regarding our products and services, at the phone number(s) or email address provided; including my wireless number if provided I understand these calls may be generated using an automated technology.`}</p>

         <h3 className={h3Style}>{`GRIEVANCE REPORTING:`}</h3>
         <p>{`I acknowledge that I have been informed of the procedure to report a grievance should I become dissatisfied with any portion of my home care experience. I understand that I may lodge a complaint without concern for reprisal, discrimination, or unreasonable interruption of service. To place a grievance, please call 888-345-1780 and speak to the Customer Services Supervisor. If your complaint is not resolved to your satisfaction within 5 working days, you may initiate a formal grievance, in writing and forward it to the Board of Directors. You can expect a written response within 7 working days of receipt.`}</p>

         <h3 className={h3Style}>{`ABOUT FINANCIAL ARRANGEMENTS AND HEALTH INSURANCE:`}</h3>
         <p>{`We are committed to providing you with the best possible care. If you have medical insurance, we are committed to helping you receive your maximum allowed benefits. In order to achieve these goals, we need your assistance and your understanding of our payment policy. Payment for services is due at the time services are rendered unless payment arrangements have been approved in advance by our staff. We accept cash, checks, & most major credit cards. We will be happy to help you process your insurance claims for reimbursement of the services. Balances older than 90 days may be subject to additional collection fees and interest charges of 1.5 % per month. We must emphasize that, as healthcare providers, our relationship is with you, not your insurance company. While the filing of the insurance claims is a courtesy that we extend to our patients, all charges are your responsibility from the date the services are rendered. We realize that temporary financial problems may affect timely payment to your account. If such problems do arise, we encourage you to contact us promptly for assistance in the management of your account. If you have any questions about the above information or any uncertainty regarding your insurance coverage, PLEASE don’t hesitate to ask us. We are here to help you.`}</p>

         <p>{`I am the patient or the patient’s authorized representative and agree to the terms and conditions contained in this form and any other documentation provided by Aeroflow Healthcare (collectively, this “Agreement”), including the following:`}</p>

         <p>{`I have been informed by Aeroflow of the medical necessity for the services prescribed by my physician or authorized by my insurance provider. I understand that in the event services are deemed not reasonable and necessary, payment may be denied and that I will be fully responsible for payment.`}</p>
      </React.Fragment>
   )
}

export default TermsAndConditions