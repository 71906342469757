import React from 'react'
import { Formatters } from '../utils/utils'
import PropTypes from 'prop-types'

function DoctorOption({ doctorResult, selectDoctor }) {

   if ( !doctorResult.first_name || !doctorResult.last_name || !doctorResult.addresses?.length ) return null

   return (
      <div className="flex text-left capitalize justify-between">
         <div className="flex flex-col gap-1 w-7/12">
            <p className="font-bold">{`${doctorResult.first_name?.toLowerCase()} ${doctorResult.last_name?.toLowerCase()}`}</p>
            <div>
               <p>{doctorResult.addresses[0].first_line?.toLowerCase()}</p>
               { doctorResult.addresses[0]?.second_line && <p>{doctorResult.addresses[0].second_line?.toLowerCase()}</p>}
               <p>{`${doctorResult.addresses[0].city.toLowerCase()}, ${doctorResult.addresses[0].state} ${doctorResult.addresses[0].postal_code?.slice( 0, 5 )?.toLowerCase()}`}</p>
               <p>{Formatters.phone( doctorResult.telephone_number )}</p>
            </div>
         </div>
         <div>
            <button
               className="sleep-btn bg-teal w-fit"
               onClick={() => { selectDoctor( doctorResult ) }}
            >
               {`Select`}
            </button>
         </div>
      </div>
   )
}

DoctorOption.propTypes = {
   selectDoctor: PropTypes.func,
   doctorResult: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      telephone_number: PropTypes.string,
      addresses: PropTypes.arrayOf( PropTypes.shape({
         first_line: PropTypes.string,
         second_line: PropTypes.string,
         city: PropTypes.string,
         state: PropTypes.string,
         postal_code: PropTypes.string
      }) )
   })
}

export default DoctorOption