import React from 'react'
import PropTypes from 'prop-types'
import { medChatApiKeys, MED_CHAT_BASE_URL } from 'constants/urls'
import { withRouter } from 'react-router-dom'

/* eslint-disable no-console */
// need to do this tom-foolery dom stuff b/c of a positioning conflict with medchat and material ui modal
// @NOTE if this isn't working the first query selector param below has likely changed :()

// Not sure if this is worthwhile to implement
const setMedchatLoc = setInterval( function() {
   const medChat = document.querySelectorAll( `#medchat-launcher-frame` )
   if ( medChat.length ) {
      medChat.forEach( frame => {
         frame.style=`right: calc(-48%); max-width: initial;`
      })
      clearInterval( setMedchatLoc )
   }
}, 500 )

export const setMedChatDisplay = ( bool ) => {
   try {
      const medChatDom = document.querySelector( `#medchat-launcher-frame` )

      if ( bool ){

         return medChatDom.style = `display: block; height: 92px;`
      }

      if ( !bool ){

         return medChatDom.style = `display: none;`
      }
   } catch ( err ){
      console.log( `MedChat disabled` )
   }
}

export const initMedChat = ( apiKey ) => {

   if ( !document.querySelector( `#med-chat-urology-script-tag` ) ){
      const target = document.querySelector( `head` )
      let newScript = document.createElement( `script` )
      newScript.async = 1

      newScript.src = `${MED_CHAT_BASE_URL}${apiKey}`
      newScript.id = `med-chat-urology-script-tag`

      try {
         target.appendChild( newScript )
      } catch ( err ){
         console.log( `medchat error: `, err )
      }

   }
}

export const terminateMedChat = () => {
   // make sure the tag we added in init is present
   const medChatTag = document.querySelector( `#med-chat-urology-script-tag` )

   // make sure the tag we added in init is present
   if ( medChatTag ){
      // this iframe is generated from the init script loading - currently it's id value is static
      const medChatFrame = document.querySelector( `#medchat-chat-frame` )

      // remove script tag we added
      medChatTag.parentNode.removeChild( medChatTag )
      // remove iframe to update ui
      medChatFrame?.parentNode?.removeChild( medChatFrame )

      const linkTags = document.getElementsByTagName( `link` )

      // to remove the generated medchat css - which has a unique id per version - we need to inspect the href of link tags
      for ( let i = 0; i < linkTags.length; i++ ) {
         let tag = linkTags[i]
         // general url pattern https://medchatapp.com/widget/widget.0ca3f8a8bcf72dd5f079.css
         if ( tag.href && tag.href.indexOf( `medchatapp.com/widget/` ) > -1 ) {
            tag.parentNode.removeChild( tag )
         }
      }

      // the script auto generates some window object as well, sigh…
      delete window.MedChat
      delete window.MedChatApp
      delete window.medchatapp

   }
}

export class MedChatController extends React.Component {
   constructor( props ){
      super( props )
   }
   componentDidMount(){
      if ( this.props.location.pathname.indexOf( `urology` ) > -1 || this.props.location.pathname.indexOf( `opt-out` ) > -1 ){
         return initMedChat( medChatApiKeys.urology )
      }
   }
   componentDidUpdate( prevProps ){

      if ( prevProps.location.pathname !== this.props.location.pathname ){

         return this.handlePortalChange( prevProps.location.pathname, this.props.location.pathname )
      }
   }
   handlePortalChange = ( prevPathname, thisPathname ) => {

      if ( prevPathname.indexOf( `urology` ) > -1 &&
         thisPathname.indexOf( `urology` ) === -1
      ) {
         return terminateMedChat()
      }

      if ( prevPathname.indexOf( `urology` ) === -1 &&
         thisPathname.indexOf( `urology` ) > -1
      ) {
         return initMedChat( medChatApiKeys.urology )
      }

      if ( prevPathname.indexOf( `opt-out` ) > -1 &&
      thisPathname.indexOf( `opt-out` ) === -1
      ) {
         return terminateMedChat()
      }

      if ( prevPathname.indexOf( `opt-out` ) === -1 &&
      thisPathname.indexOf( `opt-out` ) > -1
      ) {
         return initMedChat( medChatApiKeys.urology )
      }

   }
   componentWillUnmount(){

      return terminateMedChat()
   }
   render(){
      return null
   }
}

MedChatController.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string
   })
}

export default withRouter( MedChatController )