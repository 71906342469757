import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import urology_logo from 'resources/img/urology_logo.svg'
import sleep_logo from 'resources/img/sleep_logo.svg'
import { PortalLinkWrapper } from './components'
import AppShell from 'components/AppShell/index.js'
import { setDisplayMessage } from 'modules/auth/actions'

class Home extends Component {
   componentDidMount(){
      const msgKey = `logout_msg`
      if ( sessionStorage.getItem( msgKey ) !== null ){
         const msg = sessionStorage.getItem( msgKey )
         sessionStorage.removeItem( msgKey )

         return this.props.setDisplayMessage({
            type: `info`,
            msg: msg
         })
      }
   }
   render() {
      return (
         <AppShell>
            <div className="max-w-screen-sm justify-center">
               <div className="m-4">
                  <h2 className="page-heading text-center">
                     {`Welcome to Aeroflow Resupply!`}
                  </h2>
               </div>

               <div className="m-4">
                  <p className="text-center font-bold text-afBlue-secondary">
                     {`Please select your program below to get started.`}
                  </p>
               </div>

               <PortalLinkWrapper>
                  <Link to="/urology">
                     <img
                        className="max-w-full cursor-pointer"
                        src={urology_logo}
                        alt="Aeroflow Urology"
                     />
                  </Link>
               </PortalLinkWrapper>
               <PortalLinkWrapper>
                  <a href="https://aeroflowsleep.com/resupply/claim">
                     <img
                        className="max-w-full cursor-pointer"
                        src={sleep_logo}
                        alt="Aeroflow Sleep"
                     />
                  </a>
               </PortalLinkWrapper>
            </div>
         </AppShell>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      setDisplayMessage: ( obj ) => {
         return dispatch( setDisplayMessage( obj ) )
      }
   }
}

Home.propTypes = {
   setDisplayMessage: PropTypes.func.isRequired
}

export default connect( null, mapDispatchToProps )( Home )
