import Loader from 'components/Loader'
import PropTypes from 'prop-types'
import { RadioInput } from 'components/Forms/components'
import { nanoid } from 'nanoid'
import * as React from 'react'
import { status, json } from './api'
import { Modal } from 'components/Modal'
import { connect } from 'react-redux'
import LoginForm from 'modules/auth/LoginForm'
import { BASE_URL } from 'constants/urls'
import { reportToSentry } from 'utils/reportToSentry'

const getItems = ( store ) => {
   const suppliesKeys = Object.keys( store )

   return suppliesKeys.map( key => {
      return store[ key ]
   })
}

let CHECKLIST_SUBMIT_URL = `https://medicare-checklist.aeroflow.ninja`

if ( window.location.origin === BASE_URL ) {
   CHECKLIST_SUBMIT_URL = `https://medicare-checklist.aeroflowapi.org`
}

export function MedicareCheckListController({ user }) {
   const [ hash, setHash ] = React.useState( null )
   const [ uniqueHash, setUniqueHash ] = React.useState( null )
   const [ patientData, setPatientData ] = React.useState({
      patientId: ``,
      patientDob: ``,
      uniqueHash: ``
   })
   const [ loading, setLoading ] = React.useState( true )
   const [ items, setItems ] = React.useState( [] )
   const [ supplies, setSupplies ] = React.useState({})
   const [ modalOpen, setModalOpen ] = React.useState( false )
   const [ checklistTermsConfirmed, setChecklistTermsConfirmed ] = React.useState( false )
   const [ modalContent, setModalContent ] = React.useState({
      title: `Warning`,
      subHeading: ``,
      btnLabel: ``
   })
   const [ displayLoginForm, setDisplayLoginForm ] = React.useState( false )
   const [ checklistComplete, setChecklistComplete ] = React.useState( false )

   // we are looking for a hash in the query param and setting that hash if it exists or displaying the normal login form
   React.useEffect( () => {
      let mounted = true
      const cleanup = () => { return mounted = false }

      const searchParams = new URLSearchParams( window.location.search )
      const sgh = searchParams.get( `sgh` )
      const txt = searchParams.get( `txt` )

      const hash = sgh || txt

      if ( hash && mounted ) setHash( hash )

      if ( !hash ) setDisplayLoginForm( true )

      setLoading( false )

      return cleanup
   }, [] )

   // if there is a hash we are then trying to login with that hash
   React.useEffect( () => {
      const controller = new AbortController()
      const {signal} = controller
      const cleanup = () => { return controller.abort() }

      if ( hash ){

         fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/deprecated-login `, {
            signal:signal,
            method: `post`,
            headers: {
               'Content-Type': `application/json`
            },
            body: JSON.stringify({
               patient_hash: hash,
               profitCenterPK: 3
            })
         })
            .then( status )
            .then( json )
            .then( response => {
               const patientDob = response?.data?.dob
               const patientId = response?.data?.patient_id
               const uniqueHash = response?.data?.unique_hash

               if ( patientDob && patientId ){
                  setPatientData({
                     patientDob,
                     patientId,
                     uniqueHash
                  })
               } else {
                  setDisplayLoginForm( true )
               }

               return setLoading( false )
            })
            .catch( err => {
               if ( err.name === `AbortError` ) {
                  // eslint-disable-next-line no-console
                  console.log( `successfully aborted` )
               } else {
                  setDisplayLoginForm( true )
                  setLoading( false )
               }
            })
      }

      return cleanup
   }, [ hash ] )

   // here we are checking to see if there is a hash or not and if not then we set the unique hash
   React.useEffect( () => {
      let hasRun = false
      let mounted = true
      const cleanup = () => { return mounted = false }

      if ( !hash && !hasRun && mounted ) setUniqueHash( user.unique_hash )

      if ( user && !hasRun && mounted ) {
         setPatientData({
            patientId: user.patient_id,
            patientDob: user.dob,
            uniqueHash: user.unique_hash
         })
      }
      hasRun = true

      return cleanup
   }, [ user ] )

   // we are taking the patient dob an patient id and actually getting the checklist items
   React.useEffect( () => {
      const controller = new AbortController()
      const {signal} = controller
      const cleanup = () => { return controller.abort() }

      const { patientDob, patientId, uniqueHash } = patientData

      if ( patientDob && patientId ){
         fetch( `${process.env.REACT_APP_RESUPPLY_BASE_ENDPOINT}${patientId}&dob=${patientDob.split( `T` )[0]}&profitCenterId=3`, {
            signal:signal,
            headers: {
               Authorization: `PatientHash ${uniqueHash}`
            }
         })
            .then( status )
            .then( json )
            .then( response => {

               if ( response && response?.data?.items ) {
                  const itemsByKey = {}

                  response.data.items.forEach( item => {
                     const itemKey = nanoid()

                     item.itemKey = itemKey
                     item.value = `true` // we want the default to be checked yes
                     itemsByKey[itemKey] = item
                  })

                  setItems( getItems( itemsByKey ) )
                  setSupplies( itemsByKey )

                  if ( displayLoginForm ) setDisplayLoginForm( false )

                  return setLoading( false )
               }

               if ( hash || uniqueHash ) {
                  setLoading( false )
               } else {
                  setModalContent({
                     ...modalContent,
                     subHeading: `We are having difficulty locating your account.  Please try to log in again, or if you are still having issues call us at 844-276-5588`,
                     btnLabel: `Close`
                  })

                  setModalOpen( true )
               }
            })
            .catch( err => {
               if ( err.name === `AbortError` ) {
                  // eslint-disable-next-line no-console
                  console.log( `successfully aborted` )
               } else {
                  setDisplayLoginForm( true )
                  setLoading( false )
               }
            })
      }

      return cleanup

   }, [ patientData ] )

   const closeModal = () => {
      setModalOpen( !modalOpen )
   }

   const handleTermsConfirm = () => {
      setChecklistTermsConfirmed( !checklistTermsConfirmed )
   }

   const handleRadioChange = ( e, key ) => {
      if ( e.target.value === `false` ) {
         setModalContent({
            ...modalContent,
            subHeading: `By selecting ‘no’ you are confirming that you are NOT in need of this specific item.  This item will not be shipped to you.`,
            btnLabel: `I understand`
         })
         setModalOpen( true )
      }

      const newSupplies = Object.assign({}, supplies, {
         ...supplies,
         [key]: {
            ...supplies[key],
            value: e.target.value
         }
      }
      )

      setSupplies( newSupplies )

   }

   const handleSubmit = ( e ) => {
      e.preventDefault()
      setChecklistTermsConfirmed( false )
      const suppliesObjLength = Object.keys( supplies ).length

      let noAnswers = 0

      const suppliesArray = Object.entries( supplies )

      suppliesArray.filter( supply => {

         if ( supply[1].value === `false` ) {
            noAnswers++
         }

      })


      const suppliesKeys = Object.keys( supplies )

      const submitPayload = suppliesKeys.map( key => {
         if ( !hash ) {
            return Object.assign({}, {
               patient_hash: uniqueHash,
               medicare_checklist_hcpc: String( supplies[key].hcpc_id ),
               quality_question_response: true, // for urology pt's this question will always get stamped true
               quantity_question_response: supplies[key].value === `true`, // for urology pt's this question will be answered true or false
               storeID: 17
            })
         }

         return Object.assign({}, {
            patient_hash: hash,
            medicare_checklist_hcpc: String( supplies[key].hcpc_id ),
            quality_question_response: true, // for urology pt's this question will always get stamped true
            quantity_question_response: supplies[key].value === `true`// for urology pt's this question will be answered true or false
         })
      })

      if ( noAnswers === suppliesObjLength ) {
         setModalContent({
            ...modalContent,
            subHeading: `Your answers indicate you are not in need of any of these items. None of these items will be shipped.`,
            btnLabel: `Close`
         })

         setModalOpen( true )
      }

      submitChecklist( submitPayload )

   }

   const submitChecklist = ( payload ) => {

      const body = {
         checklists: payload
      }

      setLoading( true )
      fetch( `${CHECKLIST_SUBMIT_URL}/medicare-checklist`, {
         method: `post`,
         headers: {
            'Content-Type': `application/json`,
            'Authorization': `PatientHash ${hash}`
         },
         body: JSON.stringify(
            body
         )
      })
         .then( status )
         .then( json )
         .then( response => {
            setLoading( false )
            if ( response?.meta?.status === `OK` ) {
               return setChecklistComplete( true )
            }

            setModalContent({
               ...modalContent,
               subHeading: `Oops something went wrong please try again.`,
               btnLabel: `Close`
            })

            setModalOpen( true )

         })
         .catch( err => {
            setLoading( false )

            reportToSentry( new Error( `error submitting medicare checklist`, {
               cause:  err
            }) )

            setModalContent({
               ...modalContent,
               subHeading: `Oops something went wrong please try again.`,
               btnLabel: `Close`
            })

            setModalOpen( true )
         })
   }

   const complyText = `In order to comply with Medicare Guidelines Aeroflow Healthcare is required to verify that replacement of your Urology supplies is reasonable and necessary.`
   const thankYouText = `Thank you for completing the supplies checklist!  If you have any questions or concerns regarding your supplies please contact us directly to speak with your dedicated Continence Care Specialist.`
   const noItemsFoundText = `We are unable to locate a current reorder for you. Please call 844-276-5588 to discuss the supplies you are eligible for.`
   if ( loading ) return <Loader />
   if ( items.length === 0 && hash ) return <p className="mt-10 text-xl w-10/12">{noItemsFoundText}</p>

   return (
      <>
         {checklistComplete ?
            <p className="mt-10 text-xl w-10/12">{thankYouText}</p>
            :
            displayLoginForm ?
               <LoginForm hideRememberMe />
               :
               <form onSubmit={handleSubmit} className="w-11/12 sm:w-420 mt-10">
                  <div className="text-center">
                     <h2 className="page-heading mb-4">
                        {`Get your supplies quickly`}
                     </h2>
                     <p className="text-afBlue-dark text-lg mb-4">
                        <span>
                           {complyText}
                        </span>
                     </p>
                  </div>
                  {items.map( item => {

                     return (
                        <div key={item.itemKey} className="flex flex-col md:flex-row place-content-between items-center py-4 border-0 border-b border-solid border-gray-400">
                           <p className="font-bold">{`Is your supply of ${item.description} at or approaching zero?`}</p>
                           <div className="flex mt-4 md:mt-0">
                              <RadioInput
                                 label={`Yes`}
                                 name={item.name}
                                 value="true"
                                 id={item.name}
                                 checked={supplies[item.itemKey]?.value === `true`}
                                 onChange={( e ) => { return handleRadioChange( e, item.itemKey ) }}
                              />
                              <RadioInput
                                 label={`No`}
                                 name={item.name}
                                 value="false"
                                 id={item.name}
                                 checked={supplies[item.itemKey]?.value === `false`}
                                 onChange={( e ) => { return handleRadioChange( e, item.itemKey ) }}
                              />
                           </div>
                        </div>
                     )
                  })}
                  <div className="flex mt-4 justify-center">
                     <div>
                        <input
                           type="checkbox"
                           id="terms"
                           name="terms"
                           checked={checklistTermsConfirmed}
                           onChange={handleTermsConfirm}
                           className="mr-8"
                        />
                     </div>
                     <div>
                        <label htmlFor="terms">{`By checking this box I verify that the answers above have been answered truthfully.`}</label>
                     </div>
                  </div>
                  <div className="text-center">
                     <button className="mt-4 curated-btn cursor-pointer" type="submit" disabled={!checklistTermsConfirmed}>{`Submit`}</button>
                  </div>
               </form>

         }

         <Modal
            isOpen={modalOpen}
            handleModalClose={closeModal}
            requireBtnConfirm
         >
            <p className="text-xl text-black text-center mb-4">{modalContent.title}</p>
            <p className="mb-4">{modalContent.subHeading}</p>
            <div className="w-full text-center">
               <button className="curated-btn" onClick={closeModal}>{modalContent.btnLabel}</button>
            </div>
         </Modal>
      </>

   )
}

MedicareCheckListController.propTypes = {
   user: PropTypes.shape({
      patient_id: PropTypes.string,
      dob: PropTypes.string
   })
}

const mapStateToProps = ( state ) => {
   return {
      user: state.auth.user
   }
}

export default connect( mapStateToProps )( MedicareCheckListController )